import { FC } from 'react';
import styled from '@emotion/styled';

import {
  LogoBlue,
  LogoBlueBlack,
  LogoWhite,
  LogoWhiteBlue,
} from '@/components/Icons/Logo';
import { appColors } from '@/constants/colors';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  background-color: ${appColors.content.primary};
`;

const Logo: FC = () => {
  return (
    <Container>
      <LogoBlue />
      <LogoBlueBlack />
      <LogoWhite />
      <LogoWhiteBlue />
    </Container>
  );
};

export default Logo;
