import { css } from '@emotion/react';
import { CSSProperties, FC, memo, useMemo } from 'react';

import JobShowSmallDetail from './JobShowSmallDetail';
import { dateFormats, formatDate } from '@/utils/daysjs-config';

const postedJobIdContainerStyles = css`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

interface JobPostedIdProps {
  id: number;
  posted_at: string;
  containerStyles?: CSSProperties;
}

const JobPostedId: FC<JobPostedIdProps> = ({
  id,
  posted_at,
  containerStyles,
}) => {
  const formattedDate = useMemo(
    () => formatDate(posted_at, dateFormats.mmddyyyy),
    [posted_at]
  );

  return (
    <div
      {...{ css: postedJobIdContainerStyles, style: { ...containerStyles } }}
    >
      <JobShowSmallDetail {...{ text: `Posted ${formattedDate}` }} />
      <JobShowSmallDetail {...{ text: `Job ID: ${id}` }} />
    </div>
  );
};

export default memo(JobPostedId);
