import { FC, memo, useState } from 'react';
import { css } from '@emotion/react';
import { Menu, MenuItem, Button } from '@mui/material';
import { appColors } from '@/constants/colors';
import { BUTTON_COMMONS, buttonTextStyles } from '@/components/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import layout from '@/constants/layout';

const {
  components: { textIconStyles },
} = layout;

const pageSizes = [10, 25, 50, 100];

interface ButtonProps {
  label: string | number;
  pageSize: number[];
  onClick: (pageSize: number) => void;
}

const RowsPerPageButton: FC<ButtonProps> = ({ label, pageSize, onClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <>
      <Button
        {...{
          css: css`
            ${buttonStyles}
            ${open ? activeButtonStyles : inactiveButtonStyles}
          `,
        }}
        onClick={handleClick}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <div
          {...{
            css: textIconStyles,
          }}
        >
          {label} <KeyboardArrowDownIcon sx={iconStyles} />
        </div>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {pageSizes.map((size) => (
            <MenuItem key={size} onClick={() => onClick(size)}>
              {size}
            </MenuItem>
          ))}
        </Menu>
      </Button>
    </>
  );
};

export default memo(RowsPerPageButton);

const buttonStyles = css`
  ${buttonTextStyles}
  display: flex;
  user-select: none;
  align-items: center;
  height: ${BUTTON_COMMONS.HEIGHT}px;
  box-sizing: border-box;
  padding: 0px ${BUTTON_COMMONS.PADDING}px;
  border-radius: ${BUTTON_COMMONS.BORDER_RADIUS}px;
  border: ${BUTTON_COMMONS.BORDER_WIDTH}px solid transparent;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;
  &:hover {
    background-color: ${appColors.content.white};
  }
`;

const activeButtonStyles = css`
  background-color: ${appColors.notInDesignSystem[2]};
  color: ${appColors.content.white};
`;

const inactiveButtonStyles = css`
  background-color: ${appColors.content.white};
  color: ${appColors.content.tertiary};
  border-color: ${appColors.border.primary};
`;

const iconStyles = {
  fontSize: 35,
};
