import dayjs from 'dayjs';

const FORMAT = 'YYYY/MM/DD';

const dates = {
  last3days: {
    value: dayjs().subtract(3, 'days').format(FORMAT),
    label: 'Last 3 days',
  },
  last7days: {
    value: dayjs().subtract(7, 'days').format(FORMAT),
    label: 'Last 7 days',
  },
  last15days: {
    value: dayjs().subtract(15, 'days').format(FORMAT),
    label: 'Last 15 days',
  },
  last30days: {
    value: dayjs().subtract(30, 'days').format(FORMAT),
    label: 'Last 30 days',
  },
  last60days: {
    value: dayjs().subtract(60, 'days').format(FORMAT),
    label: 'Last 60 days / Anytime',
  },
  today: {
    value: dayjs().format(FORMAT),
    label: 'Today',
  },
};

const createShortID = (prefix?: string): string => {
  const now = dayjs();
  const formattedID = `${prefix || 'file'}-${now.format('MMDDYYYY-HHmmss')}`;
  return formattedID;
};

export { dates, createShortID };
