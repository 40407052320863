import { FC, memo, useCallback, useState } from 'react';
import Button, { ButtonProps } from '../Button';
import styled from '@emotion/styled';

import Modal from '../Modal';
import layout from '@/constants/layout';
import { PromptMessage } from '../ModalPrompt';
import { IconPlus } from '@/components/Icons/Plus';
import QuestionPrompt from '../ModalPrompt/QuestionPrompt';
import PostNewJobFlowModalContent from './Recruiter/PostNewJobFlow/PostNewJobFlowModalContent';

const {
  windowSizes: { mobile },
} = layout;

const SearchHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

interface PostNewJobButtonProps {
  buttonsProps?: ButtonProps;
}

const PostNewJobButton: FC<PostNewJobButtonProps> = ({ buttonsProps }) => {
  const [showModal, setShowModal] = useState(false);
  const [showQuestionModal, setShowQuestionModal] = useState(false);

  const openNewJobModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeNewJobModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onClickPostNew = useCallback(() => {
    openNewJobModal();
  }, []);

  const attemptToCloseModal = useCallback(() => {
    setShowQuestionModal(true);
  }, []);

  return (
    <>
      <SearchHeaderButtonsContainer>
        <Button
          {...{
            ...buttonsProps,
            onClick: onClickPostNew,
            children: 'Post New Job',
            icon: IconPlus,
          }}
        />
      </SearchHeaderButtonsContainer>
      {showModal && (
        <Modal {...{ position: 'top', onClose: attemptToCloseModal }}>
          <PostNewJobFlowModalContent
            {...{
              modalLayoutProps: { closeModal: closeNewJobModal },
              attemptToCloseModal,
            }}
          />
          {!!showQuestionModal && (
            <Modal
              {...{
                position: 'center',
                onClose: () => {
                  setShowQuestionModal(false);
                },
              }}
            >
              <QuestionPrompt
                {...{
                  headerProps: {
                    children: 'Are you sure?',
                  },
                  msgProps: [
                    <PromptMessage
                      {...{
                        children:
                          'If you exit now, all progress will be lost. Do you want to exit anyway?',
                      }}
                    />,
                  ],
                  buttonsProps: {
                    leftButton: {
                      variant: '2',
                      children: 'Back to Job',

                      onClick: () => {
                        setShowQuestionModal(false);
                      },
                    },
                    rightButton: {
                      variant: '1',
                      children: 'Yes, exit',

                      onClick: () => {
                        setShowQuestionModal(false);
                        closeNewJobModal();
                      },
                    },
                  },
                }}
              />
            </Modal>
          )}
        </Modal>
      )}
    </>
  );
};

export default memo(PostNewJobButton);
