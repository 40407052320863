import { css } from '@emotion/react';
import { CSSProperties, FC, memo } from 'react';

import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import JobShowDescriptionHeader from './JobShowDescriptionHeader';

const subheaderContentContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const contentContainerStyles = css`
  display: flex;
  overflow-x: hidden;
`;

interface JobDescDetailProps {
  header: string;
  detail: string;
  containerStyles?: CSSProperties;
}

const JobDescDetail: FC<JobDescDetailProps> = ({
  header,
  detail,
  containerStyles,
}) => {
  return (
    <div
      {...{
        css: subheaderContentContainerStyles,
        style: { ...containerStyles },
      }}
    >
      <JobShowDescriptionHeader {...{ text: header }} />
      <div {...{ css: contentContainerStyles }}>
        <Paragraph
          {...{
            children: detail,
            style: { color: appColors.content.secondary, fontWeight: 500 },
          }}
        />
      </div>
    </div>
  );
};

export default memo(JobDescDetail);
