import { FC, memo } from 'react';
import { css } from '@emotion/react';

import Card from '../Card';
import Heading from '../Typography/Heading';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

interface NoResultsFoundProps {
  header: string;
  subheader?: string;
  node?: any;
}

const contentStyles = css`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20vh 16px;
  gap: 16px;
`;

const NoResultsFound: FC<NoResultsFoundProps> = ({
  header,
  node: RenderedNode,
  subheader,
}) => {
  return (
    <Card {...{ css: contentStyles }}>
      <Heading
        {...{
          children: header,
          variant: 'h1Small',
          style: { fontSize: 20 },
        }}
      />
      {!!subheader && (
        <Paragraph
          {...{
            children: subheader,
            variant: 'md',
            style: { color: appColors.content.secondary, fontWeight: 500 },
          }}
        />
      )}
      {!!RenderedNode && <RenderedNode />}
    </Card>
  );
};

export default memo(NoResultsFound);
