import Axios from './axios-api';

import { CompanyAttributes } from '@/types/types';

export type PromiseCompany = {
  readonly avatar: string;
  readonly name: string;
  readonly url: string;
};

const COMPANIES = '/companies_for_signup';

const fetchCompanies = async (search: string) => {
  const axios = new Axios('');

  try {
    const response = await axios.get<CompanyAttributes[]>(COMPANIES, {
      params: {
        name: search,
      },
    });

    if (response.status === 200 && response.data.length > 0) {
      const companyData = response.data.map((item: CompanyAttributes) => ({
        ...item,
        value: item.name,
        label: item.name,
      }));
      return companyData;
    }

    return [
      {
        name: search,
        employer_type: '',
        logo_url: '',
        website: '',
        value: search,
        label: search,
      },
    ];
  } catch (error) {
    return Promise.reject(error);
  }
};

export { fetchCompanies };
