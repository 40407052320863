import { memo } from 'react';

import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import { dateFormats, formatDate } from '@/utils/daysjs-config';

const JobListTilePostedDate = ({ postedDate }: { postedDate: string }) => {
  const formattedDate = formatDate(postedDate, dateFormats.mmddyyyy);
  return (
    <Paragraph
      {...{
        children: `Posted ${formattedDate}`,
        variant: 'sm',
        style: {
          color: appColors.content.secondary,
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default memo(JobListTilePostedDate);
