import {
  FC,
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { css } from '@emotion/react';

import {
  FilterMenuApplyButton,
  FilterMenuHeader,
  FilterMenuResetText,
  FiltersMenuLayout,
} from './Filter/FiltersMenu';
import layout from '@/constants/layout';
import { IconFilter } from './Icons/Filter';
import { appColors } from '@/constants/colors';
import { BUTTON_COMMONS, buttonTextStyles } from './Button';

const FILTER_WIDTH = 350;

const {
  components: { iconStyles, textIconStyles },
} = layout;

const activeButtonStyles = css`
  background-color: ${appColors.notInDesignSystem[2]};
  color: ${appColors.content.white};
`;

const inactiveButtonStyles = css`
  background-color: ${appColors.content.white};
  color: ${appColors.content.tertiary};
  border-color: ${appColors.border.primary};
`;

const containerContainer = css`
  position: relative;
`;

const buttonContainer = css`
  ${buttonTextStyles}
  display: flex;
  user-select: none;
  align-items: center;
  height: ${BUTTON_COMMONS.HEIGHT}px;
  box-sizing: border-box;
  padding: 0px ${BUTTON_COMMONS.PADDING}px;
  border-radius: ${BUTTON_COMMONS.BORDER_RADIUS}px;
  border: ${BUTTON_COMMONS.BORDER_WIDTH}px solid transparent;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
`;

const dropdownContainer = css`
  display: flex;
  position: absolute;
  right: 0;
  background: transparent;
  border-radius: 16px;
  top: ${BUTTON_COMMONS.HEIGHT + 4}px;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.08);
`;

interface DropdownProps {
  form?: FunctionComponent<any>;
  resetAction?: () => void;
  applyAction?: () => void;
}

const FiltersDropdown: FC<DropdownProps> = ({
  form: Form,
  resetAction,
  applyAction,
}) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onClickButton = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const onClickReset = useCallback(() => {
    setIsOpen(false);
    !!resetAction && resetAction();
  }, []);

  const onClickApply = useCallback(() => {
    setIsOpen(false);
    !!applyAction && applyAction();
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      event.target instanceof Node &&
      !dropdownRef?.current?.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div {...{ css: containerContainer, ref: dropdownRef }}>
      <button
        {...{
          onClick: (e) => {
            e.preventDefault();
            onClickButton();
          },
          css: css`
            ${buttonContainer}
            ${isOpen ? activeButtonStyles : inactiveButtonStyles}
          `,
        }}
      >
        <div
          {...{
            css: textIconStyles,
          }}
        >
          {`Filters`}
          <div
            {...{
              css: iconStyles,
            }}
          >
            <IconFilter
              {...{
                iconColor: isOpen
                  ? appColors.content.white
                  : appColors.content.tertiary,
              }}
            />
          </div>
        </div>
      </button>
      {isOpen && (
        <div {...{ css: dropdownContainer }}>
          <FiltersMenuLayout
            {...{
              cardProps: {
                style: { width: FILTER_WIDTH },
              },
              header: (
                <>
                  <FilterMenuHeader {...{ header: 'Filters' }} />
                  <FilterMenuResetText {...{ onClick: onClickReset }} />
                </>
              ),
              ...(!!Form ? { content: <Form /> } : {}),
              buttons: (
                <FilterMenuApplyButton
                  {...{
                    buttonProps: {
                      children: 'Apply',
                      onClick: onClickApply,
                    },
                  }}
                />
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default memo(FiltersDropdown);
