import { ComponentProps, FC, memo, useCallback, useState } from 'react';
import { css } from '@emotion/react';

import useModal from '@/hooks/useModal';
import Button from '@/components/Button';
import { recoveryEmail } from '@/api/auth';
import { ResourceType } from '@/types/types';
import { appColors } from '@/constants/colors';
import FakeField from '@/components/FakeField';
import { AdminProvider } from '@/types/entities.d';
import { AdminRecruiter } from '@/types/entities.d';
import CustomDivider from '@/components/CustomDivider';
import { PromptMessage } from '@/components/ModalPrompt';
import { defaultToastSuccess } from '@/constants/toasts';
import Paragraph from '@/components/Typography/Paragraph';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import QuestionPrompt from '@/components/ModalPrompt/QuestionPrompt';
import { fieldsContainer } from '@/components/Dashboard/MyDetails/_index';

const container = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const contentContainer = css`
  display: flex;
  flex-direction: column;
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: column;
`;

interface AccountDetailsResetCardProps {
  user: AdminProvider | AdminRecruiter;
  is_type: ResourceType;
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
}

const AccountDetailsResetCard: FC<AccountDetailsResetCardProps> = ({
  user,
  is_type,
  modalLayoutProps,
}) => {
  const { ModalComponent, openModal, closeModal } = useModal();
  const [isRequesting, setIsRequesting] = useState(false);

  const onReset = useCallback(async () => {
    setIsRequesting(true);
    try {
      const response = await recoveryEmail(`${is_type}s`, is_type, {
        email: user.email,
      });
      if (response) {
        defaultToastSuccess(String(user.id), 'Reset password link sent');
        closeModal();
        // !!modalLayoutProps?.closeModal && modalLayoutProps.closeModal();
      }
    } catch (error) {
    } finally {
      setIsRequesting(false);
    }
  }, []);

  const onClickReset = useCallback(async () => {
    openModal();
  }, []);

  return (
    <>
      <div {...{ css: container }}>
        <div {...{ css: headerContainer }}>
          <Paragraph
            {...{
              children: `Account Details`,
              variant: 'lg',
              style: { fontWeight: 500 },
            }}
          />
          <CustomDivider {...{ type: 'horizontal' }} />
        </div>
        <div {...{ css: contentContainer }}>
          <div {...{ css: fieldsContainer }}>
            <FakeField
              {...{
                label: 'First Name',
                value: user?.first_name || '',
              }}
            />
            <FakeField
              {...{
                label: 'Last Name',
                value: user?.last_name || '',
              }}
            />

            <FakeField {...{ label: 'Email', value: user?.email || '' }} />
            <FakeField
              {...{ label: 'Reset Password', value: '••••••••••••' }}
            />
          </div>
        </div>
        <div {...{ css: buttonsContainer }}>
          <Button
            {...{
              children: 'Reset Password',
              onClick: onClickReset,
              disabled: isRequesting,
            }}
          />
        </div>
      </div>

      <ModalComponent {...{ position: 'center' }}>
        <div
          {...{
            style: {
              width: 572,
            },
          }}
        >
          <QuestionPrompt
            {...{
              containerStyles: { width: '100%' },
              headerProps: {
                children: 'Send Reset Password Link',
              },
              msgProps: [
                <PromptMessage>
                  {`You are about to send a reset password link to `}
                  <PromptMessage
                    {...{
                      children: user?.email,
                      style: { color: appColors.content.tertiary },
                    }}
                  />
                  {'.'}
                </PromptMessage>,
              ],
              buttonsProps: {
                leftButton: {
                  variant: '2',
                  children: 'Cancel',

                  onClick: async (e) => {
                    closeModal();
                  },
                },
                rightButton: {
                  variant: '1',
                  children: 'Send Reset Password Link',
                  onClick: async (e) => {
                    onReset();
                    closeModal();
                  },
                },
              },
            }}
          />
        </div>
      </ModalComponent>
    </>
  );
};

export default memo(AccountDetailsResetCard);
