import { title } from './title';
import { states } from './states';
import { specialty } from './specialty';
import { employerType } from './employer_type';
import { stateLicense } from './state_license';
import { assignmentType } from './selects-assignment-type';
import { geographicPreferences } from './geographic_preferences';

const selectsData = {
  state: states,
  title: title,
  assignment_type: assignmentType,
  specialty: specialty,
  geographic_preferences: geographicPreferences,
  state_licenses: stateLicense,
  employer_type: employerType,
};

export default selectsData;
