import { FC, ReactNode, memo } from 'react';
import Card from '@/components/Card';

import { css } from '@emotion/react';
import Heading from '@/components/Typography/Heading';
import CustomDivider from '@/components/CustomDivider';

const container = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 16px;
  padding-bottom: 24px;
  width: 100%;
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const contentContainer = css`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
`;

interface MyDetailsCardProps {
  header?: string;
  content?: ReactNode;
  buttons?: ReactNode;
}

const MyDetailsCard: FC<MyDetailsCardProps> = ({
  header,
  content,
  buttons,
}) => {
  return (
    <Card
      {...{
        hasBorder: true,
        style: { padding: 0 },
      }}
    >
      <div {...{ css: container }}>
        <div {...{ css: headerContainer }}>
          {!!header && (
            <div
              {...{
                css: css`
                  padding: 0px 24px;
                `,
              }}
            >
              <Heading
                {...{
                  children: header,
                  variant: 'h3',
                  style: { fontWeight: 400 },
                }}
              />
            </div>
          )}
          <CustomDivider {...{ type: 'horizontal' }} />
        </div>
        {!!content && <div {...{ css: contentContainer, children: content }} />}
        {!!buttons && <div {...{ css: buttonsContainer, children: buttons }} />}
      </div>
    </Card>
  );
};

export default memo(MyDetailsCard);
