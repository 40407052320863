import { SelectOption } from '@/types/types';

const getFormInitialValueFromParam = (
  key: string,
  value: string | any[] | undefined,
  hash: any
) => {
  // HAS VALUE
  if (!!value) {
    // AN ARRAY
    if (Array.isArray(value)) {
      if (value.length > 0) {
        const array = value.map((element) => {
          const foundElementOnHash = hash?.[element];
          if (!!foundElementOnHash) return foundElementOnHash;
        });
        return { [key]: array };
      }
    } else {
      // A STRING
      const foundElementOnHash = hash?.[value];
      if (!!foundElementOnHash) {
        return { [key]: foundElementOnHash };
      }
    }
  }

  // NO VALUE
  return {};
};

const getParamValueFromForm = (
  key: string,
  value: SelectOption | SelectOption[] | string | number
) => {
  // HAS VALUE
  if (!!value) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        const array = value.map((element) => element.value);
        return { [key]: array };
      }
    } else {
      if (typeof value === 'string' || typeof value === 'number') {
        return { [key]: value };
      } else {
        return { [key]: value.value };
      }
    }
  }
  return {};
};

const getValuesFromForm = (
  value: SelectOption | SelectOption[] | string | number
) => {
  // HAS VALUE
  if (!!value) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        const array = value.map((element) => element.value);
        return array;
      }
    } else {
      if (typeof value === 'string' || typeof value === 'number') {
        return value;
      } else {
        return value.value;
      }
    }
  }
  return '';
};

const getLabelFromValue = (value: string | any[] | undefined, hash: any) => {
  // HAS VALUE
  if (!!value) {
    // AN ARRAY
    if (Array.isArray(value)) {
      if (value.length > 0) {
        const array = value.map((element) => {
          const foundElementOnHash = hash?.[element];
          if (!!foundElementOnHash) return foundElementOnHash?.label;
        });
        return array.join(', ');
      }
    } else {
      // A STRING
      const foundElementOnHash = hash?.[value];
      if (!!foundElementOnHash) {
        return foundElementOnHash?.label;
      }
    }
  }

  // NO VALUE
  return '';
};

export {
  getFormInitialValueFromParam,
  getParamValueFromForm,
  getLabelFromValue,
  getValuesFromForm,
};
