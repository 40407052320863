import { FC, memo, useCallback, useState } from 'react';
import { css } from '@emotion/react';

import Card from '../Card';
import layout from '@/constants/layout';
import { SelectOption } from '@/types/types';
import { appColors } from '@/constants/colors';
import JobListTileHeader from '../JobTiles/JobListTileHeader';

const {
  windowSizes: { mobile },
} = layout;

const HOVER_COLOR = appColors.content.tertiary;

const jobListTileStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 40px;
  text-align: center;

  @media (max-width: ${mobile}px) {
    padding: 24px;
  }
`;

const SearchCard: FC<{
  item: SelectOption;
  onClickTile: (value: string) => void;
}> = ({ item, onClickTile }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <Card
      {...{
        css: css({
          ...jobListTileStyles,
        }),
        style: {
          ...(isHovered ? { borderColor: HOVER_COLOR } : {}),
        },
        onMouseOver: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onClick: () => onClickTile(item.value),
      }}
    >
      <JobListTileHeader
        {...{
          title: item.label || '',
          style: {
            ...(isHovered ? { color: HOVER_COLOR } : { color: 'black' }),
            fontFamily: 'Inter',
            fontSize: 16,
          },
        }}
      />
    </Card>
  );
};

export default memo(SearchCard);
