import AuthModal from '@/components/AuthModal';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';
import JobBoardIndexContent from '@/components/JobBoard/JobBoardIndexContent';

export default function JobsIndex() {
  return (
    <>
      <TopContentFooterLayout>
        <JobBoardIndexContent />
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
