import { FC, memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const SIZE = 24;

interface PaginatorNumberProps {
  isActive: boolean;
  number: number;
  onClick: (value: number) => void;
}

const textStyles = css({
  ...paragraphDesktop.md,
  fontWeight: 500,
});

const StyledNumber = styled.div<{ isActive: boolean }>`
  ${textStyles}
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  vertical-align: center;
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: 100px;
  color: ${({ isActive }) =>
    isActive ? 'white' : appColors.content.secondary};
  background-color: ${({ isActive }) =>
    isActive ? appColors.content.primary : `transparent`};
  cursor: pointer;
`;

const PaginatorNumber: FC<PaginatorNumberProps> = ({
  number,
  isActive,
  onClick,
}) => {
  return (
    <StyledNumber
      {...{
        onClick: () => onClick(number),
        isActive,
        children: number,
      }}
    />
  );
};

export default memo(PaginatorNumber);
