import { useCallback } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useForm } from 'react-hook-form';

import Card from '@/components/Card';
import layout from '@/constants/layout';
import Button from '@/components/Button';
import AuthModal from '@/components/AuthModal';
import { appColors } from '@/constants/colors';
import { useAppDispatch } from '@/storage/store';
import { ContactParams } from '@/types/api/params';
import { ContactUsFormValues } from '@/types/forms';
import { LogoSmall } from '@/components/Icons/Logo';
import Heading from '@/components/Typography/Heading';
import Paragraph from '@/components/Typography/Paragraph';
import { contactUsThunk } from '@/storage/auth/auth-actions';
import ContactForm from '@/components/Forms/Contact/ContactForm';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';

const {
  windowSizes: { tablet },
} = layout;

const container = css`
  display: flex;
`;

const contentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const headingContainer = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const formButtonContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  gap: 24px;
`;

const successContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  align-items: center;
  text-align: center;
`;

export default function Contact() {
  const dispatch = useAppDispatch();

  const methods = useForm<ContactUsFormValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      message: '',
    },
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = useCallback(
    async (form: ContactUsFormValues) => {
      const params: ContactParams = {
        first_name: form.first_name,
        last_name: form.last_name,
        email: form.email,
        message: form.message,
      };
      try {
        const response = await dispatch(contactUsThunk({ params })).unwrap();
        return;
      } catch (error) {
        console.log(' error', error);
      }
    },
    [dispatch]
  );

  return (
    <>
      <TopContentFooterLayout>
        <div
          {...{
            css: css`
              ${container}
              padding: ${isSubmitSuccessful ? 212 : 48}px 0;
              @media (max-width: ${tablet}px) {
                padding: ${isSubmitSuccessful ? 112 : 24}px 0;
                padding-left: 16px;
                padding-right: 16px;
              }
            `,
          }}
        >
          <Card {...{ hasBorder: true, style: { padding: 24 } }}>
            <div
              {...{
                css: css`
                  ${contentContainer}
                `,
              }}
            >
              {isSubmitSuccessful ? (
                <SuccessMessage />
              ) : (
                <div
                  {...{
                    css: css`
                      display: flex;
                      flex-direction: column;
                      gap: 85px;
                      width: 540px;
                      @media (max-width: ${tablet}px) {
                        gap: 42.5px;
                        width: 100%;
                      }
                    `,
                  }}
                >
                  <div {...{ css: headingContainer }}>
                    <Header
                      {...{
                        header: 'Contact Us',
                        subheader: `Have questions or need assistance? Contact us today!`,
                      }}
                    />
                  </div>
                  <div {...{ css: formButtonContainer }}>
                    <ContactForm {...{ methods }} />
                    <div
                      {...{
                        css: css`
                          display: flex;
                          align-self: flex-end;
                          @media (max-width: ${tablet}px) {
                            width: 100%;
                          }
                        `,
                      }}
                    >
                      <Button
                        {...{
                          variant: '1',
                          children: 'Submit',
                          css: css``,
                          onClick: handleSubmit(onSubmit),
                          disabled: !isValid || isSubmitting,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}

const SuccessMessage = () => {
  return (
    <div {...{ css: successContainer }}>
      <div
        {...{
          css: css``,
        }}
      >
        <LogoSmall />
      </div>
      <Header
        {...{
          header: 'Thank you!',
          subheader: `Your message has been sent.\nOur team will be in touch with you soon.`,
        }}
      />
    </div>
  );
};

//

const Header = ({
  header,
  subheader,
}: {
  header: string;
  subheader: string;
}) => {
  return (
    <>
      <Heading
        {...{
          children: header,
          variant: 'h1',
          style: {
            fontSize: 40,
          },
        }}
      />
      <Paragraph
        {...{
          children: subheader,
          variant: 'lg',
          style: {
            color: appColors.content.secondary,
          },
        }}
      />
    </>
  );
};
