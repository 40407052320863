import { toast } from 'react-toastify';

import Toast from '@/components/Toast';

const defaultToastError = (toastId: string, msg?: string) =>
  toast(
    (rest) => (
      <Toast
        {...{
          closeToast: rest.closeToast,
          msg,
          type: 'error',
        }}
      />
    ),
    {
      toastId,
    }
  );

const defaultToastWarning = (toastId: string, msg?: string) =>
  toast(
    (rest) => (
      <Toast
        {...{
          closeToast: rest.closeToast,
          msg,
          type: 'warning',
        }}
      />
    ),
    {
      toastId,
    }
  );

const defaultToastSuccess = (toastId: string, msg?: string) =>
  toast(
    (rest) => (
      <Toast
        {...{
          closeToast: rest.closeToast,
          msg,
          type: 'success',
        }}
      />
    ),
    {
      toastId,
    }
  );

export { defaultToastError, defaultToastWarning, defaultToastSuccess };
