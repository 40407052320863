//FUNCTIONALITY & HOOKS
import React from 'react';
import data from '../../controllers/data.js';

//COMPONENTS
import layout from '@/constants/layout';
import Card from './Card.jsx';

//CSS
import { css } from '@emotion/react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const TestimonialSlider = (props: any) => {
  function cards(data: {
    id: React.Key;
    alt: string;
    avatar: string;
    name: string;
    message: string;
  }) {
    return (
      <Card
        key={data.id}
        name={data.name}
        message={data.message}
        alt={data.name}
      />
    );
  }

  const settings = {
    dots: true,
    fade: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <NextBtn icon={IconArrowRight} />,
    // prevArrow: <PreviousBtn icon={IconArrowLeft} />,
    appendDots: (
      dots:
        | string
        | number
        | boolean
        | React.ReactFragment
        | React.ReactPortal
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | null
        | undefined
    ) => <ul>{dots}</ul>,
    customPaging: (i: any) => (
      <div className="ft-slick__dots--custom">
        <div className="loading" />
      </div>
    ),
  };

  return (
    <div {...{ css: Testimonial }}>
      <div {...{ css: TestimoniaContent }}>
        <Slider {...settings}>{data.map(cards)}</Slider>
      </div>
    </div>
  );
};

export default TestimonialSlider;

const Testimonial = css`
  display: flex;
  justify-content: center;
  background: #d6dfff;
  width: 50%;
  padding: 0 60px;
  margin: 50px auto;
  border-radius: 50px;
  min-width: 50%;
  @media (max-width: ${mobile}px) {
    width: 100%;
    padding: 0;
  }
`;

const TestimoniaContent = css`
  width: 80%;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;
