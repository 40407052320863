import { FC, memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Avatar from '../Avatar';
import { appColors } from '@/constants/colors';
import usePageSession from '@/hooks/usePageSession';
import { Company, Recruiter } from '@/types/entities';
import { paragraphDesktop } from '@/constants/typography';

const containerStyles = css`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const infoStyles = css`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const StyledName = styled.label({
  ...paragraphDesktop.md,
  fontWeight: 500,
  color: appColors.content.primary,
});

const StyledInfoDetails = styled.label({
  ...paragraphDesktop.sm,
  color: appColors.content.secondary,
});

interface RecruiterInfoProps {
  recruiter: Recruiter | {};
  company: Company | {};
}

const RecruiterInfo: FC<RecruiterInfoProps> = ({ recruiter, company }) => {
  const { session } = usePageSession();
  const { signed_in, current_user } = session;

  return (
    <div {...{ css: containerStyles }}>
      {!!company?.logo_url && (
        <Avatar {...{ url: company?.logo_url, size: 80 }} />
      )}

      <div {...{ css: infoStyles }}>
        <StyledName
          {...{
            children: recruiter?.first_name || 'Placeholder', // recruiter info is not retured when user is signed out
            style: {
              ...(signed_in ? {} : { filter: 'blur(5px)' }),
            },
          }}
        />

        <StyledInfoDetails
          {...{
            children: company?.name || 'Placeholder',

            style: {
              ...(signed_in ? {} : { filter: 'blur(5px)' }),
            },
          }}
        />
         <StyledInfoDetails
          {...{
            children: 'Recruiter',
          }}
        />
      </div>
    </div>
  );
};

export default memo(RecruiterInfo);
