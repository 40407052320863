import { FC } from 'react';
import styled from '@emotion/styled';

import {
  IconArrowDown,
  IconArrowLeft,
  IconArrowRight,
  IconArrowUp,
} from '../Icons/Arrow';
import {
  IconVectorDown,
  IconVectorLeft,
  IconVectorRight,
  IconVectorUp,
} from '../Icons/Vector';
import { IconDrag } from '../Icons/Drag';
import { IconSort } from '../Icons/Sort';
import { IconCopy } from '../Icons/Copy';
import { IconList } from '../Icons/List';
import { IconPlus } from '../Icons/Plus';
import { IconClip } from '../Icons/Clip';
import { IconEdit } from '../Icons/Edit';
import { IconLink } from '../Icons/Link';
import { IconAlert } from '../Icons/Alert';
import { IconClose } from '../Icons/Close';
import { IconTrash } from '../Icons/Trash';
import { IconCheck } from '../Icons/Check';
import { IconMinus } from '../Icons/Minus';
import { IconMapPin } from '../Icons/MapPin';
import { IconSearch } from '../Icons/Search';
import { IconFilter } from '../Icons/Filter';
import { IconCursor } from '../Icons/Cursor';
import { IconDollar } from '../Icons/Dollar';
import { IconShare } from '../Icons/Share';
import { IconTitle } from '../Icons/Title';
import { IconFacility } from '../Icons/Facility';
import { IconBookmark } from '../Icons/Bookmark';
import { IconMedicalJob } from '../Icons/MedicalJob';
import { IconAssignment } from '../Icons/Assignment';
import { IconInfo, IconInfoFilled } from '../Icons/Info';
import { IconMenu, IconMenuStaggered } from '../Icons/Menu';
import { IconBoardCertified } from '../Icons/BoardCertified';
import { IconAccountProfile } from '../Icons/AccountProfile';
import { IconKabobHorizontal, IconKabobVertical } from '../Icons/Kabob';
import { IconNotificationOff, IconNotificationOn } from '../Icons/Notification';

const SIZE = 24;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 0 30%;
  flex-shrink: 1;
  flex-wrap: wrap;
`;

const ICONS = [
  IconVectorLeft,
  IconVectorRight,
  IconVectorUp,
  IconVectorDown,
  IconClose,
  IconDrag,
  IconTrash,
  IconArrowDown,
  IconArrowUp,
  IconArrowRight,
  IconArrowLeft,
  IconCheck,
  IconMenu,
  IconFilter,
  IconNotificationOn,
  IconNotificationOff,
  IconBookmark,
  IconSort,
  IconCopy,
  IconKabobHorizontal,
  IconKabobVertical,
  IconInfo,
  IconInfoFilled,
  IconAlert,
  IconList,
  IconSearch,
  IconMapPin,
  IconPlus,
  IconMinus,
  IconClip,
  IconLink,
  IconCursor,
  IconAccountProfile,
  IconMedicalJob,
  IconDollar,
  IconMenuStaggered,
  IconShare,
  IconTitle,
  IconAssignment,
  IconFacility,
  IconBoardCertified,
  IconEdit,
];

const Icons: FC = () => {
  return (
    <Container>
      {ICONS.map((icon, index) => {
        const Icon = icon;
        return (
          <div {...{ style: { width: 24, height: 24 }, key: index }}>
            <Icon {...{ width: SIZE, height: SIZE }} />
          </div>
        );
      })}
    </Container>
  );
};

export default Icons;
