import { FC, useCallback } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FieldsContainer,
  FormButtonContainer,
  FormContainer,
  RecoveryEmailFormHeader,
} from '.';
import {
  emailRecoveryPlaceholder,
  fieldsDefaultProps,
} from '@/constants/form-fields';
import Input from '../Input';
import Button from '../Button';
import LinkMessage from '../LinkMessage';
import { AuthModalsMode } from '@/types/types';
import { useAppDispatch } from '@/storage/store';
import { RecoveryEmailFormValues } from '@/types/forms';
import { setAuthModalMode } from '@/storage/modal/ModalSlice';
import { fieldsDefaultRules } from '@/constants/form-validations';

const RecoveryEmailForm: FC<{
  onSubmit: (form: RecoveryEmailFormValues) => void;
  methods: UseFormReturn<RecoveryEmailFormValues, any>;
}> = ({ onSubmit, methods }) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods;

  const goToMode = useCallback(
    (mode: AuthModalsMode) => {
      dispatch(setAuthModalMode(mode));
    },
    [dispatch]
  );

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <RecoveryEmailFormHeader />
        <FieldsContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'email',
                control,
                rules: { ...fieldsDefaultRules['email'] },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['email'],
                        placeholder: emailRecoveryPlaceholder,
                        ...field,
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
        <FormButtonContainer>
          <Button
            {...{
              variant: '1',
              children: 'Continue',
              style: { width: '100%' },
              onClick: handleSubmit(onSubmit),
              disabled: !isValid || isSubmitting,
            }}
          />
        </FormButtonContainer>
        <LinkMessage
          {...{
            msg: 'Already have an account?  ',
            link: {
              children: 'Sign in.',
              onClick: () => goToMode('signIn'),
            },
          }}
        />
      </FormContainer>
    </form>
  );
};

export default RecoveryEmailForm;
