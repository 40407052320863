import { memo } from 'react';
import { OptionProps, components } from 'react-select';
import { css } from '@emotion/react';

import Checkbox from '../Checkbox';
import { appColors } from '@/constants/colors';
import { SelectOption, ShownValue } from '@/types/types';

const MultiSelectOption = ({
  shownValue = 'label',
  ...props
}: OptionProps & ShownValue) => {
  const { isFocused, isSelected, data } = props;

  const option = data as unknown as SelectOption;

  return (
    <components.MultiValueContainer {...props}>
      <div
        {...{
          css: css`
            display: flex;
            display: flex;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            flex-direction: row;
            gap: 8px;
            height: 54;
            padding: 14px;
            margin: 0;
            align-items: center;
            justify-content: flex-start;
            background-color: ${isFocused
              ? appColors.notInDesignSystem[6]
              : 'white'};
            color: ${isSelected
              ? appColors.content.primary
              : appColors.content.secondary};
            cursor: pointer;
          `,
        }}
      >
        <Checkbox
          {...{
            label: option[shownValue],
            readOnly: true,
            checked: isSelected,
            variant: 'select',
            isFocused,
          }}
        />
      </div>
    </components.MultiValueContainer>
  );
};

export default memo(MultiSelectOption);
