import { CSSProperties } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  termsPrivacyContainerStyles,
  termsPrivacyHeaderStyles,
} from '@/components/Layout/_index';
import AuthModal from '@/components/AuthModal';
import { appColors } from '@/constants/colors';
import Link from '@/components/Typography/Link';
import Heading from '@/components/Typography/Heading';
import Paragraph from '@/components/Typography/Paragraph';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';

const PRIVACY_PHONENUMBER = `844-290-4014`;
const PRIVACY_EMAIL = `privacy@locums.com`;

const contactLinkStyles: CSSProperties = {
  fontWeight: 700,
  userSelect: 'text',
  msUserSelect: 'text',
  MozUserSelect: 'text',
  WebkitUserSelect: 'text',
};

const stateLinks = Object.freeze([
  {
    state: 'California',
    link: 'https://leginfo.legislature.ca.gov/faces/codes_displayText.xhtml?division=3.&part=4.&lawCode=CIV&title=1.81.5',
  },
  {
    state: 'Colorado',
    link: 'https://leg.colorado.gov/bills/sb21-190',
  },
  {
    state: 'Connecticut',
    link: 'https://www.cga.ct.gov/asp/cgabillstatus/cgabillstatus.asp?selBillType=Bill&bill_num=SB00006&which_year=2022',
  },
  {
    state: 'Indiana',
    link: 'https://legiscan.com/IN/text/SB0005/id/2779850',
  },
  {
    state: 'Iowa',
    link: 'https://www.legis.iowa.gov/legislation/BillBook?ga=90&ba=SF%20262',
  },
  {
    state: 'Montana',
    link: 'https://laws.leg.mt.gov/legprd/LAW0210W$BSIV.ActionQuery?P_BILL_NO1=384&P_BLTP_BILL_TYP_CD=SB&Z_ACTION=Find&P_SESS=20231',
  },
  {
    state: 'Nevada',
    link: 'https://www.leg.state.nv.us/nrs/nrs-603a.html',
  },
  {
    state: 'Oregon',
    link: 'https://olis.oregonlegislature.gov/liz/2023R1/Downloads/MeasureDocument/SB619/Enrolled',
  },
  {
    state: 'Tennessee',
    link: 'https://legiscan.com/TN/text/HB1181/id/2672877',
  },
  {
    state: 'Texas',
    link: 'https://capitol.texas.gov/BillLookup/Text.aspx?LegSess=88R&Bill=HB4',
  },
  {
    state: 'Utah',
    link: 'https://le.utah.gov/~2022/bills/static/SB0227.html',
  },
  {
    state: 'Virginia',
    link: 'https://law.lis.virginia.gov/vacodefull/title59.1/chapter53/',
  },
]);

const TermsParagraph = styled(Paragraph)`
  font-weight: 500;
`;

const TermsParagraphLight = styled(Paragraph)`
  font-weight: 500;
  color: ${appColors.content.secondary} !important;
`;

const listItemStyles = css`
  margin-bottom: 1rem;
`;

const linkStyles = css`
  word-break: break-all;
`;

const StateLinksComponent = ({ states }: { states: typeof stateLinks }) => {
  return (
    <ul
      {...{
        css: css`
          padding: 16px 0 0 32px;
          margin: 0;
        `,
      }}
    >
      {states.map((stateLink, index) => (
        <li {...{ css: listItemStyles, key: index }}>
          {stateLink.state}
          <ul
            {...{
              css: css`
                padding: 0 0 0 16px;
                margin: 0;
              `,
            }}
          >
            <li {...{ css: linkStyles }}>
              <Link
                {...{
                  children: stateLink.link,
                  variant: 'md',
                  onClick: () => {
                    window.open(stateLink.link, '_blank');
                  },
                }}
              />
            </li>
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default function Privacy() {
  return (
    <>
      <TopContentFooterLayout>
        <div {...{ css: termsPrivacyContainerStyles }}>
          <div {...{ css: termsPrivacyHeaderStyles }}>
            <Heading
              variant="h1"
              children="Website Privacy Policy"
              style={{ fontSize: 40, textAlign: 'center', marginBottom: 38 }}
            />
          </div>

          <TermsParagraph>Last modified: August 29, 2023</TermsParagraph>

          {/* ------------------------------- */}
          <TermsParagraph>Introduction</TermsParagraph>

          <TermsParagraphLight>
            The Company (<TermsParagraph>“we”</TermsParagraph>,{' '}
            <TermsParagraph>“us”</TermsParagraph>, or{' '}
            <TermsParagraph>“our”</TermsParagraph>) respects your privacy and
            are committed to protecting it through our compliance with this
            policy. This policy describes the types of information we may
            collect from you or that you may provide when you visit the website
            www.locums.com (our <TermsParagraph>“Website”</TermsParagraph>) and
            our practices for collecting, using, maintaining, protecting, and
            disclosing that information. This policy applies to information we
            collect:
            <ul>
              <li>On this Website.</li>
              <li>
                In email, text, and other electronic messages between you and
                this Website.
              </li>
              <li>
                Through mobile and desktop applications you may download from
                this Website, which provide dedicated non-browser-based
                interaction between you and this Website.
              </li>
              <li>
                When you interact with our advertising and applications on
                third-party websites and services if those applications or
                advertising include links to this policy.
              </li>
              <li></li>
            </ul>
            It does not apply to information collected by:
            <ul>
              <li>
                Us offline or through any other means, including on any other
                website operated by Company or any third party; or
              </li>
              <li>
                Any third party, including through any application or content
                (including advertising) that may link to or be accessible from
                or through the Website.
              </li>
            </ul>
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Website. By accessing or using this Website, you agree to
            this privacy policy. This policy may change from time to time (see
            Changes to Our Privacy Policy Section below). Your continued use of
            this Website after we make changes is deemed to be acceptance of
            those changes, so please check the policy periodically for updates.
            Terms not otherwise defined herein shall have the meaning ascribed
            to them in the Terms of Use, found on the Website and incorporated
            herein as if fully set forth.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>Persons Under the Age of 18</TermsParagraph>
          <TermsParagraphLight>
            Our Website is not intended for persons under 18 years of age. No
            one under age 18 may provide any information to or on the Website.
            We do not knowingly collect personal information from persons under
            18. If you are under 18, do not use or provide any information on
            this Website or through any of its features, register on the
            Website, use any of the interactive or public features of this
            Website, or provide any information about yourself to us, including
            your name, address, telephone number, email address, or any screen
            name or username you may use. If we learn we have collected or
            received personal information from anyone under 18 without
            verification of parental consent, we will delete that information.
            If you believe we might have any information from or about a person
            under 18, please contact us at the contact information provided
            below in the Contact Information Section herein.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>
            Information We Collect About You and How We Collect It
          </TermsParagraph>
          <TermsParagraphLight>
            We collect several types of information from and about users of our
            Website, including information:
            <ul>
              <li>
                By which you may be personally identified, such as name, postal
                address, email address, and telephone number (“personal
                information”);
              </li>
              <li>
                Used to create a profile, apply for or post jobs, and upload
                resumes, which may include work history and education;
              </li>
              <li>
                About your activity on our Website, including your searches, the
                jobs you view and apply for, and the files viewed on our
                Website;
              </li>
              <li>
                You provide regarding your resume and employment records, which
                may include personal information and other identifiers including
                social security number, gender, marital status, passport
                numbers, driver’s licenses, state identification cards, policy
                numbers, compensation, bank account numbers or other financial
                information, and other information that may be derived from
                information you provide and supply;
              </li>
              <li>
                Regarding business records, including company names, contacts,
                financial records, and confidential information;
              </li>
              <li>
                Considered sensitive information as defined by one or more
                applicable laws, which may include, in addition to what has
                already been listed above, account login credentials, precise
                geolocation, information about racial or ethnic origin,
                religious beliefs, philosophical beliefs, and union memberships.
              </li>
              <li>
                That is about you but individually does not identify you; and/or
              </li>
              <li>
                About your internet connection, the equipment you use to access
                our Website, and usage details.
              </li>
            </ul>
            We collect this information:
            <ul>
              <li>
                Directly from you when you provide it to us, enter it onto the
                Website, and send it to us.
              </li>
              <li>
                Automatically as you navigate to or through the Website.
                Information collected automatically may include usage details,
                IP addresses, and information collected through cookies and
                potentially other tracking technologies such as web beacons.
              </li>
              <li>
                From publicly available websites or third-party providers.
              </li>
            </ul>{' '}
            Information You Provide to Us The information we collect on or
            through our Website may include:
            <ul>
              <li>
                Information that you provide by filling in forms on our Website.
                This includes information provided at the time of registering to
                use our Website, creating or updating a profile, subscribing to
                our services, posting material, or requesting further services.
                We may also ask you for information when we interact with you,
                including when you participate in our services, enter a contest
                or promotion sponsored by us, or when you report a problem with
                our Website.
              </li>
              <li>
                Records and copies of your correspondence (including email
                addresses and other contact information), if you contact us.
              </li>
              <li>
                Your responses to surveys that we might ask you to complete for
                research purposes.
              </li>
              <li>
                Details of transactions you carry out through our Website.
              </li>
              <li>Your search queries on the Website.</li>
            </ul>{' '}
            You also may provide information to be published or displayed
            (hereinafter, <TermsParagraph>“posted”</TermsParagraph>) on public
            areas of the Website or transmitted to other users of the Website or
            third parties (collectively,{' '}
            <TermsParagraph>“User Contributions”</TermsParagraph>). Your User
            Contributions are posted on and transmitted to others at your own
            risk. Although we limit access to certain pages, please be aware
            that no security measures are perfect or impenetrable. Additionally,
            we cannot control the actions of other users of the Website with
            whom you may choose to share your User Contributions. Therefore, we
            cannot and do not guarantee that your User Contributions will not be
            viewed by unauthorized persons. Information We Collect Through
            Automatic Data Collection Technologies As you navigate through and
            interact with our Website, we may use automatic data collection
            technologies to collect certain information about your equipment,
            browsing actions, and patterns, including:
            <ul>
              <li>
                Details of your visits to our Website, including traffic data,
                location data, logs, and other communication data and the
                resources that you access and use on the Website.
              </li>
              <li>
                Information about your computer and internet connection,
                including your IP address, operating system, and browser type.
              </li>
            </ul>{' '}
            The information we collect automatically may include personal
            information or we may maintain it or associate it with personal
            information we collect in other ways or receive from third parties.
            It helps us to improve our Website and to deliver a better and more
            personalized service, including by enabling us to:
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Store information about your preferences, allowing us to
                customize our Website according to your individual interests.
              </li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Website.</li>
            </ul>{' '}
            The technologies we use for this automatic data collection may
            include:
            <ul>
              <li>
                <TermsParagraph>Cookies (or browser cookies).</TermsParagraph> A
                cookie is a small file placed on the hard drive of your
                computer. You may refuse to accept browser cookies by activating
                the appropriate setting on your browser. However, if you select
                this setting, you may be unable to access certain parts of our
                Website. Unless you have adjusted your browser setting so that
                it will refuse cookies, our system will issue cookies when you
                direct your browser to our Website.
              </li>
              <li>
                <TermsParagraph>Web Beacons.</TermsParagraph> Pages of our
                Website may contain small electronic files known as web beacons
                (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who
                have visited those pages and for other related website
                statistics (for example, recording the popularity of certain
                website content and verifying system and server integrity).
              </li>
              <li>
                <TermsParagraph>Flash Cookies.</TermsParagraph> Certain features
                of our Website may use local stored objects (or Flash cookies)
                to collect and store information about your preferences and
                navigation to, from, and on our Website. Flash cookies are not
                managed by the same browser settings as are used for browser
                cookies.
              </li>
            </ul>
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>How We Use Your Information</TermsParagraph>
          <TermsParagraphLight>
            We use information that we collect about you or that you provide to
            us, including any personal information:
            <ul>
              <li>To present our Website and its contents to you.</li>
              <li>
                To contact and communicate with you, including providing
                personalized, targeted, or location- based content, services,
                and advertising both from us and other third-parties.
              </li>
              <li>To analyze, improve, and optimize our services.</li>
              <li>To process financial transactions involving our services.</li>
              <li>
                To enforce the terms of our services, protect the rights of our
                users, and prevent fraud and abuse of our systems.
              </li>
              <li>
                To provide you with notifications, information, products, or
                services that you request from us.
              </li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>
                To provide you with notices about your account, which may
                include expiration and renewal notices.
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.
              </li>
              <li>
                To notify you about changes to our Website or any services we
                (or third-parties) offer or provide though it.
              </li>
              <li>
                To allow you to participate in interactive features on our
                Website.
              </li>
              <li>To assist you with creating a profile.</li>
              <li>To inform you of other services available from us.</li>
              <li>To conduct surveys, promotions, and contests.</li>
              <li>To give search engines access to public information.</li>
              <li>
                To conduct analytics and generate reports about our Website.
              </li>
              <li>
                In any other way we may describe when you provide the
                information.
              </li>
              <li>For any other purpose with your consent.</li>
            </ul>
            We may also use your information to contact you about our own and
            third-parties' goods and services that may be of interest to you. We
            may use the information we have collected from you to enable us to
            display advertisements to our advertisers' target audiences. Even
            though we do not disclose your personal information for these
            purposes without your consent, if you click on or otherwise interact
            with an advertisement, the advertiser may assume that you meet its
            target criteria.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>Disclosure of Your Information</TermsParagraph>
          <TermsParagraphLight>
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction. We may disclose personal information that we collect,
            or you provide as described in this privacy policy:
            <ul>
              <li>To our subsidiaries and affiliates.</li>
              <li>
                To contractors, service providers, and other third parties we
                use to support our business.
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of our assets, whether as
                a going concern or as part of bankruptcy, liquidation, or
                similar proceeding, in which personal information held by us
                about our Website users is among the assets transferred.
              </li>
              <li>
                To third parties to market their products or services to you if
                you have not opted out of these disclosures.
              </li>
              <li>To fulfill the purpose for which you provide it.</li>
              <li>
                To employers, recruiters, and other persons (individually or
                otherwise) or to job seekers or candidates.
              </li>
              <li>To third-parties as required by law.</li>
              <li>
                For any other purpose disclosed by us when you provide the
                information.
              </li>
              <li>With your consent.</li>
              <li>
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request.
              </li>
              <li>
                To enforce or apply our terms of use available on the Website
                and other agreements, including for billing and collection
                purposes.
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of us, our customers, or others,
                including exchanging information with other companies and
                organizations for the purposes of fraud protection and credit
                risk reduction.
              </li>
            </ul>
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>
            Choices About How We Use and Disclose Your Information
          </TermsParagraph>
          <TermsParagraphLight>
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:
            <ul>
              <li>
                <TermsParagraph>
                  Tracking Technologies and Advertising.
                </TermsParagraph>{' '}
                You can set your browser to refuse all or some browser cookies,
                or to alert you when cookies are being sent. If you disable or
                refuse cookies, please note that some parts of this Website may
                then be inaccessible or not function properly.
              </li>
              <li>
                <TermsParagraph>
                  Disclosure of Your Information for Third-Party Advertising.
                </TermsParagraph>{' '}
                If you do not want us to share your personal information with
                unaffiliated or non-agent third parties for promotional
                purposes, you can opt-out by sending us an email with your
                request to us at the contact information provided below.
              </li>
            </ul>
            We do not control third parties' collection or use of your
            information to serve interest-based advertising. However, these
            third parties may provide you with ways to choose not to have your
            information collected or used in this way. You can opt out of
            receiving targeted ads from members of the Network Advertising
            Initiative (“NAI”) on the NAI's website. Residents of certain
            states, such as California, Nevada, Colorado, Connecticut, Virginia,
            Oregon, Montana, Texas, Iowa, Indiana, Tennessee, and Utah may have
            additional personal information rights and choices. Please see the
            State Privacy Rights Section below for more information.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>
            Accessing, Correcting, and Deleting Your Personal Information
          </TermsParagraph>
          <TermsParagraphLight>
            You can review and change your personal information by logging into
            the Website and visiting your account profile page. You may also
            send us an email at the email address provided in the contact
            information below to request access to, correct, or delete any
            personal information that you have provided to us. We may not be
            able to delete your personal information except by also deleting
            your user account. We may not accommodate a request to change
            information if we believe the change would violate any law or legal
            requirement or cause the information to be incorrect. Please keep in
            mind that we will only comply with this obligation to the extent and
            in such frequency as required by applicable law. Before we grant you
            rights to access, correct, or delete personal information, we must
            verify your identity and, if we are unable to do so, we may restrict
            your rights to access, correct, or delete personal information. We
            agree to use commercially reasonable efforts to fulfill your request
            within 30 days, however, we reserve the right to extend this
            timeframe as necessary. If you delete your User Contributions from
            the Website, copies of your User Contributions may remain viewable
            in cached and archived pages or might have been copied or stored by
            other Website users. Proper access and use of information provided
            on the Website, including User Contributions, is governed by our
            terms of use found on our Website. We do not currently sell your
            sensitive personal information; however, you may have certain rights
            under applicable privacy laws to limit both the use of and sale of
            your personal information. In order to exercise these rights, to the
            extent applicable, please contact us at the contact information
            provided below. We will not discriminate against you for exercising
            any of your rights under any applicable privacy law, expressly
            including denying you goods or services, charging you different
            prices or rates, providing you with different levels or qualities of
            goods or services, or suggesting you may receive a different price
            or rate for goods or services.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>Your State Privacy Rights</TermsParagraph>
          <TermsParagraphLight>
            State consumer privacy laws may provide their residents with
            additional rights regarding our use of their personal information.
            These state consumer privacy laws may or may not be currently active
            and may or may not be applicable to our business or our Website,
            however, we will provide appropriate links to the current state laws
            to inform you of your potential rights. To learn more about consumer
            privacy rights in these states, including how to exercise such
            rights, visit the appropriate hyperlink below:
            {/*  */}
            <StateLinksComponent {...{ states: stateLinks }} />
            {/*  */}
            As this list is updated from time to time, and as these laws change,
            are modified, amended, or supplemented, or as new jurisdictions are
            added to this list, you may have certain rights and we remain
            committed to using commercially reasonable efforts to comply with.
            To exercise any of your rights available to you under your
            applicable state privacy rights law, please contact us at the
            contact information provided below.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>Retention Period</TermsParagraph>
          <TermsParagraphLight>
            We may retain your personal information for up to seven (7) years
            after the last time you access your account, following such time we
            will automatically delete it from our systems unless we have a legal
            obligation to keep it longer. If you would like us to delete your
            information, please contact us at the contact information provided
            below.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>Resumes and Job Postings</TermsParagraph>
          <TermsParagraphLight>
            When you create or post a resume or a job posting, we store the
            information in our databases and may publish it, as appropriate. We
            cannot, and do not, control third-parties’ data retention, nor can
            we control what third-parties do with information you post. We
            cannot guarantee that all information you post will be kept
            protected and that others may not be able to gain access to it.
            Please be careful about information you post in your resume and/or
            job posting as this information may become public.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>Data Security</TermsParagraph>
          <TermsParagraphLight>
            We have implemented measures designed to secure your personal
            information from accidental loss and from unauthorized access, use,
            alteration, and disclosure. The safety and security of your
            information also depends on you. Where we have given you (or where
            you have chosen) a password for access to certain parts of our
            Website, you are responsible for keeping this password confidential.
            We ask you not to share your password with anyone. We urge you to be
            careful about giving out information in public areas of the Website
            like message boards. The information you share in public areas may
            be viewed by any user of the Website. Unfortunately, the
            transmission of information via the internet is not completely
            secure. Although we do our best to protect your personal
            information, we cannot guarantee the security of your personal
            information transmitted to our Website. Any transmission of personal
            information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on the Website.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>Changes to Our Privacy Policy</TermsParagraph>
          <TermsParagraphLight>
            It is our policy to post any changes we make to our privacy policy
            on this page. If we make material changes from our privacy policies
            as to how we treat our users' personal information, we will notify
            you by email or through a notice on the Website home page. The date
            the privacy policy was last revised is identified at the top of the
            page. You are responsible for ensuring we have an up-to-date active
            and deliverable email address for you, and for periodically visiting
            our Website and this privacy policy to check for any changes.
          </TermsParagraphLight>

          {/* ------------------------------- */}
          <TermsParagraph>Contact Information</TermsParagraph>
          <TermsParagraphLight>
            To ask questions or comment about this privacy policy and our
            privacy practices, or to exercise any additional rights you may
            have, please contact us at{' '}
            <TermsParagraphLight
              {...{
                children: `${PRIVACY_EMAIL}`,
                style: {
                  ...contactLinkStyles,
                },
                onClick: () => {
                  window.location.href = `mailto:${PRIVACY_EMAIL}`;
                },
              }}
            />
            {` or via our toll-free number: `}
            <TermsParagraphLight
              {...{
                children: `${PRIVACY_PHONENUMBER}`,
                style: {
                  ...contactLinkStyles,
                },
                onClick: () => {
                  window.location.href = `tel:${PRIVACY_PHONENUMBER}`;
                },
              }}
            />
            {'.'}
          </TermsParagraphLight>
        </div>
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
