import { Controller, useFormContext } from 'react-hook-form';

import {
  CustomValueContainer,
  FieldsContainer,
  FormContainer,
  FormSectionHeader,
  RowContainer,
} from '..';
import Input from '@/components/Input';
import InputMask from '@/components/InputMask';
import { masks } from '@/constants/input-masks';
import InputDrop from '@/components/InputDrop';
import selectsData from '@/constants/selects/_index';
import InputDropdown from '@/components/InputDropdown';
import { mediaTypeList } from '@/constants/media-types';
import useDropdownsData from '@/hooks/useDropdownsData';
import { errorMessages } from '@/components/CustomDropZone';
import { ApplicationDetailsFormValues } from '@/types/forms';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import MultiSelectOption from '@/components/InputDropdown/MultiSelectOption';

const ApplicationDetailsForm = () => {
  const { dropdownOptions } = useDropdownsData();
  const {
    control,
    formState: { isSubmitting },
    setValue,
    setError,
  } = useFormContext<ApplicationDetailsFormValues>();

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <FormSectionHeader {...{ header: 'Contact Information' }} />
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'state',
                  control,
                  rules: { ...fieldsDefaultRules['state'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['state'],
                          options: selectsData['state'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'city',
                  control,
                  rules: { ...fieldsDefaultRules['city'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['city'],
                          ...field,

                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'zip',
                  control,
                  rules: { ...fieldsDefaultRules['zip'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['zip'],
                          mask: masks['zip'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'phone',
                  control,
                  rules: { ...fieldsDefaultRules['phone'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['phone'],
                          ...field,
                          mask: masks['phone'],
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>

        {/*  */}

        <FieldsContainer>
          <FormSectionHeader {...{ header: 'Professional Information' }} />
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'title',
                  control,
                  rules: { ...fieldsDefaultRules['title'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['title'],
                          options: dropdownOptions?.profession,
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'assignment_type',
                  control,
                  rules: { ...fieldsDefaultRules['assignment_type'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['assignment_type'],
                          options: dropdownOptions?.assignment,
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'specialty',
                  control,
                  rules: { ...fieldsDefaultRules['specialty'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['specialty'],
                          options: dropdownOptions?.specialty,
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'geographic_preferences',
                  control,
                  rules: { ...fieldsDefaultRules['geographic_preferences'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['geographic_preferences'],
                          ...field,
                          options: selectsData['state'],
                          id: field.name,
                          components: {
                            Option: (rest) => (
                              <MultiSelectOption
                                {...{ ...rest, shownValue: 'value' }}
                              />
                            ),
                            ValueContainer: CustomValueContainer,
                          },
                          isMulti: true,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'state_licenses',
                  control,
                  rules: { ...fieldsDefaultRules['state_licenses'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['state_licenses'],
                          ...field,
                          options: selectsData['state'],
                          id: field.name,
                          components: {
                            Option: (rest) => (
                              <MultiSelectOption
                                {...{ ...rest, shownValue: 'value' }}
                              />
                            ),
                            ValueContainer: CustomValueContainer,
                          },
                          isMulti: true,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'npi_state_license_number',
                  control,
                  rules: { ...fieldsDefaultRules['npi_state_license_number'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['npi_state_license_number'],
                          ...field,
                          id: field.name,
                          mask: masks['npi_state_license_number'],
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
        {/*   */}
        <FieldsContainer>
          <FormSectionHeader {...{ header: 'CV & Linkedin' }} />
          <RowContainer
            {...{
              style: {
                flexDirection: 'column',
              },
            }}
          >
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'resume',
                  control,
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDrop
                        {...{
                          ...fieldsDefaultProps['resume'],
                          ...field,
                          id: field.name,
                          error,
                          removeFileAction: () => {
                            setValue(
                              'resume',
                              {},
                              {
                                shouldTouch: true,
                                shouldDirty: true,
                                shouldValidate: true,
                              }
                            );
                          },

                          dropzoneProps: {
                            maxSize: 1048576,
                            maxFiles: 1,
                            onDrop: (accepted) => {
                              if (!!accepted[0]) {
                                const file = accepted[0];
                                const isFileTypeSupported =
                                  mediaTypeList.includes(file.type);

                                if (isFileTypeSupported) {
                                  setValue('resume', file, {
                                    shouldTouch: true,
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  });
                                  return;
                                }
                                setError('resume', {
                                  type: 'manual',
                                  message: errorMessages.type,
                                });
                                return;
                              }
                              setError('resume', {
                                type: 'manual',
                                message: errorMessages.filesize,
                              });
                              return;
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }

            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'profile_link',
                  control,
                  rules: { ...fieldsDefaultRules['profile_link'] },
                  render: ({ fieldState: { error }, field }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['profile_link'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default ApplicationDetailsForm;
