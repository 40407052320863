import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import Top from '../../assets/images/page_press.jpg';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  li::marker {
    font-weight: bold;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>Locums.com launches as job connector</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeOne: {
              topImageSrc: Top,
              topImageAlt:
                'A pediatrician examining a child in a friendly and caring manner',
              topSectionTitle:
                'Locums.com launches as job connector and so much more',
              topSectionText: (
                <>
                  New resource for healthcare providers and recruiters
                  <br />
                  <br />
                  <b>New York, NY</b> – Announcing the launch of Locums.com, an
                  online connector for locum tenens (temporary) physicians and
                  other advanced healthcare providers and the recruiters that
                  source job opportunities for them.
                  <br />
                  <br />
                  There’s a shortage of healthcare providers to fill the
                  full-time need in healthcare organizations across the country,
                  and facilities use locum tenens staff to bridge coverage gaps.
                  But how do providers match with available temporary jobs?
                  That’s where Locums.com comes in — a connector for recruiters
                  who have the jobs and providers who have availability.
                  <br />
                  <br />
                  <b>Locums.com </b> hosts an easy-to-use job board where any
                  locum tenens recruiters can list open healthcare positions
                  (nationwide), and doctors and other healthcare providers can
                  search the jobs and connect with recruiters.
                  <br />
                  <br />
                  Locums.com hits the online space at a time when more
                  healthcare workers are choosing the temporary, or “traveling
                  healthcare provider” working model. Providers can typically
                  make more per hour than fulltime staff employment, and they
                  like the ability to travel and choose their schedules.
                  <br />
                  <br />
                  The online resource went live in January of 2024 with the goal
                  to have more than 1,000 physicians visiting the site within
                  months. Locums.com also has efforts targeting recruiters to
                  join the space.
                  <br />
                  <br />
                  Locums.com plans to add services and resources beyond the job
                  board and “news” functionalities. Their goal is to emerge as
                  the go-to resource for all things locum tenens for physicians
                  and recruiters across the country. There’s also an online blog
                  where Locums.com will post relevant articles for physicians
                  and recruiters.
                </>
              ),
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
