import { SVGProps, useMemo } from 'react';

const IconAccountProfile = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_86_526)" stroke="#05051B" strokeWidth={1.5}>
          <path
            d="M7.75 19.79A4.79 4.79 0 0112.54 15h.42a4.79 4.79 0 014.79 4.79v0c0 1.22-.99 2.21-2.21 2.21H9.96a2.21 2.21 0 01-2.21-2.21v0z"
            fill="none"
          />
          <rect x={9.75} y={7} width={6} height={6} rx={3} fill="none" />
        </g>
        <rect
          x={3.5}
          y={2.75}
          width={18.5}
          height={18.5}
          rx={9.25}
          stroke="#05051B"
          strokeWidth={1.5}
          fill="none"
        />
        <defs>
          <clipPath id="clip0_86_526">
            <rect x={2.75} y={2} width={20} height={20} rx={10} fill="#fff" />
          </clipPath>
        </defs>
      </svg>
    ),
    [props]
  );

export { IconAccountProfile };
