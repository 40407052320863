import { FC, memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const HEIGHT = 57;
const BORDER_RADIUS = 8;
const BORDER_WIDTH = 1;

export interface SelectButtonProps
  extends React.HTMLAttributes<HTMLDivElement> {
  readonly value?: string | number | any;
  readonly isSelected?: boolean;
  readonly hasError?: boolean;
}

const textStyles = css({
  ...paragraphDesktop.lg,
});

const StyledButton = styled.div<SelectButtonProps>`
  ${textStyles}
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: ${HEIGHT}px;
  border-radius: ${BORDER_RADIUS}px;
  color: ${appColors.content.tertiary};
  background-color: ${appColors.background.primary};
  border: ${BORDER_WIDTH}px solid ${appColors.border.secondary};
  padding: 16px 28px;
  transition: background-color 0.2s ease;
  cursor: pointer;
  ${({ isSelected, hasError }) => ({
    ...(hasError
      ? {
          borderColor: appColors.notInDesignSystem.error,
          color: appColors.notInDesignSystem.error,
        }
      : {}),
    ...(isSelected
      ? {
          borderColor: appColors.border.secondary,
          backgroundColor: appColors.background.tertiary,
          color: appColors.background.primary,
        }
      : {}),
  })}
`;

const SelectButton: FC<SelectButtonProps> = (props) => {
  return (
    <StyledButton
      {...{
        ...props,
      }}
    />
  );
};

export default memo(SelectButton);
