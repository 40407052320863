import { memo } from 'react';
import { css } from '@emotion/react';

import { avatarContainer } from '.';

const textStyles = css`
  text-decoration: none;
  color: inherit;
  text-align: center;
`;

const TextAvatar = ({ text, onClick }: { text?: string, onClick?: () => void }) => {
  const lastNameInitial = text ? text.split('')[0] : '';

  return (
    <div {...{ css: avatarContainer }} onClick={onClick}>
      <p
        {...{
          css: textStyles,
          children: lastNameInitial,
          style: {
            color: 'white',
            fontFamily: 'Hepta Slab',
            fontWeight: 500,
            fontSize: 16,
            textTransform: 'capitalize',
          },
        }}
      />
    </div>
  );
};

export default memo(TextAvatar);
