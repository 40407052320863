import { ComponentProps, FC, memo } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { IconClose } from './Icons/Close';

const {
  components: { iconStyles },
} = layout;

const closeIcon = css`
  ${iconStyles}
  align-self: flex-end;
`;

const CloseIcon: FC<{
  onClick: () => void;
  iconProps?: ComponentProps<typeof IconClose>;
  size?: number;
}> = ({ onClick, iconProps, size }) => {
  return (
    <div
      {...{
        onClick,
        style: { cursor: 'pointer' },
        css: css({
          ...(size ? { width: size, height: size } : closeIcon),
        }),
      }}
    >
      <IconClose {...iconProps} />
    </div>
  );
};

export default memo(CloseIcon);
