import { FC } from 'react';
import styled from '@emotion/styled';

import CustomCheckbox from '../Checkbox/index';

const props = { label: 'Option' };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Checkbox: FC = () => {
  return (
    <Container>
      <CustomCheckbox {...props} />
      <CustomCheckbox {...{ ...props, disabled: true, checked: true }} />
      <CustomCheckbox {...{ ...props, disabled: true, checked: false }} />
    </Container>
  );
};

export default Checkbox;
