import AuthModal from '@/components/AuthModal';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';
import JobBoardShowContent from '@/components/JobBoard/JobBoardShowContent';

export default function JobsShow() {
  return (
    <>
      <TopContentFooterLayout>
        <JobBoardShowContent />
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
