import { useMemo } from 'react';
import usePageSession from '@/hooks/usePageSession';
import { Head } from '@inertiajs/react';

import { css } from '@emotion/react';
import {
  footerStyle,
  topBarStyle,
} from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';



const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
`;

const iFrameStyle = css`
  border: 0;
  height: 125vh;
  margin: auto;
  overflow: hidden;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

const initialNav = [
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function ClaimPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>Claim You Free Tumbler</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>
      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div
        {...{
          css: pageContentStyle,
        }}
      >
        <iframe
        {...{
            css:iFrameStyle,
            src:"https://forms.monday.com/forms/embed/00c7c886d4153ba999ab70706724b9ce?r=use1",

        }}
        ></iframe>
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
    </>
  );
}
