import { ComponentProps, FC, Fragment, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { useForm as useInertiaForm } from '@inertiajs/react';

import {
  CancelNextButtons,
  FormHeader,
  ModalHeader,
  StepperHeaderContainer,
  StepperProgresBar,
} from '@/components/Forms';
import layout from '@/constants/layout';
import { useAppDispatch } from '@/storage/store';
import { PostNewJobFormValues } from '@/types/forms';
import useStepperState from '@/hooks/useStepperState';
import { PostNewJobParams } from '@/types/api/params';
import { getParamValueFromForm } from '@/utils/form-data';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';
import { createJobAsRecruiterThunk } from '@/storage/jobboard/jobboard-actions';
import PostNewJobDetailsForm from '@/components/Forms/NewJobPost/PostNewJobDetailsForm';
import PostNewJobPreviewForm from '@/components/Forms/NewJobPost/PostNewJobPreviewForm';
import PostNewJobDescAndReqsForm from '@/components/Forms/NewJobPost/PostNewJobDescAndReqsForm';

const URL = '/recruiters/jobs';

const {
  utils: { scrollToTop },
} = layout;

const steps: {
  component: FC<any>;
  header?: ComponentProps<typeof FormHeader>;
  buttonProps?: ComponentProps<typeof CancelNextButtons>;
}[] = [
  {
    component: PostNewJobDetailsForm,
    header: {
      title: 'Details',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Cancel',
      },
      nextButtonProps: {},
    },
  },
  {
    component: PostNewJobDescAndReqsForm,
    header: {
      title: 'Description and Requirements',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Back',
      },
      nextButtonProps: {
        children: 'Preview',
      },
    },
  },
  {
    component: PostNewJobPreviewForm,
    header: {
      title: 'Job Post Preview',
    },
    buttonProps: {
      cancelButtonProps: {
        children: 'Back',
      },
      nextButtonProps: {
        children: 'Post this Job',
      },
    },
  },
];

const totalSteps = steps.length;

interface PostNewJobFlowModalContentProps {
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
  attemptToCloseModal: () => void;
}

const PostNewJobFlowModalContent: FC<PostNewJobFlowModalContentProps> = ({
  modalLayoutProps,
  attemptToCloseModal,
}) => {
  const { processing } = useInertiaForm();
  const dispatch = useAppDispatch();

  const {
    state: { currentStep },
    actions: { goNext, goBack },
  } = useStepperState({
    totalSteps,
    nextActions: {
      lastStepAction: async () => {
        closeAndRefresh();
      },
    },
    backActions: {
      lastStepAction: async () => {
        beforeCloseModal();
      },
    },
  });

  const newJobMethods = useForm<PostNewJobFormValues>({
    defaultValues: {
      // Details
      title: '',
      profession: '',
      employment_type: '',
      assignment: '',
      facility: '',
      board_certification: '',
      credentialing_time: '',
      target_start_date: '',
      specialty: '',
      state: '',
      min_hourly_rate: '',
      any_state_licenses: '',
      // RECRUITERS ONLY //
      reference_number: '',
      facility_name: '',
      city: '',
      notes: '',
      // Description and Requirements
      description: '',
      requirements: '',
    },
    mode: 'onSubmit',
  });

  const {
    formState: { isSubmitting },
    watch,
  } = newJobMethods;

  const isRequesting = processing || isSubmitting;

  const closeAndRefresh = useCallback(() => {
    close();
  }, []);

  const beforeCloseModal = useCallback(async () => {
    !!attemptToCloseModal && attemptToCloseModal();
  }, []);

  const onContinue = useCallback(
    async (form: PostNewJobFormValues) => {
      // Details
      if (currentStep == 0) {
        goNext();
        scrollToTop();
      }
      // Description and Requirements
      if (currentStep == 1) {
        goNext();
        scrollToTop();
      }
      // Job Post Preview
      if (currentStep == 2) {
        const params = {
          job: {
            // DETAILS //
            title: form.title,
            //
            ...getParamValueFromForm('profession', form?.profession),
            ...getParamValueFromForm('employment_type', form?.employment_type),
            // employment_type: form.employment_type.value || 'all',
            ...getParamValueFromForm('assignment', form?.assignment),
            ...getParamValueFromForm('facility', form?.facility),
            ...getParamValueFromForm('specialty', form?.specialty),
            //
            ...getParamValueFromForm(
              'board_certification',
              form?.board_certification
            ),
            ...getParamValueFromForm(
              'credentialing_time',
              form?.credentialing_time
            ),
            target_start_date: form.target_start_date,
            ...getParamValueFromForm('state', form?.state),
            //
            min_hourly_rate: form.min_hourly_rate,
            ...getParamValueFromForm(
              'any_state_licenses',
              form?.any_state_licenses
            ),
            // RECRUITERS ONLY
            reference_number: form.reference_number,
            facility_name: form.facility_name,
            city: form.city,
            notes: form.notes,
            // DESC AND REQS //
            description: form.description,
            requirements: form.requirements,
          },
        } as PostNewJobParams;

        try {
          const response = await dispatch(
            createJobAsRecruiterThunk(params)
          ).unwrap();
          if (!!response) {
            modalLayoutProps.closeModal();
            defaultToastSuccess('', 'Your job has been posted');
            window.location.reload();
          }
        } catch (error) {
          defaultToastError('There was an error', 'Please try agin.');
        }

        // TODO? For some reason, using the inertia form method sends an empty object as the params ?
        // const metaTokenHeader = await getMetaToken();
        // if (!!metaTokenHeader) {
        //   post(URL, {
        //     preserveState: true,
        //     data: transform(() => ({
        //       ...params
        //     })),
        //     headers: {
        //       ...metaTokenHeader,
        //     },
        //     onSuccess: (successResponse) => {
        //       console.log({ successResponse });
        //       defaultToastSuccess('', 'Your job has been posted');
        //     },
        //     onError: (errorResponse) => {
        //       console.log({ errorResponse });
        //     },
        //   });
        // }

        return;
      }
    },
    [currentStep]
  );

  const detailsFieldValues = watch([
    'title',
    'profession',
    'assignment',
    //'board_certification',
    'specialty',
    'state',
    'min_hourly_rate',
    //'credentialing_time',
    //'target_start_date',
    //'any_state_licenses',
  ]);

  const descReqsFieldValues = watch(['description', 'requirements']);

  const getNextDisabled = useMemo(() => {
    // Details
    if (currentStep == 0) {
      const isDetailsDisabled = detailsFieldValues.includes('');
      return isDetailsDisabled;
    }
    // Description and Requirements
    if (currentStep == 1) {
      const isDescReqsDisabled = descReqsFieldValues.includes('');
      return isDescReqsDisabled;
    }
    // Job Post Preview
    if (currentStep == 2) {
      return false;
    }
  }, [currentStep, detailsFieldValues, descReqsFieldValues]);

  const { header } = steps[currentStep];

  return (
    <ModalLayout
      {...{
        ...modalLayoutProps,
        closeModal: beforeCloseModal,
        isLoading: isRequesting,
      }}
    >
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: column;
            gap: 8px;
          `,
        }}
      >
        <ModalHeader {...{ header: 'Post a New Job' }} />
        <FormHeader {...header} />
      </div>
      <div {...{ style: { paddingBottom: 32 } }} />
      <StepperHeaderContainer {...{}}>
        <StepperProgresBar
          {...{
            total: totalSteps,
            current: currentStep,
          }}
        />
      </StepperHeaderContainer>

      {steps.map(({ component: Form, header, buttonProps }, index) => {
        if (index === currentStep) {
          return (
            <Fragment key={index}>
              <Form
                {...{
                  methods: newJobMethods,
                  key: index,
                }}
              />
              <div {...{ style: { paddingBottom: 32 } }} />
              <CancelNextButtons
                {...{
                  cancelButtonProps: {
                    disabled: isRequesting,
                    onClick:
                      currentStep !== 0
                        ? () => {
                            goBack();
                            scrollToTop();
                          }
                        : beforeCloseModal,
                    ...buttonProps?.cancelButtonProps,
                  },
                  nextButtonProps: {
                    //disabled: getNextDisabled,
                    onClick: newJobMethods.handleSubmit(onContinue),
                    ...buttonProps?.nextButtonProps,
                  },
                }}
              />
            </Fragment>
          );
        }
      })}
    </ModalLayout>
  );
};

export default PostNewJobFlowModalContent;
