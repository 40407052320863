import { SVGProps, useMemo } from 'react';

import { appColors } from '@/constants/colors';

const IconValidationX = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={21}
        height={21}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10.5 18.833a8.333 8.333 0 100-16.666 8.333 8.333 0 000 16.666z"
          fill={appColors.content.placeholder}
        />
        <path
          d="M6.964 7.167l7.072 7.07M6.965 14.238l7.07-7.071"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    [props]
  );

const IconValidationChecked = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={20}
        height={20}
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.833 10.5a8.333 8.333 0 11-16.666 0 8.333 8.333 0 0116.666 0zm-3.636-2.386a.75.75 0 10-1.06-1.061l-5.304 5.303-1.97-1.97a.75.75 0 00-1.06 1.06l2.5 2.5a.75.75 0 001.06 0l5.834-5.832z"
          fill={appColors.content.tertiary}
        />
      </svg>
    ),
    [props]
  );

export { IconValidationX, IconValidationChecked };
