import { FC } from 'react';
import styled from '@emotion/styled';

import CustomInput, { InputProps } from '../Input/index';

const props: InputProps = { label: 'Option', placeholder: 'Placeholder Text' };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const Input: FC = () => {
  return (
    <Container>
      <CustomInput {...props} />
      <CustomInput {...{ ...props, disabled: true }} />
      <CustomInput
        {...{ ...props, error: { message: 'Error message', type: 'error' } }}
      />
    </Container>
  );
};

export default Input;
