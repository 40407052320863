import { Controller, UseFormReturn } from 'react-hook-form';

import Input from '@/components/Input';
import InputArea from '@/components/InputArea';
import { ContactUsFormValues } from '@/types/forms';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { FieldsContainer, FormContainer, RowContainer } from '..';
import { fieldsDefaultRules } from '@/constants/form-validations';

const INPUT_HEIGHT = 135;

const ContactForm = ({
  methods,
}: {
  methods: UseFormReturn<ContactUsFormValues, any>;
}) => {
  const { control } = methods;

  return (
    <form>
      <FormContainer {...{ style: { gap: 24 } }}>
        <FieldsContainer {...{ style: { gap: 24 } }}>
          <RowContainer {...{ style: { gap: 24 } }}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'first_name',
                  control,

                  rules: { ...fieldsDefaultRules['first_name'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['first_name'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'last_name',
                  control,

                  rules: { ...fieldsDefaultRules['last_name'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['last_name'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'email',
                control,
                rules: { ...fieldsDefaultRules['email'] },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['email'],
                        ...field,
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'message',
                control,

                rules: { ...fieldsDefaultRules['message'], pattern: {} },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <InputArea
                      {...{
                        ...field,
                        style: {
                          height: INPUT_HEIGHT,
                        },
                        label: 'Message',
                        placeholder: 'Enter your message here',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default ContactForm;
