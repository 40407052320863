import { FC } from 'react';
import styled from '@emotion/styled';

import CustomToast, { ToastProps } from '../Toast/index';

const EXAMPLES: ToastProps[] = [
  {
    type: 'success',
    msg: 'Your application has been sent',
  },
  {
    type: 'warning',
    msg: 'Hmmm something is happening...',
  },
  {
    type: 'error',
    msg: 'Something’s not right...',
  },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const Toast: FC = () => {
  return (
    <Container>
      {EXAMPLES.map((item) => {
        return <CustomToast {...{ ...item, key: item.type }} />;
      })}
    </Container>
  );
};

export default Toast;
