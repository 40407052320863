import { FC, ReactNode, memo } from 'react';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';

const SIZE = 24;

const TEXT_COLOR = appColors.content.primary;
const BORDER_WIDTH = 1;
const BORDER_COLOR = TEXT_COLOR;

const container = css`
  display: flex;
  width: ${SIZE}px;
  height: ${SIZE}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${TEXT_COLOR};
  border: ${BORDER_WIDTH}px solid ${BORDER_COLOR};
  border-radius: 24px;
  //
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
`;

interface BorderedNumberProps {
  children: ReactNode;
}

const BorderedNumber: FC<BorderedNumberProps> = ({ children }) => {
  return <div {...{ css: container, children }} />;
};

export default memo(BorderedNumber);
