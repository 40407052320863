import 'react-toastify/dist/ReactToastify.min.css';
import './index.css';

import { enableMapSet } from 'immer';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';

import { store } from '@/storage/store';
import { ToastContainer } from 'react-toastify';
import AppWrapper from '@/components/AppWrapper';

enableMapSet();

const pages = import.meta.globEager('../Pages/**/**.tsx');

const app = document.getElementById('app');

if (app) {
  createInertiaApp({
    resolve: (name: String) => {
      const page = pages[`../Pages/${name}.tsx`];
      if (!page) {
        throw new Error(`Could not resolve page '${name}'`);
      }

      return page;
    },
    setup({ el, App, props }) {
      const root = createRoot(el);
      root.render(
        // <React.StrictMode>
        <Provider {...{ store }}>
          <AppWrapper>
            <ToastContainer
              {...{
                autoClose: 2500,
                position: 'top-center',
                style: {
                  backgroundColor: 'transparent',
                },
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              }}
            />
            <App {...props} />
          </AppWrapper>
        </Provider>
        // </React.StrictMode>
      );
    },
  });
}
