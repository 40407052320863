import { ComponentProps, FC, Fragment, memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  FormHeader,
  SaveSkipButtons,
  StepperHeaderContainer,
  StepperProgresBar,
  resetFormDefaultOptions,
} from '../Forms';
import {
  SignupPhysicianCVFormValues,
  SignupPhysicianContactInfoFormValues,
  SignupPhysicianProfessionalDetailsFormValues,
} from '@/types/forms';
import {
  signupCloseThunk,
  signupPhysicianCVThunk,
  signupPhysicianContactInfoThunk,
  signupPhysicianProfessionalDetailsThunk,
} from '@/storage/auth/auth-actions';
import { useAppDispatch } from '@/storage/store';
import useModalState from '@/hooks/useModalState';
import ModalLayout from '../Layout/Modal/ModalLayout';
import useStepperState from '@/hooks/useStepperState';
import { afterSignupRedirect } from '@/constants/redirects';
import SignupPhysicianCVForm from '../Forms/Signup/Physycian/SignupPhysicianCVForm';
import SignupPhysicianContactInfoForm from '../Forms/Signup/Physycian/SignupPhysicianContactInfoForm';
import SignupPhysicianProfessionalDetailsForm from '../Forms/Signup/Physycian/SignupPhysicianProfessionalDetailsForm';

interface SignupPhysicianFlowProps {
  readonly id: number;
}

const steps: {
  component: FC<any>;
  header?: ComponentProps<typeof FormHeader>;
  buttonProps?: ComponentProps<typeof SaveSkipButtons>;
}[] = [
  {
    component: SignupPhysicianContactInfoForm,
    header: {
      title: 'Contact Information',
    },
    buttonProps: {
      saveButtonProps: {},
      skipButtonProps: {},
    },
  },
  {
    component: SignupPhysicianProfessionalDetailsForm,
    header: {
      title: 'Professional Information',
    },
    buttonProps: {
      saveButtonProps: {},
      skipButtonProps: {},
    },
  },
  {
    component: SignupPhysicianCVForm,
    header: {
      title: 'Upload Your CV',
      subtitle: 'Upload your CV and include a link to your Linkedin profile.',
      type: 'gray',
    },
    buttonProps: {
      saveButtonProps: {
        children: 'Save & Finish',
      },
      skipButtonProps: {},
    },
  },
];

const totalSteps = steps.length;

const SignupPhysicianFlow: FC<SignupPhysicianFlowProps> = ({ id }) => {
  const dispatch = useAppDispatch();
  const {
    actions: { close },
  } = useModalState();

  const {
    state: { canGoBack, currentStep },
    actions: { onSkip, goNext, goBack },
  } = useStepperState({
    totalSteps,
    nextActions: {
      lastStepAction: async () => {
        closeAndRefresh();
      },
    },
    skipActions: {
      lastStepAction: async () => {
        beforeCloseModal();
      },
    },
  });

  const contactInfoMethods = useForm<SignupPhysicianContactInfoFormValues>({
    defaultValues: {
      //@ts-ignore
      state: '',
      city: '',
      zip: '',
      phone: '',
    },
    mode: 'onChange',
  });

  const professionalDetailsMethods =
    useForm<SignupPhysicianProfessionalDetailsFormValues>({
      defaultValues: {
        //@ts-ignore
        title: '',
        //@ts-ignore
        assignment_type: '',
        //@ts-ignore
        specialty: '',
        //@ts-ignore
        geographic_preferences: '',
        //@ts-ignore
        state_licenses: '',
        npi_state_license_number: '',
      },
      mode: 'onChange',
    });

  const cvMethods = useForm<SignupPhysicianCVFormValues>({
    defaultValues: {
      resume: undefined,
      profile_link: '',
    },
    mode: 'onChange',
  });

  const methodsByindex: Record<number, any> = {
    0: contactInfoMethods,
    1: professionalDetailsMethods,
    2: cvMethods,
  };

  const currentFormMethods = methodsByindex[currentStep];

  const {
    formState: { isSubmitting },
    reset,
  } = currentFormMethods;

  const closeAndRefresh = useCallback(() => {
    close();
    afterSignupRedirect();
  }, []);

  const beforeCloseModal = useCallback(async () => {
    const response = await dispatch(
      signupCloseThunk({ id, resource: 'provider' })
    ).unwrap();

    if (!!response) {
      closeAndRefresh();
    }
  }, []);

  const onSaveAndContinue = useCallback(
    async (form: any) => {
      // Contact Information
      if (currentStep == 0) {
        const formValues = form as SignupPhysicianContactInfoFormValues;
        try {
          const contactInfoForm = {
            ...formValues,
            state: formValues.state.value,
          };

          const response = await dispatch(
            signupPhysicianContactInfoThunk({
              id,
              params: {
                ...contactInfoForm,
                next_signup_status: 'professional_info',
              },
            })
          ).unwrap();
          if (!!response) {
            reset(
              {
                formValues,
              },
              resetFormDefaultOptions
            );
            goNext();
          }
        } catch (error) {}
        return;
      }
      // Professional Information
      if (currentStep == 1) {
        const formValues = form as SignupPhysicianProfessionalDetailsFormValues;
        const hasStateLicense = formValues?.state_licenses.length > 0;
        const hasGeoPref = formValues?.geographic_preferences.length > 0;

        const professionalDetailsForm = {
          ...formValues,
          title: formValues.title.value,
          assignment_type: formValues.assignment_type.value,
          specialty: formValues.specialty.value,
          geographic_preferences: hasGeoPref
            ? formValues.geographic_preferences.map((item) => item.value)
            : [],
          state_licenses: hasStateLicense
            ? formValues.state_licenses.map((item) => item.value)
            : [],
        };

        try {
          const response = await dispatch(
            signupPhysicianProfessionalDetailsThunk({
              id,
              params: {
                professional_detail_attributes: { ...professionalDetailsForm },
                next_signup_status: 'cv_upload',
              },
            })
          ).unwrap();
          if (!!response) {
            reset(
              {
                formValues,
              },
              resetFormDefaultOptions
            );
            goNext();
          }
        } catch (error) {}
        return;
      }
      // Upload Your CV
      if (currentStep == 2) {
        try {
          const formValues = form as SignupPhysicianCVFormValues;

          const response = await dispatch(
            signupPhysicianCVThunk({
              id,
              params: {
                ...formValues,
                next_signup_status: 'signup_completed',
              },
            })
          ).unwrap();
          if (!!response) {
            reset(
              {
                formValues,
              },
              resetFormDefaultOptions
            );
            goNext();
          }
        } catch (error) {}
        return;
      }
    },
    [currentStep, id]
  );

  return (
    <ModalLayout
      {...{
        closeModal: beforeCloseModal,
        isLoading: isSubmitting,
        hasLogo: true,
      }}
    >
      <StepperHeaderContainer {...{ canGoBack }}>
        <StepperProgresBar
          {...{
            total: totalSteps,
            current: currentStep,
            canGoBack,
            onClickBack: goBack,
          }}
        />
      </StepperHeaderContainer>
      {steps.map(({ component: Form, header, buttonProps }, index) => {
        if (index === currentStep) {
          return (
            <Fragment key={index}>
              <FormHeader {...header} />
              <div {...{ style: { paddingBottom: 32 } }} />
              <Form
                {...{
                  onSaveAndContinue,
                  onSkip,
                  header,
                  buttonProps,
                  key: index,
                  methods: currentFormMethods,
                }}
              />
              <div {...{ style: { paddingBottom: 32 } }} />
              <SaveSkipButtons
                {...{
                  saveButtonProps: {
                    disabled:
                      !currentFormMethods.formState.isValid ||
                      !currentFormMethods.formState.isDirty ||
                      currentFormMethods.formState.isSubmitting,
                    onClick: currentFormMethods.handleSubmit(onSaveAndContinue),
                    ...buttonProps?.saveButtonProps,
                  },
                  skipButtonProps: {
                    disabled: isSubmitting,
                    onClick: onSkip,
                    ...buttonProps?.skipButtonProps,
                  },
                }}
              />
            </Fragment>
          );
        }
      })}
    </ModalLayout>
  );
};

export default memo(SignupPhysicianFlow);
