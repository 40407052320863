import { ComponentProps, FC, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import { useForm as useInertiaForm } from '@inertiajs/react';

import { getMetaToken } from '@/utils/meta-token';
import { UploadCSVFormValues } from '@/types/forms';
import { UploadCSVParams } from '@/types/api/params';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { CancelNextButtons, FormHeader } from '@/components/Forms';
import UploadCSVForm from '@/components/Forms/UploadCSV/UploadCSVForm';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';

const URL = '/recruiters/jobs/csv_upload';

interface UploadCSVFlowModalContentProps {
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
}

const UploadCSVFlowModalContent: FC<UploadCSVFlowModalContentProps> = ({
  modalLayoutProps,
}) => {
  const [errors, setErrors] = useState<string[]>([]);

  const hasErrors = errors.length > 0;

  const { post, transform, processing } = useInertiaForm();

  const methods = useForm<UploadCSVFormValues>({
    defaultValues: {
      csv: undefined,
    },
    mode: 'onSubmit',
  });

  const {
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
  } = methods;

  const isRequesting = processing || isSubmitting;

  const onClickCancel = useCallback(() => {
    !!modalLayoutProps?.closeModal && modalLayoutProps.closeModal();
  }, []);

  const cleanErrors = useCallback(() => {
    setErrors([]);
  }, []);

  const onClickUpload = useCallback(async (form: UploadCSVFormValues) => {
    cleanErrors();
    try {
      const params: UploadCSVParams = {
        csv: form.csv,
      };
      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        post(URL, {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
          onSuccess: (successResponse: { props: { csv_errors: string[] } }) => {
            const {
              props: { csv_errors },
            } = successResponse;

            if (!!csv_errors) {
              const hasCsvErrors = csv_errors.length > 0;
              if (hasCsvErrors) {
                defaultToastError(null, 'CSV upload error. Please try again');
                setErrors(csv_errors);
              } else {
                defaultToastSuccess(null, 'CSV has been uploaded');
                !!modalLayoutProps?.closeModal && modalLayoutProps.closeModal();
              }
            }

            // CHECK RESPONSE FOR ERRORS []
            // IF errors.length > 0 // GOT ERRORS

            // IF NOT

            // CLOSEMODAL
          },
          onError: (errorResponse) => {
            console.log({ errorResponse });
          },
        });
      }
    } catch (error) {}
  }, []);

  return (
    <ModalLayout
      {...{
        ...modalLayoutProps,
        closeModal:
          !!modalLayoutProps?.closeModal && modalLayoutProps.closeModal,
        isLoading: isRequesting,
      }}
    >
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: column;
            gap: 32px;
          `,
        }}
      >
        <FormHeader {...{ title: 'Upload a CSV' }} />
        <UploadCSVForm {...{ methods, csvErrors: errors, cleanErrors }} />
        <CancelNextButtons
          {...{
            cancelButtonProps: {
              variant: '5',
              onClick: onClickCancel,
              disabled: isRequesting,
            },
            nextButtonProps: {
              children: 'Upload',
              onClick: handleSubmit(onClickUpload),
              icon: undefined,
              disabled: isRequesting || !isDirty || !isValid || hasErrors,
            },
          }}
        />
      </div>
    </ModalLayout>
  );
};

export default UploadCSVFlowModalContent;
