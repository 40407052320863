import { FC, ReactNode, memo } from 'react';
import { Tooltip } from 'react-tooltip';

import { css } from '@emotion/react';
import { appColors } from '@/constants/colors';

const BORDER_RADIUS = 16;

const tooltipContainer = css`
  padding: 16px;
  border-radius: ${BORDER_RADIUS}px;
  background-color: ${appColors.content.primary};
  text-align: center;
  align-items: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  max-width: 150px;
`;

interface RemainingProps {
  children: ReactNode;
  id?: string;
  hidden?: boolean;
}

const TooltipMessage: FC<RemainingProps> = ({
  children,
  id = 'tooltip-message',
  hidden,
}) => {
  return (
    <Tooltip
      {...{
        id,
        offset: 8,
        noArrow: true,
        css: tooltipContainer,
        children,
        hidden,
      }}
    />
  );
};

export default memo(TooltipMessage);
