import { FC, memo } from 'react';

import Button from '../Button';
import { IconCheck } from '../Icons/Check';
import { IconVectorRight } from '../Icons/Vector';
import usePageSession from '@/hooks/usePageSession';

interface ApplyButtonProps {
  onClick?: () => void;
  hasApplied: boolean;
}

const ApplyButton: FC<ApplyButtonProps> = ({ onClick, hasApplied, ...rest }) => {
  const {
    session: { signed_in },
  } = usePageSession();

  const applyNowText = signed_in ? 'Apply Now' : 'Apply';

  return (
    <Button
      {...{
        ...rest,
        ...(hasApplied ? {} : { onClick }),
        children: `${hasApplied ? 'Applied' : applyNowText}`,
        icon: hasApplied ? IconCheck : IconVectorRight,
      }}
    />
  );
};

export default memo(ApplyButton);
