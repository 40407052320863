import { FC, memo, useCallback, useReducer, useState } from 'react';
import { css } from '@emotion/react';

import Card from '../Card';
import Avatar from '../Avatar';
import layout from '@/constants/layout';
import { Job } from '@/types/entities.d';
import BookMarked from '../Job/BookMarked';
import { IconMapPin } from '../Icons/MapPin';
import { IconDollar } from '../Icons/Dollar';
import { appColors } from '@/constants/colors';
import { IconArrowRight } from '../Icons/Arrow';
import usePageSession from '@/hooks/usePageSession';
import JobDetail from '@/components/JobTiles/JobDetail';
import JobListTileHeader from '@/components/JobTiles/JobListTileHeader';
import { initialState, reducer } from '@/constants/contexts/job-context';

const HOVER_COLOR = appColors.content.tertiary;

const {
  windowSizes: { tablet },
  components: { iconStyles },
} = layout;

interface JobListTileProps {
  item: Job;
  selectedItemId: number | null;
  selectTile?: () => void;
  onClickFavorited?: (id: any) => Promise<void>;
}

const jobListTileStyles = css`
  cursor: pointer;
  width: 100%;
  @media (max-width: ${tablet}px) {
    column-gap: 0px;
    row-gap: 16px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`;

const jobListTileInfoStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 100%;
`;

const bookmarkedStyles = css`
  display: flex;
  margin-left: 8px;
`;

const JobListTileSmall: FC<JobListTileProps> = (props) => {
  const [item, dispatch] = useReducer(reducer, {
    ...initialState,
    ...props.item,
  });

  const {
    session: { signed_in },
    session,
  } = usePageSession();

  const isAdmin = session?.current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <Card
      {...{
        css: css({
          ...jobListTileStyles,
        }),
        style: {
          ...(isHovered ? { borderColor: HOVER_COLOR } : {}),
          ...(props.selectedItemId == item.id
            ? { borderColor: HOVER_COLOR }
            : {}),
        },
        onMouseOver: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onClick: props.selectTile,
      }}
    >
      <div {...{ css: jobListTileInfoStyles }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'flex-start',
          }}
        >
          <JobListTileHeader
            {...{
              title: item.title || '',
              style: {
                ...(isHovered ? { color: HOVER_COLOR } : {}),
              },
            }}
          />

          <div {...{ css: bookmarkedStyles }}>
            <BookMarked
              {...{
                id: item.id,
                isActive: item.is_favorite,
                action: () => {
                  !!props?.onClickFavorited && props.onClickFavorited(item.id);
                },
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
            {!!props?.item?.recruiter_data?.company_information?.logo_url && (
              <Avatar
                {...{
                  url: props?.item?.recruiter_data?.company_information
                    ?.logo_url,
                  size: 40,
                }}
              />
            )}
            <JobDetail
              {...{
                icon: IconDollar,
                detail: `$${item?.min_hourly_rate}+/hr`,
                isBlurred: !isSignedIn || (session?.current_user?.is_type === 'recruiter' && item?.recruiter_id !== session?.current_user?.id), //not signed in, or is not the recruiter who posted it
              }}
            />
            <JobDetail
              {...{
                icon: IconMapPin,
                detail: item.state,
              }}
            />
          </div>

          <div
            {...{
              css: css`
                ${iconStyles}
                display: none;
                @media (max-width: ${tablet}px) {
                  display: flex;
                }
              `,
            }}
          >
            <IconArrowRight style={{ height: 24, width: 24 }} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(JobListTileSmall);
