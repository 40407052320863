import { CSSProperties, FC, memo } from 'react';
import { SerializedStyles, css } from '@emotion/react';

import { IconCheck } from '../Icons/Check';
import { IconAlert } from '../Icons/Alert';
import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';
import Paragraph from '../Typography/Paragraph';
import { IconNotificationOn } from '../Icons/Notification';

const BORDER_WIDTH = 1;
const BORDER_RADIUS = 16;
const ICON_SIZE = 24;
const HEIGHT = 65;
//
const SUCCESS_BORDER_COLOR = appColors.border.secondary;
//
const WARNING_BORDER_COLOR = appColors.border.quaternary;
const WARNING_TEXT_COLOR = appColors.content.secondary;
//
const ERROR_BORDER_COLOR = appColors.notInDesignSystem.error;

export const TOAST_COMMONS = {
  BORDER_WIDTH,
  BORDER_RADIUS,
  ICON_SIZE,
  SUCCESS_BORDER_COLOR,
  WARNING_BORDER_COLOR,
  WARNING_TEXT_COLOR,
  ERROR_BORDER_COLOR,
  HEIGHT,
};

export type ToastType = 'success' | 'warning' | 'error';

export interface ToastProps {
  readonly type?: ToastType;
  readonly msg?: string | null;
  readonly style?: CSSProperties;
  readonly closeToast?: (() => void) | undefined;
}

const containerStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #ffffff;
  height: ${HEIGHT}px;
  border-radius: ${BORDER_RADIUS}px;
  border: ${BORDER_WIDTH}px solid #ffffff;
  padding: 0 40px;
  z-index: 9999;
`;

const iconContainerStyles = css`
  height: ${ICON_SIZE}px;
  width: ${ICON_SIZE}px;
`;

const toastPropsByType: Record<
  ToastType,
  {
    containerStyle: SerializedStyles;
    textStyle: CSSProperties;
    iconProps: IconCustomProps;
    icon: any;
    text: string;
  }
> = {
  success: {
    containerStyle: css`
      border-color: ${SUCCESS_BORDER_COLOR};
    `,
    textStyle: { color: SUCCESS_BORDER_COLOR },
    iconProps: {
      iconColor: SUCCESS_BORDER_COLOR,
    },
    icon: IconCheck,
    text: 'Your application has been sent',
  },
  warning: {
    containerStyle: css`
      border-color: ${WARNING_BORDER_COLOR};
    `,
    textStyle: {
      color: WARNING_TEXT_COLOR,
    },
    iconProps: {
      iconColor: WARNING_TEXT_COLOR,
    },
    icon: IconNotificationOn,
    text: 'Hmmm something is happening...',
  },
  error: {
    containerStyle: css`
      border-color: ${ERROR_BORDER_COLOR};
    `,
    textStyle: {
      color: ERROR_BORDER_COLOR,
    },
    iconProps: {
      iconColor: ERROR_BORDER_COLOR,
    },
    icon: IconAlert,
    text: 'Something’s not right...',
  },
};

const Toast: FC<ToastProps> = ({ type = 'warning', msg, style }) => {
  const {
    containerStyle,
    textStyle,
    iconProps,
    icon: Icon,
    text,
  } = toastPropsByType[type];

  return (
    <div
      {...{
        css: css`
          ${containerStyles}
          ${containerStyle}
        `,
        style,
      }}
    >
      <Paragraph
        {...{
          variant: 'lg',
          children: msg || text,
          style: {
            ...textStyle,
            whiteSpace: 'nowrap',
          },
        }}
      />
      <div {...{ css: iconContainerStyles }}>
        <Icon {...iconProps} />
      </div>
    </div>
  );
};

export default Toast;
