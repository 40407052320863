import AuthModal from '@/components/AuthModal';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';
import RecruitersMyDetails from '@/components/Dashboard/Recruiter/RecruitersMyDetails';

export default function Details() {
  return (
    <>
      <TopContentFooterLayout>
        <RecruitersMyDetails />
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
