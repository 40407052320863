import { FC, memo } from 'react';
import { animated, useSpring } from '@react-spring/web';
import styled from '@emotion/styled';

import { appColors } from '@/constants/colors';

const BORDER_RADIUS = 4;

export interface AnimatedBarProps {
  readonly toValue: number;
  readonly duration?: number;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: ${BORDER_RADIUS}px;
  background-color: ${appColors.border.primary};
`;

const Bar = styled(animated.div)`
  display: flex;
  background-color: ${appColors.border.secondary};
  height: 8px;
  width: 100%;
  border-radius: ${BORDER_RADIUS}px;
`;

const AnimatedBar: FC<AnimatedBarProps> = ({ toValue, duration = 350 }) => {
  const animatedStyles = useSpring({
    from: { width: '0%' },
    to: { width: `${toValue}%` },
    config: { duration },
  });

  return (
    <Container>
      <Bar
        style={{
          ...animatedStyles,
        }}
      />
    </Container>
  );
};

export default memo(AnimatedBar);
