import { CSSProperties } from 'react';

type HeadingVariants = 'h1' | 'h1Small' | 'h2' | 'h3';

const headingDefaults: CSSProperties = {
  fontFamily: 'Hepta Slab, sans-serif',
  fontStyle: 'normal',
  lineHeight: '140%',
};

const headingDesktop: Record<HeadingVariants, CSSProperties> = {
  h1: {
    ...headingDefaults,
    fontSize: 60,
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  h1Small: {
    ...headingDefaults,
    fontSize: 40,
    fontWeight: 500,
    letterSpacing: '-0.02em',
  },
  h2: {
    ...headingDefaults,
    fontFamily: 'Inter',
    fontSize: 32,
    fontWeight: 400,
  },
  h3: {
    ...headingDefaults,
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: 24,
  },
};

const headingMobile: Record<HeadingVariants, CSSProperties> = {
  h1: { ...headingDesktop.h1, fontSize: 48 },
  h1Small: { ...headingDesktop.h1Small, fontSize: 24 },
  h2: { ...headingDesktop.h2, fontSize: 24 },
  h3: { ...headingDesktop.h3, fontSize: 20 },
};

type ParagraphVariants = 'lg' | 'md' | 'sm' | 'xs';

const paragraphDefaults: CSSProperties = {
  fontFamily: 'Inter, sans-serif',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '140%',
};

const paragraphDesktop: Record<ParagraphVariants, CSSProperties> = {
  lg: {
    ...paragraphDefaults,
    fontSize: 15,
  },
  md: {
    ...paragraphDefaults,
    fontSize: 15,
  },
  sm: {
    ...paragraphDefaults,
    fontSize: 12,
  },
  xs: {
    ...paragraphDefaults,
    fontSize: 8,
  },
};

const paragraphMobile: Record<ParagraphVariants, CSSProperties> = {
  lg: { ...paragraphDesktop.lg, fontSize: 16 },
  md: { ...paragraphDesktop.md, fontSize: 14 },
  sm: { ...paragraphDesktop.sm, fontSize: 12 },
  xs: { ...paragraphDesktop.sm, fontSize: 8 },
};

export {
  paragraphDesktop,
  paragraphMobile,
  headingDesktop,
  headingMobile,
  HeadingVariants,
  ParagraphVariants,
};
