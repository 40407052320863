import { memo } from 'react';
import styled from '@emotion/styled';

import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import { headingDesktop } from '@/constants/typography';

const { mediaQuery } = layout;

const PageHeader = styled.span({
  userSelect: 'none',
  color: appColors.content.primary,
  ...headingDesktop.h1,
  fontSize: 40,
  [mediaQuery.mobile]: {
    ...headingDesktop.h1Small,
    fontSize: 24,
  },
});

export default memo(PageHeader);
