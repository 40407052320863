import Axios from './axios-api';

import { GetDropdownDataResponse } from '@/types/api/responses.d';

export type PromiseCompany = {
  readonly avatar: string;
  readonly name: string;
  readonly url: string;
};

const DROPDOWNS = '/job_board/dropdowns';

const fetchDropdownData = async () => {
  const axios = new Axios('');
  try {
    const response = await axios.get<GetDropdownDataResponse>(DROPDOWNS, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status === 200) {
      return response.data;
    }

    return Promise.reject();
  } catch (error) {
    return Promise.reject(error);
  }
};

export { fetchDropdownData };
