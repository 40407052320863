import styled from '@emotion/styled';
import { FC, memo, useState } from 'react';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { ShareIcon } from '../Icons/ShareIcon';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton, MenuItem, Menu } from '@mui/material';
import { appColors } from '@/constants/colors';
import Card from '../Card';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import CustomTooltip from '../Tooltip';

interface MenuProps {
  linkedinUrl: string;
  emailUrl: string;
  xUrl: string;
  facebookUrl: string;
  hasCardWrap?: boolean;
}

const ShareButton: FC<MenuProps> = ({
  linkedinUrl,
  emailUrl,
  hasCardWrap,
  xUrl,
  facebookUrl,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };
  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    setIsHovered(false);
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const renderShareMenu = () => (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      disableScrollLock={true}
    >
      <MenuItem onClick={handleClose}>
        <LinkedinShareButton
          url={linkedinUrl}
          title="I wanted to share this exciting locum tenens job opportunity. Please apply, tag someone else who might be interested, or send this to a friend."
          summary=""
          source=""
        >
          <LinkedInIcon sx={iconStyles} />
        </LinkedinShareButton>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <FacebookShareButton
          url={facebookUrl}
          title="I wanted to share this exciting locum tenens job opportunity. Please apply, tag someone else who might be interested, or send this to a friend."
        >
          <FacebookIcon sx={iconStyles} />
        </FacebookShareButton>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <TwitterShareButton
          url={xUrl}
          title="I wanted to share this exciting locum tenens job opportunity. Please apply, tag someone else who might be interested, or send this to a friend."
        >
          <XIcon sx={iconStyles} />
        </TwitterShareButton>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <EmailShareButton
          url=""
          subject="I'm sharing a Locums.com job opportunity with you"
          body={`I thought you might be interested in this job listing.\nLearn more and apply:  ${emailUrl}`}
        >
          <MailOutlineIcon sx={iconStyles} />
        </EmailShareButton>
      </MenuItem>
    </Menu>
  );
  const tooltipText = 'Share this job';

  return (
    <>
      {hasCardWrap ? (
        <Card
          style={{
            padding: 12,
            paddingTop: 12,
            paddingBottom: 12,
            borderRadius: 8,
            cursor: 'pointer',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <CustomTooltip {...{ title: tooltipText, open: isHovered && !open }}>
            <div style={iconStyles}>
              <ShareIcon />
            </div>
          </CustomTooltip>
          {renderShareMenu()}
        </Card>
      ) : (
        <>
          <CustomTooltip {...{ title: tooltipText, open: isHovered && !open }}>
            <StyledButton
              onClick={handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <ShareIcon />
            </StyledButton>
          </CustomTooltip>
          {renderShareMenu()}
        </>
      )}
    </>
  );
};

export default memo(ShareButton);

const StyledButton = styled(IconButton)`
  &.MuiIconButton-root {
    margin: 0;
    padding: 0;
  }
  &:hover {
    background-color: #fff;
  }
`;

const iconStyles = {
  color: appColors.content.tertiary,
};
