import { FC, memo, useCallback } from 'react';
import { css } from '@emotion/react';

import useModal from '@/hooks/useModal';
import layout from '@/constants/layout';
import Button from '@/components/Button';
import MyDetailsCard from './MyDetailsCard';
import FakeField from '@/components/FakeField';
import { openLink } from '@/utils/openExternal';
import useDropdownsData from '@/hooks/useDropdownsData';
import MyDetailsCardSubheader from './MyDetailsCardSubheader';
import { ApplicationDetailsType, fieldsContainer } from './_index';
import ApplicationDetailsContent from './ApplicationDetailsContent';

const {
  utils: { scrollToTop },
} = layout;

const subheaderFieldContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface ApplicationDetailsProps {
  details: ApplicationDetailsType;
}

const ApplicationDetails: FC<ApplicationDetailsProps> = ({ details }) => {
  const { dropdownsHashes } = useDropdownsData();
  const { ModalComponent, openModal, closeModal } = useModal();

  const {
    title,
    assignment_type,
    specialty,
    state_licenses,
    geographic_preferences,
    npi_state_license_number,
  } = details?.profession_detail || {};

  const hasTitle = !!title;
  const hasAssignmentType = !!assignment_type;
  const hasSpecialty = !!specialty;
  const hasStateLicense = state_licenses?.length > 0;
  const hasGeoPref = geographic_preferences?.length > 0;

  const onClickEdit = useCallback(() => {
    scrollToTop();
    openModal();
  }, []);

  return (
    <>
      <MyDetailsCard
        {...{
          header: 'Application Details',
          content: (
            <div
              {...{
                css: css`
                  display: flex;
                  flex-direction: column;
                  gap: 24px;
                `,
              }}
            >
              <div {...{ css: subheaderFieldContainer }}>
                <MyDetailsCardSubheader
                  {...{ subheader: 'Contact Information' }}
                />
                <div {...{ css: fieldsContainer }}>
                  <FakeField
                    {...{
                      label: 'Location',
                      value: details.provider.state || '',
                    }}
                  />
                  <FakeField
                    {...{ label: 'City', value: details.provider.city || '' }}
                  />

                  <FakeField
                    {...{
                      label: 'ZIP Code',
                      value: details.provider.zip || '',
                    }}
                  />
                  <FakeField
                    {...{
                      label: 'Phone Number',
                      value: details.provider.phone || '',
                    }}
                  />
                </div>
              </div>
              <div {...{ css: subheaderFieldContainer }}>
                <MyDetailsCardSubheader
                  {...{ subheader: 'Professional Information' }}
                />
                <div {...{ css: fieldsContainer }}>
                  <FakeField
                    {...{
                      label: 'Title',
                      value: hasTitle
                        ? dropdownsHashes.profession?.[title]?.label
                        : '',
                    }}
                  />
                  <FakeField
                    {...{
                      label: 'Assignment Type',
                      value: hasAssignmentType
                        ? dropdownsHashes.assignment?.[assignment_type]?.label
                        : '',
                    }}
                  />

                  <FakeField
                    {...{
                      label: 'Specialty',
                      value: hasSpecialty
                        ? dropdownsHashes.specialty?.[specialty]?.label
                        : '',
                    }}
                  />
                  <FakeField
                    {...{
                      label: 'Geographic Preference',
                      value: hasGeoPref
                        ? geographic_preferences.join(', ')
                        : '' || '',
                    }}
                  />

                  <FakeField
                    {...{
                      label: 'State Licenses',
                      value: hasStateLicense
                        ? state_licenses.join(', ')
                        : '' || '',
                    }}
                  />
                  <FakeField
                    {...{
                      label: 'NPI Number',
                      value: npi_state_license_number || '',
                    }}
                  />
                </div>
              </div>
              <div {...{ css: subheaderFieldContainer }}>
                <MyDetailsCardSubheader {...{ subheader: 'CV & Linkedin' }} />
                <div {...{ css: fieldsContainer }}>
                  <FakeField
                    {...{
                      label: 'CV',
                      ...(!!details?.resume
                        ? {
                            value: details?.resume,
                            onClickValue: () => {
                              openLink(details?.resume);
                            },
                          }
                        : { value: '' }),
                    }}
                  />
                  <FakeField
                    {...{
                      label: 'Linkedin Profile',
                      ...(!!details?.provider?.profile_link
                        ? {
                            value: details?.provider?.profile_link,
                            onClickValue: () => {
                              openLink(details?.provider?.profile_link);
                            },
                          }
                        : { value: '' }),
                    }}
                  />
                </div>
              </div>
            </div>
          ),
          buttons: <Button {...{ children: 'Edit', onClick: onClickEdit }} />,
        }}
      />
      <ModalComponent
        {...{
          position: 'top',
        }}
      >
        <ApplicationDetailsContent
          {...{
            details,
            modalLayoutProps: { closeModal },
          }}
        />
      </ModalComponent>
    </>
  );
};

export default memo(ApplicationDetails);
