import { FC, memo } from 'react';
import { SerializedStyles, css } from '@emotion/react';

import CloseIcon from '../CloseIcon';
import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const HEIGHT_SM = 33;
const HEIGHT_MD = 40;

const BORDER_WIDTH = 1;
const BORDER_RADIUS = 100;
const PADDING_V = 8;
const PADDING_H = 10;

const ONE_BACKGROUND_COLOR = appColors.background.tertiary;
const ONE_BACKGROUND_TEXTCOLOR = 'white';

const TWO_BACKGROUND_COLOR = appColors.background.quaternary;
const TWO_BACKGROUND_TEXTCOLOR = appColors.background.tertiary;

const THREE_BACKGROUND_COLOR = 'white';
const THREE_BACKGROUND_TEXTCOLOR = appColors.background.tertiary;

export const TAGLABEL_COMMONS = {
  HEIGHT_SM,
  HEIGHT_MD,
  BORDER_WIDTH,
  BORDER_RADIUS,
  PADDING_V,
  PADDING_H,
  //
  ONE_BACKGROUND_COLOR,
  ONE_BACKGROUND_TEXTCOLOR,
  //
  TWO_BACKGROUND_COLOR,
  TWO_BACKGROUND_TEXTCOLOR,
  //
  THREE_BACKGROUND_COLOR,
  THREE_BACKGROUND_TEXTCOLOR,
};

export type TagLabelSize = 'sm' | 'md' | 'lg';
export type TagLabelVariant = 1 | 2 | 3 | 4 | 5;

export interface TagLabelProps {
  text: string;
  clearAction?: () => void;
  size?: TagLabelSize;
  variant?: TagLabelVariant;
}

const textStyles = css({
  ...paragraphDesktop.sm,
  lineHeight: 0,
});

const defaultStyles = css`
  ${textStyles}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  user-select: none;
  box-sizing: border-box;
  padding: ${PADDING_V}px ${PADDING_H}px;
  border-radius: ${BORDER_RADIUS}px;
  border: ${BORDER_WIDTH}px solid transparent;
  transition: background-color 0.2s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

const TagLabelOne = css`
  ${defaultStyles}
  background-color: ${ONE_BACKGROUND_COLOR};
  color: ${ONE_BACKGROUND_TEXTCOLOR};
`;

const TagLabelTwo = css`
  ${defaultStyles}
  background-color: ${TWO_BACKGROUND_COLOR};
  color: ${TWO_BACKGROUND_TEXTCOLOR};
`;

const TagLabelThree = css`
  ${defaultStyles}
  background-color: ${THREE_BACKGROUND_COLOR};
  color: ${THREE_BACKGROUND_TEXTCOLOR};
  border-color: ${THREE_BACKGROUND_TEXTCOLOR};
`;

const TagLabelFour = css`
  ${defaultStyles}
  background-color: ${TWO_BACKGROUND_COLOR};
  color: ${TWO_BACKGROUND_TEXTCOLOR};
  padding: 12px;
  max-width: 140px;
`;
const TagLabelFive = css`
  ${defaultStyles}
  background-color: #FFF6F0;
  color: #f56700;
  padding: 12px;
  max-width: 140px;
`;

const stylesByVariant: Record<TagLabelVariant, SerializedStyles> = {
  1: TagLabelOne,
  2: TagLabelTwo,
  3: TagLabelThree,
  4: TagLabelFour,
  5: TagLabelFive,
};

const iconColorByVariant: Record<TagLabelVariant, string> = {
  1: ONE_BACKGROUND_TEXTCOLOR,
  2: TWO_BACKGROUND_TEXTCOLOR,
  3: THREE_BACKGROUND_TEXTCOLOR,
  4: THREE_BACKGROUND_TEXTCOLOR,
  5: '#F56700',
};

const stylesBySize: Record<
  TagLabelSize,
  { iconSize: number; gap: SerializedStyles }
> = {
  sm: {
    iconSize: 16,
    gap: css`
      gap: 4px;
      height: ${HEIGHT_SM}px;
    `,
  },
  md: {
    iconSize: 24,
    gap: css`
      gap: 8px;
      height: ${HEIGHT_MD}px;
    `,
  },
  lg: {
    iconSize: 24,
    gap: css`
      gap: 8px;
      height: ${HEIGHT_MD}px;
    `,
  },
};

const TagLabel: FC<TagLabelProps> = ({
  text,
  clearAction,
  size = 'sm',
  variant = 1,
}) => {
  const { gap, iconSize } = stylesBySize[size];
  const iconColor = iconColorByVariant[variant];
  const containerStyle = stylesByVariant[variant];

  const hasCloseAction = !!clearAction;

  return (
    <div>
      <div
        {...{
          css: css`
            ${containerStyle}
            ${hasCloseAction ? gap : `0px`}
          `,
        }}
      >
        {text}
        {hasCloseAction && (
          <CloseIcon
            {...{
              onClick: clearAction,
              size: iconSize,
              iconProps: {
                iconColor,
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(TagLabel);
