interface Value {
  id: string;
  desc: boolean;
}

const transformSortingValues = (input: Value[]): string => {
  return input
    .filter((value) => value.id.trim() !== '') // Filter out items with empty id
    .map((value) => `${value.id} ${value.desc ? 'desc' : 'asc'}`)
    .join(', ');
};

const transformSortingParams = (input: string | string[]): Value | Value[] => {
  if (typeof input === 'string') {
    const [id, order] = input.trim().split(' ');
    if (id && (order === 'desc' || order === 'asc')) {
      return {
        id,
        desc: order === 'desc',
      };
    } else {
      return null; // Invalid input string format
    }
  } else {
    const values: Value[] = [];
    for (const str of input) {
      const [id, order] = str.trim().split(' ');
      if (id && (order === 'desc' || order === 'asc')) {
        values.push({
          id,
          desc: order === 'desc',
        });
      } else {
        return null; // Invalid input string format
      }
    }
    return values;
  }
};

export { transformSortingValues, transformSortingParams };
