import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Head } from '@inertiajs/react';
import { footerStyle, topBarStyle } from '@/layouts/TopContentFooterLayout';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';
import Collage from '../../assets/images/1000-page.jpg';
import BlogTemple from '@/components/BlogTemple';
import AuthModal from '@/components/AuthModal';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    padding: 1em;
  }
`;

const orderList = css`
  li::marker {
    font-weight: bold;
  }
`;

const initialNav = [
  { label: 'News', url: '/news' },
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
];

export default function NewsPage() {
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  return (
    <>
      <Head>
        <title>We hit 1,000 followers</title>
        <meta
          name="description"
          content="Explore premier locum tenens opportunities at Locums.com! Connecting doctors with top placements and empowering recruiters. Join our thriving community for personalized matches, flexibility, and excellence in healthcare staffing. Elevate your career or find top-tier talent with Locums.com – Empowering you to practice your way!"
        />
      </Head>

      <div
        {...{
          css: topBarStyle,
          style: {
            background: '#fff',
            borderBottom: 'none',
            color: '#000',
          },
        }}
      >
        <NavBar {...{ ...navProps, logoVariant: 'blue-black' }} />
      </div>
      <div {...{ css: pageContentStyle }}>
        <BlogTemple
          {...{
            templeThree: {
              topSectionTitle:
                'We hit 1,000 followers in record time, thanks to you',
              topSectionLeft: (
                <>
                  <b>A big “thank you” to all of you who signed up</b> <br />
                  <b>Locums.com</b> is officially on a roll. In less than three
                  months, Locums.com had 1,000 recruiters and practitioners
                  visit, sign up, and follow the online community that’s
                  dedicated to connecting recruiters and providers. In the end,
                  we’re all helping patients get the healthcare they desperately
                  need. So thank you for joining. We’re glad you’re here.
                  <br />
                  <br />
                  There are countless facilities in the country with open
                  positions, and we’re thankful for all the providers who work
                  locum tenens to help sustain healthcare where it’s needed
                  most. From large cities to the most remote towns – there are
                  facilities and patients that need help.
                </>
              ),
              topSectionRight: (
                <>
                  <b>And that’s where Locums.com comes in.</b> We invite you to
                  bookmark us, visit daily, and keep following socially. Search
                  for jobs, connect, and find valuable information. And don’t
                  forget to share jobs with colleagues and friends – and invite
                  them to like and follow the page.
                  <br />
                  <br />
                  Recruiters? You’re the ones who are “in the know” about which
                  hospitals and clinics have open shifts. Locums.com is the
                  place to post those jobs, so you can connect to the qualified
                  physicians and providers who have time and availability to
                  take those assignments.
                  <br />
                  <br />
                  <b>Locums.com Where providers and recruiters click.</b>
                </>
              ),
              bottomImageSrc: Collage,
              bottomImageAlt: 'Collage of providers and recruiters',
              testimonial:
                'Locums.com is the place to post those jobs, so you can connect to the qualified physicians and providers who have time and availability to take those assignments. Bookmark and visit daily.',
            },
          }}
        />
      </div>
      <div {...{ css: footerStyle }}>
        <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
      </div>
      <AuthModal />
    </>
  );
}
