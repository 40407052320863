import { CSSProperties, FC, memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { usePage } from '@inertiajs/react';

import Card from '../Card';
import layout from '@/constants/layout';
import { JobShowProps } from '@/types/page.d';
import { appColors } from '@/constants/colors';
import { IconBookmark } from '../Icons/Bookmark';
import useModalState from '@/hooks/useModalState';
import SigninRecoverySignup from '../AuthFlows/SigninRecoverySignup';
import usePageSession from '@/hooks/usePageSession';

const {
  components: { iconStyles },
} = layout;

const STROKE_COLOR = appColors.content.tertiary;
const ACTIVE_COLOR = STROKE_COLOR;
const INACTIVE_COLOR = 'transparent';

interface BookMarkedProps {
  isActive?: boolean;
  hasCardWrap?: boolean;
  style?: CSSProperties;
  action?: () => void;
}

const containerStyles = css`
  ${iconStyles}
  cursor: pointer;
`;

const BookMarked: FC<BookMarkedProps> = (props) => {
  const {
    session: { signed_in },
    session,
  } = usePageSession();
  const {
    actions: { openAuth, close },
  } = useModalState();

  const { isActive, hasCardWrap, style, action } = props;

  const isAdmin = session?.current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const onClickButton = useCallback(() => {
    if (!isSignedIn) {
      // LOGIN TRIGGER
      openAuth(
        {
          children: <SigninRecoverySignup />,
          position: 'top',
          onClose: close,
        },
        'signIn'
      );

      return;
    }

    !!action && action();
  }, [openAuth, action]);

  const fillColor = isActive ? ACTIVE_COLOR : INACTIVE_COLOR;
  const iconProps = { strokeColor: STROKE_COLOR, fillColor };

  if (hasCardWrap) {
    return (
      <Card
        {...{
          style: {
            ...style,
            cursor: 'pointer',
            padding: 12,
            paddingTop: 11,
            paddingBottom: 11,
            borderRadius: 8,
          },
          onClick: onClickButton,
        }}
      >
        <div
          {...{
            css: containerStyles,
          }}
        >
          <IconBookmark {...iconProps} />
        </div>
      </Card>
    );
  }

  return (
    <div
      {...{
        role: 'button',
        css: containerStyles,
        onClick: (e) => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          onClickButton();
        },
      }}
    >
      <IconBookmark {...iconProps} />
    </div>
  );
};

export default memo(BookMarked);
