import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  resetPassword,
  identifyUser,
  signIn,
  recoveryEmail,
  signUp,
  signUpResendConfirmation,
  logout,
  signupClose,
  signupPhysician,
  signupRecruiter,
  contact,
  content,
  news,
} from '@/api/auth';
import {
  SignupPhysicianCVParams,
  ResetPasswordParams,
  SignInParams,
  SignupParams,
  RecoveryEmailParams,
  SignupPhysicianContactInfoParams,
  SignupPhysicianProfessionalDetailsParams,
  SignupRecruiterCompanyParams,
  SignupRecruiterContactInfoParams,
  SignupResendConfirmationParams,
  LogoutParams,
  ContactParams,
} from '@/types/api/params';
import {
  SignupPhysicianCVResponse,
  SignupPhysicianContactInfoResponse,
  SignupPhysicianProfessionalDetailsResponse,
  SignupRecruiterCompanyResponse,
  SignupRecruiterContactInfoResponse,
} from '@/types/api/responses';
import { ResourceType, UserType } from '@/types/types';
import { ContactUsFormValues } from '@/types/forms';

//#//#//#//#//#// T H U N K S //#//#//#//#//#//

const signInThunk = createAsyncThunk(
  `auth/signInThunk`,
  async (params: SignInParams) => {
    try {
      const identifyResponse = await identifyUser(params);
      if (identifyResponse.user) {
        const loginResponse = await signIn(
          identifyResponse.user,
          identifyResponse.resource,
          params
        );
        if (loginResponse.success === true) {
          return loginResponse;
        } else {
          return 'User not confirmed'; // User is not confirmed
        }
      } else {
        return 'User not found'; // User is not found
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const recoveryEmailThunk = createAsyncThunk(
  `auth/recoveryEmailThunk`,
  async (params: RecoveryEmailParams) => {
    try {
      const identifyResponse = await identifyUser(params);
      if (identifyResponse) {
        const loginResponse = await recoveryEmail(
          identifyResponse.user,
          identifyResponse.resource,
          params
        );
        if (loginResponse) {
          return loginResponse;
        }
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const recoveryEmailResendConfirmationThunk = createAsyncThunk(
  `auth/recoveryEmailThunkResendConfirmation`,
  async (params: RecoveryEmailParams) => {
    try {
      const identifyResponse = await identifyUser(params);
      if (identifyResponse) {
        const loginResponse = await recoveryEmail(
          identifyResponse.user,
          identifyResponse.resource,
          params
        );
        if (loginResponse) {
          return loginResponse;
        }
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const resetPasswordThunk = createAsyncThunk(
  `auth/resetPasswordThunk`,
  async ({
    user,
    resource,
    params,
  }: {
    user: UserType;
    resource: ResourceType;
    params: ResetPasswordParams;
  }) => {
    try {
      const response = await resetPassword(user, resource, params);
      if (response) {
        return response;
      }

      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const signUpThunk = createAsyncThunk(
  `auth/signUpThunk`,
  async ({
    type,
    resource,
    params,
  }: {
    type: UserType;
    resource: ResourceType;
    params: SignupParams;
  }) => {
    try {
      const response = await signUp(type, resource, params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const signUpResendConfirmationThunk = createAsyncThunk(
  `auth/signUpResendConfirmationThunk`,
  async (params: SignupResendConfirmationParams) => {
    try {
      const response = await signUpResendConfirmation(params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const signupCloseThunk = createAsyncThunk(
  `auth/signupCloseThunk`,
  async ({ id, resource }: { id: number; resource: ResourceType }) => {
    try {
      const response = await signupClose(id, resource);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// SIGNUP PHYSICIAN

// I
const signupPhysicianContactInfoThunk = createAsyncThunk(
  `auth/signupPhysicianContactInfoThunk`,
  async ({
    id,
    params,
  }: {
    id: number;
    params: SignupPhysicianContactInfoParams;
  }) => {
    try {
      const response = await signupPhysician<
        SignupPhysicianContactInfoParams,
        SignupPhysicianContactInfoResponse
      >(id, params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// II

const signupPhysicianProfessionalDetailsThunk = createAsyncThunk(
  `auth/signupPhysicianProfessionalDetailsThunk`,
  async ({
    id,
    params,
  }: {
    id: number;
    params: SignupPhysicianProfessionalDetailsParams;
  }) => {
    try {
      const response = await signupPhysician<
        SignupPhysicianProfessionalDetailsParams,
        SignupPhysicianProfessionalDetailsResponse
      >(id, params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// III

const signupPhysicianCVThunk = createAsyncThunk(
  `auth/signupPhysicianCVThunk`,
  async ({ id, params }: { id: number; params: SignupPhysicianCVParams }) => {
    try {
      const response = await signupPhysician<
        SignupPhysicianCVParams,
        SignupPhysicianCVResponse
      >(id, params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// SIGNUP RECRUITER

// I
const signupRecruiterContactInfoThunk = createAsyncThunk(
  `auth/signupRecruiterContactInfoThunk`,
  async ({
    id,
    params,
  }: {
    id: number;
    params: SignupRecruiterContactInfoParams;
  }) => {
    try {
      const response = await signupRecruiter<
        SignupRecruiterContactInfoParams,
        SignupRecruiterContactInfoResponse
      >(id, params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// II

const signupRecruiterCompanyThunk = createAsyncThunk(
  `auth/signupRecruiterCompanyThunk`,
  async ({
    id,
    params,
  }: {
    id: number;
    params: SignupRecruiterCompanyParams;
  }) => {
    try {
      const response = await signupRecruiter<
        SignupRecruiterCompanyParams,
        SignupRecruiterCompanyResponse
      >(id, params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// LOGOUT

const logoutThunk = createAsyncThunk(
  `auth/logoutThunk`,
  async ({ user, params }: { user: ResourceType; params?: LogoutParams }) => {
    try {
      const response = await logout(user, params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// CONTACT

const contactUsThunk = createAsyncThunk(
  `auth/contactUsThunk`,
  async ({ params }: { params?: ContactParams }) => {
    try {
      const response = await contact(params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// CONTENT

const contentThunk = createAsyncThunk(
  `auth/contentThunk`,
  async ({ params }: { params?: ContactParams }) => {
    try {
      const response = await contact(params);
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// News

const newsThunk = createAsyncThunk(
  `auth/newsThunk`,
  async () => {
    try {
      const response = await news();
      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export {
  signInThunk,
  //
  recoveryEmailThunk,
  recoveryEmailResendConfirmationThunk,
  resetPasswordThunk,
  //
  signUpThunk,
  signUpResendConfirmationThunk,
  //
  signupCloseThunk,
  //
  signupPhysicianContactInfoThunk,
  signupPhysicianProfessionalDetailsThunk,
  signupPhysicianCVThunk,
  //
  signupRecruiterContactInfoThunk,
  signupRecruiterCompanyThunk,
  //
  logoutThunk,
  contactUsThunk,
  contentThunk,
  newsThunk,
};
