import { memo } from 'react';
import styled from '@emotion/styled';

import { appColors } from '@/constants/colors';

const CustomDivider = styled.div<{
  type?: 'horizontal' | 'vertical';
  thickness?: number;
}>(({ type = 'horizontal', thickness = 1 }) => {
  return `
    display: flex;
    width: ${type === 'horizontal' ? `100%` : `${thickness}px`};
    height: ${type === 'horizontal' ? `${thickness}px` : `100%`};
    background-color: ${appColors.border.primary};
  `;
});

export default memo(CustomDivider);
