import { css } from '@emotion/react';
import { FC, memo } from 'react';

import LinkMessage from './LinkMessage';
import Heading from './Typography/Heading';
import Paragraph from './Typography/Paragraph';
import { appColors } from '@/constants/colors';

const checkEmailContainer = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const EmailConfirmation: FC<{
  email: string;
  header: string;
  msg: string;
  msg2: string;
  resendAction?: () => void;
}> = ({ email, msg, msg2, header, resendAction }) => {
  return (
    <div
      {...{
        css: checkEmailContainer,
      }}
    >
      <Heading {...{ variant: 'h3', children: header }} />
      <Paragraph {...{ variant: 'md' }}>
        {`${msg} `}
        <Paragraph
          {...{
            children: email,
            variant: 'md',
            style: {
              color: appColors.content.tertiary,
            },
          }}
        />
        . {msg2}.
      </Paragraph>
      <LinkMessage
        {...{
          msg: 'Didn’t receive a link?',
          link: {
            ...(resendAction ? { onClick: resendAction } : {}),
            children: 'Resend it.',
          },
        }}
      />
    </div>
  );
};

export default memo(EmailConfirmation);
