import styled from '@emotion/styled';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import Button, { ButtonProps } from '../Button';
import Paragraph, { ParagraphProps } from '../Typography/Paragraph';

const {
  windowSizes: { mobile },
} = layout;

const PromptContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 32px;
  width: 420px;
  @media (max-width: ${mobile}px) {
    padding: 16px;
    width: 100%;
  }
`;

const PromptHeader = (props: ParagraphProps) => {
  return (
    <Paragraph
      {...{
        ...props,
        variant: 'lg',
        style: {
          fontWeight: 500,
        },
      }}
    />
  );
};

const PromptMessage = (props: ParagraphProps) => {
  return (
    <Paragraph
      {...{
        ...props,
        style: {
          color: appColors.content.secondary,
          ...props?.style,
        },
        variant: 'md',
      }}
    />
  );
};

const PromptButtons = ({
  leftButton,
  rightButton,
}: {
  leftButton: ButtonProps;
  rightButton: ButtonProps;
}) => {
  return (
    <div
      {...{
        css: css`
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 8px;
          @media (max-width: ${mobile}px) {
            flex-direction: column;
          }
        `,
      }}
    >
      <Button
        {...{
          ...leftButton,
        }}
      />
      <Button
        {...{
          ...rightButton,
        }}
      />
    </div>
  );
};

export { PromptContainer, PromptHeader, PromptMessage, PromptButtons };
