import { useCallback, useState } from 'react';
import { router, useForm as useInertiaForm, usePage } from '@inertiajs/react';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/react';

import {
  emailRecoveryPlaceholder,
  fieldsDefaultProps,
} from '@/constants/form-fields';
import {
  FieldsContainer,
  FormContainer,
  RecoveryEmailConfirmation,
  RecoveryEmailFormHeader,
} from '@/components/Forms';
import Input from '@/components/Input';
import Button from '@/components/Button';
import { RecoveryFlowState } from '@/types/types';
import { getMetaToken } from '@/utils/meta-token';
import LinkMessage from '@/components/LinkMessage';
import BackComponent from '@/components/BackComponent';
import { RecoveryEmailFormValues } from '@/types/forms';
import { RecoveryEmailParams } from '@/types/api/params';
import SignInContent from '@/components/AdminPanel/SignInContent';
import { fieldsDefaultRules } from '@/constants/form-validations';
import AdminOnlyDesktopWrapper from '@/components/AdminPanel/AdminOnlyDesktopWrapper';

const URL = '/admins/password';

const buttonContainer = css``;

const checkContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const recoveryFlowInitialState: RecoveryFlowState = {
  mode: 'form',
};

export default function PasswordsNew() {
  const { props } = usePage<{ show_success_component: boolean | null }>();

  const { show_success_component } = props;
  const { post, transform, processing } = useInertiaForm();
  const [{ mode }, setState] = useState<RecoveryFlowState>({
    mode: show_success_component === true ? 'check' : 'form',
  });

  const isFormMode = mode === 'form';

  const methods = useForm<RecoveryEmailFormValues>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { isSubmitting, isValid },
  } = methods;

  const isRequesting = processing || isSubmitting;

  const email = watch('email');

  const setCheckMode = useCallback(async () => {
    setState({ mode: 'check' });
  }, []);

  const goToSignIn = useCallback(async () => {
    router.get('/admins/sign_in');
  }, []);

  const onSubmit = useCallback(async (form: RecoveryEmailFormValues) => {
    const params: RecoveryEmailParams = {
      email: form.email,
    };
    const metaTokenHeader = await getMetaToken();
    if (!!metaTokenHeader) {
      post(URL, {
        preserveState: true,
        data: transform(() => ({
          admin: { ...params },
        })),
        headers: {
          ...metaTokenHeader,
        },
        onSuccess: ({ props }) => {
          const { admin_errors } = props;

          const hasErrors =
            Array.isArray(admin_errors) && admin_errors.length > 0;

          if (hasErrors) {
            setError('email', {
              message: '',
              type: 'manual',
            });
            return;
          }
          setCheckMode();
          return;
        },
        onError: (errorResponse) => {
          console.log({ errorResponse });
        },
      });
    }
  }, []);

  const resendAction = useCallback(async () => {
    handleSubmit(onSubmit)();
  }, []);

  return (
    <AdminOnlyDesktopWrapper>
      <SignInContent>
        {isFormMode ? (
          <form {...{ style: { width: '100%' } }}>
            <FormContainer
              {...{
                style: { ...(isRequesting ? { pointerEvents: 'none' } : {}) },
              }}
            >
              <RecoveryEmailFormHeader />
              <FieldsContainer>
                {
                  //@ts-ignore
                  <Controller
                    {...{
                      name: 'email',
                      control,

                      rules: { ...fieldsDefaultRules['email'] },
                      render: ({ field, fieldState: { error } }) => {
                        return (
                          <Input
                            {...{
                              ...fieldsDefaultProps['email'],
                              autoComplete: 'off',
                              placeholder: emailRecoveryPlaceholder,
                              ...field,
                              id: field.name,
                              error,
                            }}
                          />
                        );
                      },
                    }}
                  />
                }
              </FieldsContainer>
              <div {...{ css: buttonContainer }}>
                <Button
                  {...{
                    variant: '1',
                    children: 'Continue',
                    style: { width: '100%' },
                    onClick: handleSubmit(onSubmit),
                    disabled: !isValid || isRequesting,
                  }}
                />
              </div>
              <LinkMessage
                {...{
                  msg: 'Already have an account?  ',
                  link: {
                    children: 'Sign in.',
                    onClick: goToSignIn,
                  },
                }}
              />
            </FormContainer>
          </form>
        ) : (
          <div {...{ css: checkContainer }}>
            <BackComponent {...{ onClickBack: goToSignIn }} />
            <RecoveryEmailConfirmation {...{ email, resendAction }} />
          </div>
          //
        )}
      </SignInContent>
    </AdminOnlyDesktopWrapper>
  );
}
