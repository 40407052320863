import { FC, ReactNode, memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';

const HEIGHT = 38;
const BORDER_RADIUS = 8;
const BORDER_WIDTH = 1;

const activeStyles = css`
  border: ${BORDER_WIDTH}px solid ${appColors.border.secondary};
  background: ${appColors.notInDesignSystem[4]};
  color: ${appColors.border.secondary};
`;

const inactiveStyles = css`
  color: ${appColors.content.secondary};
`;

const Container = styled.div`
  border: ${BORDER_WIDTH}px solid transparent;
  display: flex;
  height: ${HEIGHT}px;
  border-radius: ${BORDER_RADIUS}px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  user-select: none;
`;

interface NavButtonProps {
  isActive?: boolean;
  onClick: () => void;
  children: ReactNode;
}

const NavButton: FC<NavButtonProps> = ({ isActive, onClick, children }) => {
  return (
    <Container
      {...{ onClick, children, css: isActive ? activeStyles : inactiveStyles }}
    />
  );
};

export default memo(NavButton);
