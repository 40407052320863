import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ApplyJobAsProviderParams,
  JobboardSearchParams,
  PostNewJobParams,
  SaveSearchValuesParams,
  UpdateProviderParams,
  UpdateSaveSearchParams,
} from '@/types/api/params';
import { RootState } from '../store';
import { SortType } from '@/types/types';
import {
  applyJobAsProvider,
  createJobAsRecruiter,
  deleteJobs,
  deleteSavedSearch,
  jobboardSearch,
  saveSearchProvider,
  updateProvider,
  updateSavedSearch,
} from '@/api/jobs';
import {
  SaveSearchProviderResponse,
  UpdateProviderResponse,
} from '@/types/api/responses';
//#//#//#//#//#// T H U N K S //#//#//#//#//#//

const handleFilterMenuThunk = createAsyncThunk(
  `jobboard/handleFilterMenuThunk`,
  async (_, { getState }) => {
    const {
      jobboard: { isFilterMenuActive },
    } = getState() as RootState;
    return !isFilterMenuActive;
  }
);

const handleMoreFiltersThunk = createAsyncThunk(
  `jobboard/handleMoreFiltersThunk`,
  async (_, { getState }) => {
    const {
      jobboard: { hasMoreFilters },
    } = getState() as RootState;
    return !hasMoreFilters;
  }
);

const handleSortThunk = createAsyncThunk(
  `jobboard/handleSortThunk`,
  async (_, { getState }): Promise<SortType> => {
    const {
      jobboard: {
        jobs: { sort },
      },
    } = getState() as RootState;

    if (sort === 'posted_at asc') {
      return 'posted_at desc';
    }
    return 'posted_at asc';
  }
);

const changePageThunk = createAsyncThunk(
  `jobboard/changePageThunk`,
  async (params: { page: number }, { getState }) => {
    return params.page;
  },
  {
    condition: (params, { getState }) => {
      const {
        jobboard: {
          jobs: { pagination },
        },
      } = getState() as RootState;
      return pagination.currentPage !== params.page;
    },
  }
);

//

const jobboardSearchThunk = createAsyncThunk(
  `jobboard/jobboardSearchThunk`,
  async (params: JobboardSearchParams, { getState }) => {
    const {
      jobboard: {
        jobs: { pagination, sort },
      },
    } = getState() as RootState;

    return await jobboardSearch({
      ...params,
      page: pagination.currentPage,
      sort: sort,
    });
  },
  {
    condition: (params, { getState }) => {
      const {
        jobboard: {
          jobs: { status },
        },
      } = getState() as RootState;

      return status !== 'FETCHING';
    },
  }
);

//

const applyJobAsProviderThunk = createAsyncThunk(
  `jobboard/applyJobAsProviderThunk`,
  async (params: ApplyJobAsProviderParams, { getState }) => {
    const response = await applyJobAsProvider(params);
    if (response.success) {
      return response;
    }
    return Promise.reject();
  },
  {
    condition: (params, { getState }) => {
      return true;
    },
  }
);

const updateProviderThunk = createAsyncThunk(
  `jobboard/updateProviderThunk`,
  async ({ params }: { params: UpdateProviderParams }) => {
    try {
      const response = await updateProvider<
        UpdateProviderParams,
        UpdateProviderResponse
      >(params);
      if (response.success) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

const createJobAsRecruiterThunk = createAsyncThunk(
  `jobboard/createJobAsRecruiterThunk`,
  async (params: PostNewJobParams) => {
    const response = await createJobAsRecruiter(params);

    if (response) {
      return response;
    }
    return Promise.reject();
  }
);

const bulkDestroyJobsThunk = createAsyncThunk(
  `jobboard/bulkDestroyJobsThunk`,
  async (params: number[]) => {
    const response = await deleteJobs(params);

    if (response) {
      return response;
    }
    return Promise.reject();
  }
);


const destroySavedSearchThunk = createAsyncThunk(
  `jobboard/destroySavedSearchThunk`,
  async (params: number[]) => {
    const response = await deleteSavedSearch(params);

    if (response) {
      return response;
    }
    return Promise.reject();
  }
);

// Save Search
const saveSearchProviderThunk = createAsyncThunk(
  `jobboard/saveSearchProviderThunk`,
  async (params: SaveSearchValuesParams, { getState }) => {
    const response = await saveSearchProvider(params);
    if (response.success) {
      return response;
    }
    return Promise.reject(response.message);
  },
  {
    condition: (params, { getState }) => {
      return true;
    },
  }
);

// Update Save Search
const updateSavedSearchThunk = createAsyncThunk(
  'jobboard/updateSavedSearchThunk',
  async ({ params }: { params: UpdateSaveSearchParams }) => {
    try {
      const response = await updateSavedSearch<UpdateSaveSearchParams>(params);
      if (response.success) {
        return response;
      }
      return Promise.reject(response.message);
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export {
  handleFilterMenuThunk,
  handleMoreFiltersThunk,
  handleSortThunk,
  changePageThunk,
  jobboardSearchThunk,
  applyJobAsProviderThunk,
  updateProviderThunk,
  createJobAsRecruiterThunk,
  bulkDestroyJobsThunk,
  saveSearchProviderThunk,
  updateSavedSearchThunk,
  destroySavedSearchThunk
};
