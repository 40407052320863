import { useMemo } from 'react';
import { IconCustomProps } from '@/types/types';


const IconSaveSearch = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 1080 1080"
        {...props}
      >
        <g>
          <g id="Layer_1">
            <g>
              <path
                fill={iconColor || "#1a54ff"}
                d="M745.5,627.7c-68.5,0-124,55.5-124,124s55.5,124,124,124,124-55.5,124-124-55.5-124-124-124ZM745.5,828c-42.1,0-76.2-34.2-76.2-76.3s34.2-76.2,76.2-76.2,76.3,34.1,76.3,76.2-34.2,76.3-76.3,76.3Z"
              />
              <rect
                fill={iconColor || "#1a54ff"}
                x="825.3"
                y="777.6"
                width="49.5"
                height="100.7"
                transform="translate(-323.4 1003.8) rotate(-52.8)"
              />
              <g>
                <path
                  fill={iconColor || "#1a54ff"}
                  d="M535.5,179.4c-199.2,0-360.6,161.5-360.6,360.6s161.5,360.7,360.6,360.7,57.4-3.4,84.5-10c-17.2-15.6-31.5-34.3-42-55.2-13.9,2-28.1,3-42.5,3-164.8,0-298.5-133.6-298.5-298.5s133.6-298.5,298.5-298.5,298.5,133.6,298.5,298.5-1.1,30.3-3.4,45c20.9,10.7,39.5,25.2,54.9,42.5,7-28,10.7-57.3,10.7-87.5,0-199.2-161.5-360.6-360.7-360.6h0Z"
                />
                <path
                  fill={iconColor || "#1a54ff"}
                  d="M572.2,324.5v356.4c-9,21.8-13.9,45.7-13.9,70.8s0,2.5,0,3.8h-59.4v-431h73.2,0Z"
                />
                <path
                  fill={iconColor || "#1a54ff"}
                  d="M751,503.4v61.2c-1.8,0-3.7,0-5.5,0-23.3,0-45.7,4.3-66.3,12.1h-359.1v-73.2h431Z"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    ),
    [props]
  );

export { IconSaveSearch };
