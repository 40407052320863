import { FC, memo } from 'react';

import Link from './Typography/Link';
import { appColors } from '@/constants/colors';
import Paragraph from './Typography/Paragraph';
import { MessageLinkProps } from '@/types/types';

type TexColor = 'gray' | 'dark';

interface LinkMessageProps extends MessageLinkProps {
  readonly textColor?: TexColor;
}

const LinkMessage: FC<LinkMessageProps> = ({
  msg,
  link,
  textColor = 'dark',
}) => {
  return (
    <div>
      <Paragraph
        {...{
          variant: 'sm',
          style: {
            ...(textColor === 'dark'
              ? {}
              : { color: appColors.content.secondary }),
          },
        }}
      >
        {msg} <Link {...{ ...link, hasUnderline: false }} />
      </Paragraph>
    </div>
  );
};

export default memo(LinkMessage);
