import produce from 'immer';

import { Job } from '@/types/entities.d';

type TileModalHandle = {
  selectedJobId: number | null;
  isTileModalOpen: boolean;
};

type ProviderDashboardAction =
  | {
      payload: number;
      type: 'FAVORITE_APPLIED_JOB';
    }
  | {
      payload: number;
      type: 'UNFAVORITE_APPLIED_JOB';
    }
  | {
      payload: number;
      type: 'UNFAVORITE_SAVED_JOB';
    }
  | {
      payload: number;
      type: 'SET_HAS_APPLIED';
    }
  | {
      payload: Job[];
      type: 'SET_APPLIED';
    }
  | {
      payload: Job[];
      type: 'SET_SAVED';
    }
  | {
      payload: Job;
      type: 'OPEN_TILE_MODAL';
    }
  | {
      payload: null;
      type: 'CLOSE_TILE_MODAL';
    };

type State = {
  appliedJobs: Job[];
  savedJobs: Job[];
  modal: TileModalHandle;
};

const initialState: State = {
  appliedJobs: [],
  savedJobs: [],
  modal: {
    selectedJobId: null,
    isTileModalOpen: false,
  },
};

function removeObjectFromArrayById<T extends { id: number }>(
  arr: T[],
  id: number
): T[] {
  const index = arr.findIndex((obj) => obj.id === id);
  if (index !== -1) {
    arr.splice(index, 1);
  }
  return arr;
}

const reducer = produce((draft, { type, payload }: ProviderDashboardAction) => {
  switch (type) {
    case 'SET_SAVED':
      draft.savedJobs = payload;
      return;
    case 'SET_APPLIED':
      draft.appliedJobs = payload;
      return;
    case 'SET_HAS_APPLIED':
      const hasAppliedIndex = draft.savedJobs.findIndex(
        (job) => job?.id === payload
      );
      if (hasAppliedIndex !== -1) {
        draft.savedJobs[hasAppliedIndex].has_applied = true;
      }
      return;
    case 'FAVORITE_APPLIED_JOB':
      const favoriteIndex = draft.appliedJobs.findIndex(
        (job) => job?.id === payload
      );

      if (favoriteIndex !== -1) {
        draft.appliedJobs[favoriteIndex].is_favorite = true;
      }
      return;
    case 'UNFAVORITE_APPLIED_JOB':
      const unfavoritedIndex = draft.appliedJobs.findIndex(
        (job) => job?.id === payload
      );
      if (unfavoritedIndex !== -1) {
        draft.appliedJobs[unfavoritedIndex].is_favorite = false;
      }
      return;
    case 'UNFAVORITE_SAVED_JOB':
      const unfavoritedSavedIndex = draft.savedJobs.findIndex(
        (job) => job?.id === payload
      );
      if (unfavoritedSavedIndex !== -1) {
        draft.savedJobs = draft.savedJobs.filter((item) => item.id !== payload);
      }
      return;
    case 'OPEN_TILE_MODAL':
      draft.modal.selectedJobId = payload.id;
      draft.modal.isTileModalOpen = true;
      return;
    case 'CLOSE_TILE_MODAL':
      draft.modal.selectedJobId = null;
      draft.modal.isTileModalOpen = false;
      return;
  }
}, initialState);

export { reducer, initialState, ProviderDashboardAction };
