import { FC, memo } from 'react';
import { css } from '@emotion/react';
import layout from '@/constants/layout';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
} = layout;

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  image?: string;
  title?: string;
  subTitle?: string;
  description?: string;
}

const InstructionCard: FC<CardProps> = ({
  image,
  title,
  subTitle,
  description,
}) => {
  return (
    <div {...{ css: cardWrraper }}>
      <div {...{ css: cardStyles }}>
        <img {...{ css: cardImage, src: image, alt: title }} />
        <div {...{ css: cardContent }}>
          <h2 {...{ css: cardTitle }}>{subTitle}</h2>
          <p {...{ css: cardText }}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(InstructionCard);
const cardWrraper = css`
  background-color: #d6dfff;
  padding: 2em 1em;
  @media (max-width: ${mobile}px) {
    padding: 10px;
  }
`;
const cardStyles = css`
  width: 500px; 
  max-height: 450px;
  border: 1px solid #ccc; 
  border-radius: 8px; 
  overflow: hidden; 
  margin: 10px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  @media (max-width: ${mobile}px) {
    width: 350px;
    max-height: 510px;
  }
`;

const cardImage = css`
  width: 100%;
  height: 300px; 
  padding: 1em 1em 0 1em;
  background-color: #ccc;
`;

const cardContent = css`
  padding: 0px 15px;
  text-align: center;
  background-color: #fff;
`;
const cardTitle = css`
  color: #000;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 20px auto;
`;

const cardText = css`
  color: #000;
  font-family: 'Hepta Slab';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
