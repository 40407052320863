import { CSSProperties, FC, memo } from 'react';
import styled from '@emotion/styled';

import {
  HeadingVariants,
  headingDesktop,
  headingMobile,
} from '@/constants/typography';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';

const { mediaQuery } = layout;

export interface HeadingProps {
  readonly style?: CSSProperties;
  readonly variant: HeadingVariants;
  readonly children: React.ReactNode;
}

const StyledText = styled.span<{ variant: HeadingVariants }>(({ variant }) => ({
  ...headingDesktop[variant],
  userSelect: 'none',
  [mediaQuery.mobile]: {
    ...headingMobile[variant],
  },
}));

const Heading: FC<HeadingProps> = ({ variant = 'h1', ...props }) => {
  return (
    <StyledText
      {...{
        ...props,
        variant,
        style: {
          color: appColors.content.primary,
          ...props.style,
        },
      }}
    />
  );
};

export default memo(Heading);
