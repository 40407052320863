import { FC, memo, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';

import Button from '../Button';
import Label from '../Typography/Label';
import layout from '@/constants/layout';
import CustomDivider from '../CustomDivider';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import { JobApplication } from '@/types/entities';
import useDropdownsData from '@/hooks/useDropdownsData';
import { dateFormats, formatDate } from '@/utils/daysjs-config';

const {
  windowSizes: { tablet },
} = layout;

interface JobApplicationTileProps {
  item: JobApplication;
}

const container = css`
  display: flex;
  flex-direction: column;
`;

const contentContainer = css`
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
  @media (max-width: ${tablet}px) {
    flex-direction: column;
  }
`;

const infoContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const moreInfoContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  @media (max-width: ${tablet}px) {
    flex-direction: row;
    width: 100%;
  }
`;

const contactContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 8px;
  @media (max-width: ${tablet}px) {
    gap: 16px;
    flex-direction: column-reverse;
  }
`;

const JobApplicationTile: FC<JobApplicationTileProps> = ({ item }) => {
  const { dropdownsHashes } = useDropdownsData();
  const formattedDate = useMemo(
    () => formatDate(item.created_at, dateFormats.mmddyyyy),
    [item.created_at]
  );

  const onClickContact = useCallback(() => {
    window.location.href = `tel:${item.phone}`;
  }, [item.phone]);

  const onClickProfileLink = useCallback(() => {
    window.open(item.profile_link || '', '_blank');
  }, [item.profile_link]);

  const onClickViewCV = useCallback(() => {
    window.open(item.application_resume_url || '', '_blank');
  }, [item.application_resume_url]);

  return (
    <div {...{ css: container }}>
      <div {...{ css: contentContainer }}>
        <div {...{ css: infoContainer }}>
          <Paragraph
            {...{
              children: `Applied ${formattedDate}`,
              variant: 'sm',
              style: {
                color: appColors.content.tertiary,
                fontWeight: 600,
              },
            }}
          />
          <div
            {...{
              css: css`
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                gap: 8px;
              `,
            }}
          >
            <Paragraph
              {...{
                children: `${item.provider_information.first_name} ${item.provider_information.last_name}`,
                variant: 'md',
                style: {
                  fontWeight: 500,
                },
              }}
            />
            <Paragraph
              {...{
                children:
                  dropdownsHashes?.profession?.[item.title]?.label || 'TBD',
                variant: 'sm',
                style: {
                  color: appColors.content.secondary,
                },
              }}
            />
          </div>
          <div
            {...{
              css: css`
                display: flex;
                flex-direction: column;
                gap: 4px;
                @media (max-width: ${tablet}px) {
                  flex-direction: row;
                  width: 100%;
                }
              `,
            }}
          >
            <div
              {...{
                css: css`
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  width: 100%;
                `,
              }}
            >
              {!!item?.provider_information?.email && (
                <Paragraph
                  {...{
                    children: item?.provider_information?.email,
                    variant: 'sm',
                    style: {
                      color: appColors.content.secondary,
                    },
                  }}
                />
              )}
              {!!item?.phone && (
                <Paragraph
                  {...{
                    children: item?.phone,
                    variant: 'sm',
                    style: {
                      color: appColors.content.secondary,
                    },
                  }}
                />
              )}
            </div>
            <div
              {...{
                css: css`
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  width: 100%;
                `,
              }}
            >
              <Paragraph
                {...{
                  children: `NPI # ${item.npi_state_license_number}`,
                  variant: 'sm',
                  style: {
                    color: appColors.content.secondary,
                  },
                }}
              />
              <Paragraph
                {...{
                  children: `Licensed:  ${item.state_licenses.join(', ')}`,
                  variant: 'sm',
                  style: {
                    color: appColors.content.secondary,
                  },
                }}
              />
            </div>
          </div>
        </div>
        {/*  */}
        <div
          {...{
            css: css`
              @media (max-width: ${tablet}px) {
                display: none;
              }
            `,
          }}
        >
          <CustomDivider {...{ type: 'vertical' }} />
        </div>
        {/*  */}
        <div {...{ css: moreInfoContainer }}>
          <div
            {...{
              css: css`
                width: 100%;
              `,
            }}
          >
            <Detail
              {...{
                label: 'Specialty',
                value:
                  dropdownsHashes?.specialty?.[item.specialty]?.label || '',
              }}
            />
          </div>
          <div
            {...{
              css: css`
                width: 100%;
              `,
            }}
          >
            <Detail
              {...{
                label: 'Geographic Preference',
                value: item.geographic_preferences.join(', '),
              }}
            />
          </div>
        </div>
        {/*  */}
        <div {...{ css: contactContainer }}>
          {!!item.phone && (
            <Button
              {...{
                children: 'Contact',
                onClick: onClickContact,
                variant: '2',
              }}
            />
          )}

          <div
            {...{
              css: css`
                display: flex;
                flex-direction: column;
                gap: 8px;
                align-items: flex-end;
                @media (max-width: ${tablet}px) {
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                }
              `,
            }}
          >
            {!!item.application_resume_url && (
              <Paragraph
                {...{
                  onClick: onClickViewCV,
                  children: 'View CV',
                  variant: 'sm',
                  style: {
                    color: appColors.content.tertiary,
                  },
                }}
              />
            )}
            {!!item.profile_link && (
              <Paragraph
                {...{
                  onClick: onClickProfileLink,
                  children: 'Linkedin Profile',
                  variant: 'sm',
                  style: {
                    color: appColors.content.tertiary,
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(JobApplicationTile);

const Detail = ({ label, value }: { label: string; value: string }) => {
  return useMemo(() => {
    return (
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: column;
            gap: 8px;
          `,
        }}
      >
        <Label
          {...{
            text: label,
          }}
        />
        <Paragraph {...{ children: value, variant: 'sm' }} />
      </div>
    );
  }, []);
};
