import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconMapPin = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.8 6.05a7 7 0 019.9 0v0a7 7 0 010 9.9l-4.29 4.29a.933.933 0 01-1.32 0L7.8 15.95a7 7 0 010-9.9v0z"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
        <circle
          cx={12.75}
          cy={11}
          r={3}
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconMapPin };
