import styled from '@emotion/styled';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import PageHeader from '../PageHeader';
import { css } from '@emotion/react';
import { router, useForm as useInertiaForm } from '@inertiajs/react';
import { Controller, useForm } from 'react-hook-form';

import {
  HEADER_TITLE,
  HeaderContainer,
  NavHeaderButtonsContainer,
  NavHeaderContainer,
  dashboardContainer,
} from '../_index';
import SearchBar from '../SearchBar';
import NavButton from '../NavButton';
import Remaining from '../Remaining';
import List from '@/components/List';
import Modal from '@/components/Modal';
import { SortKey } from '@/types/types';
import layout from '@/constants/layout';
import InfoTooltip from '../InfoTooltip';
import { Job } from '@/types/entities.d';
import { usePage } from '@inertiajs/react';
import UploadCSVButton from '../UploadCSVButton';
import { getMetaToken } from '@/utils/meta-token';
import PostNewJobButton from '../PostNewJobButton';
import CustomPaginator from '@/components/Paginator';
import { RecruiterDashboardJobsProps } from '@/types/page';
import { useUrlSearchParams } from 'use-url-search-params';
import CustomDataTable from '@/components/Table/DataTable';
import { transformQueryParams } from '@/utils/query-params';
import { transformSortingValues } from '@/utils/data-table';
import NoResultsFound from '@/components/List/NoResultsFound';
import { RecruitersDashboardFormValues } from '@/types/forms';
import { datatableUtils } from '@/constants/datatables/_index';
import { initialState, reducer } from '@/constants/contexts/datatable-jobs';
import DeleteRecruiterJobsButton from '@/components/DeleteRows/DeleteRecruiterJobsButton';
import RecruitersDashboardListTile from '@/components/JobTiles/RecruitersDashboardListTile';
import { recruiterCustomDatatableConfig } from '@/constants/datatables/recruiter-dashboard';
import RecruitersDashboardTileModalContent from './TableListTile/RecruitersDashboardTileModalContent';

const {
  windowSizes: { tablet, mobile },
} = layout;

const NavHeaderRemainingContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1px;
  @media (max-width: ${mobile}px) {
    flex-direction: row-reverse;
  }
`;

const SearchHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${mobile}px) {
    flex-direction: column-reverse;
    gap: 24px;
  }
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const navButtons: ComponentProps<typeof NavButton>[] = [
  {
    children: 'Jobs',
    onClick: () => {},
  },
  {
    children: 'My Details',
    onClick: () => {
      router.get(
        `/recruiters/details`,
        {},
        {
          preserveState: false,
          preserveScroll: false,
        }
      );
    },
  },
];

const RecruitersDashboardTable = CustomDataTable<Job>;
const RecruitersDashboardList = List<Job>;

function RecruitersDashboard() {
  const { get, transform } = useInertiaForm();

  // URL PARAMS
  const {
    props: { session, status, ...rest },
  } = usePage<RecruiterDashboardJobsProps>();

  const [params, setParams] = useUrlSearchParams({});
  const urlParams = params as unknown as RecruitersDashboardFormValues;
  const parsedParams =
    transformQueryParams<RecruitersDashboardFormValues>(urlParams);
  //

  // INITAL STATES
  const initialRowSelection = {};
  const initialSorting = [
    {
      id: '',
      desc: true,
    },
  ];

  const initialFilterValues: RecruitersDashboardFormValues = {
    q: {
      facility_name_cont: parsedParams?.q?.facility_name_cont || '',
    },
    per: parsedParams?.per || 10,
    sort: parsedParams?.sort || '',
    page: parsedParams?.page || 1,
  };
  //

  // LOCAL STATE
  const [{ modal }, localDispatch] = useReducer(reducer, {
    ...initialState,
  });

  // UI TABLE CONTROLS
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [sorting, setSorting] = useState(initialSorting);

  //

  // FORM STATE
  const methods = useForm<RecruitersDashboardFormValues>({
    defaultValues: {
      ...initialFilterValues,
    },
    shouldUnregister: false,
  });

  // CONSTANTS
  const { isTileModalOpen, clickedTile } = modal;
  const { control, handleSubmit, setValue } = methods;
  const { collection, pagination, sort } = rest;
  //
  const rowSelected = Object.keys(rowSelection).map((id) => {
    return collection[parseInt(id)];
  });

  const isEmpty = collection.length === 0;

  const isAllowToCreateMoreJobs =
    pagination.totalCount < datatableUtils.jobs.TOTAL_MAX;
  //

  const triggerSearch = useCallback(
    async (form: RecruitersDashboardFormValues) => {
      const hasSearchValue = !!form?.q?.facility_name_cont;
      const hasSort = !!form?.sort;
      const isPageDiffOne = !!form?.page && form.page > 1;
      const isPerDiffTen = !!form?.per && form.per !== 10;
      const params = {
        q: {
          ...(hasSearchValue
            ? { facility_name_cont: form.q.facility_name_cont }
            : {}),
        },
        ...(hasSort ? { sort: form.sort } : {}),
        ...(isPerDiffTen ? { per: form.per } : {}),
        ...(isPageDiffOne ? { page: form.page } : {}),
      };
      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        get('/recruiters/jobs', {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
        });
      }
    },
    []
  );

  const submitSearch = useCallback(async () => {
    changePage(1);
  }, [triggerSearch]);

  const onCloseTileModal = useCallback(() => {
    localDispatch({ type: 'CLOSE_TILE_MODAL', payload: null });
  }, [localDispatch]);

  const onOpenTileModal = useCallback(
    (job: Job) => {
      localDispatch({ type: 'OPEN_TILE_MODAL', payload: job });
    },
    [localDispatch]
  );

  const onRowClicked = useCallback(
    (job: Job) => {
      onOpenTileModal(job);
    },
    [onOpenTileModal]
  );

  const resetRows = useCallback(() => {
    setRowSelection(initialRowSelection);
  }, [initialRowSelection]);

  const changePage = useCallback(async (page: number) => {
    setValue('page', page, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(triggerSearch)();
    setRowSelection(initialRowSelection);
  }, []);

  // EFFECT FOR SORTING
  useEffect(() => {
    const sortValue = transformSortingValues(sorting) as SortKey<Job>;
    setValue('sort', sortValue);
    changePage(1);
    return () => {};
  }, [sorting]);

  return (
    <>
      <head>
        <meta prefix="og: https://www.locums.com/" />
        <title>Recruiters - Jobs</title>
        <meta
          property="og:description"
          content="Advance Your Career: Explore a wide range of healthcare job opportunities with Locums.com Connect with top recruiters and find your dream job today!"
        />
        <meta
          property="og:image"
          content="https://locums-public.s3.us-west-2.amazonaws.com/locums-logo.png"
        />
      </head>
      <div {...{ css: dashboardContainer }}>
        <HeaderContainer>
          <PageHeader {...{ variant: 'h1Small', children: HEADER_TITLE }} />
          <NavHeaderContainer>
            <NavHeaderButtonsContainer>
              {navButtons.map((navButton, index) => {
                const isActive = index === 0;
                return (
                  <NavButton {...{ ...navButton, isActive, key: index }} />
                );
              })}
            </NavHeaderButtonsContainer>
            <NavHeaderRemainingContainer>
              <Remaining
                {...{
                  total: datatableUtils.jobs.TOTAL_MAX,
                  current: pagination.totalCount,
                  name: 'jobs',
                }}
              />
              <InfoTooltip
                {...{
                  message: datatableUtils.jobs.LIMIT_MSG,
                }}
              />
            </NavHeaderRemainingContainer>
          </NavHeaderContainer>
          <SearchHeaderContainer>
            {/* @ts-ignore */}
            <Controller
              {...{
                name: 'q.facility_name_cont',
                control,
                rules: {},
                render: ({ field }) => {
                  return (
                    <SearchBar
                      {...{
                        ...field,
                        searchIconAction: handleSubmit(submitSearch),
                        placeholder: 'Search facility name',
                      }}
                    />
                  );
                },
              }}
            />

            <div
              {...{
                css: buttonsContainer,
              }}
            >
              <div
                {...{
                  css: css`
                    display: flex;
                    @media (max-width: ${tablet}px) {
                      display: none;
                    }
                  `,
                }}
              >
                <DeleteRecruiterJobsButton
                  {...{
                    resetRows,
                    rowSelectedIds: rowSelected.map(({ id }) => id),
                  }}
                />
              </div>
              <UploadCSVButton
                {...{
                  buttonsProps: {
                    disabled: !isAllowToCreateMoreJobs,
                  },
                }}
              />
              <PostNewJobButton
                {...{
                  buttonsProps: {
                    disabled: !isAllowToCreateMoreJobs,
                  },
                }}
              />
            </div>
          </SearchHeaderContainer>
        </HeaderContainer>

        {isEmpty ? (
          <NoResultsFound
            {...{
              header: 'You don’t have any active jobs.',
              node: EmptyComponent,
            }}
          />
        ) : (
          <>
            <div
              {...{
                css: css`
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  gap: 10px;
                  overflow: auto;
                  @media (max-width: ${tablet}px) {
                    display: none;
                  }
                `,
              }}
            >
              <RecruitersDashboardTable
                {...{
                  data: collection,
                  sorting,
                  rowSelection,
                  onClickRow: onRowClicked,
                  onSortingChange: setSorting,
                  onRowSelectionChange: setRowSelection,
                  columns: recruiterCustomDatatableConfig.columns,
                }}
              />
              <CustomPaginator
                {...{
                  pagination,
                  changePage,
                }}
              />
            </div>
            <div
              {...{
                css: css`
                  display: none;
                  @media (max-width: ${tablet}px) {
                    display: flex;
                    width: 100%;
                  }
                `,
              }}
            >
              <RecruitersDashboardList
                {...{
                  data: collection,
                  pagination: pagination,
                  sort: sort,
                  ListEmptyComponent: EmptyComponent,
                  changePage,
                  RenderedItem: (props) => (
                    <RecruitersDashboardListTile
                      {...{ ...props, onClick: () => onRowClicked(props.item) }}
                    />
                  ),
                }}
              />
            </div>
            {isTileModalOpen && !!clickedTile && (
              <Modal {...{ position: 'top', onClose: onCloseTileModal }}>
                <RecruitersDashboardTileModalContent
                  {...{
                    modalProps: { closeModal: onCloseTileModal },
                    job: clickedTile,
                  }}
                />
              </Modal>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default RecruitersDashboard;

const EmptyComponent = () => {
  return (
    <div
      {...{
        css: buttonsContainer,
      }}
    >
      <UploadCSVButton />
      <PostNewJobButton />
    </div>
  );
};
