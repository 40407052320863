import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  forwardRef,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const SIZE = 24;
const BORDER_RADIUS = 24;
const BORDER_WIDTH = 1;

const CHECKED_COLOR = appColors.content.tertiary;
const DISABLED_COLOR = appColors.content.disabled;

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  readonly label: string;
}

const StyledRadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const StyledLabel = styled.label({
  ...paragraphDesktop.md,
  color: appColors.content.secondary,
});

const disabledStyles = css`
  background-color: ${DISABLED_COLOR};
  border-color: ${DISABLED_COLOR};
  cursor: not-allowed;
`;

const StyledRadio = styled.input`
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  width: ${SIZE}px;
  height: ${SIZE}px;
  background-color: #fff;
  border-radius: ${BORDER_RADIUS}px;
  border: ${BORDER_WIDTH}px solid ${appColors.border.primary};
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 0;
  &:hover {
    border-color: ${CHECKED_COLOR};
  }
  &:checked {
    background-color: ${CHECKED_COLOR};
    border-color: ${CHECKED_COLOR};
    &:disabled {
      ${disabledStyles}
    }
  }
  &:disabled {
    ${disabledStyles}
    background-color: transparent
  }
`;

const Radio: ForwardRefRenderFunction<HTMLInputElement, RadioProps> = (
  { label, ...props },
  ref
) => {
  return (
    <StyledRadioWrapper>
      <div
        {...{
          css: css`
            display: flex;
            position: relative;
          `,
        }}
      >
        <StyledRadio
          {...{
            ...props,
            type: 'radio',
            ref,
          }}
        />
        {(props.checked || props.value) && (
          <div
            {...{
              css: css`
                position: absolute;
                background-color: ${CHECKED_COLOR};
                pointer-events: none;
                top: 3px;
                left: 3px;
                border-radius: 20px;
                width: 18px;
                height: 18px;
                border: 2px solid white;
                ${props.disabled &&
                css`
                  background-color: ${DISABLED_COLOR};
                `}
              `,
            }}
          />
        )}
      </div>

      {!!label && <StyledLabel {...{ children: label }} />}
    </StyledRadioWrapper>
  );
};

export default forwardRef(Radio);
