import { FC, memo, useCallback } from 'react';

import Button, { ButtonProps } from './Button';
import { AuthModalsMode } from '@/types/types';
import useModalState from '@/hooks/useModalState';
import SigninRecoverySignup from './AuthFlows/SigninRecoverySignup';

export interface LoginSignupButtonProps {
  readonly buttonProps?: ButtonProps;
  readonly mode: AuthModalsMode;
}

const buttonPropsByMode: Record<AuthModalsMode, ButtonProps> = {
  signIn: {
    children: 'Log in',
    variant: '1',
  },
  signUp: {
    children: 'Sign Up',
    variant: '2',
  },
  recovery: {
    children: 'Recovery',
    variant: '1',
  },
};

const LoginSignupButton: FC<LoginSignupButtonProps> = (props) => {
  const {
    actions: { openAuth, close },
  } = useModalState();

  const onPressButton = useCallback(() => {
    openAuth(
      {
        children: <SigninRecoverySignup />,
        position: 'top',
        onClose: close,
      },
      props.mode
    );
    window.scrollTo(0,0);
  }, []);

  return (
    <>
      <Button
        {...{
          ...buttonPropsByMode[props.mode],
          ...props.buttonProps,
          onClick: onPressButton,
        }}
      />
    </>
  );
};

export default memo(LoginSignupButton);
