//FUNCTIONALITY & HOOKS
import { useCallback, useMemo, useState } from 'react';
import { HomeSearchParams } from '@/types/api/params';
import { getParamValueFromForm } from '@/utils/form-data';
import usePageSession from '@/hooks/usePageSession';
import { FormProvider, useForm } from 'react-hook-form';
import { router, Head } from '@inertiajs/react';

//COMPONENTS
import HomeFilterForm from '@/components/Forms/Home/HomeFilterForm';
import SearchCardDisplayer from '@/components/Home/SearchCardDisplayer';
import LoginSignupButton from '@/components/LoginSignupButton';
import AnchorLink from '@/components/Anchor';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import Bottomsheet from '@/components/Bottomsheet';
import TestimonialSlider from '@/components/Testimonials/TestimonialSlider';
import Heading from '@/components/Typography/Heading';
import AuthModal from '@/components/AuthModal';

//LAYOUTS & STRUCTURE
import {
  contentStyle,
  footerStyle,
  topBarStyle,
} from '@/layouts/TopContentFooterLayout';
import {
  FilterMenuButtons,
  FilterMenuHeader,
  FiltersMenuLayout,
} from '@/components/Filter/FiltersMenu';
import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';

//CSS & BACKGROUND
import { css } from '@emotion/react';
import { appColors } from '@/constants/colors';
import NursesBG from '../../../app/assets/images/recruiter-hero.png';
import Tumbler from '../../../app/assets/images/bottle.png';

//ICONS
import CloseIcon from '@/components/CloseIcon';
import { IconSearch } from '@/components/Icons/Search';
import {
  InstantEngagement,
  PostYourJobs,
  SignUp,
} from '@/components/Icons/RecruiterIconsPage';

const TEXT_COLOR = appColors.content.white;

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  components: { iconStyles },
  mediaQuery,
} = layout;

const initialNav = [
  { label: 'Job Board', url: '/jobs' },
  { label: 'Contact Us', url: '/contact' },
  //{ label: 'Content', url: '/content' },
];

type FiltersHomeFormValues = {
  professions: '';
  state_in: '';
  specialties: '';
};

const initialFilterValues: FiltersHomeFormValues = {
  professions: '',
  state_in: '',
  specialties: '',
};

const Form = HomeFilterForm<FiltersHomeFormValues>;

export default function HomePage() {
  const [isFilterMenuActive, setIsFilterMenuActive] = useState(false);
  const {
    session: { signed_in, current_user },
  } = usePageSession();

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const methods = useForm<FiltersHomeFormValues>({
    defaultValues: {
      ...initialFilterValues,
    },
    shouldUnregister: false,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting, isDirty },
    watch,
  } = methods;

  const navProps: NavBarProps = useMemo(() => {
    if (isSignedIn) {
      return {
        navLinks: [
          ...initialNav,
          {
            label: 'Dashboard',
            url:
              current_user?.is_type === 'recruiter'
                ? '/recruiters/jobs'
                : '/providers/applied_jobs?per=5&page=1',
          },
        ],
        buttons: [
          { children: 'Log in', variant: '1' },
          { children: 'Sign Up', variant: '2' },
        ],
      };
    }
    return {
      navLinks: initialNav,
      buttons: [
        { children: 'Log in', variant: '1' },
        { children: 'Sign Up', variant: '2' },
      ],
    };
  }, [isSignedIn]);

  const triggerSearch = useCallback(
    async ({ form }: { form: FiltersHomeFormValues }) => {
      const hasProfession = !!form?.professions;
      const hasState = !!form?.state_in && Array.isArray(form?.state_in);
      const hasSpecialty = !!form?.specialties;

      const params: HomeSearchParams = {
        q: {
          ...getParamValueFromForm('state_in', form?.state_in),
        },
        ...getParamValueFromForm('professions', form?.professions),
        ...getParamValueFromForm('specialties', form?.specialties),
      };

      const hasAtLeastOneParam = hasProfession || hasState || hasSpecialty;
      if (hasAtLeastOneParam) {
        router.get(SEARCH_URL, { ...params }, { data: {} });
      }
    },
    []
  );

  const handleFilterMenu = useCallback(() => {
    setIsFilterMenuActive(!isFilterMenuActive);
    return;
  }, [isFilterMenuActive]);

  const submitForm = useCallback(
    async (form: FiltersHomeFormValues) => {
      triggerSearch({
        form,
      });
    },
    [triggerSearch]
  );

  const onClickResetMobile = useCallback(() => {
    reset({
      ...initialFilterValues,
    });
    handleSubmit(submitForm)();
    return;
  }, []);

  return (
    <>
      <Head>
        <title>Recruiter Sign Up</title>
        <meta
          name="description"
          content="Locums.com offers a vast network of highly qualified healthcare professionals ready to fill your locum staffing needs, saving you time and resources."
        />
      </Head>
      <FormProvider {...methods}>
        <div style={{ backgroundColor: '#d6dfff' }}>
          <div {...{ css: heroImgStyle }}>
            <div
              {...{
                css: topBarStyle,
                style: {
                  background: 'transparent',
                  borderBottom: 'none',
                  color: 'white',
                },
              }}
            >
              <NavBar {...{ ...navProps, logoVariant: 'black-white' }} />
            </div>

            <div
              {...{
                css: css`
                  ${contentStyle}
                  justify-content: flex-start;
                  background-color: transparent;
                  flex-direction: row;
                  padding: 50px 0 80px 200px;
                  @media (max-width: ${tablet}px) {
                    padding: 20px 0%;
                  }
                  @media (max-width: ${mobile}px) {
                    justify-content: center;
                  }
                `,
              }}
            >
              <div {...{ css: headerContainerStyle }}>
                <label
                  {...{
                    css: css`
                      ${TitlePage}
                    `,
                    children: 'Empowering you',
                    style: { color: TEXT_COLOR },
                  }}
                />
                <label
                  {...{
                    css: css`
                      ${TitlePage}
                    `,
                    children: 'to recruit your way',
                    style: { color: TEXT_COLOR },
                  }}
                />
                <div {...{ css: HeroParagraphContainer }}>
                  <label
                    {...{
                      css: css`
                        ${HeroParagraph}
                      `,
                      children: 'Are you a locum tenens recruiter? Join the',
                      style: { color: TEXT_COLOR },
                    }}
                  />
                  <label
                    {...{
                      css: css`
                        ${HeroParagraph}
                      `,
                      children: 'industry’s fastest-growing job board today. ',
                      style: { color: TEXT_COLOR },
                    }}
                  />
                  <label
                    {...{
                      css: css`
                        ${HeroParagraph}
                      `,
                      children:
                        'Simply sign up, post your available jobs, and find',
                      style: { color: TEXT_COLOR },
                    }}
                  />
                  <label
                    {...{
                      css: css`
                        ${HeroParagraph}
                      `,
                      children: 'top healthcare providers, fast.',
                      style: { color: TEXT_COLOR },
                    }}
                  />
                  <div {...{ css: ButtonsContainer }}>
                    <LoginSignupButton
                      {...{
                        mode: 'signUp',
                        buttonProps: {
                          style: {
                            width: '45%',
                          },
                        },
                      }}
                    />
                    <LoginSignupButton
                      {...{
                        mode: 'signIn',
                        buttonProps: {
                          children: 'Post Jobs',
                          style: {
                            background: 'transparent',
                            width: '45%',
                            border: '1px solid #fff',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div {...{ css: pageContentStyle }}>
          <div {...{ css: ContentContainer }}>
            <div {...{ css: ContentTextContainer }}>
              <h3 {...{ css: ContentTitle }}>1. Sign up FREE to access</h3>
              <h3 {...{ css: ContentTitle, style: { marginLeft: '25px' } }}>
                your dashboard
              </h3>
              <div {...{ css: ContentParagraph1 }}>
                <li>Dashboard features your posted jobs,</li>
              </div>
              <div {...{ css: ContentParagraph2 }}>
                the number of views they receive,
              </div>
              <div {...{ css: ContentParagraph2 }}>
                {' '}
                and candidate applications in one{' '}
              </div>
              <div {...{ css: ContentParagraph2 }}> easy-to-access spot. </div>
            </div>
            <div {...{
              css: css`
                @media (max-width: ${mobile}px) {
                  align-self: center
                }
              `,
            }}>
              <SignUp />
            </div>
          </div>
          <div
            {...{
              css: css`
                ${ContentContainer}
                @media (max-width: ${mobile}px) {
                  flex-direction: column-reverse;
                  gap: 1em;
                  margin: 0;
                }
              `,
            }}
          >
            <div>
              <PostYourJobs />
            </div>
            <div {...{ css: ContentTextContainer }}>
              <h3 {...{ css: ContentTitle }}>2. Post jobs</h3>
              <div {...{ css: ContentParagraph1 }}>
                <li>Create job descriptions for any</li>
              </div>
              <div {...{ css: ContentParagraph2 }}>and all specialties.</div>
              <div {...{ css: ContentParagraph1, style: { marginTop: '1em' } }}>
                <li>Jobs are seen by thousands of</li>
              </div>
              <div {...{ css: ContentParagraph2 }}>
                healthcare professionals.
              </div>
              <div {...{ css: ContentParagraph1, style: { marginTop: '1em' } }}>
                <li>Edit and repost jobs in a snap.</li>
              </div>
            </div>
          </div>
          <div {...{ css: ContentContainer, style: { marginTop: '2em' } }}>
            <div {...{ css: ContentTextContainer }}>
              <h3 {...{ css: ContentTitle }}>3. Get results</h3>
              <div {...{ css: ContentParagraph1 }}>
                <li>You’ll receive notifications, CVs, and </li>
              </div>
              <div {...{ css: ContentParagraph2 }}>
                candidate contact information when
              </div>
              <div {...{ css: ContentParagraph2 }}>providers apply.</div>
              <div {...{ css: ContentParagraph1, style: { marginTop: '1em' } }}>
                <li>Easily connect with candidates once they</li>
              </div>
              <div {...{ css: ContentParagraph2 }}>
                apply for or inquire about your positions.
              </div>
              <div {...{ css: ContentParagraph1, style: { marginTop: '1em' } }}>
                <li>Recruiting quality healthcare providers has </li>
              </div>
              <div {...{ css: ContentParagraph2 }}>
                never been easier! MDs, DOs, CRNAs,
              </div>
              <div {...{ css: ContentParagraph2 }}>
                CNMs, PAs, NPs, PsyDs, PhDs, LCSWs.
              </div>
            </div>
            <div>
              <InstantEngagement />
            </div>
          </div>
          <h3
            {...{
              css: css`
                ${ContentTitle}
                margin: 3em 0 0 0;
                align-self: center;
                @media (max-width: ${mobile}px) {
                  align-self: center;
                  margin: 0 auto;
                  font-size: 20px;
                }
              `,
            }}
          >
            Locums.com
          </h3>
          <h3
            {...{
              css: css`
                ${ContentTitle}
                margin-bottom: 0;
                align-self: center;
                @media (max-width: ${mobile}px) {
                  align-self: center;
                  margin: 0 auto 0.5em auto;
                  font-size: 18px;                
                }
              `,
            }}
          >
            Where providers and recruiters click.
          </h3>
          <TestimonialSlider />
        </div>
        <div
          {...{
            css: pageContentStyle,
            style: { backgroundColor: '#fff', padding: '2em 0' },
          }}
        >
          <div {...{ css: ContentContainer }}>
            <div {...{ css: ContentTextContainer }}>
              <h3 {...{ css: ContentTitle, style: { marginBottom: '0' } }}>
                Move beyond candidate
              </h3>
              <h3 {...{ css: ContentTitle, style: { marginBottom: '0' } }}>
                pools and into active
              </h3>
              <h3 {...{ css: ContentTitle, style: { marginBottom: '0' } }}>
                engagement with top
              </h3>
              <h3 {...{ css: ContentTitle, style: { marginBottom: '0' } }}>
                quality applicants
              </h3>
              <LoginSignupButton
                {...{
                  mode: 'signUp',
                  buttonProps: {
                    variant: '1',
                    style: {
                      width: '50%',
                      marginTop: '1em',
                    },
                  },
                }}
              />
            </div>
            <div
              {...{
                css: css`
                  @media (max-width: ${mobile}px) {
                    align-self: center;
                  }
                `,
              }}
            >
              <SignUp />
            </div>
          </div>
        </div>
        <div {...{ css: pageContentStyle, style: { padding: '4em 0' } }}>
          <div {...{ css: ContentContainer }}>
            <div
              {...{
                css: css`
                  @media (max-width: ${mobile}px) {
                    align-self: center;
                  }
                `,
              }}
            >
              <img src={Tumbler} style={{ width: '350px' }} />
            </div>
            <div {...{ css: ContentTextContainer }}>
              <h3 {...{ css: ContentTitle, style: { marginBottom: '0' } }}>
                Act fast.
              </h3>
              <h3 {...{ css: ContentTitle, style: { marginBottom: '0' } }}>
                Get a free tumbler
              </h3>
              <h3 {...{ css: ContentTitle, style: { marginBottom: '0' } }}>
                when you sign up and
              </h3>
              <h3 {...{ css: ContentTitle, style: { marginBottom: '0' } }}>
                post your first job.
              </h3>
              <LoginSignupButton
                {...{
                  mode: 'signUp',
                  buttonProps: {
                    children: 'Post Now',
                    variant: '1',
                    style: {
                      width: '50%',
                      marginTop: '1em',
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
        <div
          {...{
            css: pageContentStyle,
            style: { backgroundColor: '#fff', padding: '40px', gap: '15px' },
          }}
        >
          <Heading {...{ variant: 'h2', children: 'Browse by specialty' }} />
          <SearchCardDisplayer />
        </div>
        <div {...{ css: footerStyle }}>
          <Footer {...{ ...navProps, logoVariant: 'blue-white' }} />
        </div>
        <div
          {...{
            css: css`
              display: none;
              @media (max-width: ${tablet}px) {
                display: ${isFilterMenuActive ? 'flex' : 'none'};
              }
            `,
          }}
        >
          <Bottomsheet
            {...{
              isOpen: isFilterMenuActive,
              onClose: handleFilterMenu,
              isLoading: isSubmitting,
            }}
          >
            <FiltersMenuLayout
              {...{
                cardProps: { hasBorder: false },
                header: (
                  <>
                    <FilterMenuHeader {...{ header: 'Search' }} />
                    <CloseIcon {...{ onClick: handleFilterMenu }} />
                  </>
                ),
                content: (
                  <>
                    <Form {...{}} />
                  </>
                ),
                buttons: (
                  <FilterMenuButtons
                    {...{
                      resetButtonProps: {
                        onClick: onClickResetMobile,
                        // disabled: !isDirty,
                      },
                      applyButtonProps: {
                        children: (
                          <div
                            {...{
                              css: css`
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                              `,
                            }}
                          >
                            Search
                            <div
                              {...{
                                css: css`
                                  ${iconStyles}
                                  margin-left: 4px;
                                `,
                              }}
                            >
                              <IconSearch {...{ iconColor: 'white' }} />
                            </div>
                          </div>
                        ),
                        onClick: () => {
                          handleFilterMenu();
                          handleSubmit(submitForm)();
                        },
                        disabled: !isDirty,
                      },
                    }}
                  />
                ),
              }}
            />
          </Bottomsheet>
        </div>
        <AuthModal />
      </FormProvider>
    </>
  );
}

const heroImgStyle = css`
  background: ${`url(${NursesBG})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 12em;
  @media (max-width: ${mobile}px) {
    padding-bottom: 0;
  }
`;

const headerContainerStyle = css`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-self: center;
  @media (max-width: ${desktop}px) {
    padding: 0px 15vw;
  }
  @media (max-width: ${mobile}px) {
    padding: 0 16px;
  }
`;

const pageContentStyle = css`
  width: 100%;
  background-color: ${appColors.background.secondary};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #d6dfff;
  //gap: 40px;
  //padding: 80px 0px;
`;

const TitlePage = css`
  text-align: left;
  font-family: Hepta Slab;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -1px;
  //padding-left: 4em;
  @media (max-width: ${tablet}px) {
    font-weight: 500;
    font-size: 25px;
  }
  @media (max-width: ${mobile}px) {
    padding: 0;
  }
`;

const HeroParagraphContainer = css`
  flex-direction: column;
  display: flex;
  padding: 1em 0 0 0;
  @media (max-width: ${tablet}px) {
    font-weight: 500;
    font-size: 25px;
  }
  @media (max-width: ${mobile}px) {
    padding: 1em 0 0 0;
  }
`;

const HeroParagraph = css`
  text-align: left;
  font-family: Hepta Slab;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -1px;
  //padding-left: 4em;
  @media (max-width: ${tablet}px) {
    font-weight: 400;
    font-size: 16px;
  }
  @media (max-width: ${mobile}px) {
    padding: 0;
  }
`;
const ContentContainer = css`
  display: flex;
  justify-content: space-evenly;
  gap: 10em;
  padding: 2em 0 0 0;
  @media (max-width: ${mobile}px) {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    gap: 0;
    margin: 1em 0 0 0;
  }
`;
const ContentTextContainer = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  @media (max-width: ${mobile}px) {
    margin: 0 auto;
    align-items: center;
  }
`;

const ContentTitle = css`
  font-size: 25px;
  font-weight: 500;
  font-family: Hepta Slab;
  margin: 0 1em 0.5em 0;
  align-self: flex-start;
  @media (max-width: ${mobile}px) {
    align-self: center;
    margin: 0 auto;
  }
`;
const ContentParagraph1 = css`
  display: block;
  text-align: left;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -1px;
  margin-left: 25px;
  //padding-left: 4em;
  @media (max-width: ${tablet}px) {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
  }
`;

const ContentParagraph2 = css`
  display: inline-block;
  text-align: left;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -1px;
  margin-left: 25px;
  //padding-left: 4em;
  @media (max-width: ${tablet}px) {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    margin: 0 auto;
  }
`;

const ButtonsContainer = css`
  display: flex;
  padding: 1em 0 0 0;
  gap: 1em;
`;
