import { ComponentProps, FC, memo } from 'react';

import { Job } from '@/types/entities.d';
import usePageSession from '@/hooks/usePageSession';
import JobShowDetailCard from './JobShowDetailCard';
import { getLabelFromValue } from '@/utils/form-data';
import useDropdownsData from '@/hooks/useDropdownsData';
import { datatableUtils } from '@/constants/datatables/_index';
import { boardCertificationHash } from '@/constants/selects/boardCertification';
import { credentialingTimeHash } from '@/constants/selects/credentialingtime';
import { formatDate } from '@/utils/daysjs-config';
import layout from '@/constants/layout';
import { Tooltip, Typography } from '@mui/material';

const {
  windowSizes: { mobile },
} = layout;

interface JobDetailsProps {
  job: Partial<Job>;
  defaultCardProps?: Partial<ComponentProps<typeof JobShowDetailCard>>;
  isShowing?: boolean;
}

const JobDetails: FC<JobDetailsProps> = ({
  job,
  defaultCardProps,
  isShowing,
}) => {
  const {
    session: { signed_in },
    session,
  } = usePageSession();
  const { dropdownsHashes } = useDropdownsData();

  const isAdmin = session?.current_user?.is_type === 'admin';
  const isSignedIn = signed_in;

  return (
    <>
      {!!job.profession && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'title',
            content: getLabelFromValue(
              job.profession,
              dropdownsHashes?.profession
            ),
          }}
        />
      )}
      {!!job.employment_type && job.employment_type.length !== 0 && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'employment_type',
            content: getLabelFromValue(
              job.employment_type,
              dropdownsHashes?.employment
            ),
          }}
        />
      )}
      {!!job?.assignment && job.assignment.length !== 0 && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'assignment',
            content: getLabelFromValue(
              job?.assignment,
              dropdownsHashes?.assignment
            ),
          }}
        />
      )}
      {!!job.facility && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'facility',
            content: getLabelFromValue(job.facility, dropdownsHashes?.facility),
          }}
        />
      )}
      {!!job.board_certification && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'board_certification',
            content: getLabelFromValue(
              job.board_certification,
              boardCertificationHash
            ),
          }}
        />
      )}
      {!!job.specialty && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'specialty',
            content: getLabelFromValue(
              job.specialty,
              dropdownsHashes?.specialty
            ),
          }}
        />
      )}

      {!!job.credentialing_time && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'credentialing_time',
            content: getLabelFromValue(
              job.credentialing_time,
              credentialingTimeHash
            ),
          }}
        />
      )}
      {!!job.target_start_date && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'target_start_date',
            content: formatDate(job.target_start_date),
          }}
        />
      )}
      {job.any_state_licenses === 'yes' && !!job.any_state_licenses && (
        <JobShowDetailCard
          {...{
            ...defaultCardProps,
            type: 'any_state_licenses',
            content: 'Accepted', //getLabelFromValue(job.any_state_licenses, anyStateLicensesHash),
          }}
        />
      )}
      {!!job.min_hourly_rate && (
        //@ts-ignore
        <JobShowDetailCard
          {...{
            extraChar: datatableUtils.MIN_HOUR_SUBTEXT,
            ...defaultCardProps,
            type: 'min_hourly_rate',
            content:
              !isSignedIn ||
              (session?.current_user?.is_type === 'recruiter' &&
                job?.recruiter_id !== session?.current_user?.id) ? (
                <Tooltip
                  {...{
                    title: 'Sign In to see Rates!',
                    slotProps: {
                      ...{
                        tooltip: {
                          sx: {
                            backgroundColor: '#05051B',
                            fontSize: '12px',
                            fontWeight: '600',
                          },
                        },
                      },
                    },
                  }}
                >
                  <Typography>
                    {isShowing
                      ? datatableUtils.getFormattedHourlyRate(
                          job.min_hourly_rate
                        )
                      : 'N/A'}
                  </Typography>
                </Tooltip>
              ) : (
                datatableUtils.getFormattedHourlyRate(job.min_hourly_rate)
              ),
            isBlurred:
              !isSignedIn ||
              (session?.current_user?.is_type === 'recruiter' &&
                job?.recruiter_id !== session?.current_user?.id), //not signed in, or is not the recruiter who posted it
          }}
        />
      )}
    </>
  );
};

export default memo(JobDetails);
