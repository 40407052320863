import { FC, memo } from 'react';
import styled from '@emotion/styled';

import {
  HeadingVariants,
  ParagraphVariants,
  headingDesktop,
  paragraphDesktop,
} from '@/constants/typography';
import Heading from '../Typography/Heading';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

const TEXT = 'SAMPLE_TEXT / sample_text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${appColors.background.quaternary};
`;

const Sample: FC<{
  name: string;
  children: React.ReactNode;
}> = ({ name, children }) => {
  return (
    <SampleContainer>
      <Heading
        {...{
          children: name,
          variant: 'h3',
        }}
      />
      {children}
    </SampleContainer>
  );
};

const MemoizedSampleComponent = memo(Sample);

const Typography: FC = () => {
  const defaultStyles = { backgroundColor: appColors.background.tertiary };
  return (
    <Container>
      <Heading
        {...{
          children: 'Heading',
          variant: 'h3',
          style: {
            textTransform: 'capitalize',
          },
        }}
      />
      {Object.entries(headingDesktop).map(([key, value]) => {
        const variant = key as HeadingVariants;
        return (
          <MemoizedSampleComponent {...{ name: key, key }}>
            <Heading
              {...{
                variant,
                children: TEXT,
                style: { ...defaultStyles },
              }}
            />
          </MemoizedSampleComponent>
        );
      })}
      <Heading
        {...{
          children: 'Paragraph',
          variant: 'h3',
          style: {
            textTransform: 'capitalize',
          },
        }}
      />
      {Object.entries(paragraphDesktop).map(([key, value]) => {
        const variant = key as ParagraphVariants;
        return (
          <MemoizedSampleComponent {...{ name: key, key }}>
            <Paragraph
              {...{
                variant,
                children: TEXT,
                style: { ...defaultStyles },
              }}
            />
          </MemoizedSampleComponent>
        );
      })}
    </Container>
  );
};

export default Typography;
