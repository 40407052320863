import { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import { MenuListProps, components } from 'react-select';

import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

const MenuList = ({ children, ...props }: MenuListProps) => {
  const {
    hasValue,
    clearValue,
    selectProps: { isClearable },
  } = props;
  const isClearDisabled = !hasValue;

  return (
    <div>
      <components.MenuList {...{ ...props }}>
        {isClearable && (
          <ClearButton
            {...{ isDisabled: isClearDisabled, action: clearValue }}
          />
        )}
        {children}
      </components.MenuList>
    </div>
  );
};

export default memo(MenuList);

const ClearButton = ({
  action,
  isDisabled,
}: {
  action: () => void;
  isDisabled: boolean;
}) => {
  return useMemo(() => {
    return (
      <div
        {...{
          ...(isDisabled
            ? {}
            : {
                onClick: isDisabled
                  ? () => {}
                  : (e) => {
                      e.preventDefault();
                      !!action && action();
                    },
              }),
          css: css`
            display: flex;
            padding-top: 12px;
            padding-left: 16px;
            padding-bottom: 12px;
            pointer-events: ${isDisabled ? 'none' : 'auto'};
            cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
            &:active {
              background-color: ${appColors.notInDesignSystem[4]};
            }
          `,
        }}
      >
        <Paragraph
          {...{
            children: 'Clear',
            variant: 'md',
            style: {
              cursor: 'inherit',
              fontWeight: 500,
              color: isDisabled
                ? appColors.content.disabled
                : appColors.content.tertiary,
            },
          }}
        />
      </div>
    );
  }, [action, isDisabled]);
};
