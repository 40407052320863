import { FC, memo } from 'react';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';

const AVATAR_SIZE = 38;

export interface AvatarProps {
  readonly url: string | null;
  readonly size?: number;
  onClick?: () => void;
}

export const avatarContainer = css`
  display: flex;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  background-color: ${appColors.content.tertiary};
  cursor: pointer;
`;

const Avatar: FC<AvatarProps> = (props) => {
  return (
    <div
    onClick={props.onClick}
      {...{
        css: css`
          ${avatarContainer}
          ${!!props.size
            ? { width: `${props.size}px`, height: `${props.size}px` }
            : ``}
        `,
      }}
    >
      {!!props?.url && (
        <img
          {...{
            src: '',
            style: {
              ...(props.size
                ? { width: AVATAR_SIZE, height: AVATAR_SIZE, borderRadius: 100 }
                : {}),
            },
          }}
        />
      )}
    </div>
  );
};

export default memo(Avatar);
