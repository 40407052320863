import { RefObject } from 'react';
import { IMaskMixin } from 'react-imask';

import Input from '../Input';

const InputMask = IMaskMixin(
  (props: { inputRef: RefObject<HTMLInputElement> }) => {
    return (
      <Input
        {...{
          ...props,
          ref: props.inputRef,
        }}
      />
    );
  }
);

export default InputMask;
