import { useMemo } from 'react';

import { IconCustomProps } from '@/types/types';

const IconCheck = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          stroke={iconColor || '#05051B'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m17.75 9-6 6-3-3"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconCheck };
