import { createSlice } from '@reduxjs/toolkit';

import {
  changePageThunk,
  handleFilterMenuThunk,
  handleSortThunk,
  handleMoreFiltersThunk,
  jobboardSearchThunk,
} from './jobboard-actions';
import { Job } from '@/types/entities.d';
import { ListProps } from '@/types/types';

type JobboardState = {
  jobs: ListProps<Job>;
  isFilterMenuActive: boolean;
  hasMoreFilters: boolean;
};

const initialState: JobboardState = {
  jobs: {
    list: new Map([]),
    status: 'IDLE',
    pagination: {
      previousPage: null,
      nextPage: null,
      totalCount: 0,
      currentPage: 1,
      totalPages: 0,
      per: 10,
    },
    requestId: '',
    fetchMoreStatus: 'IDLE',
    sort: 'posted_at desc',
  },
  //
  isFilterMenuActive: false,
  hasMoreFilters: true,
};

export const modalSlice = createSlice({
  name: 'jobboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // handleFilterMenuThunk //
    builder.addCase(
      handleFilterMenuThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      handleFilterMenuThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isFilterMenuActive = payload;
      }
    );
    builder.addCase(
      handleFilterMenuThunk.rejected,
      (state, { meta: { requestId } }) => {}
    );
    // handleMoreFiltersThunk //
    builder.addCase(
      handleMoreFiltersThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      handleMoreFiltersThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.hasMoreFilters = payload;
      }
    );
    builder.addCase(
      handleMoreFiltersThunk.rejected,
      (state, { meta: { requestId } }) => {}
    );

    // handleSortThunk //
    builder.addCase(
      handleSortThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      handleSortThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.jobs.sort = payload;
      }
    );
    builder.addCase(
      handleSortThunk.rejected,
      (state, { meta: { requestId } }) => {}
    );

    // changePageThunk //
    builder.addCase(
      changePageThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      changePageThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.jobs.pagination.currentPage = payload;
      }
    );
    builder.addCase(
      changePageThunk.rejected,
      (state, { meta: { requestId } }) => {}
    );

    // jobboardSearchThunk //
    builder.addCase(
      jobboardSearchThunk.pending,
      (state, { meta: { requestId } }) => {
        state.jobs.fetchMoreStatus = 'IDLE';
        state.jobs.status = 'FETCHING';
      }
    );
    builder.addCase(
      jobboardSearchThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.jobs.requestId = requestId;
        state.jobs.list = new Map(
          payload.collection.map((job) => [job.id, job])
        );
        state.jobs.pagination = payload.pagination;
        state.jobs.sort = payload.sort;
        state.jobs.status = 'SUCCESS';
      }
    );
    builder.addCase(
      jobboardSearchThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.jobs.fetchMoreStatus = 'IDLE';
        state.jobs.status = 'ERROR';
      }
    );
  },
});

export const {} = modalSlice.actions;

export default modalSlice.reducer;
