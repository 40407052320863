import Axios from './axios-api';

const csvTemplateDownload = async () => {
  const axios = new Axios('');
  try {
    const response = await axios.get<any>('/csv-instructions', {
      params: {},
      headers: {},
      responseType: 'blob',
    });

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { csvTemplateDownload };
