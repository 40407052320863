import AuthModal from '@/components/AuthModal';
import Heading from '@/components/Typography/Heading';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';

export default function Index() {
  return (
    <>
      <TopContentFooterLayout {...{}}>
        <Heading variant="h1">Index</Heading>
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
