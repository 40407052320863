import { FC } from 'react';
import styled from '@emotion/styled';

import AnimatedBar from '.';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
`;

export interface ProgressBarTrimmedProps {
  readonly total: number;
  readonly current: number;
  readonly duration?: number;
}

const ProgressBarTrimmed: FC<ProgressBarTrimmedProps> = (props) => {
  return (
    <Container>
      {[...new Array(props.total)].map((_, index) => {
        return (
          <AnimatedBar
            {...{
              key: index,
              duration: props.duration,
              toValue: props.current >= index ? 100 : 0,
            }}
          />
        );
      })}
    </Container>
  );
};

export default ProgressBarTrimmed;
