import {
  ContactParams,
  IdentifyParams,
  LogoutParams,
  RecoveryEmailParams,
  ResetPasswordParams,
  SignInParams,
  SignupParams,
  SignupPhysicianCVParams,
  SignupPhysicianContactInfoParams,
  SignupPhysicianProfessionalDetailsParams,
  SignupRecruiterCompanyParams,
  SignupRecruiterContactInfoParams,
  SignupResendConfirmationParams,
} from '@/types/api/params';
import {
  IdentifyResponse,
  LogoutResponse,
  RecoveryEmailResponse,
  ResetPasswordResponse,
  SignIResponse,
  SignupCloseResponse,
  SignupPhysicianCVResponse,
  SignupPhysicianContactInfoResponse,
  SignupPhysicianProfessionalDetailsResponse,
  SignupRecruiterCompanyResponse,
  SignupRecruiterContactInfoResponse,
  SignupResendConfirmationResponse,
  SignupResponse,
} from '@/types/api/responses';
import Axios from './axios-api';
import { ResourceType, UserType, UserTypeResponse } from '@/types/types';
import { ContactUsFormValues } from '@/types/forms';

// SIGN_IN

const IDENTIFY_TYPE_PATH = '/identify_user';
const SIGN_IN_PATH = `/sign_in`;

const identifyUser = async ({ email }: IdentifyParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.post<IdentifyResponse>(IDENTIFY_TYPE_PATH, {
      email,
    });

    if (response.status === 200) {
      if (
        response.data.user === 'providers' ||
        response.data.user === 'recruiters'
      ) {
        return response.data;
      } else if (!response.data.user_exists) {
        return response.data;
      }
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const signIn = async (
  type: UserTypeResponse,
  resource: ResourceType,
  { email, password }: SignInParams
) => {
  const axios = new Axios('');
  try {
    const response = await axios.post<SignIResponse>(
      `/${type}${SIGN_IN_PATH}`,
      {
        [resource]: {
          email,
          password,
        },
      }
    );
    if (response.status === 200) {
      // if (response.data.success) {
      return response.data;
      // }
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// RECOVERY

const PASSWORD_PATH = '/password';
const USER_PASSWORD_PATH = '/password?reset_password_token=';

const recoveryEmail = async (
  type: UserTypeResponse,
  resource: ResourceType,
  { email }: RecoveryEmailParams
) => {
  const axios = new Axios('');
  try {
    const response = await axios.post<RecoveryEmailResponse>(
      `/${type}${PASSWORD_PATH}`,
      {
        [resource]: {
          email,
        },
      }
    );

    if (response.status === 200) {
      return 'all good';
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const resetPassword = async (
  user: UserType,
  resource: ResourceType,
  params: ResetPasswordParams
) => {
  const axios = new Axios('');
  try {
    const response = await axios.put<ResetPasswordResponse>(
      `/${user}${USER_PASSWORD_PATH}${params.reset_password_token}`,
      {
        [resource]: {
          password: params.password,
          password_confirmation: params.password_confirmation,
          reset_password_token: params.reset_password_token,
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

// SIGNUP

const signUp = async (
  type: UserType,
  resource: ResourceType,
  params: SignupParams
) => {
  const axios = new Axios('');
  try {
    const response = await axios.post<SignupResponse>(`/${type}`, {
      [resource]: {
        ...params,
      },
    });

    if (response.status === 200) {
      if (response.data.success) {
        return response.data;
      }
    }
    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

// RESEND SIGNUP CONFIRMATION

const RESEND_SIGNUP_CONF = '/resend_confirmation_instructions';

const signUpResendConfirmation = async (
  params: SignupResendConfirmationParams
) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<SignupResendConfirmationResponse>(
      RESEND_SIGNUP_CONF,
      {
        params: {
          ...params,
        },
      }
    );

    if (response.status === 200) {
      if (response.data.success) {
        return response.data;
      }
    }
    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

const signupClose = async (id: number, resource: ResourceType) => {
  const axios = new Axios('');
  try {
    const response = await axios.put<SignupCloseResponse>(
      `/${resource}s/${id}`,
      {
        [resource]: {
          next_signup_status: 'signup_completed',
        },
      }
    );

    if (response.status === 200) {
      if (response.data.success) {
        return response.data;
      }
    }
    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

// SIGNUP PHYSICIAN

const SIGNUP_PHYSICIAN_PATH = '/providers/';

const signupPhysician = async <
  P extends
    | SignupPhysicianContactInfoParams
    | SignupPhysicianProfessionalDetailsParams
    | SignupPhysicianCVParams,
  R extends
    | SignupPhysicianContactInfoResponse
    | SignupPhysicianProfessionalDetailsResponse
    | SignupPhysicianCVResponse
>(
  id: number,
  params: P
) => {
  const axios = new Axios('');
  try {
    const response = await axios.put<R>(
      `${SIGNUP_PHYSICIAN_PATH}${id}`,
      {
        ['provider']: {
          ...params,
        },
      },
      {
        headers: {
          'Content-Type': params?.resume
            ? 'multipart/form-data'
            : 'application/json',
        },
      }
    );

    if (response.status === 200) {
      if (response.data.success) {
        return response.data;
      }
    }
    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

// SIGNUP RECRUITER

const SIGNUP_RECRUITER_PATH = '/recruiters/';

const signupRecruiter = async <
  P extends SignupRecruiterContactInfoParams | SignupRecruiterCompanyParams,
  R extends SignupRecruiterContactInfoResponse | SignupRecruiterCompanyResponse
>(
  id: number,
  params: P
) => {
  const axios = new Axios('');
  try {
    const response = await axios.put<R>(`${SIGNUP_RECRUITER_PATH}${id}`, {
      ['recruiter']: {
        ...params,
      },
    });

    if (response.status === 200) {
      if (response.data.success) {
        return response.data;
      }
    }
    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

// LOGOUT

const LOGOUT_PATH = '/sign_out';

const logout = async (user: ResourceType, params?: LogoutParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.delete<LogoutResponse>(
      `/${user}s${LOGOUT_PATH}`,
      params
    );
    if (response.status === 200) {
      if (response.data.success) {
        return response.data;
      }
    }

    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

// CONTACT

const CONTACT_PATH = '/contacts';

const contact = async (params: ContactParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.post(`${CONTACT_PATH}`, params);

    if (response.status === 200) {
      return response.data;
    }

    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

//content
const CONTENT_PATH = '/contents';

const content = async (params: ContactParams) => {
  const axios = new Axios('');
  try {
    const response = await axios.post(`${CONTACT_PATH}`, params);

    if (response.status === 200) {
      return response.data;
    }

    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

//news
const NEWS_PATH = '/news';

const news = async () => {
  const axios = new Axios('');
  try {
    const response = await axios.post(`${NEWS_PATH}`);

    if (response.status === 200) {
      return response.data;
    }

    return Promise.reject(response.data.message);
  } catch (error) {
    return Promise.reject(error);
  }
};

export {
  // SIGN_IN
  identifyUser,
  signIn,
  // RECOVERY
  recoveryEmail,
  resetPassword,
  // SIGN_UP
  signUp,
  signUpResendConfirmation,
  //
  signupClose,
  // SIGN_UP_PROVIDER
  signupPhysician,
  // SIGN_UP_RECRUITER
  signupRecruiter,
  // LOGOUT
  logout,
  // CONTACT
  contact,
  //CONTENT
  content,
  //NEWS
  news,
};
