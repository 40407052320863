import { useCallback, useEffect, useReducer, useState } from 'react';
import { css } from '@emotion/react';
import { router, useForm as useInertiaForm } from '@inertiajs/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  initialState,
  reducer,
} from '@/constants/contexts/datatable-admin-recruiter';
import {
  AdminRecruiterColumnIds,
  adminRecruitersCustomDatatableConfig,
} from '@/constants/datatables/admin-recruiters';
import Modal from '@/components/Modal';
import { SessionProps } from '@/types/page';
import { getMetaToken } from '@/utils/meta-token';
import { AdminRecruiter } from '@/types/entities.d';
import { DataTable, SortKey } from '@/types/types';
import CustomPaginator from '@/components/Paginator';
import SearchBar from '@/components/Dashboard/SearchBar';
import NavButton from '@/components/Dashboard/NavButton';
import FiltersDropdown from '@/components/FiltersDropdown';
import CustomDataTable from '@/components/Table/DataTable';
import { useUrlSearchParams } from 'use-url-search-params';
import { transformQueryParams } from '@/utils/query-params';
import { transformSortingValues } from '@/utils/data-table';
import NoResultsFound from '@/components/List/NoResultsFound';
import { datatableUtils } from '@/constants/datatables/_index';
import { AdminRecruitersFilterFormValues } from '@/types/forms';
import { AdminPanelLayout } from '@/components/AdminPanel/_index';
import DeleteAdminUsersButton from '@/components/DeleteRows/DeleteAdminUsersButton';
import AdminRecruitersFilterForm from '@/components/Forms/Admin/AdminRecruitersFilterForm';
import AdminPanelContentLayout from '@/components/AdminPanel/Layout/AdminPanelContentLayout';
import AdminRecruiterRowTileModal from '@/components/AdminPanel/Users/AdminRecruiterRowTileModal';
import RowsPerPageButton from '@/components/RowsPerPageButton';

const URL = '/admins/recruiters';

type AdminRecruitersPageProps = DataTable<AdminRecruiter> & SessionProps;

const AdminRecruiterTable = CustomDataTable<AdminRecruiter>;

export default function Recruiters(props: AdminRecruitersPageProps) {
  const { get: getJobs, patch, transform, processing } = useInertiaForm();

  const [params, setParams] = useUrlSearchParams({});
  const urlParams = params as unknown as AdminRecruitersFilterFormValues;
  const parsedParams =
    transformQueryParams<AdminRecruitersFilterFormValues>(urlParams);

  // LOCAL STATE
  const [{ modal }, localDispatch] = useReducer(reducer, {
    ...initialState,
  });

  // INITAL STATES
  const initialRowSelection = {};
  const initialSorting = [
    {
      id: '',
      desc: true,
    },
  ];
  const initialFilterValues: AdminRecruitersFilterFormValues = {
    q: {
      search: parsedParams?.q?.search || '',
      last_name_cont: parsedParams?.q?.last_name_cont || '',
      company_name_cont: '',
      created_at_eq: '',
      location_cont: '',
    },
    per: parsedParams?.per || 10,
    sort: parsedParams?.sort || '',
    page: parsedParams?.page || 1,
  };
  //

  // UI TABLE CONTROLS
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [sorting, setSorting] = useState(initialSorting);

  // FORM STATE
  const methods = useForm<AdminRecruitersFilterFormValues>({
    defaultValues: {
      ...initialFilterValues,
    },
    shouldUnregister: false,
  });

  // CONSTANTS
  const { isTileModalOpen, clickedTile } = modal;
  const { control, handleSubmit, setValue, reset } = methods;
  const { collection, pagination } = props;

  //
  const rowSelected = Object.keys(rowSelection).map((id) => {
    return collection[parseInt(id)];
  });

  const isEmpty = collection.length === 0;

  //

  const onCloseTileModal = useCallback(() => {
    localDispatch({ type: 'CLOSE_TILE_MODAL', payload: null });
  }, [localDispatch]);

  const onOpenTileModal = useCallback(
    (recruiter: AdminRecruiter) => {
      localDispatch({ type: 'OPEN_TILE_MODAL', payload: recruiter });
    },
    [localDispatch]
  );

  const triggerSearch = useCallback(
    async (form: AdminRecruitersFilterFormValues) => {
      const hasCompany = !!form?.q?.company_name_cont;
      const hasJoined = !!form?.q?.created_at_eq;
      const hasState = !!form?.q?.location_cont;
      const hasSearchValue = !!form?.q?.search;
      const hasSort = !!form?.sort;
      const isPageDiffOne = !!form?.page && form.page > 1;
      const isPerDiffTen = !!form?.per && form.per !== 10;

      const filterNames = form?.q?.search?.split(' ');
      const firstName = filterNames[0];
      const lastName = filterNames[1];

      const params = {
        q: {
          ...(hasSearchValue && filterNames.length === 1
            ? { first_name_or_last_name_cont: form?.q?.search }
            : {}),
          ...(hasSearchValue && filterNames.length === 2
            ? { first_name_cont: firstName }
            : {}),
          ...(hasSearchValue && filterNames.length === 2
            ? { last_name_cont: lastName }
            : {}),
          ...(hasCompany
            ? { company_name_cont: form?.q?.company_name_cont?.value }
            : {}),
          ...(hasJoined ? { posted_at_eq: form?.q?.created_at_eq?.value } : {}),
          ...(hasState ? { location_cont: form?.q?.location_cont?.value } : {}),
        },
        ...(hasSort ? { sort: form.sort } : {}),
        ...(isPageDiffOne ? { page: form.page } : {}),
        ...(isPerDiffTen ? { per: form.per } : {}),
      };

      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        getJobs(URL, {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
        });
      }
    },
    []
  );

  const onRowClicked = useCallback((recruiter: AdminRecruiter) => {
    onOpenTileModal(recruiter);
  }, []);

  const changePage = useCallback(async (page: number) => {
    setValue('page', page, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(triggerSearch)();
    setRowSelection(initialRowSelection);
  }, []);

  const submitSearch = useCallback(async () => {
    changePage(1);
  }, [triggerSearch]);

  const goToPage = useCallback((url: string) => {
    router.get(url, {}, { preserveScroll: false, preserveState: true });
  }, []);

  const onResetFilters = useCallback(() => {
    reset({
      ...initialFilterValues,
    });
    changePage(1);
  }, [triggerSearch]);

  const onApplyilters = useCallback(() => {
    changePage(1);
  }, [submitSearch]);

  const resetRows = useCallback(() => {
    setRowSelection(initialRowSelection);
  }, [initialRowSelection]);

  // EFFECT FOR SORTING
  useEffect(() => {
    const sortValue = transformSortingValues(
      sorting
    ) as SortKey<AdminRecruiterColumnIds>;
    setValue('sort', sortValue);
    changePage(1);
    return () => {};
  }, [sorting]);

  // EFFECT FOR ROWS PER PAGE
  const pageSizes = [10, 25, 50, 100] as number[];

  const rowsPerPage = useCallback(async (per: 10 | 25 | 50 | 100) => {
    setValue('per', per, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(triggerSearch)();
    setRowSelection(initialRowSelection);
  }, []);

  const handlePageSizeChange = (pageSize: number) => {
    const allowedPageSizes = [10, 25, 50, 100];
    if (allowedPageSizes.includes(pageSize)) {
      rowsPerPage(pageSize as 10 | 25 | 50 | 100);
      changePage(1);
    }
  };

  return (
    <div {...{ style: { pointerEvents: processing ? 'none' : 'auto' } }}>
      <AdminPanelLayout>
        <FormProvider {...methods}>
          <AdminPanelContentLayout
            {...{
              pageHeader: 'Users',
              searchComponent: (
                //@ts-ignore
                <Controller
                  {...{
                    name: 'q.search',
                    control,
                    rules: {},
                    render: ({ field }) => {
                      return (
                        <SearchBar
                          {...{
                            placeholder: 'Search all recruiters',
                            ...field,
                            searchIconAction: submitSearch,
                          }}
                        />
                      );
                    },
                  }}
                />
              ),
              navButtonsComponent: (
                <>
                  <NavButton
                    {...{
                      children: 'Clinicians',
                      isActive: false,
                      onClick: () => goToPage('/admins/providers'),
                    }}
                  />
                  <NavButton
                    {...{
                      children: 'Recruiters',
                      isActive: true,
                      onClick: () => {},
                    }}
                  />
                </>
              ),
              optionsComponent: (
                <>
                  <div>
                    <RowsPerPageButton
                      {...{
                        label: 'rows per page',
                        pageSize: pageSizes,
                        onClick: handlePageSizeChange,
                      }}
                    />
                  </div>
                  <div>
                    <DeleteAdminUsersButton
                      {...{
                        url: URL,
                        rowSelected,
                        resetRows,
                        rowSelectedIds: rowSelected.map(({ id }) => id),
                      }}
                    />
                  </div>
                  <div>
                    <FiltersDropdown
                      {...{
                        form: AdminRecruitersFilterForm,
                        resetAction: handleSubmit(onResetFilters),
                        applyAction: handleSubmit(onApplyilters),
                      }}
                    />
                  </div>
                </>
              ),
              tableComponent: isEmpty ? (
                <>
                  <NoResultsFound
                    {...{
                      header: datatableUtils.EMPTY_HEADER,
                      subheader: datatableUtils.EMPTY_SUBHEADER,
                    }}
                  />
                </>
              ) : (
                <>
                  <div
                    {...{
                      css: css`
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 10px;
                        overflow: auto;
                      `,
                    }}
                  >
                    <AdminRecruiterTable
                      {...{
                        data: collection,
                        sorting,
                        rowSelection,
                        onClickRow: onRowClicked,
                        onSortingChange: setSorting,
                        onRowSelectionChange: setRowSelection,
                        columns: adminRecruitersCustomDatatableConfig.columns,
                      }}
                    />
                  </div>
                  <CustomPaginator
                    {...{
                      pagination,
                      changePage,
                    }}
                  />
                  {isTileModalOpen && (
                    <Modal {...{ position: 'top', onClose: onCloseTileModal }}>
                      {!!clickedTile && (
                        <AdminRecruiterRowTileModal
                          {...{
                            modalProps: { closeModal: onCloseTileModal },
                            recruiter: clickedTile,
                          }}
                        />
                      )}
                    </Modal>
                  )}
                </>
              ),
            }}
          />
        </FormProvider>
      </AdminPanelLayout>
    </div>
  );
}
