import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconNotificationOn = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10.963 17a2 2 0 004 0m-2-12a5 5 0 015 5c0 1.902.303 3.326 1.106 5.311.303.75-.213 1.689-1.021 1.689H7.853c-.803 0-1.317-.927-1.016-1.671.783-1.935 1.125-3.316 1.126-5.329a5 5 0 015-5z"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
      </svg>
    ),
    [props]
  );

const IconNotificationOff = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.24 5.282L19.968 18.01"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.645 7.81A5.733 5.733 0 007.213 10c0 1.894-.315 3.18-1.071 5.047a2.025 2.025 0 00.142 1.777c.3.51.854.926 1.569.926h2.463a2.751 2.751 0 005.293 0h1.977l-1.5-1.5H7.853c-.089 0-.194-.047-.276-.187a.526.526 0 01-.045-.453c.81-2 1.18-3.478 1.18-5.61 0-.346.042-.682.12-1.004L7.645 7.81zm.486-.929l1.094 1.094A4.25 4.25 0 0117.213 10c0 2.012.328 3.531 1.16 5.592a.544.544 0 01-.046.466c-.084.145-.191.192-.28.192H17.5l1.321 1.321c.347-.17.62-.447.801-.756.297-.507.39-1.172.142-1.785-.772-1.91-1.051-3.239-1.051-5.03A5.75 5.75 0 008.13 6.881zm4.832 11.369c-.41 0-.772-.196-1-.5h2c-.228.304-.591.5-1 .5z"
          fill={iconColor || appColors.content.primary}
        />
      </svg>
    ),
    [props]
  );

export { IconNotificationOn, IconNotificationOff };
