import { Controller, UseFormReturn } from 'react-hook-form';

import Input from '@/components/Input';
import InputMask from '@/components/InputMask';
import { masks } from '@/constants/input-masks';
import selectsData from '@/constants/selects/_index';
import InputDropdown from '@/components/InputDropdown';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import { FieldsContainer, FormContainer, RowContainer } from '../..';
import { SignupPhysicianContactInfoFormValues } from '@/types/forms';

const SignupPhysicianContactInfoForm = ({
  methods,
}: {
  methods: UseFormReturn<SignupPhysicianContactInfoFormValues, any>;
}) => {
  const {
    control,
    formState: { isSubmitting },
  } = methods;

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'state',
                  control,
                  rules: { ...fieldsDefaultRules['state'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['state'],
                          options: selectsData['state'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'city',
                  control,
                  rules: { ...fieldsDefaultRules['city'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['city'],
                          ...field,

                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'zip',
                  control,
                  rules: { ...fieldsDefaultRules['zip'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['zip'],
                          mask: masks['zip'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'phone',
                  control,
                  rules: { ...fieldsDefaultRules['phone'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['phone'],
                          ...field,
                          mask: masks['phone'],
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default SignupPhysicianContactInfoForm;
