import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconTitle = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={1}
          y={4}
          width={22}
          height={16}
          rx={3}
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
        <path
          d="M12 9h7.5M12 12h7.5M12 15h7.5"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          strokeLinecap="round"
          fill="none"
        />
        <rect
          x={4}
          y={8}
          width={5}
          height={5}
          rx={1}
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconTitle };
