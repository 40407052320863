import { memo } from 'react';

import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

const JobListTileDescription = ({ description }: { description: string }) => {
  return (
    <Paragraph
      {...{
        children: description,
        variant: 'sm',
        style: {
          color: appColors.content.secondary,
          cursor: 'pointer',
          display: '-webkit-box',
          WebkitLineClamp: 4,
          webkitBoxOrient: 'vertical',
          overflow: 'hidden',
        },
      }}
    />
  );
};

export default memo(JobListTileDescription);
