import { memo } from 'react';

import Button from './Button';
import useLogout from '@/hooks/useLogout';

const LogoutButton = () => {
  const {
    state,
    actions: { logout },
  } = useLogout();

  if (state.signed_in) {
    return (
      <Button
        {...{
          children: 'Logout',
          variant: '1',
          onClick: logout,
          disabled: state.isLoginOut || !state.canLogout,
        }}
      />
    );
  }
  return <></>;
};

export default memo(LogoutButton);
