import { FC, useCallback } from 'react';
import { css } from '@emotion/react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Input from '../Input';
import Button from '../Button';
import Link from '../Typography/Link';
import LinkMessage from '../LinkMessage';
import { AuthModalsMode } from '@/types/types';
import { SigninFormValues } from '@/types/forms';
import { useAppDispatch } from '@/storage/store';
import { FieldsContainer, FormContainer } from '.';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { setAuthModalMode } from '@/storage/modal/ModalSlice';
import { fieldsDefaultRules } from '@/constants/form-validations';

const buttonContainer = css``;

const SigninForm: FC<{
  onSubmit: (form: SigninFormValues) => void;
  methods: UseFormReturn<SigninFormValues, any>;
}> = ({ methods, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods;
  const dispatch = useAppDispatch();

  const goToMode = useCallback(
    (mode: AuthModalsMode) => {
      dispatch(setAuthModalMode(mode));
    },
    [dispatch]
  );

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'email',
                control,

                rules: { ...fieldsDefaultRules['email'] },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['email'],
                        ...field,
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          <div
            {...{
              css: css`
                display: flex;
                flex-direction: column;
                gap: 8px;
              `,
            }}
          >
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'password',
                  control,

                  rules: { ...fieldsDefaultRules['password'], pattern: {} },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['password'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            <Link
              {...{
                children: 'Forgot your password?',
                onClick: () => goToMode('recovery'),
              }}
            />
          </div>
        </FieldsContainer>
        <div {...{ css: buttonContainer }}>
          <Button
            {...{
              variant: '1',
              children: 'Sign in',
              style: { width: '100%' },
              onClick: handleSubmit(onSubmit),
              disabled: !isValid || isSubmitting,
            }}
          />
        </div>
        <LinkMessage
          {...{
            msg: 'Don’t have an account?',
            link: {
              children: 'Sign Up',
              onClick: () => goToMode('signUp'),
            },
            textColor: 'gray',
          }}
        />
      </FormContainer>
    </form>
  );
};

export default SigninForm;
