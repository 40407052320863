import { useCallback } from 'react';
import { router, useForm as useInertiaForm } from '@inertiajs/react';
import { Controller, useForm } from 'react-hook-form';
import { css } from '@emotion/react';

import Input from '@/components/Input';
import Button from '@/components/Button';
import { SignInParams } from '@/types/api';
import Link from '@/components/Typography/Link';
import { SigninFormValues } from '@/types/forms';
import { getMetaToken } from '@/utils/meta-token';
import { fieldsDefaultProps } from '@/constants/form-fields';
import SignInContent from '@/components/AdminPanel/SignInContent';
import { fieldsDefaultRules } from '@/constants/form-validations';
import { FieldsContainer, FormContainer } from '@/components/Forms';
import AdminOnlyDesktopWrapper from '@/components/AdminPanel/AdminOnlyDesktopWrapper';

const URL = '/admins/sign_in';

const buttonContainer = css``;

export default function SignIn() {
  const { post, transform, processing } = useInertiaForm();

  const methods = useForm<SigninFormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isValid },
  } = methods;

  const goToRecovery = useCallback(async () => {
    router.get('/admins/password/new');
  }, []);

  const onSubmit = useCallback(async (form: SigninFormValues) => {
    const params: SignInParams = {
      email: form.email,
      password: form.password,
    };

    const metaTokenHeader = await getMetaToken();
    if (!!metaTokenHeader) {
      post(URL, {
        preserveState: true,
        data: transform(() => ({
          admin: { ...params },
        })),
        headers: {
          ...metaTokenHeader,
        },
        onSuccess: ({ props }) => {
          const { admin_errors } = props;

          const hasErrors =
            Array.isArray(admin_errors) && admin_errors.length > 0;

          if (hasErrors) {
            setError('email', {
              message: '',
              type: 'manual',
            });
            setError(
              'password',
              {
                message: '',
                type: 'manual',
              },
              {
                shouldFocus: true,
              }
            );
            return;
          }
          // REFRESH NEEDED FOR META TOKEN
          window.location.reload();
          return;
        },
        onError: (errorResponse) => {
          console.log({ errorResponse });
        },
      });
    }
  }, []);

  return (
    <AdminOnlyDesktopWrapper>
      <SignInContent>
        <form {...{ style: { width: '100%' } }}>
          <FormContainer
            {...{
              style: {
                ...(isSubmitting || processing
                  ? { pointerEvents: 'none' }
                  : {}),
              },
            }}
          >
            <FieldsContainer>
              {
                //@ts-ignore
                <Controller
                  {...{
                    name: 'email',
                    control,

                    rules: { ...fieldsDefaultRules['email'] },
                    render: ({ field, fieldState: { error } }) => {
                      return (
                        <Input
                          {...{
                            ...fieldsDefaultProps['email'],
                            ...field,
                            id: field.name,
                            error,
                          }}
                        />
                      );
                    },
                  }}
                />
              }
              <div
                {...{
                  css: css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                  `,
                }}
              >
                {
                  //@ts-ignore
                  <Controller
                    {...{
                      name: 'password',
                      control,

                      rules: { ...fieldsDefaultRules['password'], pattern: {} },
                      render: ({ field, fieldState: { error } }) => {
                        return (
                          <Input
                            {...{
                              ...fieldsDefaultProps['password'],
                              ...field,
                              id: field.name,
                              error,
                            }}
                          />
                        );
                      },
                    }}
                  />
                }
                <Link
                  {...{
                    children: 'Forgot your password?',
                    onClick: goToRecovery,
                  }}
                />
              </div>
            </FieldsContainer>
            <div {...{ css: buttonContainer }}>
              <Button
                {...{
                  variant: '1',
                  children: 'Sign in',
                  style: { width: '100%' },
                  onClick: handleSubmit(onSubmit),
                  disabled: !isValid || isSubmitting || processing,
                }}
              />
            </div>
          </FormContainer>
        </form>
      </SignInContent>
    </AdminOnlyDesktopWrapper>
  );
}
