import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  PLS_ENTER_MSG,
  fieldsDefaultRules,
} from '@/constants/form-validations';
import Input from '../../Input';
import InputMask from '@/components/InputMask';
import { masks } from '@/constants/input-masks';
import { ApplyNotLoggedInFormValues } from '@/types/forms';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { FieldsContainer, FormContainer, RowContainer } from '..';

const ApplyNotLoggedInForm: FC<{
  methods: UseFormReturn<ApplyNotLoggedInFormValues, any>;
}> = ({ methods }) => {
  const {
    control,
    formState: { isSubmitting },
  } = methods;

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'first_name',
                  control,
                  rules: { ...fieldsDefaultRules['first_name'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['first_name'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'last_name',
                  control,
                  rules: { ...fieldsDefaultRules['last_name'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['last_name'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'email',
                  control,
                  rules: { ...fieldsDefaultRules['email'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['email'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'phone',
                  control,
                  rules: {
                    required: {
                      value: true,
                      message: `${PLS_ENTER_MSG} phone number.`,
                    },
                    ...fieldsDefaultRules['phone'],
                  },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['phone'],
                          ...field,
                          mask: masks['phone'],
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default ApplyNotLoggedInForm;
