import { CSSProperties, FC, ReactNode, memo } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import CloseIcon from '@/components/CloseIcon';
import { LogoBlueBlack } from '@/components/Icons/Logo';

const {
  windowSizes: { mobile },
} = layout;

export const modalLayoutContainer = css`
  display: flex;
  flex-direction: column;
  padding: 40px;
  width: 600px;
  gap: 32px;
  position: relative;
  @media (max-width: ${mobile}px) {
    width: 100%;
    padding: 16px;
  }
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 4.5px;
  width: 100%;

  @media (max-width: ${mobile}px) {
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

const closeIconContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 16px;
  right: 16px;
`;

const logoContainer = css`
  display: flex;
  width: 243px;
  height: 50px;
`;

interface ModalLayoutProps {
  children?: ReactNode;
  closeModal: () => void;
  isLoading?: boolean;
  hasLogo?: boolean;
  containerStyles?: CSSProperties;
  openModal?: () => void;
}

const ModalLayout: FC<ModalLayoutProps> = ({
  children,
  closeModal,
  isLoading,
  hasLogo,
  containerStyles,
  openModal,
}) => {
  return (
    <div
      {...{
        css: css({
          ...modalLayoutContainer,
        }),
        style: {
          ...containerStyles,
          ...(!hasLogo ? { gap: 0 } : { gap: 32 }),
          ...(isLoading
            ? {
                pointerEvents: 'none',
              }
            : {}),
        },
        open: openModal,
      }}
    >
      <div
        {...{
          css: css`
            ${headerContainer}
          `,
        }}
      >
        <div {...{ css: closeIconContainer }}>
          <CloseIcon {...{ onClick: closeModal }} />
        </div>
        {hasLogo && (
          <div {...{ css: logoContainer }}>
            <LogoBlueBlack />
          </div>
        )}
      </div>
      <div {...{ style: { width: '100%', flexShrink: 1 } }}>{children}</div>
    </div>
  );
};

export default memo(ModalLayout);
