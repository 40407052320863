import { useCallback } from 'react';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';

import Button from '../Button';
import { FormHeader } from '../Forms';
import layout from '@/constants/layout';
import LinkMessage from '../LinkMessage';
import { applyJobAsGuest } from '@/api/jobs';
import { appColors } from '@/constants/colors';
import useModalState from '@/hooks/useModalState';
import { paragraphDesktop } from '@/constants/typography';
import { ApplyNotLoggedInFormValues } from '@/types/forms';
import ApplyForm from '../Forms/Jobboard/ApplyNotLoggedInForm';
import SigninRecoverySignup from '../AuthFlows/SigninRecoverySignup';

const {
  windowSizes: { mobile },
} = layout;

const ApplyModalContentGuest = ({
  closeModal,
  setHasApplied,
  job_id,
}: {
  closeModal: () => void;
  setHasApplied: (id: number) => void;
  job_id: number;
}) => {
  const {
    actions: { openAuth, close },
  } = useModalState();

  const initialFormValues: ApplyNotLoggedInFormValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
  };

  const methods = useForm<ApplyNotLoggedInFormValues>({
    defaultValues: {
      ...initialFormValues,
    },
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = methods;

  const onClickApplyNow = useCallback(
    async (form: ApplyNotLoggedInFormValues) => {
      try {
        const response = await applyJobAsGuest({
          job_id,
          ...form,
        });

        if (response.success) {
          setHasApplied(job_id);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [job_id]
  );

  const onClickSignIn = useCallback(() => {
    closeModal();
    openAuth(
      {
        children: <SigninRecoverySignup />,
        position: 'top',
        onClose: close,
      },
      'signIn'
    );
  }, [openAuth]);

  const onClickSignUp = useCallback(() => {
    closeModal();
    openAuth(
      {
        children: <SigninRecoverySignup />,
        position: 'top',
        onClose: close,
      },
      'signUp'
    );
  }, [openAuth]);

  return (
    <>
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: column;
            gap: 24px;
            @media (max-width: ${mobile}px) {
              gap: 32px;
            }
          `,
        }}
      >
        <FormHeader
          {...{
            title: 'Apply as a Guest',
            subtitle:
              'You can apply for this job without a locums.com account. However, your application will move quicker if you create an account or sign in.',
            type: 'gray_2',
          }}
        />
        <ApplyForm {...{ methods }} />
        <div
          {...{
            css: css`
              display: flex;
              flex-direction: column;
              gap: 32px;
            `,
          }}
        >
          <div
            {...{
              css: css`
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 8px;
              `,
            }}
          >
            <Button
              {...{
                children: 'Apply Now',
                onClick: handleSubmit(onClickApplyNow),
                variant: '1',
                disabled: !isValid || isSubmitting,
              }}
            />
            <label
              {...{
                css: css({
                  ...paragraphDesktop.md,
                  fontWeight: 500,
                  color: appColors.content.secondary,
                }),
                children: 'or',
              }}
            />
            <Button
              {...{
                children: 'Sign In',
                onClick: onClickSignIn,
                variant: '2',
              }}
            />
          </div>
          <LinkMessage
            {...{
              msg: 'Don’t have an account?',
              link: {
                children: 'Sign Up',
                onClick: onClickSignUp,
              },
              textColor: 'gray',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ApplyModalContentGuest;
