import { Controller, UseFormReturn } from 'react-hook-form';

import Input from '@/components/Input';
import InputDrop from '@/components/InputDrop';
import { FieldsContainer, FormContainer } from '../..';
import { mediaTypeList } from '@/constants/media-types';
import { errorMessages } from '@/components/CustomDropZone';
import { SignupPhysicianCVFormValues } from '@/types/forms';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';

const SignupPhysicianCVForm = ({
  methods,
}: {
  methods: UseFormReturn<SignupPhysicianCVFormValues, any>;
}) => {
  const {
    control,
    setValue,
    setError,
    formState: { isSubmitting },
  } = methods;

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'resume',
                control,
                // rules: { required: true },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDrop
                      {...{
                        ...fieldsDefaultProps['resume'],
                        ...field,
                        id: field.name,
                        error,
                        removeFileAction: () => {
                          setValue('resume', undefined, {
                            shouldTouch: true,
                            shouldDirty: true,
                            shouldValidate: true,
                          });
                        },

                        dropzoneProps: {
                          maxSize: 1048576,
                          maxFiles: 1,
                          onDrop: (accepted) => {
                            if (!!accepted[0]) {
                              const file = accepted[0];
                              const isFileTypeSupported =
                                mediaTypeList.includes(file.type);

                              if (isFileTypeSupported) {
                                setValue('resume', file, {
                                  shouldTouch: true,
                                  shouldDirty: true,
                                  shouldValidate: true,
                                });
                                return;
                              }
                              setError('resume', {
                                type: 'manual',
                                message: errorMessages.type,
                              });
                              return;
                            }
                            setError('resume', {
                              type: 'manual',
                              message: errorMessages.filesize,
                            });
                            return;
                          },
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'profile_link',
                control,
                rules: { ...fieldsDefaultRules['profile_link'] },
                render: ({ fieldState: { error }, field }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['profile_link'],
                        ...field,
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default SignupPhysicianCVForm;
