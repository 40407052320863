import { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FieldsContainer,
  FormContainer,
  MultiValueText,
  RecruitersOnlyDetailsCardHeader,
  RowContainer,
} from '../';
import Input, { INPUT_COMMONS, inputDefaultStyles } from '@/components/Input';
import selectsData from '@/constants/selects';
import InputMask from '@/components/InputMask';
import InputArea from '@/components/InputArea';
import { PostEditJobFormValues } from '@/types/forms';
import InputDropdown from '@/components/InputDropdown';
import useDropdownsData from '@/hooks/useDropdownsData';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { datatableUtils } from '@/constants/datatables/_index';
import MultiSelectOption from '@/components/InputDropdown/MultiSelectOption';
import RecruitersOnlyDetailsCard from '@/components/Dashboard/RecruitersOnlyDetailsCard';
import { css } from '@emotion/react';

const INPUT_HEIGHT = 100;

const placeHolderStyle = css`
  ${inputDefaultStyles}
  &:focus {
    outline: none;
    border-color: ${INPUT_COMMONS.BORDER_COLOR_DEFAULT_FOCUS};
    color: ${INPUT_COMMONS.TEXT_COLOR_DEFAULT};
  }
  &:disabled {
    border-color: ${INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED};
    color: ${INPUT_COMMONS.TEXT_COLOR_DISABLED};
    cursor: not-allowed;
  }
  &::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT};
  }
  &:focus::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_FOCUS};
  }
  &:visited::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_FOCUS};
  }
  &:disabled::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DISABLED};
  }
`;

const EditJobPostFormForm = ({
  methods,
}: {
  methods: UseFormReturn<PostEditJobFormValues, any>;
}) => {
  const { dropdownOptions } = useDropdownsData();

  const { control, watch } = methods;

  return (
    <form>
      <FormContainer>
        <FieldsContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'job.title',
                rules: {
                  required: { value: true, message: 'Job title is required' },
                  maxLength: {
                    value: 120,
                    message: 'Job title cannot exceed 120 characters',
                  },
                },
                control,
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['jobTitle'],
                        ...field,
                        label: 'Job Title',
                        required: true,
                        id: field.name,
                        error,
                        limit: error ? '' : watch('job.title').length,
                        maxLength: 120,
                      }}
                    />
                  );
                },
              }}
            />
          }

          {
            //@ts-ignore
            <Controller
              {...{
                name: 'job.profession',
                control,
                rules: {
                  required: {
                    value: true,
                    message: 'Position title is required',
                  },
                },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdown
                      {...{
                        ...fieldsDefaultProps['title'],
                        ...field,
                        label: 'Position Title',
                        required: true,
                        options: dropdownOptions.profession,
                        id: field.name,
                        error,
                        isMulti: true,
                        components: {
                          Option: (rest) => (
                            <MultiSelectOption
                              {...{ ...rest, shownValue: 'label' }}
                            />
                          ),
                          MultiValue: (rest) => (
                            <MultiValueText
                              {...{
                                ...rest,
                                shownValue: 'label',
                                allOptionsShown: true,
                              }}
                            />
                          ),
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.employment_type',
                  control,
                  rules: {
                    required: {
                      value: true,
                      message: 'Employer type is required',
                    },
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['employment_type'],
                          ...field,
                          label: 'Employer Type',
                          required: true,
                          options: dropdownOptions.employment,
                          id: field.name,
                          error,
                          components: {
                            Option: (rest) => (
                              <MultiSelectOption
                                {...{ ...rest, shownValue: 'label' }}
                              />
                            ),
                            MultiValue: (rest) => (
                              <MultiValueText
                                {...{
                                  ...rest,
                                  shownValue: 'label',
                                  allOptionsShown: true,
                                }}
                              />
                            ),
                          },
                          isMulti: true,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.specialty',
                  control,
                  rules: {
                    required: { value: true, message: 'Specialty is required' },
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['specialty'],
                          ...field,
                          label: 'Specialty',
                          required: true,
                          placeholder: 'Select specialty',
                          options: dropdownOptions.specialty,
                          id: field.name,
                          error,
                          components: {
                            Option: (rest) => (
                              <MultiSelectOption
                                {...{ ...rest, shownValue: 'label' }}
                              />
                            ),
                            MultiValue: (rest) => (
                              <MultiValueText
                                {...{
                                  ...rest,
                                  shownValue: 'label',
                                }}
                              />
                            ),
                          },
                          isMulti: true,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.facility',
                  control,
                  rules: {
                    required: { value: true, message: 'Facility is required' },
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['facility_eq'],
                          ...field,
                          label: 'Facility',
                          required: true,
                          placeholder: 'Select facility type',
                          options: dropdownOptions.facility,
                          error,
                          id: field.name,
                          components: {
                            Option: (rest) => (
                              <MultiSelectOption
                                {...{ ...rest, shownValue: 'label' }}
                              />
                            ),
                            MultiValue: (rest) => (
                              <MultiValueText
                                {...{
                                  ...rest,
                                  shownValue: 'label',
                                }}
                              />
                            ),
                          },
                          isMulti: true,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.state',
                  control,
                  rules: {
                    required: { value: true, message: 'State is required' },
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['state'],
                          options: selectsData['state'],
                          ...field,
                          label: 'State',
                          required: true,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>

          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.assignment',
                  control,
                  required: {
                    value: true,
                    message: 'Assignment is required',
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['assignment_type'],
                          ...field,
                          label: 'Assignment',
                          required: true,
                          options: dropdownOptions.assignment,
                          id: field.name,
                          error,
                          components: {
                            Option: (rest) => (
                              <MultiSelectOption
                                {...{ ...rest, shownValue: 'label' }}
                              />
                            ),
                            MultiValue: (rest) => (
                              <MultiValueText
                                {...{
                                  ...rest,
                                  shownValue: 'label',
                                }}
                              />
                            ),
                          },
                          isMulti: true,
                        }}
                      />
                    );
                  },
                }}
              />
            }

            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.min_hourly_rate',
                  control,
                  rules: {
                    required: {
                      value: true,
                      message: 'Min hourly compensation is required',
                    },
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['min_hourly_rate_gt'],
                          ...field,
                          error,
                          label: 'Min Hourly Compensation',
                          required: true,
                          id: field.name,
                          mask: Number,
                          hasExtraChar: true,
                          //subText: datatableUtils.MIN_HOUR_SUBTEXT,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>

          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.board_certification',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['board_certification'],
                          options: [
                            { value: 'required', label: 'Required' },
                            { value: 'not_required', label: 'Not Required' },
                          ],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.any_state_licenses',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['any_state_licenses'],
                          options: [
                            { value: 'yes', label: 'Yes' },
                            { value: 'no', label: 'No' },
                          ],
                          ...field,
                          id: field.name,
                          error,
                          styles: {
                            placeholder: () => {
                              return {
                                fontSize: '15px',
                                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                              };
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.credentialing_time',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['credentialing_time'],
                          options: [
                            { value: '1_week', label: '1 Week' },
                            { value: '1-2_weeks', label: '1-2 Weeks' },
                            { value: '2-4_weeks', label: '2-4 Weeks' },
                            { value: '4-8_weeks', label: '4-8 Weeks' },
                          ],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'job.target_start_date',
                  control,
                  rules: {
                    required: false,
                  },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['target_start_date'],
                          ...field,
                          error,
                          id: field.name,
                          type: 'date',
                          css: placeHolderStyle,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'job.description',
                control,
                rules: {
                  required: true,
                },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <InputArea
                      {...{
                        ...field,
                        style: {
                          height: INPUT_HEIGHT,
                        },
                        label: 'Job Description',
                        placeholder: 'Enter job description',
                        labelHint:
                          '(general location, dates of coverage, work schedule, job details, etc)',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'job.requirements',
                control,
                rules: {
                  required: true,
                },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <InputArea
                      {...{
                        ...field,
                        style: {
                          height: INPUT_HEIGHT,
                        },
                        label: 'Requirements',
                        placeholder: 'Enter job requirements',
                        labelHint:
                          '(list of certifications, procedures, call coverage, years of experience, etc)',
                        id: field.name,

                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
        <RecruitersOnlyDetailsCard
          {...{
            header: <RecruitersOnlyDetailsCardHeader />,
            content: (
              <FieldsContainer>
                <RowContainer>
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'job.reference_number',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                label: 'Internal Job #',
                                placeholder: 'Enter internal job number',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                  {
                    //@ts-ignore
                    <Controller
                      {...{
                        name: 'job.facility_name',
                        control,
                        render: ({ field, fieldState: { error } }) => {
                          return (
                            <Input
                              {...{
                                ...field,
                                label: 'Facility Name',
                                placeholder: 'Enter Facility Name',
                                id: field.name,
                                error,
                              }}
                            />
                          );
                        },
                      }}
                    />
                  }
                </RowContainer>
                {
                  //@ts-ignore
                  <Controller
                    {...{
                      name: 'job.city',
                      control,
                      render: ({ field, fieldState: { error } }) => {
                        return (
                          <Input
                            {...{
                              ...field,
                              label: 'City',
                              placeholder: 'Enter city name',
                              id: field.name,
                              error,
                            }}
                          />
                        );
                      },
                    }}
                  />
                }
                {
                  //@ts-ignore
                  <Controller
                    {...{
                      name: 'job.notes',
                      control,
                      render: ({ field, fieldState: { error } }) => {
                        return (
                          <InputArea
                            {...{
                              ...field,
                              label: 'Additional Notes',
                              placeholder: 'Add other additional notes',
                              id: field.name,
                              error,
                            }}
                          />
                        );
                      },
                    }}
                  />
                }
              </FieldsContainer>
            ),
          }}
        />
      </FormContainer>
    </form>
  );
};

export default memo(EditJobPostFormForm);
