import { SVGProps, useMemo } from 'react';

const IconMinus = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M19.75 12h-14"
          stroke="#05051B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconMinus };
