import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import usePageSession from '@/hooks/usePageSession';

const {
  windowSizes: { tablet, desktop, desktopHD },
} = layout;

interface TopContentFooterLayoutProps {
  // readonly topBar: React.ReactNode;
  readonly children?: React.ReactNode;
  // readonly footer?: React.ReactNode;
  // readonly scrollableFooter?: boolean; // New prop to enable/disable scrollable footer
}

export const horizontalHeaderFooterPadding = css`
  padding-left: 16vw;
  padding-right: 16vw;
`;

export const layoutContainerStyle = css`
  display: grid;
  grid-template-rows: auto 1fr auto;
  margin: 0 auto;
  width: 100%;
  grid-template-areas:
    'topBar'
    'content'
    'footer';
`;

export const topBarStyle = css`
  grid-area: topBar;
  ${horizontalHeaderFooterPadding}
  background-color: white;
  border-bottom: 1px solid ${appColors.border.primary};
  @media (max-width: ${desktopHD}px) {
    padding: 0px 60px;
  }
  @media (max-width: ${tablet}px) {
    padding: 0 16px;
  }
`;

export const contentStyle = css`
  display: flex;
  grid-area: content;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 60px 0px;
  gap: 80px;
  background-color: ${appColors.background.secondary};
  @media (max-width: ${desktopHD}px) {
  }
  @media (max-width: ${desktop}px) {
  }
  @media (max-width: ${tablet}px) {
    padding: 24px 0px;
  }
`;

export const footerStyle = css`
  grid-area: footer;
  background-color: ${appColors.content.primary};
  ${horizontalHeaderFooterPadding}
  @media (max-width: ${desktopHD}px) {
    padding: 0px 60px;
  }
  @media (max-width: ${tablet}px) {
    padding: 0px 16px;
  }
  /* New styles to make the footer scrollable */
  overflow-y: auto;
  max-height: calc(100vh - 60px); /* Adjust the height to include topBar */
`;

const TopContentFooterLayout: React.FC<TopContentFooterLayoutProps> = ({
  // topBar,
  children,
  // footer,
  // scrollableFooter = false, // Default value for scrollableFooter prop
}) => {
  const {
    session: { signed_in, current_user },
  } = usePageSession();
  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  const nav = [
    { label: 'News', url: '/news' },
    { label: 'Job Board', url: '/jobs' },
    { label: 'Contact Us', url: '/contact' },
  ];

  const [navPropLinks, setNavPropLinks] = useState(nav);

  useEffect(() => {
    const dashLink = {
      label: 'Dashboard',
      url:
        current_user?.is_type === 'recruiter'
          ? '/recruiters/jobs'
          : '/providers/applied_jobs?per=5&page=1',
    };

    if (
      isSignedIn &&
      !navPropLinks.some((item) => item.label === 'Dashboard')
    ) {
      setNavPropLinks([...navPropLinks, dashLink]);
    }
  }, [isSignedIn, current_user]);

  const navProps: NavBarProps = {
    navLinks: navPropLinks,
    buttons: [
      { children: 'Log in', variant: '1' },
      { children: 'Sign Up', variant: '2' },
    ],
  };

  return (
    <div {...{ css: layoutContainerStyle }}>
      <div {...{ css: topBarStyle, children: <NavBar {...navProps} /> }} />
      {!!children && <div {...{ css: contentStyle, children }} />}
      {/* {!!footer && (
        <div
          {...{
            css: scrollableFooter
              ? [footerStyle, { overflowY: 'auto' }]
              : footerStyle,
          }}
        >
          <Footer {...navProps} />
        </div>
      )} */}
      <div
        {...{
          css: footerStyle,
        }}
      >
        <Footer {...navProps} />
      </div>
    </div>
  );
};

export default TopContentFooterLayout;
