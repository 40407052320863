import { FC } from 'react';
import styled from '@emotion/styled';

import CustomBreadcrumbs from '../Breadcrumbs/index';

const LINKS = [
  { label: 'Home', url: '/', isActive: true },
  { label: 'Products', url: '/products', isActive: false },
  { label: 'Product', url: '/products/123', isActive: false },
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Breadcrumbs: FC = () => {
  return (
    <Container>
      <CustomBreadcrumbs {...{ links: LINKS }} />
    </Container>
  );
};

export default Breadcrumbs;
