import { FC, Fragment, useMemo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import {
  GroupBase,
  MultiValueProps,
  OptionProps,
  ValueContainerProps,
  components,
} from 'react-select';

import ProgressBarTrimmed, {
  ProgressBarTrimmedProps,
} from '../ProgressBar/ProgressBarTrimmed';
import {
  CompanyAttributes,
  SelectOption,
  ShownValue,
  TruncateProps,
} from '@/types/types';
import TagLabel from '../TagLabel';
import layout from '@/constants/layout';
import Heading from '../Typography/Heading';
import BackComponent from '../BackComponent';
import TextAvatar from '../Avatar/TextAvatar';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import Button, { ButtonProps } from '../Button';
import { IconVectorRight } from '../Icons/Vector';
import { CSSProperties } from '@emotion/serialize';
import EmailConfirmation from '../EmailConfirmation';
import { paragraphDesktop } from '@/constants/typography';
import { OPTION_HEIGHT, OPTION_PADDING } from '../InputDropdownAsync';

const {
  mediaQuery,
  windowSizes: { mobile },
} = layout;

const resetFormDefaultOptions = {
  keepValues: true,
  keepDirty: false,
  keepIsSubmitted: true,
};

type HeaderType = 'gray' | 'gray_2' | 'dark';

const styleByType: Record<
  HeaderType,
  { container: {}; title: {}; subtitle: {} }
> = {
  gray: {
    container: {
      gap: 16,
    },
    title: {},
    subtitle: {
      color: appColors.content.secondary,
    },
  },
  gray_2: {
    container: {
      gap: 8,
    },
    title: {},
    subtitle: {
      fontSize: 12,
      color: appColors.content.secondary,
    },
  },
  dark: {
    container: {},
    title: {},
    subtitle: {},
  },
};

const FormHeader: FC<{
  title?: string;
  subtitle?: string;
  type?: HeaderType;
}> = ({ title, subtitle, type = 'dark' }) => {
  return useMemo(
    () => (
      <div
        {...{
          css: css`
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 32px;
          `,
          style: styleByType[type].container,
        }}
      >
        {!!title && (
          <Heading
            {...{
              variant: 'h3',
              children: title,
              style: styleByType[type].title,
            }}
          />
        )}
        {!!subtitle && (
          <Paragraph
            {...{
              variant: 'md',
              children: subtitle,
              style: styleByType[type].subtitle,
            }}
          />
        )}
      </div>
    ),
    [title, subtitle]
  );
};

// // //

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  @media (max-width: ${mobile}px) {
    gap: 24px;
  }
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const FormButtonContainer = styled.div`
  display: flex;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
  }
`;

const RowGridContainer = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  gap: 16px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: ${mobile}) {
    grid-template-rows: repeat(1, 1fr);
  }
`;

const StepperHeaderContainer = styled.div<{ canGoBack?: boolean }>`
  margin-bottom: ${({ canGoBack }) => (canGoBack ? '8px' : '32px')};
`;

// // //

const SaveSkipButtons: FC<{
  saveButtonProps: ButtonProps;
  skipButtonProps: ButtonProps;
}> = ({ saveButtonProps, skipButtonProps }) => {
  return (
    <div
      {...{
        css: css`
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 8px;
        `,
      }}
    >
      <Button
        {...{
          children: 'Save & Continue',
          ...saveButtonProps,
          style: { ...saveButtonProps?.style, width: '100%' },
          variant: '1',
        }}
      />
      <Button
        {...{
          children: 'Skip for Now',
          ...skipButtonProps,
          style: { ...skipButtonProps?.style, width: '100%' },
          variant: '2',
        }}
      />
    </div>
  );
};

const CancelNextButtons: FC<{
  cancelButtonProps: ButtonProps;
  nextButtonProps: ButtonProps;
}> = ({ cancelButtonProps, nextButtonProps }) => {
  return (
    <div
      {...{
        css: css`
          display: flex;
          flex-direction: row;
          width: 100%;
          gap: 8px;
          @media (max-width: ${mobile}px) {
            flex-direction: column-reverse;
          }
        `,
      }}
    >
      <Button
        {...{
          children: 'Cancel',
          variant: '2',
          ...cancelButtonProps,
          style: { ...cancelButtonProps?.style, width: '100%' },
        }}
      />
      <Button
        {...{
          children: 'Next',
          icon: IconVectorRight,
          variant: '1',
          ...nextButtonProps,
          style: { ...nextButtonProps?.style, width: '100%' },
        }}
      />
    </div>
  );
};

//

interface StepperProgresBarProps extends ProgressBarTrimmedProps {
  readonly canGoBack?: boolean;
  readonly onClickBack?: () => void;
  readonly containerStyle?: CSSProperties;
}

const StepperProgresBar: FC<StepperProgresBarProps> = ({
  canGoBack,
  total,
  current,
  onClickBack,
}) => {
  return (
    <div
      {...{
        css: css`
          display: flex;
          flex-direction: column;
          gap: 32px;
        `,
      }}
    >
      <ProgressBarTrimmed {...{ total, current, duration: 150 }} />
      {canGoBack && <BackComponent {...{ onClickBack }} />}
    </div>
  );
};

const CompanySelectOption = ({
  isFocused,
  isSelected,
  data,
  ...props
}: OptionProps<CompanyAttributes, boolean>) => {
  return (
    <components.Option {...{ ...props, isFocused, isSelected, data }}>
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 0;
            gap: 8px;
            height: ${OPTION_HEIGHT}px;

            padding: 0px ${OPTION_PADDING}px ${OPTION_PADDING}px;
            background-color: ${isFocused || isSelected
              ? appColors.notInDesignSystem[6]
              : 'white'};
            cursor: pointer;
          `,
        }}
      >
        <div
          {...{
            css: css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            `,
          }}
        >
          {!!data.logo_url ? (
            <img
              {...{
                css: css`
                  display: flex;
                  width: 48px;
                  height: 48px;
                  border-radius: 48px;
                `,
                src: data.logo_url,
              }}
            />
          ) : (
            <TextAvatar {...{ text: data.name }} />
          )}
        </div>
        <div
          {...{
            css: css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              gap: 4px;
            `,
          }}
        >
          <Paragraph {...{ variant: 'md', children: data.name }} />
          {!!data.website && (
            <Paragraph
              {...{
                variant: 'sm',
                children: data.website,
                style: { color: appColors.content.secondary },
              }}
            />
          )}
        </div>
      </div>
    </components.Option>
  );
};

const CustomValueContainer = ({
  children,
  shownValue = 'value',
  ...props
}: ValueContainerProps<SelectOption> & ShownValue) => {
  let [values, input] = children as any;

  if (Array.isArray(values)) {
    const val = (i: number) => {
      return values[i].props.data[shownValue];
    };

    const { length } = values;

    switch (length) {
      case 1:
        values = `${val(0)}`;
        break;
      case 2:
        values = `${val(0)}, ${val(1)}`;
        break;
      case 3:
        values = `${val(0)}, ${val(1)}, ${val(2)}`;
        break;
      default:
        const plural = values.length === 3 + 1 ? '' : 's';
        const otherCount = length - 3;
        values = `${val(0)}, ${val(1)}, ${val(
          2
        )} and ${otherCount} other${plural}.`;
        break;
    }
  }

  return (
    <components.ValueContainer {...props}>
      <div
        {...{
          css: css`
            display: flex;
            width: fit-content;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `,
        }}
      >
        <label
          {...{
            css: css`
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
            `,
            children: values,
          }}
        />
      </div>
      {input}
    </components.ValueContainer>
  );
};

const CustomAssignmentValueContainer = ({
  children,
  shownValue = 'value',
  ...props
}: ValueContainerProps<SelectOption> & ShownValue) => {
  let [values, input] = children as any;

  if (Array.isArray(values)) {
    const val = (i: number) => {
      return values[i].props.data[shownValue];
    };

    const { length } = values;

    switch (length) {
      case 1:
        values = `${val(0)}`;
        break;
      case 2:
        values = `${val(0)}, ${val(1)}`;
        break;
      default:
        values = `${val(0)}, ${val(1)}, ${val(2)}`;
        break;
    }
  }

  return (
    <components.ValueContainer {...props}>
      <div
        {...{
          css: css`
            display: flex;
            width: fit-content;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `,
        }}
      >
        <label
          {...{
            css: css`
              font-family: Inter;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 140%;
            `,
            children: values,
          }}
        />
      </div>
      {input}
    </components.ValueContainer>
  );
};

const MultiValueChip: React.ComponentType<
  MultiValueProps<unknown, boolean, GroupBase<unknown>> & ShownValue
> = ({ data, removeProps, shownValue = 'label', ...props }) => {
  const onClick = removeProps.onClick as () => void;

  const option = data as unknown as SelectOption;

  return (
    <TagLabel
      {...{
        text: option[shownValue],
        variant: 2,
        size: 'md',
        clearAction: onClick,
      }}
    />
  );
};

const MultiValueText = ({
  data,
  removeProps,
  shownValue = 'label',
  truncateNumber,
  isFirstOptionTruncated,
  allOptionsShown,
  ...props
}: MultiValueProps & ShownValue & TruncateProps) => {
  const option = data as unknown as SelectOption;

  const {
    selectProps: { value },
  } = props;

  const size = (Array.isArray(value) && value.length) || 0;

  const index =
    Array.isArray(value) &&
    value.findIndex((item) => item[shownValue] === option[shownValue]);

  const hasMoreThanOne = Array.isArray(value) && value.length > 1;
  const isLastOne = index === size - 1;
  const isFirstOne = index === 0;
  const isRest = typeof index === 'number' && index > 1;

  const shownOption = useMemo(() => {
    const text = option[shownValue];

    const truncatedText = text.substring(0, truncateNumber || 8);

    if (!!allOptionsShown) {
      if (hasMoreThanOne) {
        return isFirstOne
          ? `${option?.[shownValue]}, `
          : isLastOne
          ? `${option?.[shownValue]}`
          : `${option?.[shownValue]}, `;
      }
      return option?.[shownValue];
    }

    if (isFirstOptionTruncated) {
      if (hasMoreThanOne) {
        return isFirstOne
          ? `${truncatedText}...`
          : isRest
          ? ``
          : ` +${size - 1}`;
      }
      return isFirstOne ? `${truncatedText}...` : ``;
    }
    if (hasMoreThanOne) {
      return isFirstOne ? `${text},` : isRest ? `` : ` +${size - 1}`;
    }

    return `${text}${hasMoreThanOne ? `${isLastOne ? `` : `, `}` : ''}`;
  }, [
    isFirstOne,
    hasMoreThanOne,
    isLastOne,
    option,
    hasMoreThanOne,
    truncateNumber,
    size,
    isFirstOptionTruncated,
  ]);

  if (!shownOption) return <Fragment />;

  return (
    <div
      {...{
        css: css`
          display: flex;
          flex-shrink: 0;
          flex-grow: 0;
        `,
      }}
    >
      <div
        {...{
          css: css`
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          `,
        }}
      >
        <label
          {...{
            css: css({
              ...paragraphDesktop.lg,
              [mediaQuery.mobile]: {
                ...paragraphDesktop.sm,
              },
            }),
            children: shownOption,
          }}
        />
      </div>
    </div>
  );
};

const ModalHeader = ({
  header,
  style,
}: {
  header: string;
  style?: CSSProperties;
}) => {
  return (
    <Paragraph
      {...{
        style: {
          color: appColors.content.secondary,
          ...style,
        },
        children: header,
        variant: 'sm',
      }}
    />
  );
};

const FormSectionHeader = ({ header }: { header: string }) => {
  return (
    <Paragraph
      {...{ children: header, variant: 'lg', style: { fontWeight: 500 } }}
    />
  );
};

//  RECOVERY REUSED COMPONENTS

const RecoveryEmailFormHeader = () => {
  return useMemo(() => {
    return (
      <FormHeader
        {...{
          title: 'Enter your email',
          subtitle: 'We’ll send an email with a link to reset your password.',
        }}
      />
    );
  }, []);
};

const RecoveryEmailConfirmation = ({
  email,
  resendAction,
}: {
  email: string;
  resendAction: () => void;
}) => {
  return useMemo(() => {
    return (
      <EmailConfirmation
        {...{
          email,
          header: 'Check your email',
          msg: 'We sent a password reset link to',
          msg2: 'Please click the link provided in the email to reset your password',
          resendAction,
        }}
      />
    );
  }, [email, resendAction]);
};

const RecruitersOnlyDetailsCardHeader = () => {
  return useMemo(
    () => (
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
          `,
        }}
      >
        <Paragraph
          {...{
            children: 'Other Details for Recruiter Use Only',
            variant: 'md',
            style: {
              fontWeight: 500,
            },
          }}
        />
        <Paragraph
          {...{
            children: '(will not be made public)',
            variant: 'sm',
            style: {
              color: appColors.content.secondary,
            },
          }}
        />
      </div>
    ),
    []
  );
};

export {
  FormHeader,
  FormContainer,
  FieldsContainer,
  FormButtonContainer,
  RowContainer,
  SaveSkipButtons,
  StepperProgresBar,
  StepperHeaderContainer,
  CompanySelectOption,
  CustomValueContainer,
  resetFormDefaultOptions,
  MultiValueChip,
  MultiValueText,
  CancelNextButtons,
  ModalHeader,
  CustomAssignmentValueContainer,
  FormSectionHeader,
  RowGridContainer,
  RecoveryEmailFormHeader,
  RecoveryEmailConfirmation,
  RecruitersOnlyDetailsCardHeader,
};
