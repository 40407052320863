import { FC, memo } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { IconInfo } from '../Icons/Info';
import TooltipMessage from '../TooltipMessage';

const {
  components: { iconStyles },
} = layout;

interface InfoTooltipProps {
  message: string;
}

const InfoTooltip: FC<InfoTooltipProps> = ({ message }) => {
  return (
    <div
      {...{
        css: css`
          ${iconStyles}
          cursor: pointer;
        `,
        'data-tooltip-id': 'tooltip-message',
      }}
    >
      <IconInfo />
      <TooltipMessage
        {...{
          children: message,
        }}
      />
    </div>
  );
};

export default memo(InfoTooltip);
