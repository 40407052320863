import { FC, ReactNode } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';

const {
  windowSizes: { desktopHD, desktop },
} = layout;

const FOOTER_HEIGHT = 108;
const SIDEBAR_WIDTH = 240;
const SIDEBAR_ALT_WIDTH = 205;

interface SideBarContentLayoutProps {
  children: ReactNode;
  siderbar: ReactNode;
  footer: ReactNode;
}

const layoutStyles = css`
  display: grid;
  width: 100%;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
  grid-template-columns: ${SIDEBAR_WIDTH}px 1fr;
  @media (max-width: ${desktop}px) {
    width: 100%;
    grid-template-columns: ${SIDEBAR_ALT_WIDTH}px 1fr;
  }
`;

const sidebarStyles = css`
  grid-column: 1;
  grid-row: 1 / span 2;
  padding: 40px 20px;
  width: ${SIDEBAR_WIDTH}px;
  background-color: white;
  position: fixed;
  height: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  overflow: hidden;
  box-shadow: 4px 0px 4px -10px rgba(0, 0, 0, 0.8);
  @media (max-width: ${desktop}px) {
    width: ${SIDEBAR_ALT_WIDTH}px;
  }
`;

const contentContainerStyles = css`
  grid-column: 2;
  grid-row: 1;
  overflow-y: auto;
  width: 100%;

  background-color: ${appColors.background.secondary};
`;

const contentStyles = css`
  display: flex;
  padding: 40px;
  padding-right: 8%;
  width: 100%;
  @media (max-width: ${desktopHD}px) {
    padding-right: 5.5%;
  }
  @media (max-width: ${desktop}px) {
    padding-right: 40px;
  }
`;

const footerStyles = css`
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${appColors.content.primary};
  height: ${FOOTER_HEIGHT}px;
  padding: 40px 60px;
`;

const SideBarContentLayout: FC<SideBarContentLayoutProps> = ({
  children,
  siderbar,
  footer,
}) => {
  return (
    <div {...{ css: layoutStyles }}>
      <div {...{ css: sidebarStyles, children: siderbar }} />
      <div {...{ css: contentContainerStyles }}>
        <div {...{ css: contentStyles }}>{children}</div>
      </div>
      <div {...{ css: footerStyles, children: footer }} />
    </div>
  );
};

export default SideBarContentLayout;
