import { CSSProperties, ComponentProps, FC, Fragment } from 'react';
import { css } from '@emotion/react';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';

import { PromptButtons, PromptContainer, PromptHeader } from '.';

interface QuestionPromptProps {
  headerProps: ComponentProps<typeof PromptHeader>;
  msgProps: EmotionJSX.Element[];
  buttonsProps: ComponentProps<typeof PromptButtons>;
  containerStyles?: CSSProperties;
}

const QuestionPrompt: FC<QuestionPromptProps> = ({
  headerProps,
  msgProps,
  buttonsProps,
  containerStyles,
}) => {
  return (
    <PromptContainer {...{ style: { ...containerStyles } }}>
      <PromptHeader {...headerProps} />
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `,
        }}
      >
        {!!msgProps &&
          msgProps.length > 0 &&
          msgProps.map((item, index) => {
            const Msg = item;
            return <Fragment key={index}>{Msg}</Fragment>;
          })}
      </div>
      <PromptButtons {...buttonsProps} />
    </PromptContainer>
  );
};

export default QuestionPrompt;
