import { ComponentProps, FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useForm as useInertiaForm } from '@inertiajs/react';

import { getMetaToken } from '@/utils/meta-token';
import { statesHash } from '@/constants/selects/states';
import useDropdownsData from '@/hooks/useDropdownsData';
import { defaultToastSuccess } from '@/constants/toasts';
import { ApplicationDetailsFormValues } from '@/types/forms';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { CancelNextButtons, FormHeader } from '@/components/Forms';
import { ApplicationDetailsType, SAVED_MSG, containerStyles } from './_index';
import ApplicationDetailsForm from '@/components/Forms/DashboardMyDetails/ApplicationDetailsForm';

interface ApplicationDetailsContentProps {
  details: ApplicationDetailsType;
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
}

const ApplicationDetailsContent: FC<ApplicationDetailsContentProps> = ({
  details,
  modalLayoutProps,
}) => {
  const { closeModal } = modalLayoutProps;
  const { patch, transform, processing } = useInertiaForm();

  const { dropdownsHashes } = useDropdownsData();

  const {
    title,
    assignment_type,
    specialty,
    state_licenses,
    geographic_preferences,
    npi_state_license_number,
  } = details?.profession_detail || {};

  const hasTitle = !!title;
  const hasAssignmentType = !!assignment_type;
  const hasSpecialty = !!specialty;
  const hasStateLicense = state_licenses?.length > 0;
  const hasGeoPref = geographic_preferences?.length > 0;

  const initialFormValues: ApplicationDetailsFormValues = {
    user_id: details?.provider.id,
    state: statesHash?.[details?.provider?.state] || '',
    city: details?.provider?.city || '',
    zip: details?.provider?.zip || '',
    phone: details?.provider?.phone || '',
    //
    title: hasTitle ? dropdownsHashes.profession?.[title] : '',
    assignment_type: hasAssignmentType
      ? dropdownsHashes.assignment[assignment_type]
      : '',
    specialty: hasSpecialty ? dropdownsHashes.specialty[specialty] : '',
    geographic_preferences: hasGeoPref
      ? geographic_preferences.map((item) => {
          return statesHash[item];
        })
      : '',
    state_licenses: hasStateLicense
      ? state_licenses.map((item) => {
          return statesHash[item];
        })
      : '',
    npi_state_license_number: npi_state_license_number || '',
    //
    profile_link: details?.provider?.profile_link || '',
    resume: { name: details?.resume || '' },
  };

  const methods = useForm<ApplicationDetailsFormValues>({
    defaultValues: { ...initialFormValues },
    mode: 'onChange',
  });

  const {
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    reset,
  } = methods;

  const isSubmittingForm = processing || isSubmitting;

  const resetForm = useCallback(() => {
    reset(
      {},
      {
        keepIsSubmitted: false,
        keepErrors: false,
        keepDefaultValues: true,
        keepDirty: false,
      }
    );
  }, [reset]);

  const closeResetModal = useCallback(() => {
    resetForm();
    !!closeModal && closeModal();
  }, [closeModal]);

  const onClickCancel = useCallback(() => {
    resetForm();
    closeResetModal();
  }, []);

  const onClickSave = useCallback(
    async (form: ApplicationDetailsFormValues) => {
      const hasNewFile = form.resume?.path;
      const isSameFile = form.resume?.name === initialFormValues?.resume?.name;

      const hasStateLicense = form?.state_licenses?.length > 0;
      const hasGeoPref = form?.geographic_preferences?.length > 0;

      const url = `/providers/${details?.provider.id}/inertia`;

      const params = {
        provider: {
          //
          state: form.state.value || '',
          city: form.city,
          zip: form.zip,
          phone: form.phone,
          //
          professional_detail_attributes: {
            title: form?.title?.value || '',
            assignment_type: form?.assignment_type?.value || '',
            specialty: form?.specialty?.value || '',
            geographic_preferences: hasGeoPref
              ? form?.geographic_preferences.map((item) => item.value)
              : [],
            state_licenses: hasStateLicense
              ? form?.state_licenses.map((item) => item.value)
              : [],
            npi_state_license_number: form.npi_state_license_number || '',
          },
          //
          profile_link: form.profile_link,
          ...(hasNewFile
            ? { resume: form.resume }
            : {
                ...(isSameFile ? {} : { purge_cv: true }),
              }),
        },
      };

      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        patch(url, {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
          onSuccess: (successResponse) => {
            defaultToastSuccess(undefined, SAVED_MSG);
            closeModal();
          },
          onError: (errorResponse) => {
            console.log(errorResponse);
          },
        });
      }
    },
    [details?.provider.id]
  );

  return (
    <FormProvider {...methods}>
      <ModalLayout {...{ closeModal: closeResetModal }}>
        <div {...{ css: containerStyles }}>
          <FormHeader {...{ title: 'Edit Application Details' }} />
          <div {...{}}>
            <ApplicationDetailsForm />
          </div>
          <CancelNextButtons
            {...{
              cancelButtonProps: {
                onClick: onClickCancel,
                disabled: isSubmittingForm,
              },
              nextButtonProps: {
                onClick: handleSubmit(onClickSave),
                disabled: isSubmittingForm || !isDirty || !isValid,
                variant: '6',
                children: 'Save Changes',
                icon: undefined,
              },
            }}
          />
        </div>
      </ModalLayout>
    </FormProvider>
  );
};

export default memo(ApplicationDetailsContent);
