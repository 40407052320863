import { ColumnDef } from '@tanstack/react-table';
import Checkbox from '@/components/Checkbox';
import { AdminProvider } from '@/types/entities.d';
import { ProfessionCell, SpecialtyCell } from './_index';
import TooltipMessage from '@/components/TooltipMessage';
import ColumnHeader from '@/components/Table/ColumnHeader';
import CellRendererText from '@/components/Table/CellRendererText';
import { Tooltip } from '@mui/material';
import CvsFile from '@/components/Icons/Cvs';
import { appColors } from '../colors';

// // //

interface AdminProviderColumnIds {
  id: string;
  first_name: string;
  professional_detail_title: string;
  professional_detail_specialty: string;
  professional_detail_npi_state_license_number: string;
  state: string;
  jobs_applied: string;
  professional_detail_state_licenses: string;
  resume_information_name: string;
}

// //

const columns: ColumnDef<AdminProvider>[] = [
  {
    id: 'select',
    accessorKey: 'select',
    enableSorting: false,
    header: ({ table }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'User ID' }} />,
    id: 'id',
    accessorFn: (row) => row?.id,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'User' }} />,
    id: 'first_name',
    accessorFn: (row) => `${row?.first_name} ${row?.last_name}`,
    cell: (info) => {
      return <CellRendererText {...{ text: info.getValue() || '' }} />;
    },
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Profession' }} />,
    id: 'professional_detail_title',
    accessorFn: (row) => row?.professional_detail?.title,
    cell: (info) => <ProfessionCell {...{ value: info.getValue() }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Specialty' }} />,
    id: 'professional_detail_specialty',
    accessorFn: (row) => row?.professional_detail?.specialty,
    cell: (info) => <SpecialtyCell {...{ value: info.getValue() }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'NPI Number' }} />,
    id: 'professional_detail_npi_state_license_number',
    accessorFn: (row) => row?.professional_detail?.npi_state_license_number,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Location' }} />,
    id: 'state',
    accessorFn: (row) => row?.state,
    cell: (info) => (
      <CellRendererText {...{ text: info.getValue() || '' }} />
    ),
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: '# Jobs applied' }} />,
    id: 'jobs_applied',
    accessorFn: (row) => row?.jobs_applied,
    cell: (info) => (
      <CellRendererText {...{ text: String(info.getValue()) || 0 }} />
    ),
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'State License' }} />,
    id: 'professional_detail_state_licenses',
    accessorFn: (row) => row?.professional_detail?.state_licenses,
    cell: (info) => {
      const value = info.getValue();

      const hasStates = Array.isArray(value) && value.length > 0;

      if (hasStates) {
        const statesText = value.join(', ') || '';
        return (
          <div>
            <Tooltip
              {...{
                title: statesText,
                slotProps: {
                  ...{
                    tooltip: {
                      sx: {
                        backgroundColor: '#05051B',
                        fontSize: '12px',
                        fontWeight: '600',
                      },
                    },
                  },
                },
              }}
            >
              <div>
                <CellRendererText {...{ text: statesText }} />
              </div>
            </Tooltip>
          </div>
        );
      }
      return <CellRendererText {...{ text: '' }} />;
    },
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'CV' }} />,
    id: 'resume_information_name',
    accessorFn: (row) => row?.resume_information?.name,
    cell: (info) => (
      <CellRendererText
        {...{ text: info.getValue() ? <CvsFile /> : '' }}
      />
    ),
  },
];

const adminProvidersCustomDatatableConfig = {
  columns,
};

export { adminProvidersCustomDatatableConfig, AdminProviderColumnIds };
