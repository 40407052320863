import { FC, memo, useCallback } from 'react';

import Link from '../Typography/Link';
import Paragraph from '../Typography/Paragraph';

type LinkType = 'email' | 'link';

interface CellRendererTextProps {
  text?: string;
  link?: string;
  linkType?: LinkType;
}

const CellRendererText: FC<CellRendererTextProps> = ({
  text,
  link,
  linkType,
}) => {
  const openLink = useCallback(() => {
    window.open(link || '', '_blank');
  }, [link, text]);

  if (!!link) {
    return (
      <Link
        {...{
          ...(linkType === 'email'
            ? {
                href: `mailto:${link || ''}?subject=${encodeURIComponent(
                  link || ''
                )}&body=${encodeURIComponent('' || '')}`,
              }
            : {}),
          ...(linkType === 'link'
            ? {
                onClick: openLink,
              }
            : {}),
          children: link || '',
          style: {
            cursor: 'inherit',
          },
        }}
      />
    );
  }

  return (
    <Paragraph
      {...{
        variant: 'sm',
        children: text || '',
        style: {
          cursor: 'inherit',
        },
      }}
    />
  );
};

export default memo(CellRendererText);
