import { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  CustomValueContainer,
  FieldsContainer,
  FormContainer,
  RowContainer,
} from '../..';
import { SelectOption } from '@/types/types';
import FakeField from '@/components/FakeField';
import InputMask from '@/components/InputMask';
import { masks } from '@/constants/input-masks';
import selectsData from '@/constants/selects/_index';
import InputDropdown from '@/components/InputDropdown';
import { ApplyLoggedinFormValues } from '@/types/forms';
import useDropdownsData from '@/hooks/useDropdownsData';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import MultiSelectOption from '@/components/InputDropdown/MultiSelectOption';

const ApplyLoggedinProfessionalDetailsForm = ({
  methods,
  isEditMode,
}: {
  methods: UseFormReturn<ApplyLoggedinFormValues, any>;
  isEditMode: boolean;
}) => {
  const { dropdownOptions } = useDropdownsData();
  const {
    control,
    formState: { isSubmitting },
  } = methods;

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'title',
                  control,
                  rules: { ...fieldsDefaultRules['title'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    if (isEditMode) {
                      return (
                        <InputDropdown
                          {...{
                            ...fieldsDefaultProps['title'],
                            options: dropdownOptions?.profession,
                            ...field,
                            id: field.name,
                            error,
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label: fieldsDefaultProps['title'].label || '',
                          value: field.value.label || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'assignment_type',
                  control,
                  rules: { ...fieldsDefaultRules['assignment_type'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    if (isEditMode) {
                      return (
                        <InputDropdown
                          {...{
                            ...fieldsDefaultProps['assignment_type'],
                            options: dropdownOptions?.assignment,
                            ...field,
                            id: field.name,
                            error,
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label:
                            fieldsDefaultProps['assignment_type'].label || '',
                          value: field.value.label || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'specialty',
                  control,
                  rules: { ...fieldsDefaultRules['specialty'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    if (isEditMode) {
                      return (
                        <InputDropdown
                          {...{
                            ...fieldsDefaultProps['specialty'],
                            options: dropdownOptions?.specialty,
                            ...field,
                            id: field.name,
                            error,
                          }}
                        />
                      );
                    }

                    return (
                      <FakeField
                        {...{
                          label: fieldsDefaultProps['specialty'].label || '',
                          value: field.value.label || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'geographic_preferences',
                  control,
                  rules: { ...fieldsDefaultRules['geographic_preferences'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    if (isEditMode) {
                      return (
                        <InputDropdown
                          {...{
                            ...fieldsDefaultProps['geographic_preferences'],
                            ...field,
                            options: selectsData['state'],
                            id: field.name,
                            components: {
                              Option: (rest) => (
                                <MultiSelectOption
                                  {...{ ...rest, shownValue: 'value' }}
                                />
                              ),
                              ValueContainer: CustomValueContainer,
                            },
                            isMulti: true,
                            error,
                          }}
                        />
                      );
                    }
                    const hasGeoPref =
                      Array.isArray(field.value) && field.value.length > 0;
                    return (
                      <FakeField
                        {...{
                          label:
                            fieldsDefaultProps['geographic_preferences']
                              .label || '',
                          value: hasGeoPref
                            ? field.value
                                .map((item: SelectOption) => item.value)
                                .join(', ')
                            : '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'state_licenses',
                  control,
                  rules: { ...fieldsDefaultRules['state_licenses'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    if (isEditMode) {
                      return (
                        <InputDropdown
                          {...{
                            ...fieldsDefaultProps['state_licenses'],
                            ...field,
                            options: selectsData['state'],
                            id: field.name,
                            components: {
                              Option: (rest) => (
                                <MultiSelectOption
                                  {...{ ...rest, shownValue: 'value' }}
                                />
                              ),
                              ValueContainer: CustomValueContainer,
                            },
                            isMulti: true,
                            error,
                          }}
                        />
                      );
                    }
                    const hasStateLicenses =
                      Array.isArray(field.value) && field.value.length > 0;

                    return (
                      <FakeField
                        {...{
                          label:
                            fieldsDefaultProps['state_licenses'].label || '',
                          value: hasStateLicenses
                            ? field.value
                                .map((item: SelectOption) => item.value)
                                .join(', ')
                            : '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'npi_state_license_number',
                  control,
                  rules: { ...fieldsDefaultRules['npi_state_license_number'] },
                  render: ({ field, fieldState: { error } }) => {
                    if (isEditMode) {
                      return (
                        <InputMask
                          {...{
                            ...fieldsDefaultProps['npi_state_license_number'],
                            ...field,
                            id: field.name,
                            mask: masks['npi_state_license_number'],
                            error,
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label:
                            fieldsDefaultProps['npi_state_license_number']
                              .label || '',
                          value: field.value || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default memo(ApplyLoggedinProfessionalDetailsForm);
