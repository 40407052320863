import { css } from '@emotion/react';

import layout from '@/constants/layout';

const {
  windowSizes: { tablet, mobile, desktop },
} = layout;

const termsPrivacyContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-top: 100px;
  padding-left: 25vw;
  padding-right: 25vw;
  text-align: justify;
  @media (max-width: ${desktop}px) {
    padding-left: 20vw;
    padding-right: 20vw;
  }
  @media (max-width: ${tablet}px) {
    padding-top: 25px;
    padding-left: 10vw;
    padding-right: 10vw;
  }
  @media (max-width: ${mobile}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const termsPrivacyHeaderStyles = css`
  text-align: center;
  margin-bottom: 38px;

  @media (max-width: ${tablet}px) {
    margin-bottom: 8px;
  }
`;

export { termsPrivacyContainerStyles, termsPrivacyHeaderStyles };
