import { ComponentProps, FC, useCallback } from 'react';
import { css } from '@emotion/react';
import { useForm as useInertiaForm } from '@inertiajs/react';

import layout from '@/constants/layout';
import Button from '@/components/Button';
import { IconEye } from '@/components/Icons/Eye';
import { getMetaToken } from '@/utils/meta-token';
import JobDetails from '@/components/Job/JobDetails';
import Heading from '@/components/Typography/Heading';
import JobPostedId from '@/components/Job/JobPostedId';
import JobDetail from '@/components/JobTiles/JobDetail';
import { PromptMessage } from '@/components/ModalPrompt';
import JobDescDetail from '@/components/Job/JobDescDetail';
import DeleteButton from '@/components/Dashboard/DeleteButton';
import { datatableUtils } from '@/constants/datatables/_index';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { GetAdminJobByIdResponse } from '@/types/api/responses';
import ActiveExpires from '@/components/Dashboard/ActiveExpires';
import JobShowRecruiterCard from '@/components/Job/JobShowRecruiterCard';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';
import JobShowDescriptionHeader from '@/components/Job/JobShowDescriptionHeader';
import RecruitersOnlyDetails from '@/components/Dashboard/RecruitersOnlyDetails';

const {
  windowSizes: { tablet },
} = layout;

const minTablet = tablet + 1;

interface AdminJobRowTileModalContentProps {
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
  jobByIdResponse: GetAdminJobByIdResponse;
}

const contentContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  @media (max-width: ${tablet}px) {
    gap: 16px;
  }
`;

const statusContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${tablet}px) {
    margin-top: 16px;
    gap: 8px;
  }
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const subheaderContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${tablet}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
  }
`;

const subheaderContentContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const desktopButtonsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media (max-width: ${tablet}px) {
    visibility: hidden;
    display: none;
    height: 0;
  }
`;

const mobileButtonsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media (min-width: ${minTablet}px) {
    visibility: hidden;
    display: none;
    height: 0;
  }
`;

const detailGridContainerStyles = css`
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const AdminJobRowTileModalContent: FC<AdminJobRowTileModalContentProps> = ({
  jobByIdResponse,
  modalLayoutProps,
}) => {
  const { patch, transform } = useInertiaForm();

  const { job, recruiter, company } = jobByIdResponse;

  const hasApplications = job.applications_count > 0;
  const hasViews = job.views_count > 0;

  const onDelete = useCallback(async () => {
    const id = `Job-delete-${job.id}`;

    const metaTokenHeader = await getMetaToken();
    if (!!metaTokenHeader) {
      patch(`/admins/jobs`, {
        preserveState: true,
        data: transform(() => ({
          ids: [job.id],
        })),
        headers: {
          ...metaTokenHeader,
        },
        onProgress: () => {},
        onError: () => {
          defaultToastError(id);
        },
        onSuccess: () => {
          modalLayoutProps.closeModal();
          const toastSuccessMessage = `Job has been deleted`;
          defaultToastSuccess(id, toastSuccessMessage);
        },
      });
    }
  }, [job.id]);

  const onClickClose = useCallback(() => {
    modalLayoutProps.closeModal();
  }, []);

  const deleteButtonProps: ComponentProps<typeof DeleteButton> = {
    deleteButtonLabel: 'Delete this job',
    hasIcon: false,
    deleteButtonProps: {},
    questionPromptProps: {
      msgProps: [
        <PromptMessage {...{ children: datatableUtils.jobs.DELETE_MSG }} />,
      ],
      buttonsProps: {
        leftButton: {},
        rightButton: { onClick: onDelete },
      },
    },
  };

  return (
    <>
      <div {...{ css: contentContainer }}>
        <div {...{ css: statusContainer }}>
          <ActiveExpires
            {...{
              isActive: job.is_active,
              days: job.expires_in,
            }}
          />

          <div {...{ css: mobileButtonsContainer }}>
            <DeleteButton
              {...{
                ...deleteButtonProps,
                hasIcon: true,
                deleteButtonLabel: '',
              }}
            />
          </div>
        </div>
        <div
          {...{
            css: headerContainer,
          }}
        >
          <Heading {...{ children: job.title, variant: 'h2' }} />
          <div
            {...{
              css: subheaderContainer,
            }}
          >
            <JobPostedId {...{ id: job.id, posted_at: job.posted_at }} />
            {(hasViews || hasApplications) && (
              <div
                {...{
                  css: css`
                    display: flex;
                    flex-direction: row;
                    gap: 16px;
                  `,
                }}
              >
                {hasViews && (
                  <JobDetail
                    {...{
                      icon: IconEye,
                      detail: `${job.views_count} view${
                        job.views_count === 1 ? '' : 's'
                      }`,
                      textStyles: {
                        fontWeight: 600,
                      },
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>

        {/*  DETAILS  */}
        <div>
          {/*  DESKTOP DETAILS  */}
          <div
            {...{
              css: css`
                ${subheaderContentContainerStyles}
                @media (max-width: ${tablet}px) {
                  display: none;
                }
              `,
            }}
          >
            <JobShowDescriptionHeader {...{ text: 'Details' }} />
            <div
              {...{
                css: detailGridContainerStyles,
              }}
            >
              <JobDetails
                {...{
                  job,
                  defaultCardProps: {
                    extraChar: '',
                    dense: true,
                  },
                }}
              />
            </div>
          </div>
        </div>

        {/*  DESC / REQS  */}

        {!!job?.description && (
          <JobDescDetail
            {...{
              header: 'Description',
              detail: job.description,
            }}
          />
        )}

        {!!job?.requirements && (
          <JobDescDetail
            {...{
              header: 'Requirements',
              detail: job.requirements,
            }}
          />
        )}

        {/*  RECRUITER DETAILS  */}
        <div
          {...{
            css: css`
              display: flex;
              align-items: flex-start;
            `,
          }}
        >
          <JobShowRecruiterCard {...{ recruiter, company }} />
        </div>

        {/*  RECRUITER ONLY  */}
        <div>
          <RecruitersOnlyDetails
            {...{
              hasHeader: true,
              recruiter: {
                job_reference_number: job.reference_number || '',
                facility_name: job.facility_name || '',
                city: job.city || '',
                aditional_notes: job.notes || '',
              },
            }}
          />
        </div>

        {/* BUTTONS  */}
        <div {...{ css: desktopButtonsContainer }}>
          <Button
            {...{ children: 'Close', onClick: onClickClose, variant: '2' }}
          />
          <DeleteButton {...{ ...deleteButtonProps }} />
        </div>
      </div>
    </>
  );
};

export default AdminJobRowTileModalContent;
