import { SessionProps } from '@/types/page';
import { usePage } from '@inertiajs/react';

const usePageSession = (): SessionProps => {
  const { props, url } = usePage<SessionProps>();

  if (!!props?.session) {
    return { ...props, url };
  }

  return {
    session: { signed_in: false, current_user: null },
    url,
  };
};

export default usePageSession;
