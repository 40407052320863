import { FC, memo } from 'react';

import { PaginatorType } from '@/types/types';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

interface ListShowCounterProps extends PaginatorType {}

const ListShowCounter: FC<ListShowCounterProps> = (props) => {
  const beginningSlice = props.currentPage === 1 ? 1 : ((props.currentPage - 1) * 10) + 1;
  const endingSlice = ((props.currentPage - 1) * 10) + props.currentCount;
  const pages =
    props.totalPages === 0 ? '0' : `${beginningSlice}-${endingSlice}`;
  return (
    <div>
      <Paragraph
        {...{
          variant: 'sm',
          children: `Showing ${pages} of ${props.totalCount}`,
          style: { color: appColors.content.secondary },
        }}
      />
    </div>
  );
};

export default memo(ListShowCounter);
