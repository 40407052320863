import { FC, memo, useMemo } from 'react';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import TagLabel, { TagLabelProps } from '../TagLabel';

const EXPIRES_COLOR = appColors.content.secondary;

const containerStyles = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
`;

type State = 'active' | 'inactive';

interface ActiveExpiresProps {
  isActive: boolean;
  days?: number;
}

const propsByActive: Record<State, { tagLabelProps: TagLabelProps }> = {
  active: {
    tagLabelProps: {
      text: 'Active',
      variant: 2,
    },
  },
  inactive: {
    tagLabelProps: {
      text: 'Expired',
      variant: 3,
    },
  },
};

const ActiveExpires: FC<ActiveExpiresProps> = ({ isActive, days }) => {
  return (
    <div
      {...{
        css: containerStyles,
      }}
    >
      <ActiveExpiredTagLabel {...{ isActive }} />
      {days !== -1 && (
        <Paragraph
          {...{
            variant: 'sm',
            style: {
              fontWeight: 400,
              color: EXPIRES_COLOR,
            },
          }}
        >
          {`${isActive ? 'expires in ' : 'expired '}`}
          <Paragraph
            {...{
              children: `${days} day${days === 1 ? '' : 's'}`,
              variant: 'sm',
              style: {
                fontWeight: 600,
                color: EXPIRES_COLOR,
              },
            }}
          />
        </Paragraph>
      )}
    </div>
  );
};

export default memo(ActiveExpires);

const ActiveExpiredTagLabel = ({ isActive }: { isActive: boolean }) => {
  const propsKey = isActive ? 'active' : 'inactive';

  const { tagLabelProps } = propsByActive[propsKey];

  return useMemo(() => {
    return <TagLabel {...{ ...tagLabelProps, size: 'md' }} />;
  }, [isActive]);
};

export { ActiveExpiredTagLabel };
