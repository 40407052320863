import { CSSProperties, FC, memo } from 'react';
import { css } from '@emotion/react';

import Card from '../Card';
import layout from '@/constants/layout';
import { Job } from '@/types/entities.d';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
// ICONS
import Label from '../Typography/Label';
import { IconInfo } from '../Icons/Info';
import { IconTitle } from '../Icons/Title';
import { IconMapPin } from '../Icons/MapPin';
import { IconDollar } from '../Icons/Dollar';
import { IconFacility } from '../Icons/Facility';
import { IconMedicalJob } from '../Icons/MedicalJob';
import { IconAssignment } from '../Icons/Assignment';
import { IconBoardCertified } from '../Icons/BoardCertified';
import { IconCredentialingTime } from '../Icons/CredentialingTime';
import { IconTargetStartDate } from '../Icons/TargetStartTime';
import { IconAnyStateLicenses } from '../Icons/AnyStateLicenses';

const {
  components: { iconStyles },
} = layout;

type Variant = 'default' | 'dense';

const propsPerField: Record<
  keyof Partial<Job>,
  {
    icon: any;
    title: string;
  }
> = {
  title: {
    title: 'Title',
    icon: IconTitle,
  },
  employment_type: {
    title: 'Employment Type',
    icon: IconAssignment,
  },
  assignment: {
    title: 'Assignment',
    icon: IconAssignment,
  },
  facility: {
    title: 'Facility',
    icon: IconFacility,
  },
  board_certification: {
    title: 'Board Certification',
    icon: IconBoardCertified,
  },
  specialty: {
    title: 'Specialty',
    icon: IconMedicalJob,
  },
  state: {
    title: 'Location',
    icon: IconMapPin,
  },
  min_hourly_rate: {
    title: 'Compensation',
    icon: IconDollar,
  },
  credentialing_time: {
    title: 'Credential Time',
    icon: IconCredentialingTime,
  },
  target_start_date: {
    title: 'Target Start Date',
    icon: IconTargetStartDate,
  },
  any_state_licenses: {
    title: 'Any State licenses',
    icon: IconAnyStateLicenses,
  },
};

interface JobShowDetailCardProps {
  type: keyof Partial<Job>;
  content?: string;
  isBlurred?: boolean;
  extraChar?: string;
  variant?: Variant;
  dense?: boolean;
  noBlurred?: boolean;
}

const cardStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const contentContainer = css`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  text-align: center;
`;

const headerContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
`;

const JobShowDetailCard: FC<JobShowDetailCardProps> = ({
  type,
  content,
  isBlurred,
  extraChar = null,
  dense,
  noBlurred,
}) => {
  const { icon: Icon, title } = propsPerField[type] || {
    title: 'N/A',
    icon: IconInfo,
  };

  if (dense) {
    return (
      <Card {...{ hasBorder: true, css: cardStyles }}>
        <div
          {...{
            css: css`
              display: flex;
              flex-direction: row;
              gap: 8px;
              width: 100%;
            `,
          }}
        >
          <div {...{ css: headerContainer }}>
            <div
              {...{
                css: css`
                  ${iconStyles}
                `,
              }}
            >
              <Icon {...{ iconColor: appColors.content.tertiary }} />
            </div>
          </div>
          {content && (
            <div
              {...{
                css: css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                `,
              }}
            >
              <Label {...{ text: title }} />
              <Content
                {...{
                  text: content,
                  style: {
                    ...(isBlurred ? { filter: 'blur(5px)' } : {}),
                    ...(noBlurred ? { filter: '' } : {}),
                  },
                }}
              />
            </div>
          )}
        </div>
      </Card>
    );
  }

  return (
    <Card {...{ hasBorder: true, css: cardStyles }}>
      <div {...{ css: contentContainer }}>
        <div {...{ css: headerContainer }}>
          <div
            {...{
              css: css`
                ${iconStyles}
              `,
            }}
          >
            <Icon {...{ iconColor: appColors.content.tertiary }} />
          </div>
          <Label {...{ text: title }} />
        </div>
        {content && (
          <Content
            {...{
              text: content,
              style: {
                ...(isBlurred ? { filter: 'blur(5px)' } : {}),
                ...(noBlurred ? { filter: '' } : {}),
              },
            }}
          />
        )}
        {extraChar && (
          <Label
            {...{
              text: extraChar,
              style: {
                color: appColors.content.placeholder,
              },
            }}
          />
        )}
      </div>
    </Card>
  );
};

export default memo(JobShowDetailCard);

const Content: FC<{ text: string; style?: CSSProperties }> = ({
  text,
  style,
}) => {
  return (
    <div
      {...{
        css: css`
          display: flex;
          overflow: hidden;
          text-overflow: ellipsis;
        `,
      }}
    >
      <Paragraph
        {...{
          variant: 'md',
          style,
          children: text,
        }}
      />
    </div>
  );
};
