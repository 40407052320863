import { ComponentProps, FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useForm as useInertiaForm } from '@inertiajs/react';

import {
  CompanyDetailsType,
  MyDetailsModalContentLayout,
  SAVED_MSG,
} from './_index';
import { getMetaToken } from '@/utils/meta-token';
import { statesHash } from '@/constants/selects/states';
import useDropdownsData from '@/hooks/useDropdownsData';
import { CompanyDetailsFormValues } from '@/types/forms';
import { defaultToastSuccess } from '@/constants/toasts';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import CompanyDetailsForm from '@/components/Forms/DashboardMyDetails/CompanyDetailsForm';

interface CompanyDetailsContentProps {
  details: CompanyDetailsType;
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
}

const CompanyDetailsContent: FC<CompanyDetailsContentProps> = ({
  details,
  modalLayoutProps,
}) => {
  const { closeModal } = modalLayoutProps;
  const { dropdownsHashes } = useDropdownsData();
  const { patch, transform, processing } = useInertiaForm();
  const { employer_type, name, website, logo_url } = details?.company || {};

  const hasEmployerType = !!employer_type;
  const employerType = hasEmployerType
    ? dropdownsHashes?.employer_type?.[details.company.employer_type]?.label
    : '';

  const hasCompany = !!details.company.id;

  const hasState = !!details.location;

  const initialFormValues: CompanyDetailsFormValues = {
    user_id: details?.user_id || -1,
    name: name || '',
    employer_type: hasEmployerType
      ? dropdownsHashes?.employer_type?.[details.company.employer_type]
      : '',
    website: website || '',
    company: hasCompany
      ? {
          name,
          employer_type: employer_type,
          website,
          logo_url,
          value: name,
          label: name,
        }
      : '',
    //
    phone: details.phone || '',
    location: hasState ? statesHash?.[details.location] : '',
  };

  const methods = useForm<CompanyDetailsFormValues>({
    defaultValues: {
      ...initialFormValues,
    },
    mode: 'onChange',
  });

  const {
    formState: { isSubmitting, isDirty },
    handleSubmit,
    reset,
  } = methods;

  const isSubmittingForm = processing || isSubmitting;

  // //

  const resetForm = useCallback(() => {
    reset(
      {},
      {
        keepIsSubmitted: false,
        keepErrors: false,
        keepDefaultValues: true,
        keepDirty: false,
      }
    );
  }, [reset]);

  const closeResetModal = useCallback(() => {
    resetForm();
    !!closeModal && closeModal();
  }, [closeModal]);

  const onClickCancel = useCallback(() => {
    resetForm();
    closeResetModal();
  }, []);

  const onClickSave = useCallback(
    async (form: CompanyDetailsFormValues) => {
      const url = `/recruiters/${details?.user_id}/inertia`;
      const params = {
        recruiter: {
          phone: form.phone,
          location: form?.location?.value || '',
          company_attributes: {
            name: form.company.name,
            employer_type: form.employer_type.value,
            website: form.website,
          },
        },
      };

      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        patch(url, {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
          onSuccess: (successResponse) => {
            defaultToastSuccess(undefined, SAVED_MSG);
            closeModal();
          },
          onError: (errorResponse) => {
            console.log(errorResponse);
          },
        });
      }
    },
    [details?.user_id]
  );

  return (
    <FormProvider {...methods}>
      <ModalLayout {...{ closeModal: closeResetModal }}>
        <MyDetailsModalContentLayout
          {...{
            formHeaderProps: { title: 'Edit Company Details' },
            cancelNextButtonsProps: {
              cancelButtonProps: {
                onClick: onClickCancel,
                disabled: isSubmittingForm,
              },
              nextButtonProps: {
                onClick: handleSubmit(onClickSave),
                disabled: isSubmittingForm || !isDirty,
              },
            },
            formComponent: <CompanyDetailsForm />,
          }}
        />
      </ModalLayout>
    </FormProvider>
  );
};

export default memo(CompanyDetailsContent);
