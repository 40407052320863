import { CSSProperties, FC, memo } from 'react';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';

const Label: FC<{ text: string; style?: CSSProperties }> = ({
  text,
  style,
}) => {
  return (
    <label
      {...{
        css: css`
          font-family: Inter;
          font-size: 8px;
          font-weight: 700;
          line-height: '140%';
          user-select: none;
        `,
        children: text,
        style: {
          color: appColors.content.secondary,
          textTransform: 'uppercase',
          ...style,
        },
      }}
    />
  );
};

export default memo(Label);
