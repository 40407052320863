import { ComponentProps } from 'react';

import { Job } from '@/types/entities.d';
import usePageSession from '@/hooks/usePageSession';
import ModalLayout from '../Layout/Modal/ModalLayout';
import ApplyModalContentGuest from './ApplyModalContentGuest';
import ApplyModalContentSignedIn from './ApplyModalContentSignedIn';

const ApplyModalContent = ({
  modalProps,
  setHasApplied,
  job_id,
}: {
  modalProps: ComponentProps<typeof ModalLayout>;
  setHasApplied: (id: number) => void;
  job_id: number;
}) => {
  const {
    session: { signed_in },
  } = usePageSession();

  const { closeModal } = modalProps;

  if (signed_in) {
    return (
      <ModalLayout
        {...{
          ...modalProps,
          hasLogo: false,
          containerStyles: {
            padding: 24,
          },
        }}
      >
        <ApplyModalContentSignedIn {...{ closeModal, setHasApplied, job_id }} />
      </ModalLayout>
    );
  }

  return (
    <ModalLayout
      {...{
        ...modalProps,
        hasLogo: true,
        containerStyles: {
          padding: 24,
        },
      }}
    >
      <ApplyModalContentGuest {...{ closeModal, setHasApplied, job_id }} />
    </ModalLayout>
  );
};

export default ApplyModalContent;
