const delayWithValue = <T>(value: T, delay: number): Promise<T> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value);
    }, delay);
  });
};

const debounce = (func: any, timeout = 350) => {
  let timer: any;
  return (...args: [any]) => {
    clearTimeout(timer);
    return (timer = setTimeout(() => {
      return func.apply(this, args);
    }, timeout));
  };
};

const getActiveFieldsNumber = (array: any[]) => {
  return array.filter((field) => {
    return !(!field || (Array.isArray(field) && field.length === 0));
  }).length;
};

export { delayWithValue, debounce, getActiveFieldsNumber };
