import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconBoardCertified = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M6.328 15.26l.352-.662-.466-.248-.39.355.504.555zm1.028 7.223l-.267-.7.267.7zm4.288-1.633l-.267-.701.267.7zm.712 0l-.267.7.267-.7zm4.288 1.633l.267-.7-.267.7zm1.028-7.223l.505-.555-.391-.355-.466.248.352.662zm-.473.55l.708.25-.708-.25zm-.414 1.176l.707.25-.707-.25zm-1.127.65l-.138.738.138-.737zm-1.226-.228l-.138.737.138-.737zm-.834.223l-.488-.57.488.57zm-.947.812l.488.57-.488-.57zm-1.302 0l-.488.57.488-.57zm-.947-.812l.488-.57-.488.57zm-.834-.223l.138.737-.138-.737zm-1.226.229l-.138-.737.138.737zm-1.127-.65l.708-.25-.708.25zm-.414-1.177l-.708.25.708-.25zM6.75 16c0-.073.03-.139.082-.185l-1.009-1.11A1.747 1.747 0 005.25 16h1.5zm0 5.549V16h-1.5v5.549h1.5zm.339.233a.25.25 0 01-.339-.233h-1.5a1.75 1.75 0 002.373 1.635l-.534-1.402zm4.288-1.633l-4.288 1.633.534 1.402 4.288-1.633-.534-1.402zm1.246 0a1.75 1.75 0 00-1.246 0l.534 1.402a.25.25 0 01.178 0l.534-1.402zm4.288 1.633l-4.288-1.633-.534 1.402 4.288 1.633.534-1.402zm.339-.233a.25.25 0 01-.339.233l-.534 1.402a1.75 1.75 0 002.373-1.635h-1.5zm0-5.549v5.549h1.5V16h-1.5zm-.082-.185a.247.247 0 01.082.185h1.5c0-.514-.222-.976-.573-1.295l-1.01 1.11zm.152-1.217a1.75 1.75 0 00-.828.963l1.415.498a.25.25 0 01.118-.137l-.705-1.324zm-.828.963l-.415 1.176 1.415.498.415-1.176-1.415-.498zm-.415 1.176a.25.25 0 01-.281.163l-.276 1.474a1.75 1.75 0 001.972-1.139l-1.415-.498zm-.281.163l-1.226-.23-.276 1.475 1.226.23.276-1.475zm-1.226-.23a1.75 1.75 0 00-1.46.392l.976 1.139a.25.25 0 01.208-.056l.276-1.474zm-1.46.392l-.947.811.976 1.14.947-.812-.976-1.14zm-.947.811a.25.25 0 01-.326 0l-.976 1.14a1.75 1.75 0 002.277 0l-.975-1.14zm-.326 0l-.947-.811-.976 1.139.947.811.976-1.139zm-.947-.811a1.75 1.75 0 00-1.46-.392l.276 1.475a.25.25 0 01.208.056l.976-1.14zm-1.46-.392l-1.226.23.276 1.474 1.226-.229-.276-1.474zm-1.226.23a.25.25 0 01-.281-.163l-1.415.498a1.75 1.75 0 001.972 1.14L8.204 16.9zm-.281-.163l-.415-1.176-1.415.498.415 1.176 1.415-.498zm-.415-1.176a1.75 1.75 0 00-.828-.963l-.705 1.324a.25.25 0 01.118.137l1.415-.498z"
          fill={iconColor || appColors.content.primary}
        />
        <path
          d="M11.35 1.557a1 1 0 011.3 0l.948.812a1 1 0 00.834.223l1.226-.229a1 1 0 011.127.65l.414 1.177a1 1 0 00.611.61l1.176.415a1 1 0 01.65 1.127l-.228 1.226a1 1 0 00.223.834l.812.947a1 1 0 010 1.302l-.812.947a1 1 0 00-.223.834l.229 1.226a1 1 0 01-.65 1.127l-1.177.414a1 1 0 00-.61.611l-.415 1.176a1 1 0 01-1.127.65l-1.226-.228a1 1 0 00-.834.223l-.947.812a1 1 0 01-1.302 0l-.947-.812a1 1 0 00-.834-.223l-1.226.229a1 1 0 01-1.127-.65l-.414-1.177a1 1 0 00-.611-.61l-1.176-.415a1 1 0 01-.65-1.127l.228-1.226a1 1 0 00-.223-.834l-.812-.947a1 1 0 010-1.302l.812-.947a1 1 0 00.223-.834l-.229-1.226a1 1 0 01.65-1.127l1.177-.414a1 1 0 00.61-.611l.415-1.176a1 1 0 011.127-.65l1.226.228a1 1 0 00.834-.223l.947-.812z"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
        <rect
          x={7}
          y={5}
          width={10}
          height={10}
          rx={5}
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconBoardCertified };
