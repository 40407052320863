import { FC } from 'react';
import styled from '@emotion/styled';

import CustomTagLabel, {
  TagLabelProps,
  TagLabelSize,
  TagLabelVariant,
} from '../TagLabel/index';

const TEXT = 'Label';

const EXAMPLE: Record<TagLabelSize, Record<TagLabelVariant, TagLabelProps>> = {
  sm: {
    1: {
      text: TEXT,
      clearAction: () => {},
    },
    2: {
      text: TEXT,
      clearAction: () => {},
    },
    3: {
      text: TEXT,
      clearAction: () => {},
    },
  },
  md: {
    1: {
      text: TEXT,
      clearAction: () => {},
    },
    2: {
      text: TEXT,
      clearAction: () => {},
    },
    3: {
      text: TEXT,
      clearAction: () => {},
    },
  },
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const TagLabel: FC = () => {
  return (
    <Container>
      {Object.entries(EXAMPLE).map(([size, variants]) => {
        const tagSize = size as unknown as TagLabelSize;
        return Object.entries(variants).map(([variant, props], index) => {
          const tagVariant = variant as unknown as TagLabelVariant;
          return (
            <CustomTagLabel
              {...{ ...props, variant: tagVariant, size: tagSize, key: index }}
            />
          );
        });
      })}
    </Container>
  );
};

export default TagLabel;
