import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';

import { MultiValueChip } from '..';
import Radio from '@/components/Radio';
import { SelectOption } from '@/types/types';
import InputMask from '@/components/InputMask';
import { StyledLabel } from '@/components/Input';
import selectsData from '@/constants/selects/_index';
import InputDropdown from '@/components/InputDropdown';
import useDropdownsData from '@/hooks/useDropdownsData';
import { MenuPlacement, MenuPosition } from 'react-select';
import { fieldsDefaultProps } from '@/constants/form-fields';
import MultiSelectOption from '@/components/InputDropdown/MultiSelectOption';

const radioFieldContentStyles = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const radiosFieldContentStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

interface FilterFormProps {
  hasMoreFilters?: boolean;
  dropdownProps?: {
    menuPlacement?: MenuPlacement;
    menuPosition?: MenuPosition;
  };
}

const FilterForm = <T extends FieldValues>({
  hasMoreFilters,
  dropdownProps,
}: FilterFormProps) => {
  const methods = useFormContext<T>();
  const { control } = methods;
  const { dropdownOptions } = useDropdownsData();

  const employmentTypeOptions: SelectOption[] = useMemo(() => {
    return [{ label: 'All', value: '' }, ...dropdownOptions.employment];
  }, [dropdownOptions.employment]);

  return (
    <>
      <div
        {...{
          css: css`
            ${hasMoreFilters ? `display: flex;` : `display: none; `}
            gap: 16px;
            flex-direction: column;
          `,
        }}
      >
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'professions',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['profession_eq'],
                      options: dropdownOptions.profession,
                      ...field,
                      id: field.name,
                      ...dropdownProps,
                      components: {
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'state_in',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['state_in'],
                      ...field,
                      isMulti: true,
                      isClearable: true,
                      options: selectsData['state'],
                      ...dropdownProps,
                      id: field.name,
                      components: {
                        Option: (rest) => (
                          <MultiSelectOption
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'specialties',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['specialty_eq'],
                      ...field,
                      options: dropdownOptions.specialty,
                      isMulti: true,
                      ...dropdownProps,
                      id: field.name,
                      components: {
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'label' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
      </div>

      {/*  */}
      {
        //@ts-ignore
        <Controller
          {...{
            name: 'assignments',
            control,
            rules: {},
            render: ({ field: { ref, ...field } }) => {
              return (
                <InputDropdown
                  {...{
                    ...fieldsDefaultProps['assignments'],
                    ...field,
                    menuPosition: 'fixed',
                    menuPlacement: 'top',
                    options: dropdownOptions.assignment,
                    isMulti: true,
                    ...dropdownProps,
                    id: field.name,
                    components: {
                      MultiValue: MultiValueChip,
                    },
                  }}
                />
              );
            },
          }}
        />
      }
      {
        //@ts-ignore
        <Controller
          {...{
            name: 'facilities',
            control,
            rules: {},
            render: ({ field: { ref, ...field } }) => {
              return (
                <InputDropdown
                  {...{
                    ...fieldsDefaultProps['facility_eq'],
                    ...field,
                    menuPlacement: 'top',
                    menuPosition: 'fixed',
                    options: dropdownOptions.facility,
                    isMulti: true,
                    ...dropdownProps,
                    id: field.name,
                    components: {
                      MultiValue: MultiValueChip,
                    },
                  }}
                />
              );
            },
          }}
        />
      }
      <div {...{ css: radioFieldContentStyles }}>
        <StyledLabel
          {...{
            children: fieldsDefaultProps['employment_type_eq'].label,
          }}
        />
        <div {...{ css: radiosFieldContentStyles }}>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'employment_types',
                control,
                rules: {},
                render: ({ field: { value, onChange, ...field } }) => {
                  return (
                    <>
                      {employmentTypeOptions.map(
                        ({ label, ...radio }, index) => {
                          const newValue = value || '';
                          return (
                            <Radio
                              {...{
                                ...field,
                                label,
                                checked: newValue === radio.value,
                                onChange: () => {
                                  onChange(radio.value);
                                },
                                key: index,
                              }}
                            />
                          );
                        }
                      )}
                    </>
                  );
                },
              }}
            />
          }
        </div>
      </div>
      {
        //@ts-ignore
        <Controller
          {...{
            name: 'posted_at_gt',
            control,
            rules: {},
            render: ({ field: { ref, ...field } }) => {
              return (
                <InputDropdown
                  {...{
                    menuPlacement: 'top',
                    menuPosition: 'fixed',
                    ...fieldsDefaultProps['posted_at_gt'],
                    options: dropdownOptions.date,
                    ...field,
                    ...dropdownProps,
                    id: field.name,
                  }}
                />
              );
            },
          }}
        />
      }
      {
        //@ts-ignore
        <Controller
          {...{
            name: 'min_hourly_rate_gt',
            control,
            rules: {},
            render: ({ field: { ref, ...field } }) => {
              return (
                <InputMask
                  {...{
                    ...fieldsDefaultProps['min_hourly_rate_gt'],
                    ...field,
                    id: field.name,
                    mask: Number,
                    hasExtraChar: true,
                  }}
                />
              );
            },
          }}
        />
      }
    </>
  );
};

export default FilterForm;
