type AppColors = {
  content: {
    primary: string;
    secondary: string;
    tertiary: string;
    placeholder: string;
    disabled: string;
    white: string;
  };

  border: {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
  };

  background: {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
  };
  notInDesignSystem: {
    error: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
    6: string;
    7: string;
    8: string;
  };
};

const appColors: AppColors = {
  content: {
    primary: '#05051B',
    secondary: '#696976',
    tertiary: '#315EFF',
    placeholder: '#9B9BA4',
    disabled: '#CDCDD1',
    white: '#FFFFFF',
  },
  border: {
    primary: '#F2F2F3',
    secondary: '#315EFF',
    tertiary: '#839EFF',
    quaternary: '#D6DFFF',
  },
  background: {
    primary: '#FFFFFF',
    secondary: '#FAFAFA',
    tertiary: '#315EFF',
    quaternary: '#F0F3FF',
  },
  //
  notInDesignSystem: {
    error: '#FF3131',
    1: '#2C55E5',
    2: '#2242B2',
    3: '#B0C1FF',
    4: '#E0E8FF',
    5: '#FAFBFF',
    6: '#F5F7FF',
    7: '#E3E9FF',
    8: '#F2F5FF',
  },
};

export { appColors };
