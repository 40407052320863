import { SVGProps, useMemo } from 'react';

const IconClip = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M18.365 12.606l-6.312 6.313a3.692 3.692 0 01-5.222-5.222l8.977-8.976a2.463 2.463 0 013.48 3.482l-8.982 8.983a1.23 1.23 0 01-1.733-1.749L14.98 9.03m-4.668 8.148l-.008.009"
          stroke="#05051B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconClip };
