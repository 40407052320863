import { InputProps } from '@/components/Input';

const defaultProps = {
  autoCapitalize: 'none',
  autoCorrect: 'off',
};

const PLS_MSG = 'Please enter your';

const emailRecoveryPlaceholder =
  'Please enter the email address associated with your account.';

const fieldsDefaultProps: Record<string, InputProps> = {
  email: {
    ...defaultProps,
    label: 'Email',
    placeholder: `${PLS_MSG} email address.`,
    autoComplete: 'username',
    type: 'email',
  },
  password: {
    ...defaultProps,
    label: 'Password',
    placeholder: `Please enter a password.`,
    autoComplete: 'current-password',
    type: 'password',
  },
  password_confirmation: {
    ...defaultProps,
    label: 'Confirm New Password',
    placeholder: 'Confirm Password',
    autoComplete: 'new-password',
    type: 'password',
  },
  // // //
  is_type: {
    label: 'I am a...',
  },
  first_name: {
    label: 'First Name',
    placeholder: `${PLS_MSG} first name.`,
    autoComplete: 'given-name',
  },
  last_name: {
    label: 'Last name',
    placeholder: `${PLS_MSG} last name.`,
    autoComplete: 'family-name',
  },
  // // //
  state: {
    label: 'State',
    placeholder: 'Select state',
    autoComplete: 'address-level1',
  },
  city: {
    label: 'City',
    placeholder: `${PLS_MSG} city.`,
    autoComplete: 'address-level2',
  },
  zip: {
    label: 'ZIP Code',
    placeholder: `${PLS_MSG} ZIP code.`,
    autoComplete: 'postal-code',
  },
  phone: {
    label: 'Phone Number',
    placeholder: `${PLS_MSG} phone number.`,
    autoComplete: 'off',
  },
  // // //
  title: {
    label: 'Title',
    placeholder: 'Select title',
    autoComplete: 'off',
  },
  jobTitle: {
    label: 'Job Title',
    placeholder: 'Enter job title',
    autoComplete: 'off',
  },
  assignment_type: {
    label: 'Assignment Type Preference',
    placeholder: 'Select type',
    autoComplete: 'off',
  },
  specialty: {
    label: 'Specialty',
    placeholder: 'Select all that apply',
    autoComplete: 'off',
  },
  geographic_preferences: {
    label: 'Geographic Preference',
    placeholder: 'Select state(s)',
    autoComplete: 'off',
  },
  state_licenses: {
    label: 'State Licenses',
    placeholder: 'Select state(s)',
    autoComplete: 'off',
  },
  npi_state_license_number: {
    label: 'NPI or State License Number',
    placeholder: `${PLS_MSG} NPI number.`,
    autoComplete: 'off',
  },
  //
  resume: { label: 'CV Upload' },
  profile_link: {
    label: 'LinkedIn Profile Link',
    placeholder: `${PLS_MSG} LinkedIn profile link.`,
    autoComplete: 'off',
  },
  //

  company_id: {
    label: 'Company Name',
    placeholder: 'Enter your company name',
    autoComplete: 'off',
  },

  website: {
    label: 'Company Website',
    placeholder: `${PLS_MSG} website URL.`,
    autoComplete: 'off',
  },
  employer_type: {
    label: 'Employer Type',
    placeholder: 'Select employer type',
    autoComplete: 'off',
  },

  //
  specialty_eq: {
    label: 'Specialty',
    placeholder: 'Select specialty',
    autoComplete: 'off',
  },
  state_in: {
    label: 'Location',
    placeholder: 'Select location',
    autoComplete: 'off',
  },
  profession_eq: {
    label: 'Profession',
    placeholder: 'Select profession',
    autoComplete: 'off',
  },
  //
  facility_eq: {
    label: 'Facility',
    placeholder: 'Select facility',
    autoComplete: 'off',
  },
  assignments: {
    label: 'Assignment',
    placeholder: 'Select assignment',
    autoComplete: 'off',
  },
  employment_type: {
    label: 'Employment Type',
    placeholder: 'Select employment type',
    autoComplete: 'off',
  },
  employment_type_eq: {
    label: 'Full or Part Time',
    placeholder: '',
    autoComplete: 'off',
  },
  min_hourly_rate_gt: {
    label: 'Min Hourly Compensation',
    placeholder: '0+',
    autoComplete: 'off',
  },
  posted_at_gt: {
    label: 'Date Posted',
    placeholder: 'Select posting date',
    autoComplete: 'off',
  },

  company_name_eq: {
    label: 'Agency',
    placeholder: 'Select agency',
    autoComplete: 'off',
  },

  board_certification: {
    label: 'Board certification required?',
    placeholder: 'Select board certification',
    autoComplete: 'off',
  },
  message: {
    label: 'Message',
    placeholder: 'Enter your message here',
    autoComplete: 'off',
  },
  credentialing_time: {
    label: 'Credential Time',
    placeholder: 'Select time',
    autoComplete: 'off',
  },
  target_start_date: {
    label: 'Target Start Date',
    // placeholder: 'mm/dd/yyyy',
    autoComplete: 'off',
    type: 'date'
  },
  any_state_licenses: {
    label: 'Any State License?',
    placeholder: 'Select option',
    autoComplete: 'off',
  },
  search_name: {
    label: 'Search Name',
    placeholder: 'Enter search name',
    autoComplete: 'off',
  },
  send_notifications: {
    label: 'Send Notifications',
    placeholder: '',
    autoComplete: 'off',
  },
};

export { fieldsDefaultProps, emailRecoveryPlaceholder };
