import { ComponentProps, FC, useCallback, useEffect, useReducer } from 'react';
import produce from 'immer';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Status } from '@/types/types';
import { Job } from '@/types/entities.d';
import { getJobApplications } from '@/api/jobs';
import { JobApplication } from '@/types/entities';
import ModalLayout from '../Layout/Modal/ModalLayout';
import JobApplicationTile from './JobApplicationTile';
import { paragraphDesktop } from '@/constants/typography';

interface ViewApplicationModalContentProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  job: Partial<Job>;
}

const contentContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const listContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: (3 * 200) px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const HeaderText = styled.label({ ...paragraphDesktop.lg });

type Action =
  | {
      payload: JobApplication[];
      type: 'SET_LIST';
    }
  | {
      payload: Status;
      type: 'SET_STATUS';
    };

interface State {
  list: JobApplication[];
  status: Status;
}

const initialState: State = {
  list: [],
  status: 'IDLE',
};

const reducer = produce((draft, { type, payload }: Action) => {
  switch (type) {
    case 'SET_LIST':
      draft.list = payload;
      draft.status = 'SUCCESS';
      return;
    case 'SET_STATUS':
      draft.status = payload;
      return;
  }
}, initialState);

const ViewApplicationModalContent: FC<ViewApplicationModalContentProps> = ({
  modalProps,
  job,
}) => {
  const [{ list, status }, localDispatch] = useReducer(reducer, initialState);

  const applicationesCount = list.length;

  const hasError = status === 'ERROR';
  const isReady = status === 'SUCCESS';
  const isLoading = status === 'IDLE' || status === 'FETCHING';

  const initialRequest = useCallback(async () => {
    localDispatch({ type: 'SET_STATUS', payload: 'FETCHING' });
    try {
      const response = await getJobApplications(job.id);
      if (response) {
        localDispatch({ type: 'SET_LIST', payload: response.collection });
        return;
      }
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
    } catch (error) {
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
    }
  }, [localDispatch]);

  useEffect(() => {
    initialRequest();
    return () => {};
  }, [initialRequest]);

  return (
    <ModalLayout
      {...{
        ...modalProps,
        containerStyles: {},
      }}
    >
      <div {...{ css: contentContainer }}>
        <HeaderText
          {...{
            children: `Applications for Locums ID: ${job.id} (${applicationesCount})`,
          }}
        />
        {isLoading && <div />}
        {hasError && <div />}
        {isReady && (
          <div {...{ css: listContainer }}>
            {list.map((jobApp) => {
              return (
                <JobApplicationTile {...{ item: jobApp, key: jobApp.id }} />
              );
            })}
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

export default ViewApplicationModalContent;
