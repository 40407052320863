import Axios from './axios-api';
import { GetAdminRecruiterByIdResponse } from '@/types/api/responses';

// GET

const getAdminRecruiterById = async (id: number) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<GetAdminRecruiterByIdResponse>(
      `/admins/recruiters/${id}`,
      {
        params: {},
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getAdminRecruiterById };
