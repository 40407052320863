import { FC } from 'react';
import styled from '@emotion/styled';

import CustomButton, { ButtonVariants, stylesByVariant } from '../Button';

const TEXT = 'Button';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Button: FC = () => {
  return (
    <Container>
      {Object.keys(stylesByVariant).map((key, index) => {
        const variant = key as ButtonVariants;
        return (
          <div
            {...{
              key: index,
              style: { display: 'flex', flexDirection: 'row', gap: 12 },
            }}
          >
            <CustomButton
              {...{ variant, children: TEXT, style: { width: '100%' } }}
            />
            <CustomButton {...{ variant, children: TEXT, disabled: true }} />
          </div>
        );
      })}
    </Container>
  );
};

export default Button;
