import { FC, memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { IconFilter } from '../Icons/Filter';
import { appColors } from '@/constants/colors';
import Button, { ButtonProps } from '../Button';
import { paragraphDesktop } from '@/constants/typography';
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import { IconSaveSearch } from '../Icons/SaveSearch';

const {
  components: { iconStyles },
  windowSizes: { tablet, mobile },
} = layout;
interface SaveSearchButtonProps {
  isActive: boolean;
  onClick: () => void;
  isButton?: boolean;
  isMobile?: boolean;
}

const StyledButton = styled(Button)<ButtonProps>`
  display: flex;
  height: 48px;
  width: 171px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 20px;
  cursor: pointer;
`;

const buttonTextStyles = css({
  ...paragraphDesktop.md,
});

const saveSearchContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  ${buttonTextStyles}
`;

const iconContainerStyles = css`
  ${iconStyles}
  margin-left: 4px;
`;

const iconStyle = {
  color: appColors.content.tertiary,
};

const SaveSearchButton: FC<SaveSearchButtonProps> = ({
  isActive,
  onClick,
  isButton = true,
  isMobile,
}) => {
  const buttonText = 'Save Search';
  const buttonVariant = isActive ? '1' : '2';

  if (isButton) {
    return (
      <StyledButton
        {...{
          variant: buttonVariant,
          onClick,
          icon: IconSaveSearch,
          children: buttonText,
          style: { width: isMobile ? '100%' : '171px' },
        }}
      />
    );
  }

  return (
    <div
      {...{
        css: css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        `,
        onClick,
      }}
    >
      <div
        {...{
          css: css`
            ${saveSearchContainerStyles}
            color: ${appColors.content.tertiary};
            font-weight: 500;
            cursor: pointer;
          `,
          //children: buttonText,
        }}
      />
      <div
        {...{
          css: css`
            ${iconContainerStyles}
            cursor: pointer;
          `,
        }}
      >
        <IconSaveSearch {...{ iconColor:"#fff"}}/>
      </div>
    </div>
  );
};

export default memo(SaveSearchButton);
