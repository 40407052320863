import { css } from '@emotion/react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';

import { MultiValueChip } from '..';
import selectsData from '@/constants/selects/_index';
import InputDropdown from '@/components/InputDropdown';
import useDropdownsData from '@/hooks/useDropdownsData';
import { fieldsDefaultProps } from '@/constants/form-fields';
import MultiSelectOption from '@/components/InputDropdown/MultiSelectOption';

interface HomeFilterFormProps {
  hasMoreFilters?: boolean;
}

const HomeFilterForm = <T extends FieldValues>({}: HomeFilterFormProps) => {
  const methods = useFormContext<T>();
  const { control } = methods;
  const { dropdownOptions } = useDropdownsData();

  return (
    <>
      <div
        {...{
          css: css`
            display: flex;
            gap: 16px;
            flex-direction: column;
            z-index: 99999;
          `,
        }}
      >
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'professions',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      menuPlacement: 'top',
                      menuPosition: 'fixed',
                      ...fieldsDefaultProps['profession_eq'],
                      options: dropdownOptions.profession,
                      ...field,
                      id: field.name,
                      isClearable: true,
                      components: {
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'state_in',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['state_in'],
                      ...field,
                      menuPlacement: 'top',
                      menuPosition: 'fixed',
                      options: selectsData['state'],
                      isMulti: true,
                      isClearable: true,
                      id: field.name,
                      components: {
                        Option: (rest) => (
                          <MultiSelectOption
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'specialties',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['specialty_eq'],
                      ...field,
                      menuPlacement: 'top',
                      menuPosition: 'fixed',
                      options: dropdownOptions.specialty,
                      isMulti: true,
                      id: field.name,
                      isClearable: true,

                      components: {
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'label' }}
                          />
                        ),
                        Option: (rest) => (
                          <MultiSelectOption
                            {...{ ...rest, shownValue: 'label' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
      </div>
    </>
  );
};

export default HomeFilterForm;
