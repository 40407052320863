import { css } from '@emotion/react';
import { Controller, useFormContext } from 'react-hook-form';

import { MultiValueChip } from '..';
import selectsData from '@/constants/selects';
import InputDropdown from '@/components/InputDropdown';
import useDropdownsData from '@/hooks/useDropdownsData';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { AdminRecruitersFilterFormValues } from '@/types/forms';

const AdminRecruitersFilterForm = () => {
  const methods = useFormContext<AdminRecruitersFilterFormValues>();
  const { control } = methods;
  const { dropdownOptions } = useDropdownsData();

  return (
    <form>
      <div
        {...{
          css: css`
            display: flex;
            gap: 16px;
            flex-direction: column;
          `,
        }}
      >
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'q.company_name_cont',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['company_name_eq'],
                      options: dropdownOptions.agency,
                      ...field,
                      id: field.name,
                      components: {
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'q.location_cont',
              control,
              rules: {},
              render: ({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['state'],
                      label: 'Location',
                      placeholder: 'Select location',
                      options: selectsData['state'],
                      ...field,
                      id: field.name,
                      error,
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'q.created_at_eq',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['posted_at_gt'],
                      label: 'Date Joined',
                      placeholder: 'Select date joined',
                      options: dropdownOptions.date,
                      ...field,
                      id: field.name,
                    }}
                  />
                );
              },
            }}
          />
        }
      </div>
    </form>
  );
};
export default AdminRecruitersFilterForm;
