import AuthModal from '@/components/AuthModal';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';
import ProvidersMyDetails from '@/components/Dashboard/Clinician/ProvidersMyDetails';

export default function Details() {
  return (
    <>
      <TopContentFooterLayout>
        <ProvidersMyDetails />
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
