import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FieldsContainer,
  FormButtonContainer,
  FormContainer,
  FormHeader,
} from '.';
import Input from '../Input';
import Button from '../Button';
import { RecoveryPasswordFormValues } from '@/types/forms';
import PasswordRequirements from '../PasswordRequirements';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';

const RecoveryPasswordForm: FC<{
  onSubmit: (form: RecoveryPasswordFormValues) => void;
  methods: UseFormReturn<RecoveryPasswordFormValues, any>;
}> = ({ onSubmit, methods }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    watch,
    // setValue,
  } = methods;
  const [passwordEngaged, setPasswordEngaged] = useState(false);

  const passwordField = watch('password');

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FormHeader
          {...{
            title: 'Reset your password',
          }}
        />

        <FieldsContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'password',
                control,

                rules: { ...fieldsDefaultRules['password'] },
                render: ({ field, fieldState: { error } }) => {
                  const isPasswordRequirementsShown =
                    passwordEngaged || (!passwordEngaged && field.value);

                  return (
                    <div
                      {...{
                        css: css`
                          display: flex;
                          flex-direction: column;
                          gap: 16px;
                        `,
                      }}
                    >
                      <Input
                        {...{
                          ...fieldsDefaultProps['password'],
                          ...field,
                          error,
                          id: field.name,
                          onFocus: () => {
                            setPasswordEngaged(true);
                          },
                          onBlur: () => {
                            setPasswordEngaged(false);
                            field.onBlur();
                          },
                        }}
                      />
                      {isPasswordRequirementsShown && (
                        <PasswordRequirements {...{ value: field.value }} />
                      )}
                    </div>
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'password_confirmation',
                control,
                rules: {
                  validate: (value) => {
                    return value === passwordField || 'Passwords do not match.';
                  },
                },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['password_confirmation'],
                        ...field,
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
        <FormButtonContainer>
          <Button
            {...{
              variant: '1',
              children: 'Reset Password',
              style: { width: '100%' },
              onClick: handleSubmit(onSubmit),
              disabled: !isValid || isSubmitting,
            }}
          />
        </FormButtonContainer>
      </FormContainer>
    </form>
  );
};

export default RecoveryPasswordForm;
