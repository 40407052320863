import { Controller, UseFormReturn } from 'react-hook-form';

import {
  CustomValueContainer,
  FieldsContainer,
  FormContainer,
  RowContainer,
} from '../..';
import InputMask from '@/components/InputMask';
import { masks } from '@/constants/input-masks';
import selectsData from '@/constants/selects/_index';
import InputDropdown from '@/components/InputDropdown';
import useDropdownsData from '@/hooks/useDropdownsData';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import { SignupPhysicianProfessionalDetailsFormValues } from '@/types/forms';
import MultiSelectOption from '@/components/InputDropdown/MultiSelectOption';

const SignupPhysicianProfessionalDetailsForm = ({
  methods,
}: {
  methods: UseFormReturn<SignupPhysicianProfessionalDetailsFormValues, any>;
}) => {
  const { dropdownOptions } = useDropdownsData();
  const {
    control,
    formState: { isSubmitting },
  } = methods;

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'title',
                  control,
                  rules: { ...fieldsDefaultRules['title'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['title'],
                          options: dropdownOptions.profession,
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'assignment_type',
                  control,
                  rules: { ...fieldsDefaultRules['assignment_type'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['assignment_type'],
                          options: dropdownOptions.assignment,
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'specialty',
                  control,
                  rules: { ...fieldsDefaultRules['specialty'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['specialty'],
                          options: dropdownOptions.specialty,
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'geographic_preferences',
                  control,
                  rules: { ...fieldsDefaultRules['geographic_preferences'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['geographic_preferences'],
                          ...field,
                          options: selectsData['state'],
                          id: field.name,
                          components: {
                            Option: (rest) => (
                              <MultiSelectOption
                                {...{ ...rest, shownValue: 'value' }}
                              />
                            ),
                            ValueContainer: CustomValueContainer,
                          },
                          isMulti: true,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'state_licenses',
                  control,
                  rules: { ...fieldsDefaultRules['state_licenses'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['state_licenses'],
                          ...field,
                          options: selectsData['state'],
                          id: field.name,
                          components: {
                            Option: (rest) => (
                              <MultiSelectOption
                                {...{ ...rest, shownValue: 'value' }}
                              />
                            ),
                            ValueContainer: CustomValueContainer,
                          },
                          isMulti: true,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'npi_state_license_number',
                  control,
                  rules: { ...fieldsDefaultRules['npi_state_license_number'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['npi_state_license_number'],
                          ...field,
                          id: field.name,
                          mask: masks['npi_state_license_number'],
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default SignupPhysicianProfessionalDetailsForm;
