import { memo, useCallback } from 'react';
import { css } from '@emotion/react';

import Link from '../Typography/Link';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import { AdminLogoDesktop, AdminLogoMobile, pageContainer } from './_index';

const TEAM_LINK = '';

const {
  windowSizes: { mobile },
} = layout;

const DesktopLogoContainer = css`
  display: flex;

  @media (max-width: ${mobile}px) {
    display: none;
  }
`;
const MobileLogoContainer = css`
  display: none;
  @media (max-width: ${mobile}px) {
    display: flex;
  }
`;

const messageContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-shrink: 1;
  gap: 24px;
  text-align: center;
`;

const AdminOnlyDesktopMessage = () => {
  const onClickContactOurTeam = useCallback(() => {
    window.open(TEAM_LINK, '_blank');
  }, []);

  return (
    <div
      {...{
        css: css`
          ${pageContainer}
          flex-direction: column;
          padding: 60px;
          align-items: center;
          justify-content: space-between;
          @media (max-width: ${mobile}px) {
            padding: 40px;
          }
        `,
      }}
    >
      <div {...{ css: DesktopLogoContainer }}>
        <AdminLogoDesktop />
      </div>
      <div {...{ css: MobileLogoContainer }}>
        <AdminLogoMobile />
      </div>
      <div {...{ css: messageContainer }}>
        <div
          {...{
            css: css`
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 16px;
            `,
          }}
        >
          <div
            {...{
              css: css`
                display: flex;
                height: 48px;
                width: 64px;
              `,
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={64}
              height={48}
              fill="none"
            >
              <path
                fill="#696976"
                fillRule="evenodd"
                d="M55.48 3h-47a5 5 0 0 0-5 5v32a5 5 0 0 0 5 5h47a5 5 0 0 0 5-5V8a5 5 0 0 0-5-5Zm-47-3a8 8 0 0 0-8 8v32a8 8 0 0 0 8 8h47a8 8 0 0 0 8-8V8a8 8 0 0 0-8-8h-47Z"
                clipRule="evenodd"
              />
              <path
                fill="#315EFF"
                fillRule="evenodd"
                d="M19.864 36.047a1 1 0 0 1-1-1v-9a1 1 0 1 1 2 0v6.586l18.71-18.71H32.99a1 1 0 1 1 0-2h9a1 1 0 0 1 1 1v9a1 1 0 1 1-2 0v-6.586l-18.71 18.71h6.585a1 1 0 1 1 0 2h-9Z"
                clipRule="evenodd"
              />
              <path
                fill="#696976"
                d="M11.48 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM16.48 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM21.48 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
              />
            </svg>
          </div>
          <label
            {...{
              css: css`
                font-family: Hepta Slab;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                color: ${appColors.content.primary};
              `,
              children: 'Your browser size is too small.',
            }}
          />
        </div>
        <div {...{ css: css`` }}>
          <Paragraph
            {...{
              children:
                'Please expand your browser size to at least 992px to access your account.',
              variant: 'sm',
            }}
          />
        </div>

        <div
          {...{
            css: css`
              display: flex;
              align-items: center;
              padding: 10px;
            `,
          }}
        >
          <Paragraph {...{ variant: 'sm' }}>
            {`Questions? `}
            <Link {...{ onClick: onClickContactOurTeam }}>
              Contact Our Team
            </Link>
          </Paragraph>
        </div>
      </div>
    </div>
  );
};

export default memo(AdminOnlyDesktopMessage);
