import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';

import {
  ApplyLoggedinFormValues,
  UpdateProviderFormValues,
} from '@/types/forms';
import {
  applyJobAsProviderThunk,
  updateProviderThunk,
} from '@/storage/jobboard/jobboard-actions';
import Button from '../Button';
import { FormContainer } from '../Forms';
import Heading from '../Typography/Heading';
import { appColors } from '@/constants/colors';
import ApplyFormDetail from './ApplyFormDetail';
import { useAppDispatch } from '@/storage/store';
import { getLoggedInProvider } from '@/api/providers';
import { statesHash } from '@/constants/selects/states';
import useDropdownsData from '@/hooks/useDropdownsData';
import { paragraphDesktop } from '@/constants/typography';
import { ApplyJobAsProviderParams } from '@/types/api/params';
import ApplyLoggedinCVForm from '../Forms/Jobboard/ApplyLoggedin/ApplyLoggedinCVForm';
import ApplyLoggedinContactInfoForm from '../Forms/Jobboard/ApplyLoggedin/ApplyLoggedinContactInfoForm';
import ApplyLoggedinProfessionalDetailsForm from '../Forms/Jobboard/ApplyLoggedin/ApplyLoggedinProfessionalDetailsForm';

const initialFormValues: ApplyLoggedinFormValues = {
  user_id: -1,
  //@ts-ignore
  state: '',
  city: '',
  zip: '',
  phone: '',
  //
  //@ts-ignore
  title: '',
  //@ts-ignore
  assignment_type: '',
  //@ts-ignore
  specialty: '',
  //@ts-ignore
  geographic_preferences: '',
  //@ts-ignore
  state_licenses: '',
  npi_state_license_number: '',
  //
  resume: {
    name: '',
  },
  profile_link: '',
};

const ApplyModalContentSignedIn = ({
  job_id,
  setHasApplied,
}: {
  setHasApplied: (id: number) => void;
  job_id: number;
}) => {
  const dispatch = useAppDispatch();
  const [isEditMode, setIsEditMode] = useState(false);
  const { dropdownsHashes } = useDropdownsData();

  const methods = useForm<ApplyLoggedinFormValues>({
    defaultValues: {
      ...initialFormValues,
    },
    mode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const initialRequest = useCallback(async () => {
    const response = await getLoggedInProvider();
    if (response) {
      const {
        title,
        assignment_type,
        geographic_preferences,
        specialty,
        state_licenses,
        npi_state_license_number,
      } = response.professional_detail || {
        title: '',
        assignment_type: '',
        geographic_preferences: '',
        specialty: '',
        state_licenses: '',
        npi_state_license_number: '',
      };

      const hasTitle = !!title;
      const hasAssignmentType = !!assignment_type;
      const hasSpecialty = !!specialty;
      const hasStateLicense = state_licenses?.length > 0;
      const hasGeoPref = geographic_preferences?.length > 0;

      reset(
        {
          user_id: response.id,
          //@ts-ignore
          state: statesHash?.[response?.state] || '',
          city: response?.city || '',
          zip: response?.zip || '',
          phone: response?.phone || '',
          //
          title: hasTitle ? dropdownsHashes.profession?.[title] : '',
          assignment_type: hasAssignmentType
            ? dropdownsHashes.assignment[assignment_type]
            : '',
          specialty: hasSpecialty ? dropdownsHashes.specialty[specialty] : '',
          geographic_preferences: hasGeoPref
            ? geographic_preferences.map((item) => {
                return statesHash[item];
              })
            : '',
          state_licenses: hasStateLicense
            ? state_licenses.map((item) => {
                return statesHash[item];
              })
            : '',
          npi_state_license_number: npi_state_license_number || '',
          //
          profile_link: response.profile_link || '',
          resume: { name: response.resume_information.name || '' },
        },
        {}
      );
    }
  }, []);

  const goToEditMode = useCallback(() => {
    setIsEditMode(true);
  }, []);

  const cancelEditMode = useCallback(() => {
    setIsEditMode(false);
  }, []);

  const onClickSaveChanges = useCallback(
    async (form: UpdateProviderFormValues) => {
      const hasNewFile = form.resume?.path;
      const isSameFile = form.resume?.name === initialFormValues?.resume?.name;

      const hasStateLicense = form?.state_licenses?.length > 0;
      const hasGeoPref = form?.geographic_preferences?.length > 0;

      const params = {
        user_id: form.user_id,
        //
        state: form.state.value || '',
        city: form.city,
        zip: form.zip,
        phone: form.phone,
        //
        professional_detail_attributes: {
          title: form?.title?.value || '',
          assignment_type: form?.assignment_type?.value || '',
          specialty: form?.specialty?.value || '',
          geographic_preferences: hasGeoPref
            ? form?.geographic_preferences.map((item) => item.value)
            : [],
          state_licenses: hasStateLicense
            ? form?.state_licenses.map((item) => item.value)
            : [],
          npi_state_license_number: form.npi_state_license_number || '',
        },
        //
        profile_link: form.profile_link,
        ...(hasNewFile
          ? { resume: form.resume }
          : {
              ...(isSameFile ? {} : { purge_cv: true }),
            }),
      };

      const response = await dispatch(
        updateProviderThunk({
          params: {
            ...params,
          },
        })
      ).unwrap();

      if (response.success) {
        setIsEditMode(false);
      }
    },
    []
  );

  const onClickSubmit = useCallback(
    async (form: ApplyLoggedinFormValues) => {
      const params: ApplyJobAsProviderParams = {
        provider_id: form.user_id,
        job_id: job_id,
      };
      const response = await dispatch(applyJobAsProviderThunk(params)).unwrap();
      if (response.success) {
        setHasApplied(job_id);
      }
    },
    [job_id]
  );

  useEffect(() => {
    if (!isEditMode) {
      initialRequest();
    }
    return () => {};
  }, [isEditMode, initialRequest]);

  return (
    <>
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            margin-bottom: 40px;
          `,
        }}
      >
        <Heading
          {...{
            variant: 'h3',
            children: isEditMode ? 'Edit Application' : 'Before you apply...',
          }}
        />
        <label
          {...{
            css: css({
              ...paragraphDesktop.md,
              fontWeight: 500,
              color: appColors.content.secondary,
            }),
            children: isEditMode
              ? 'Note: These changes will be reflected under the My Details tab inside your dashboard.'
              : 'Please make sure your information is correct before submitting this application.',
          }}
        />
      </div>

      <FormContainer
        {...{
          css: css`
            max-height: 735px;
            overflow: auto;
            flex-shrink: 1;
            padding-left: 4px;
            padding-right: 4px;
          `,
        }}
      >
        <ApplyFormDetail
          {...{
            header: 'Contact Information',
          }}
        >
          <ApplyLoggedinContactInfoForm {...{ methods, isEditMode }} />
        </ApplyFormDetail>
        <ApplyFormDetail
          {...{
            header: 'Professional Information',
          }}
        >
          <ApplyLoggedinProfessionalDetailsForm {...{ methods, isEditMode }} />
        </ApplyFormDetail>
        <ApplyFormDetail
          {...{
            header: 'CV & Linkedin',
          }}
        >
          <ApplyLoggedinCVForm {...{ methods, isEditMode }} />
        </ApplyFormDetail>
        <div
          {...{
            css: css`
              display: flex;
              flex-direction: row;
              gap: 8px;
            `,
          }}
        >
          {isEditMode ? (
            <>
              <Button
                {...{
                  children: 'Cancel',
                  variant: '5',
                  onClick: cancelEditMode,
                  disabled: isSubmitting,
                }}
              />

              <Button
                {...{
                  children: 'Save Changes',
                  variant: '6',
                  onClick: handleSubmit(onClickSaveChanges),
                  disabled: isSubmitting || !isDirty,
                }}
              />
            </>
          ) : (
            <>
              <Button
                {...{
                  children: 'Edit',
                  variant: '2',
                  onClick: goToEditMode,
                  disabled: isSubmitting,
                }}
              />
              <Button
                {...{
                  children: 'Submit',
                  variant: '1',
                  onClick: handleSubmit(onClickSubmit),
                  disabled: isSubmitting,
                }}
              />
            </>
          )}
        </div>
      </FormContainer>
    </>
  );
};

export default ApplyModalContentSignedIn;
