import { SVGProps, useMemo } from 'react';

const IconKabobHorizontal = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect x={6.75} y={11} width={2} height={2} rx={1} fill="#05051B" />
        <rect x={11.75} y={11} width={2} height={2} rx={1} fill="#05051B" />
        <rect x={16.75} y={11} width={2} height={2} rx={1} fill="#05051B" />
      </svg>
    ),
    [props]
  );

const IconKabobVertical = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={13.75}
          y={6}
          width={2}
          height={2}
          rx={1}
          transform="rotate(90 13.75 6)"
          fill="#05051B"
        />
        <rect
          x={13.75}
          y={11}
          width={2}
          height={2}
          rx={1}
          transform="rotate(90 13.75 11)"
          fill="#05051B"
        />
        <rect
          x={13.75}
          y={16}
          width={2}
          height={2}
          rx={1}
          transform="rotate(90 13.75 16)"
          fill="#05051B"
        />
      </svg>
    ),
    [props]
  );

export { IconKabobHorizontal, IconKabobVertical };
