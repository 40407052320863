import { createSlice } from '@reduxjs/toolkit';
import { logoutThunk } from './auth-actions';

type AuthState = {
  currentUser: any;
  isLoginOut: boolean;
};

const initialState: AuthState = {
  currentUser: {},
  isLoginOut: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // LOGOUT

    builder.addCase(logoutThunk.pending, (state, { meta: { requestId } }) => {
      state.isLoginOut = true;
    });
    builder.addCase(
      logoutThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoginOut = false;
      }
    );
    builder.addCase(logoutThunk.rejected, (state, { meta: { requestId } }) => {
      state.isLoginOut = false;
    });
  },
});

export const {} = authSlice.actions;

export default authSlice.reducer;
