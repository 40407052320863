import { FC, memo, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import layout from '@/constants/layout';
import { SelectOption } from '@/types/types';
import { appColors } from '@/constants/colors';
import JobListTileHeader from '../JobTiles/JobListTileHeader';
import CardLink from '../Card/CardLink';

const {
  windowSizes: { mobile },
} = layout;

const HOVER_COLOR = appColors.content.tertiary;

const jobListTileStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  @media (max-width: ${mobile}px) {
    padding: 24px;
  }
`;

const SearchLink: FC<{
  item: SelectOption;
  onClickTile: (value: string) => void;
}> = ({ item, onClickTile }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <CardLink
      {...{
        css: css({
          ...jobListTileStyles,
        }),
        style: {
          // ...(isHovered ? { borderColor: HOVER_COLOR } : {}),
          ...(isHovered ? { backgroundColor: 'white ' } : {}),
        },
        onMouseOver: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
        onClick: () => onClickTile(item.value),
      }}
    >
      <JobListTileHeader
        {...{
          title: item.label || '',
          style: {
            ...(isHovered ? { color: "#000" } : { color: 'white' }),
            fontFamily: 'Inter',
            fontSize: 15,
            fontWeight: 400,
          },
        }}
      />
    </CardLink>
  );
};

export default memo(SearchLink);
