import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconMenu = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M4.75 6h16M4.75 12h16M4.75 18h16"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

const IconMenuStaggered = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M4 6h16M8 12h12M4 18h16"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconMenu, IconMenuStaggered };
