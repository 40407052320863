import { FC, memo } from 'react';

import FakeField from '../FakeField';
import { appColors } from '@/constants/colors';
import RecruitersOnlyDetailsCard from './RecruitersOnlyDetailsCard';
import { RecruitersOnlyDetailsCardHeader, RowContainer } from '../Forms';

const NO_DATA_TEXT = 'No data provided';

interface RecruitersOnlyDetailsProps {
  recruiter: {
    job_reference_number: string;
    facility_name: string;
    city: string;
    aditional_notes: string;
  };
  hasHeader?: boolean;
}

const RecruitersOnlyDetails: FC<RecruitersOnlyDetailsProps> = ({
  recruiter,
  hasHeader,
}) => {
  const id = String(recruiter?.job_reference_number || '');
  return (
    <RecruitersOnlyDetailsCard
      {...{
        ...(hasHeader ? { header: <RecruitersOnlyDetailsCardHeader /> } : {}),
        content: (
          <>
            <RowContainer>
              <FakeField
                {...{
                  label: 'Internal Job #',
                  value: id || NO_DATA_TEXT,
                  textStyles: {
                    ...(!!recruiter?.job_reference_number
                      ? {}
                      : { color: appColors.content.secondary }),
                  },
                }}
              />
              <FakeField
                {...{
                  label: 'Facility Name',
                  value: recruiter?.facility_name || NO_DATA_TEXT,
                  textStyles: {
                    ...(!!recruiter?.facility_name
                      ? {}
                      : { color: appColors.content.secondary }),
                  },
                }}
              />
            </RowContainer>
            <RowContainer>
              <FakeField
                {...{
                  label: 'City',
                  value: recruiter?.city || NO_DATA_TEXT,
                  textStyles: {
                    ...(!!recruiter?.city
                      ? {}
                      : { color: appColors.content.secondary }),
                  },
                }}
              />
              <FakeField
                {...{
                  label: 'Additional Notes',
                  value: recruiter?.aditional_notes || NO_DATA_TEXT,
                  textStyles: {
                    ...(!!recruiter?.aditional_notes
                      ? {}
                      : { color: appColors.content.secondary }),
                  },
                }}
              />
            </RowContainer>
          </>
        ),
      }}
    />
  );
};

export default memo(RecruitersOnlyDetails);
