import { ComponentProps, FC, ReactNode, useMemo } from 'react';
import { css } from '@emotion/react';

import Card from '../Card';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import Button, { ButtonProps } from '../Button';
import RestartAltSharpIcon from '@mui/icons-material/RestartAltSharp';

const APPLY = 'Apply';
const RESET = (
  <>
    Reset <RestartAltSharpIcon />
  </>
);

const filterMenuStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const filterMenuHeaderStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const filterMenuContentStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const filterMenuButtonsStyles = css`
  display: flex;
  overflow-y: auto;
`;

interface FiltersMenuProps {
  cardProps?: ComponentProps<typeof Card>;
  header?: ReactNode;
  content?: ReactNode;
  buttons?: ReactNode;
}

const FiltersMenuLayout: FC<FiltersMenuProps> = ({
  cardProps,
  header,
  content,
  buttons,
}) => {
  return useMemo(() => {
    return (
      <Card {...{ css: filterMenuStyles, ...cardProps }}>
        {!!header && <div {...{ css: filterMenuHeaderStyles }}>{header}</div>}
        {!!content && (
          <div {...{ css: filterMenuContentStyles }}>{content}</div>
        )}
        {!!buttons && (
          <div {...{ css: filterMenuButtonsStyles }}>{buttons}</div>
        )}
      </Card>
    );
  }, [header, content, buttons]);
};

const FilterMenuResetText: FC<{ onClick: () => void }> = ({ onClick }) => {
  return useMemo(() => {
    return (
      <Paragraph
        {...{
          children: 'Reset',
          variant: 'md',
          onClick,
          style: {
            color: appColors.content.tertiary,
          },
        }}
      />
    );
  }, []);
};

const FilterMenuHeader: FC<{ header: string }> = ({ header }) => {
  return useMemo(() => {
    return <Paragraph {...{ children: header, variant: 'lg' }} />;
  }, [header]);
};

const FilterMenuApplyButton: FC<{ buttonProps: ButtonProps }> = ({
  buttonProps,
}) => {
  return useMemo(() => {
    return (
      <Button
        {...{
          children: APPLY,
          ...buttonProps,
        }}
      />
    );
  }, [buttonProps]);
};

const FilterMenuButtons: FC<{
  resetButtonProps: ButtonProps;
  applyButtonProps: ButtonProps;
  reset?: boolean;
}> = ({ resetButtonProps, applyButtonProps, reset }) => {
  return useMemo(() => {
    return (
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 8px;
          `,
        }}
      >
        {!!reset && (
          <Button
            {...{
              children: RESET,
              ...resetButtonProps,
              variant: '2',
            }}
          />
        )}
        <Button
          {...{
            children: APPLY,
            ...applyButtonProps,
          }}
        />
      </div>
    );
  }, [resetButtonProps, applyButtonProps]);
};

export {
  FilterMenuResetText,
  FilterMenuHeader,
  FiltersMenuLayout,
  FilterMenuButtons,
  FilterMenuApplyButton,
};
