import { ReactNode, useState } from 'react';

import Modal, { Position } from '../components/Modal/index';

const useModal = () => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const ModalComponent = ({
    children,
    position,
    isLoading,
    onClose,
  }: {
    children: ReactNode;
    position?: Position;
    isLoading?: boolean;
    onClose?: () => void;
  }) => {
    return (
      <>
        {showModal && (
          <Modal
            {...{
              position,
              children,
              onClose: () => {
                !!onClose && onClose();
                closeModal();
              },
              isLoading,
            }}
          />
        )}
      </>
    );
  };

  return { ModalComponent, openModal, closeModal, showModal };
};

export default useModal;
