import { ForwardRefRenderFunction, forwardRef } from 'react';
import { DropzoneProps } from 'react-dropzone';
import { css } from '@emotion/react';

import {
  INPUT_COMMONS,
  Input,
  StyledErrorMessage,
  StyledInputWrapper,
  StyledLabel,
  inputDefaultStyles,
} from '../Input';
import { IconTrash } from '../Icons/Trash';
import CustomDropZone from '../CustomDropZone';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

export interface InputDropProps extends Input {
  readonly disabled?: boolean;
  readonly dropzoneProps: DropzoneProps;
  readonly value: any;
  readonly removeFileAction: () => void;
  readonly labelHint?: string;
}

const InputDrop: ForwardRefRenderFunction<unknown, InputDropProps> = (
  { label, labelHint, ...props },
  ref
) => {
  return (
    <StyledInputWrapper>
      <StyledLabel
        {...{
          style: {
            ...(props.disabled
              ? {
                  color: INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED,
                }
              : {}),
          },
        }}
      >
        {label}
        {!!labelHint && (
          <Paragraph
            {...{
              children: `  ${labelHint}`,
              variant: 'sm',
              style: {
                color: appColors.content.secondary,
                flexWrap: 'wrap',
              },
            }}
          />
        )}
      </StyledLabel>
      {!!props?.value?.name ? (
        <DropzoneWithValue
          {...{
            name: props?.value?.name,
            onPressIcon: props.removeFileAction,
          }}
        />
      ) : (
        <CustomDropZone
          {...{
            dropzoneProps: props.dropzoneProps,
            error: props.error,
            ref,
          }}
        />
      )}
      {!!props.error?.message && (
        <StyledErrorMessage
          {...{
            children: props.error?.message,
          }}
        />
      )}
    </StyledInputWrapper>
  );
};

export default forwardRef(InputDrop);

const DropzoneWithValue = ({
  name,
  onPressIcon,
}: {
  name: string;
  onPressIcon: () => void;
}) => {
  return (
    <div
      {...{
        css: css`
          ${inputDefaultStyles}
          display: flex;
          flex-direction: row;
          height: 40px;
          border-color: ${INPUT_COMMONS.BORDER_COLOR_DEFAULT_FOCUS};
          align-items: center;
          justify-content: space-between;
        `,
      }}
    >
      <div
        {...{
          css: css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `,
        }}
      >
        <div>
          <Paragraph
            {...{
              children: !!name ? `${name.slice(0, 20)}...` : '',
              variant: 'md',
              style: {
                color: INPUT_COMMONS.BORDER_COLOR_DEFAULT_FOCUS,
                textDecoration: 'underline',
              },
            }}
          />
        </div>
      </div>
      <div
        {...{
          onClick: onPressIcon,
          css: css`
            width: 24px;
            height: 24px;
            cursor: pointer;
          `,
        }}
      >
        <IconTrash />
      </div>
    </div>
  );
};
