const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

const usPhoneRegex = new RegExp(
  /^(?:\([2-9]\d{2}\)\ ?|[2-9]\d{2}(?:\-?|\ ?))[2-9]\d{2}[- ]?\d{4}$/g
);

const passwordRegex = new RegExp(
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!"\#$%&'()*+,\-./:;<=>?@\[\\\]^_'{|}~]).{8,}$/
);

const linkedInProfileUrlRegex = new RegExp(
  /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9-_.]+\/?$/
);

const noNumbersOrSpecialCharsRegex = new RegExp(
  /^(?!.*[^A-Za-z\s])[A-Za-z\s]+$/
);

const lengthRegex = new RegExp(/^(?!.*[^A-Za-z\s])[A-Za-z\s]{2,50}$/);
const zipLengthRegex = new RegExp(/^\d{5}$/);
const zipValidRegex = new RegExp(/^\d{5}(?:-\d{4})?$/);
const npiLengthRegex = new RegExp(/^\d{10}$/);
const websiteRegex = new RegExp(
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/
);

export default {
  regexExps: {
    emailRegex,
    usPhoneRegex,
    passwordRegex,
    linkedInProfileUrlRegex,
    noNumbersOrSpecialCharsRegex,
    lengthRegex,
    zipLengthRegex,
    zipValidRegex,
    npiLengthRegex,
    websiteRegex,
  },
  isValidEmail: (value: string) => {
    return emailRegex.test(value);
  },
  isValidPhone: (value: string) => {
    return usPhoneRegex.test(value);
  },
  formatUsPhone: (value: string) => {
    return '1' + value.replace(/\D/g, '');
  },
};
