import AuthModal from '@/components/AuthModal';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';
import RecruiterDashboard from '@/components/Dashboard/Recruiter/RecruitersDashboard';

export default function Index() {
  return (
    <>
      <TopContentFooterLayout>
        <RecruiterDashboard />
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
