import { ComponentProps, FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Select from 'react-select';

import {
  optionStyles,
  singleValueStyles,
  menuListStyles,
  menuStyles,
  valueContainerStyles,
} from '../InputDropdown/index';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import MenuList from '../InputDropdown/MenuList';
import { Input, StyledInputWrapper } from '../Input';
import { paragraphDesktop } from '@/constants/typography';
import DropdownIndicator from '../InputDropdown/DropdownIndicator';
import IndicatorsContainer from '../InputDropdown/IndicatorsContainer';

const MENU_WIDTH = 225;
const INPUT_WIDTH_DESKTOP = 125;
const INPUT_WIDTH_MOBILE = INPUT_WIDTH_DESKTOP / 2;

const PLACEHOLDER_TEXTCOLOR = appColors.content.secondary;

const {
  windowSizes: { mobile },
  components: {
    selects: { multiProps },
  },
  mediaQuery,
} = layout;

const valueContainerStyle = css`
  width: ${INPUT_WIDTH_DESKTOP}px;
  @media (max-width: ${mobile}px) {
    width: ${INPUT_WIDTH_MOBILE}px;
  }
`;

const placeholderDesktopTextStyles = css({
  ...paragraphDesktop.lg,
  color: PLACEHOLDER_TEXTCOLOR,
  userSelect: 'none',
    position: 'absolute',
});

const placeholderMobileTextStyles = css({
  ...paragraphDesktop.sm,
  color: PLACEHOLDER_TEXTCOLOR,
  userSelect: 'none',
});

export interface FilterDropdownProps extends Input, ComponentProps<Select> {
  readonly labelHint?: string;
}

export const StyledFilterDropdown = styled(Select)<FilterDropdownProps>`
  outline: none !important;
  border: none !important;
  flex-grow: 0;
`;

const FilterDropdown: FC<FilterDropdownProps> = ({
  label,
  labelHint,
  options,
  error,
  ...props
}) => {
  return (
    <StyledInputWrapper>
      <StyledFilterDropdown
        {...{
          isClearable: false,
          isSearchable: false,
          ...(props.isMulti ? multiProps : {}),
          // menuShouldScrollIntoView: true,
          ...props,
          styles: {
            control: (base) => {
              return {
                ...base,
                fontFamily: 'Inter, sans-serif',
                fontSize: 18,
                border: 0,
                boxShadow: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                margin: 0,
                flexGrow: 0,
                flexWrap: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                [mediaQuery.mobile]: {
                  padding: 0,
                  pointerEvents: 'none',
                },
              };
            },
            indicatorSeparator: (base) => ({
              ...base,
              display: 'none',
            }),
            indicatorsContainer: (base) => ({
              ...base,
              [mediaQuery.mobile]: {
                display: 'none',
              },
            }),
            valueContainer: (base) => ({
              ...base,
              ...valueContainerStyles,
              justifyContent: 'center',
            }),
            singleValue: (base) => {
              return {
                ...base,
                ...singleValueStyles,
              };
            },
            option: (base, { isSelected, isFocused }) => ({
              ...base,
              ...optionStyles({ isFocused, isSelected }),
            }),
            multiValue: (base) => ({
              ...base,
            }),
            multiValueLabel: (base) => ({
              ...base,
              ...paragraphDesktop.sm,
            }),
            multiValueRemove: (base) => ({
              ...base,
            }),
            menuList: (base) => ({
              ...base,
              ...menuListStyles,
            }),
            menu: (base) => ({
              ...base,
              width: MENU_WIDTH,
              ...menuStyles,
            }),

            ...props.styles,
          },
          components: {
            MenuList: MenuList,
            IndicatorsContainer: IndicatorsContainer,
            DropdownIndicator: (props) => (
              <DropdownIndicator {...{ ...props, hasError: !!error }} />
            ),
            MultiValueContainer: ({ innerProps, children }) => {
              return (
                <div
                  {...{
                    ...innerProps,
                    css: css`
                      display: flex;
                      align-items: center;
                      justify-content: flex-start;
                      ${valueContainerStyle}
                    `,
                    children,
                  }}
                />
              );
            },
            Placeholder: ({ innerProps, children }) => {
              return (
                <div
                  {...{
                    ...innerProps,
                    css: css`
                      display: flex;
                      width: 100%;
                      align-items: center;
                      justify-content: center;
                      ${placeholderDesktopTextStyles}
                      @media (max-width: ${mobile}px) {
                        padding: 0;
                        ${placeholderMobileTextStyles}
                      }
                    `,
                    children,
                  }}
                />
              );
            },

            ...props.components,
          },
          options,
        }}
      />
    </StyledInputWrapper>
  );
};

export default FilterDropdown;
