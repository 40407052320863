import {
  ComponentProps,
  useCallback,
  useReducer,
  useState,
} from 'react';
import styled from '@emotion/styled';
import { router, usePage, useForm as useInertiaForm } from '@inertiajs/react';

import NavButton from '../NavButton';
import PageHeader from '../PageHeader';
import layout from '@/constants/layout';
import { ProviderSavedSearchProps } from '@/types/page';
import { HEADER_TITLE, providerDashboardContainer } from '../_index';
import { css } from '@emotion/react';
import CustomDataTable from '@/components/Table/DataTable';
import { SearchProvider } from '@/types/entities.d';
import { useUrlSearchParams } from 'use-url-search-params';
import { ProvidersDashboardFormValues } from '@/types/forms';
import { transformQueryParams } from '@/utils/query-params';
import {
  initialState,
  reducer,
} from '@/constants/contexts/datatable-provider-dasboard';
import { useForm } from 'react-hook-form';
import { datatableUtils } from '@/constants/datatables/_index';
import { getMetaToken } from '@/utils/meta-token';
import CustomPaginator from '@/components/Paginator';
import { providersCustomDatatableConfig} from '@/constants/datatables/provider-dashboard';
import AdminPanelContentLayout from '@/components/AdminPanel/Layout/AdminPanelContentLayout';
import NoResultsFound from '@/components/List/NoResultsFound';
import DeleteProviderSearchButton from '@/components/DeleteRows/DeleteProviderSearchButton';

const {
  windowSizes: { tablet, mobile, desktop, desktopHD },
} = layout;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const NavHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const NavHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
`;

const navButtons: ComponentProps<typeof NavButton>[] = [
  {
    children: 'Applied Jobs',
    onClick: () => {
      router.get(`/providers/applied_jobs`);
    },
  },
  {
    children: 'Saved Jobs',
    onClick: () => {
      router.get(`/providers/saved_jobs`);
    },
  },
  {
    children: 'Saved Search',
    onClick: () => {
      router.get(`/providers/saved_searches`);
    },
  },
  {
    children: 'My Details',
    onClick: () => {
      router.get(
        `/providers/details`,
        {},
        {
          preserveState: false,
          preserveScroll: false,
        }
      );
    },
  },
];

const ProviderDashboardTable = CustomDataTable<SearchProvider>;

export default function ProvidersSearch() {
  const { get, transform } = useInertiaForm();

  //URL PARAMS
  const {
    props: { session, status, ...rest },
  } = usePage<ProviderSavedSearchProps>();

  const [params, setParams] = useUrlSearchParams({});
  const urlParams = params as unknown as ProvidersDashboardFormValues;
  const parsedParams =
    transformQueryParams<ProvidersDashboardFormValues>(urlParams);

  // INITAL STATES
  const initialRowSelection = {};
  const initialSorting = [
    {
      id: '',
      desc: true,
    },
  ];

  const initialFilterValues: ProvidersDashboardFormValues = {
    per: parsedParams?.per || 10,
    sort: parsedParams?.sort || '',
    page: parsedParams?.page || 1,
  };
  //

  // LOCAL STATE
  const [{ modal }, localDispatch] = useReducer(reducer, {
    ...initialState,
  });

  // UI TABLE CONTROLS
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [sorting, setSorting] = useState(initialSorting);

  //

  // FORM STATE
  const methods = useForm<ProvidersDashboardFormValues>({
    defaultValues: {
      ...initialFilterValues,
    },
    shouldUnregister: false,
  });

  // CONSTANTS
  const { control, handleSubmit, setValue } = methods;
  const { collection, pagination, sort } = rest;

  const rowSelected = Object.keys(rowSelection).map((id) => {
    return collection[parseInt(id)];
  });

  const isEmpty = collection.length === 0;

  const triggerSearch = useCallback(
    async (form: ProvidersDashboardFormValues) => {
      const hasSort = !!form?.sort;
      const isPageDiffOne = !!form?.page && form.page > 1;
      const isPerDiffTen = !!form?.per && form.per !== 10;
      const params = {
        ...(hasSort ? { sort: form.sort } : {}),
        ...(isPerDiffTen ? { per: form.per } : {}),
        ...(isPageDiffOne ? { page: form.page } : {}),
      };
      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        get('/providers/saved_searches', {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
        });
      }
    },
    []
  );

  const submitSearch = useCallback(async () => {
    changePage(1);
  }, []);

  const onCloseTileModal = useCallback(() => {
    localDispatch({ type: 'CLOSE_TILE_MODAL', payload: null });
  }, [localDispatch]);

  const resetRows = useCallback(() => {
    setRowSelection(initialRowSelection);
  }, [initialRowSelection]);

  const changePage = useCallback(async (page: number) => {
    setValue('page', page, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(triggerSearch)();
    setRowSelection(initialRowSelection);
  }, []);

  return (
    <div
      {...{
        css: providerDashboardContainer,
      }}
    >
      <HeaderContainer>
        <PageHeader {...{ variant: 'h1Small', children: HEADER_TITLE }} />
        <NavHeaderContainer>
          <NavHeaderButtonsContainer>
            {navButtons.map((navButton, index) => {
              const isActive = index === 2;
              return <NavButton {...{ ...navButton, isActive, key: index }} />;
            })}
          </NavHeaderButtonsContainer>
        </NavHeaderContainer>
      </HeaderContainer>
      <AdminPanelContentLayout
        {...{
          optionsComponent: (
            <div>
              <DeleteProviderSearchButton
                {...{
                  resetRows,
                  rowSelectedIds: rowSelected.map(({ id }) => id),
                }}
              />
            </div>
          ),
          tableComponent: isEmpty ? (
            <>
              <NoResultsFound
                {...{
                  header: datatableUtils.EMPTY_HEADER,
                  subheader: datatableUtils.EMPTY_SUBHEADER,
                }}
              />
            </>
          ) : (
            <>
              <div
                {...{
                  css: css`
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    gap: 10px;
                    overflow: auto;
                  `,
                }}
              >
                <ProviderDashboardTable
                  {...{
                    data: collection,
                    sorting,
                    rowSelection,
                    onSortingChange: setSorting,
                    onRowSelectionChange: setRowSelection,
                    columns: providersCustomDatatableConfig.columns,
                  }}
                />
                <CustomPaginator
                  {...{
                    pagination,
                    changePage,
                  }}
                />
              </div>
            </>
          ),
        }}
      />
    </div>
  );
}
