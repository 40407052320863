import { FC, memo } from 'react';
import { css } from '@emotion/react';

import { SortType } from '@/types/types';
import { appColors } from '@/constants/colors';
import { IconArrowDown } from '../Icons/Arrow';
import Paragraph from '../Typography/Paragraph';

const sortTextByType: Record<SortType, string> = {
  'posted_at desc': 'Newest',
  'posted_at asc': 'Oldest',
};

const newest = sortTextByType['posted_at desc'];
const oldest = sortTextByType['posted_at asc'];

interface ListSorterProps {
  sort: SortType;
  changeSort: () => void;
  disabled?: boolean;
}

const listSorterContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ListSorter: FC<ListSorterProps> = ({ sort, changeSort, disabled }) => {
  const sortTextValue = sortTextByType[sort];

  const sortText =
    sortTextValue === 'Newest'
      ? `${newest} - ${oldest}`
      : `${oldest} - ${newest}`;

  const sortTextColor = disabled
    ? appColors.content.secondary
    : appColors.content.tertiary;

  return (
    <div
      {...{
        css: listSorterContainer,
        onClick: (e) => {
          if (!disabled) {
            e.preventDefault();
            !!changeSort && changeSort();
          }
        },
      }}
    >
      <Paragraph
        {...{
          variant: 'md',
          style: {
            color: sortTextColor,
            fontWeight: 500,
            display: 'flex',
            flexDirection: 'row',
          },
          onClick: () => {},
        }}
      >
        {sortText}
        <div {...{ style: { width: 24, height: 24, marginLeft: 4 } }}>
          <IconArrowDown {...{ iconColor: sortTextColor }} />
        </div>
      </Paragraph>
    </div>
  );
};

export default memo(ListSorter);
