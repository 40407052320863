import React, { CSSProperties, ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTransition, animated, config } from '@react-spring/web';

import layout from '@/constants/layout';

const {
  windowSizes: { mobile },
} = layout;

const OVERLAY_COLOR = 'rgba(0, 0, 0, 0.5)';

const top = css`
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
`;
const center = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const bottom = css`
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
`;
const left = css`
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
`;
const right = css`
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
`;

const modalStyles = css`
  position: absolute;
  z-index: 9997;
  background-color: #fff;
  border-radius: 10px;
  overflow: visible;
  @media (max-width: ${mobile}px) {
    width: 95%;
    ${top}
  }
`;

const overlayStyles = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${OVERLAY_COLOR};
  z-index: 9995;
  overflow-y: auto;
`;

const contentContainerStyles = css`
  height: fit-content;
  z-index: 9996;
`;

export type Position = 'top' | 'center' | 'bottom' | 'left' | 'right';

export type ModalProps = {
  onClose: () => void;
  children: ReactNode;
  position?: Position;
  isLoading?: boolean;
  isAnimated?: boolean;
  overlayStyle?: CSSProperties;
  modalPositionStyle?: CSSProperties;
};

const getPositionStyles = (position: Position | undefined) => {
  const positionStyles = {
    top,
    center,
    bottom,
    left,
    right,
  };

  return positionStyles[position || 'center'];
};

const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  position,
  isLoading,
  isAnimated,
  overlayStyle,
  modalPositionStyle,
}) => {
  const modalPositionStyles = css`
    ${modalStyles};
    ${getPositionStyles(position)};
  `;

  const transitions = useTransition(true, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { ...config.default, duration: 250 },
  });

  return transitions((styles, item) => {
    if (item)
      return (
        <>
          <animated.div
            {...{
              onClick: onClose,
              css: css({
                ...overlayStyles,
                ...overlayStyle,
              }),
              style: {
                pointerEvents: isLoading ? 'none' : 'auto',
              },
              ...(isAnimated ? { style: styles } : {}),
            }}
          />
          <animated.div
            {...{
              css: css({
                ...modalPositionStyles,
                ...modalPositionStyle,
              }),
              style: {
                pointerEvents: isLoading ? 'none' : 'auto',
              },
              ...(isAnimated ? { style: styles } : {}),
            }}
          >
            <div
              {...{
                css: contentContainerStyles,

                children,
              }}
            />
          </animated.div>
        </>
      );
  });
};

export default Modal;
