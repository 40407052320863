import dayjs from 'dayjs';
dayjs.locale('en');

const dateFormats = {
  mmddyyyy: 'MM/DD/YYYY',
};

const formatDate = (hour: string, format: string = dateFormats.mmddyyyy) => {
  return dayjs(hour, 'hhmm').format(format);
};

export { dayjs, formatDate, dateFormats };
