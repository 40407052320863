import { ComponentProps, FC, memo } from 'react';

import useModal from '@/hooks/useModal';
import { PromptMessage } from '../ModalPrompt';
import Button, { ButtonProps } from '../Button';
import QuestionPrompt from '../ModalPrompt/QuestionPrompt';

interface RepostButtonProps {
  buttonProps?: ButtonProps;
  questionPromptProps: ComponentProps<typeof QuestionPrompt>;
}

const RepostButton: FC<RepostButtonProps> = ({
  buttonProps,
  questionPromptProps: { headerProps = {}, buttonsProps = {} },
}) => {
  const { ModalComponent, openModal, closeModal } = useModal();

  const { leftButton = {}, rightButton = {} } = buttonsProps;

  return (
    <>
      <Button
        {...{
          ...buttonProps,
          variant: '1',
          onClick: openModal,
          children: 'Repost',
        }}
      />
      <ModalComponent {...{}}>
        <QuestionPrompt
          {...{
            headerProps: {
              ...headerProps,
              children: 'Repost this Job?',
            },
            msgProps: [
              <PromptMessage
                {...{
                  children:
                    'Reposting an expired job will allow new candidates to apply and will be moved to the “active” status.',
                }}
              />,
            ],
            buttonsProps: {
              ...buttonsProps,
              leftButton: {
                variant: '2',
                children: 'Cancel',
                ...leftButton,
                onClick: async (e) => {
                  leftButton?.onClick && leftButton?.onClick(e);
                  closeModal();
                },
              },
              rightButton: {
                variant: '1',
                children: 'Yes, Repost',
                ...rightButton,
                onClick: async (e) => {
                  rightButton?.onClick && rightButton?.onClick(e);
                  closeModal();
                },
              },
            },
          }}
        />
      </ModalComponent>
    </>
  );
};

export default memo(RepostButton);
