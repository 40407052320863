import { ComponentProps, FC, ReactNode } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { Company } from '@/types/entities';
import { Provider } from '@/types/entities';
import { CancelNextButtons, FormHeader } from '@/components/Forms';
import { ProfessionalDetails, ResourceType } from '@/types/types';

const {
  windowSizes: { mobile, tablet },
} = layout;

export type AccountDetailsType = {
  user_id: number;
  is_type: ResourceType;
  first_name: string;
  last_name: string;
  email: string;
};

export type ApplicationDetailsType = {
  resume: string;
  profession_detail: ProfessionalDetails;
  provider: Provider;
};

export type CompanyDetailsType = {
  readonly user_id: number;
  readonly company: Company;
  readonly phone: string;
  readonly location: string;
};

const SAVED_MSG = 'Your changes have been saved';

const containerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
  @media (max-width: ${mobile}px) {
    gap: 24px;
  }
`;

const fieldsContainer = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  grid-auto-rows: 1fr;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

interface MyDetailsModalContentLayoutProps {
  formHeaderProps: ComponentProps<typeof FormHeader>;
  formComponent: ReactNode;
  cancelNextButtonsProps: ComponentProps<typeof CancelNextButtons>;
}

const MyDetailsModalContentLayout: FC<MyDetailsModalContentLayoutProps> = ({
  formHeaderProps,
  formComponent,
  cancelNextButtonsProps,
}) => {
  return (
    <div {...{ css: containerStyles }}>
      <FormHeader {...formHeaderProps} />
      {!!formComponent && <div {...{ children: formComponent }} />}
      <CancelNextButtons
        {...{
          cancelButtonProps: {
            ...cancelNextButtonsProps.cancelButtonProps,
          },
          nextButtonProps: {
            ...cancelNextButtonsProps.nextButtonProps,
            variant: '6',
            children: 'Save Changes',
            icon: undefined,
          },
        }}
      />
    </div>
  );
};

export {
  MyDetailsModalContentLayout,
  SAVED_MSG,
  containerStyles,
  fieldsContainer,
};
