import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  recoveryEmailThunk,
  recoveryEmailResendConfirmationThunk,
  resetPasswordThunk,
  signInThunk,
  signUpThunk,
  signUpResendConfirmationThunk,
  signupPhysicianContactInfoThunk,
  signupPhysicianProfessionalDetailsThunk,
  signupPhysicianCVThunk,
  signupRecruiterContactInfoThunk,
  signupRecruiterCompanyThunk,
  signupCloseThunk,
} from '../auth/auth-actions';
import { AuthModalsMode } from '@/types/types';
import { ModalProps } from '@/components/Modal';
import { openModal, closeModal, openAuthModals } from './modal-actions';

type ModalState = {
  modalProps: ModalProps;
  isVisible: boolean;
  isLoading: boolean;
  //
  modals: {
    authModals: {
      mode: AuthModalsMode;
    };
  };
};

const initialState: ModalState = {
  modalProps: {
    onClose: () => {},
    children: null,
    position: 'top',
  },
  isLoading: false,
  isVisible: false,
  //
  modals: {
    authModals: {
      mode: 'signIn',
    },
  },
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setAuthModalMode: (state, { payload }: PayloadAction<AuthModalsMode>) => {
      state.modals.authModals.mode = payload;
    },
    setModalLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
  },
  extraReducers: (builder) => {
    // openModal //
    builder.addCase(openModal.pending, (state) => {});
    builder.addCase(openModal.fulfilled, (state, { payload }) => {
      state.modalProps = payload;
      state.isVisible = true;
    });
    builder.addCase(openModal.rejected, (state) => {
      state.modalProps = initialState.modalProps;
    });

    // openAuthModals //
    builder.addCase(openAuthModals.pending, (state) => {});
    builder.addCase(openAuthModals.fulfilled, (state, { payload }) => {
      state.modalProps = payload.params;
      modalSlice.caseReducers.setAuthModalMode(state, {
        payload: payload.mode,
        type: '',
      });
      state.isVisible = true;
    });
    builder.addCase(openAuthModals.rejected, (state) => {
      state.modalProps = initialState.modalProps;
    });

    // closeModal //
    builder.addCase(closeModal.pending, (state) => {});
    builder.addCase(closeModal.fulfilled, (state, { payload }) => {
      return initialState;
    });
    builder.addCase(closeModal.rejected, (state) => {
      state.modalProps = initialState.modalProps;
    });

    // MODAL LOADING STATE FOR AUTH PROMISES

    // signInThunk
    builder.addCase(signInThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(signInThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(signInThunk.rejected, (state) => {
      state.isLoading = false;
    });

    // recoveryEmailThunk
    builder.addCase(recoveryEmailThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(recoveryEmailThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(recoveryEmailThunk.rejected, (state) => {
      state.isLoading = false;
    });

    // recoveryEmailResendConfirmationThunk //
    builder.addCase(
      recoveryEmailResendConfirmationThunk.pending,
      (state, { meta: { requestId } }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      recoveryEmailResendConfirmationThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      recoveryEmailResendConfirmationThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.isLoading = false;
      }
    );

    // resetPasswordThunk
    builder.addCase(resetPasswordThunk.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPasswordThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(resetPasswordThunk.rejected, (state) => {
      state.isLoading = false;
    });

    // signUpThunk //
    builder.addCase(signUpThunk.pending, (state, { meta: { requestId } }) => {
      state.isLoading = true;
    });
    builder.addCase(
      signUpThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(signUpThunk.rejected, (state, { meta: { requestId } }) => {
      state.isLoading = false;
    });

    // signUpResendConfirmationThunk //
    builder.addCase(
      signUpResendConfirmationThunk.pending,
      (state, { meta: { requestId } }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      signUpResendConfirmationThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      signUpResendConfirmationThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.isLoading = false;
      }
    );

    // signupCloseThunk //
    builder.addCase(
      signupCloseThunk.pending,
      (state, { meta: { requestId } }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      signupCloseThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      signupCloseThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.isLoading = false;
      }
    );

    // SIGNUP PHYSICIAN

    // I

    builder.addCase(
      signupPhysicianContactInfoThunk.pending,
      (state, { meta: { requestId } }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      signupPhysicianContactInfoThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      signupPhysicianContactInfoThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.isLoading = false;
      }
    );

    // II

    builder.addCase(
      signupPhysicianProfessionalDetailsThunk.pending,
      (state, { meta: { requestId } }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      signupPhysicianProfessionalDetailsThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      signupPhysicianProfessionalDetailsThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.isLoading = false;
      }
    );

    // III

    builder.addCase(
      signupPhysicianCVThunk.pending,
      (state, { meta: { requestId } }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      signupPhysicianCVThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      signupPhysicianCVThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.isLoading = false;
      }
    );

    // SIGNUP RECRUITER

    // I
    builder.addCase(
      signupRecruiterContactInfoThunk.pending,
      (state, { meta: { requestId } }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      signupRecruiterContactInfoThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      signupRecruiterContactInfoThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.isLoading = false;
      }
    );

    // II

    builder.addCase(
      signupRecruiterCompanyThunk.pending,
      (state, { meta: { requestId } }) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      signupRecruiterCompanyThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      signupRecruiterCompanyThunk.rejected,
      (state, { meta: { requestId } }) => {
        state.isLoading = false;
      }
    );
  },
});

export const { setAuthModalMode, setModalLoading } = modalSlice.actions;

export default modalSlice.reducer;
