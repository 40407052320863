import { SVGProps, useMemo } from 'react';

const IconSort = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.75 6v12m0 0l3-3m-3 3l-3-3M17.75 18V6m0 0l3 3m-3-3l-3 3"
          stroke="#05051B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconSort };
