import { CSSProperties, FunctionComponent, memo } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const {
  components: { iconStyles },
} = layout;

const container = css`
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
`;

const contentContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 8px;
`;

type State = 'active' | 'inactive';

const styleByState: Record<
  State,
  {
    contatinerStyles: CSSProperties;
    textStyles: CSSProperties;
    iconProps: IconCustomProps;
  }
> = {
  active: {
    contatinerStyles: {
      background: appColors.content.tertiary,
    },
    textStyles: { color: 'white' },
    iconProps: { iconColor: 'white' },
  },
  inactive: {
    contatinerStyles: {
      background: appColors.background.secondary,
    },
    textStyles: { color: appColors.content.primary },
    iconProps: { iconColor: appColors.content.primary },
  },
};

const NavButton = ({
  icon: Icon,
  label,
  isActive,
  onClick,
}: {
  icon: FunctionComponent<IconCustomProps>;
  isActive: boolean;
  label: string;
  onClick?: () => void;
}) => {
  const buttonState: State = isActive ? 'active' : 'inactive';

  const { contatinerStyles, textStyles, iconProps } = styleByState[buttonState];

  return (
    <div {...{ css: container, style: { ...contatinerStyles }, onClick }}>
      <div {...{ css: contentContainer }}>
        {!!Icon && (
          <div {...{ css: iconStyles, children: <Icon {...iconProps} /> }} />
        )}
        {!!label && (
          <label
            {...{
              css: css`
                font-family: Hepta Slab;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                vertical-align: center;
                user-select: none;
                cursor: inherit;
              `,
              style: { ...textStyles },
              children: label,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(NavButton);
