import { useCallback, useEffect, useReducer } from 'react';
import { css } from '@emotion/react';
import { usePage } from '@inertiajs/react';

import useModal from '@/hooks/useModal';
import layout from '@/constants/layout';
import JobDetails from '../Job/JobDetails';
import BookMarked from '../Job/BookMarked';
import { JobShowProps } from '@/types/page';
import Heading from '../Typography/Heading';
import BackComponent from '../BackComponent';
import ApplyButton from '../Job/ApplyButton';
import JobPostedId from '../Job/JobPostedId';
import JobDescDetail from '../Job/JobDescDetail';
import { favoriteJob, unfavoriteJob } from '@/api/jobs';
import ApplyModalContent from '../Job/ApplyModalContent';
import JobShowRecruiterCard from '../Job/JobShowRecruiterCard';
import JobShowDescriptionHeader from '../Job/JobShowDescriptionHeader';
import { initialState, reducer } from '@/constants/contexts/job-context';
import ShareButton from '@/components/ShareIcon';

const {
  windowSizes: { tablet, mobile, desktop, desktopHD },
} = layout;

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  padding-left: 25%;
  padding-right: 25%;
  @media (max-width: ${desktopHD}px) {
    padding-left: 20%;
    padding-right: 20%;
  }
  @media (max-width: ${desktop}px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (max-width: ${tablet}px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media (max-width: ${mobile}px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const navHeaderContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const headerContainerStyles = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const titlePostedJobIdContainerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  gap: 8px;
  @media (max-width: ${mobile}px) {
    gap: 16px;
  }
`;

export const bookmarkApplyContainerStyles = css`
  display: flex;
  flex-direction: row;
  height: 46px;
  gap: 8px;
`;

export const subheaderContentContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const detailGridContainerStyles = css`
  display: grid;
  grid-auto-rows: 1fr;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
  gap: 8px;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: ${mobile}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const JobBoardShowContent = () => {
  const { props } = usePage<JobShowProps>();

  const {
    session: { current_user, signed_in },
    company,
    recruiter,
  } = props;

  const [
    {
      id,
      posted_at,
      title,
      description,
      requirements,
      is_favorite,
      has_applied,
      // Details
      profession,
      assignment,
      facility,
      board_certification,
      specialty,
      state,
      min_hourly_rate,
      employment_type,
      recruiter_id,
      credentialing_time,
      target_start_date,
      any_state_licenses,
    },
    dispatch,
  ] = useReducer(reducer, { ...initialState, ...props.job });

  const { ModalComponent, openModal, closeModal } = useModal();

  const isThereCurrentUser = !!current_user;

  const isAdmin = current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  // PROVIDER
  const isAProvider = isThereCurrentUser && current_user.is_type === 'provider';
  const providerId = (isThereCurrentUser && current_user.id) || -1;
  const canFavoritedAndApply = !isSignedIn || isAProvider;

  useEffect(() => {
    const applied = localStorage.getItem(`${id}`);
    dispatch({
      type: 'SET_HAS_APPLIED',
      payload: has_applied || applied === 'true',
    });
  }, []);

  const onClickBack = useCallback(() => {
    window.history.back();
  }, []);

  const setHasApplied = useCallback(() => {
    dispatch({ type: 'SET_HAS_APPLIED', payload: true });
    localStorage.setItem(`${id}`, 'true');
    closeModal();
  }, []);

  const onClickFavorited = useCallback(async () => {
    if (is_favorite) {
      // UNFAVORITED
      const unfavoritedResponse = await unfavoriteJob({
        job_id: id,
      });
      if (!!unfavoritedResponse && unfavoritedResponse.success) {
        dispatch({ type: 'SET_IS_FAVORITED', payload: false });
      }
    } else {
      //FAVORITED
      const favoritedResponse = await favoriteJob({
        job_id: id,
        provider_id: providerId,
      });
      if (favoritedResponse && favoritedResponse.saved_job.id) {
        dispatch({ type: 'SET_IS_FAVORITED', payload: true });
      }
    }
  }, [dispatch, providerId, is_favorite]);

  const url = `https://www.locums.com/jobs/${props.job.id}`;

  return (
    <>
      <head>
      <meta prefix="og: https://www.locums.com/jobs/" content={`${props.job.id}`} />
        <title>Locums - Job {props.job.id}</title>
        <meta
          property="og:description"
          content="Advance Your Career: Explore a wide range of healthcare job opportunities with Locums.com Connect with top recruiters and find your dream job today!"
        />
        <meta
          property="og:image"
          content="https://locums-public.s3.us-west-2.amazonaws.com/locums-logo.png"
        />
      </head>
      <div {...{ css: containerStyles }}>
        <div {...{ css: navHeaderContainerStyles }}>
          <BackComponent
            {...{
              onClickBack,
              backArrowType: 'arrow',
              backText: 'Back to Results',
            }}
          />
          <div {...{ css: headerContainerStyles }}>
            <div {...{ css: titlePostedJobIdContainerStyles }}>
              <Heading {...{ children: title, variant: 'h1Small' }} />
              <JobPostedId {...{ id, posted_at }} />
            </div>
            {/*  */}
            {canFavoritedAndApply && (
              <div {...{ css: bookmarkApplyContainerStyles }}>
                <>
                  <ShareButton
                    {...{
                      linkedinUrl: url,
                      emailUrl: url,
                      facebookUrl: url,
                      xUrl: url,
                      hasCardWrap: true,
                    }}
                  />
                  <BookMarked
                    {...{
                      id,
                      isActive: is_favorite,
                      hasCardWrap: true,
                      ...(canFavoritedAndApply
                        ? { action: onClickFavorited }
                        : {}),
                    }}
                  />
                  <ApplyButton
                    {...{
                      ...(canFavoritedAndApply ? { onClick: openModal } : {}),
                      hasApplied: !!has_applied && has_applied,
                    }}
                  />
                </>
              </div>
            )}
          </div>
        </div>

        <div {...{ css: subheaderContentContainerStyles }}>
          <JobShowDescriptionHeader {...{ text: 'Details' }} />
          <div
            {...{
              css: detailGridContainerStyles,
            }}
          >
            <JobDetails
              {...{
                job: {
                  profession,
                  assignment,
                  facility,
                  board_certification,
                  employment_type,
                  specialty,
                  state,
                  min_hourly_rate,
                  recruiter_id,
                  credentialing_time,
                  target_start_date,
                  any_state_licenses,
                },
              }}
            />
          </div>
        </div>

        {!!description && (
          <JobDescDetail
            {...{
              header: 'Description',
              detail: description,
            }}
          />
        )}
        {!!requirements && (
          <JobDescDetail
            {...{
              header: 'Requirements',
              detail: requirements,
            }}
          />
        )}
        <div
          {...{
            css: css`
              display: flex;
              align-items: flex-start;
            `,
          }}
        >
          <JobShowRecruiterCard {...{ company, recruiter }} />
        </div>
      </div>

      <ModalComponent {...{ position: 'top' }}>
        <ApplyModalContent
          {...{
            modalProps: { closeModal },
            setHasApplied,
            job_id: props.job.id,
          }}
        />
      </ModalComponent>
    </>
  );
};

export default JobBoardShowContent;
