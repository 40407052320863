import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconVectorRight = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M4.75 12h16m0 0l-4-4m4 4l-4 4"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    [props]
  );

const IconVectorLeft = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          stroke={iconColor || appColors.content.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M20.75 12h-16m0 0 4 4m-4-4 4-4"
        />
      </svg>
    ),
    [props]
  );

const IconVectorUp = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        {...props}
      >
        <path
          stroke={iconColor || appColors.content.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12.75 20V4m0 0-4 4m4-4 4 4"
        />
      </svg>
    ),
    [props]
  );

const IconVectorDown = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        {...props}
      >
        <path
          stroke={iconColor || appColors.content.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12.75 4v16m0 0 4-4m-4 4-4-4"
        />
      </svg>
    ),
    [props]
  );

export { IconVectorRight, IconVectorLeft, IconVectorUp, IconVectorDown };
