import { memo } from 'react';
import { css } from '@emotion/react';

import { LogoWhiteBlue } from '@/components/Icons/Logo';

const AdminPanelFooter = () => {
  return (
    <div
      {...{
        css: css`
          height: 28px;
        `,
      }}
    >
      <LogoWhiteBlue />
    </div>
  );
};

export default memo(AdminPanelFooter);
