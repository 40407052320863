import IMask from 'imask';

const phoneMask = IMask.createMask({
  mask: '(000) 000-0000',
  // placeholderChar: '0',
  lazy: true,
});

const zipCodeMask = IMask.createMask({
  mask: [
    {
      mask: '00000',
    },
    {
      mask: '00000-0000',
    },
  ],
  lazy: true,
});

const npiMask = IMask.createMask({
  mask: '0000000000',
  lazy: true,
});

const masks = {
  phone: phoneMask,
  zip: zipCodeMask,
  npi_state_license_number: npiMask,
};

export { masks };
