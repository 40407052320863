import { FC, memo, useCallback, useState } from 'react';
import Button, { ButtonProps } from '../Button';

import Modal from '../Modal';
import styled from '@emotion/styled';
import layout from '@/constants/layout';
import UploadCSVFlowModalContent from './Recruiter/UploadCSV/UploadCSVFlowModalContent';

const {
  windowSizes: { mobile },
} = layout;

const SearchHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

interface UploadCSVButtonProps {
  buttonsProps?: ButtonProps;
}

const UploadCSVButton: FC<UploadCSVButtonProps> = ({ buttonsProps }) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const onClickUpload = useCallback(() => {
    openModal();
  }, []);

  return (
    <>
      <SearchHeaderButtonsContainer>
        <Button
          {...{
            ...buttonsProps,
            children: 'Upload CSV',
            variant: '2',
            onClick: onClickUpload,
          }}
        />
      </SearchHeaderButtonsContainer>
      {showModal && (
        <Modal {...{ position: 'center', onClose: closeModal }}>
          <UploadCSVFlowModalContent
            {...{ modalLayoutProps: { closeModal: closeModal } }}
          />
        </Modal>
      )}
    </>
  );
};

export default memo(UploadCSVButton);
