import { ComponentProps, FC } from 'react';
import styled from '@emotion/styled';
import Select from 'react-select';

import {
  INPUT_COMMONS,
  Input,
  StyledErrorMessage,
  StyledInputWrapper,
  StyledLabel,
} from '../Input';
import MenuList from './MenuList';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import DropdownIndicator from './DropdownIndicator';
import IndicatorsContainer from './IndicatorsContainer';
import { paragraphDesktop } from '@/constants/typography';
import { css } from '@emotion/react';

const BORDER_RADIUS_MENU = 10;
const OPTION_HEIGHT = 54;
const OPTION_PADDING = 16;

const requiredLabel = css`
  color: red;
`;

const {
  mediaQuery,
  components: {
    selects: { multiProps },
  },
} = layout;

export const optionStyles = ({
  isFocused,
  isSelected,
}: {
  isFocused: boolean;
  isSelected: boolean;
}) => ({
  ...paragraphDesktop.md,
  height: OPTION_HEIGHT,
  padding: OPTION_PADDING,
  margin: 0,
  backgroundColor:
    isFocused || isSelected ? appColors.notInDesignSystem[6] : 'white',
  color:
    isFocused || isSelected
      ? appColors.content.primary
      : appColors.content.secondary,
  cursor: 'pointer',
});

export const singleValueStyles = {
  color: INPUT_COMMONS.TEXT_COLOR_DEFAULT,
  ...paragraphDesktop.md,
  [mediaQuery.mobile]: {
    ...paragraphDesktop.sm,
  },
};

export const menuListStyles = {
  padding: 0,
  margin: 0,
  borderRadius: BORDER_RADIUS_MENU,
  maxHeight: OPTION_HEIGHT * 4,
};

export const menuStyles = {
  outline: 1,
  padding: 0,
  marginTop: 7,
  borderRadius: BORDER_RADIUS_MENU,
  border: `1px solid ${appColors.border.primary}`,
  boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.08)',
  transition: 'background-color 0.2s ease',
};

export const valueContainerStyles = {
  flexWrap: 'nowrap',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: 4,
  height: INPUT_COMMONS.HEIGHT - INPUT_COMMONS.PADDING,
  padding: 0,
  margin: 0,
};

export interface InputDropdownProps extends Input, ComponentProps<Select> {
  readonly labelHint?: string;
}

export const StyledInputDropdown = styled(Select)<InputDropdownProps>`
  outline: none;
  width: 100%;
  border: none;
`;

const InputDropdown: FC<InputDropdownProps> = ({
  label,
  required,
  labelHint,
  options,
  error,
  ...props
}) => {
  return (
    <StyledInputWrapper>
      <StyledLabel
        {...{
          children: label,
          style: {
            ...(props.isDisabled
              ? {
                  color: appColors.content.disabled,
                }
              : {}),
          },
        }}
      >
        {label} <span {...{ css: requiredLabel }}>{required ? '*' : ''}</span>
        {!!labelHint && (
          <Paragraph
            {...{
              children: `  ${labelHint}`,
              variant: 'sm',
              style: {
                color: appColors.content.secondary,
                flexWrap: 'wrap',
              },
            }}
          />
        )}
      </StyledLabel>

      <StyledInputDropdown
        {...{
          isClearable: true,
          isSearchable: false,
          ...(props.isMulti ? multiProps : {}),
          // menuShouldScrollIntoView: true,
          ...props,
          styles: {
            container: (provided, {}) => ({
              ...provided,
              ...INPUT_COMMONS.TEXT_STYLE,
              padding: 0,
              margin: 0,
              outline: 0,
              border: `0 !important`,
            }),
            control: (provided, {}) => {
              return {
                ...provided,
                flexGrow: 0,
                flexWrap: 'nowrap',
                ...INPUT_COMMONS.TEXT_STYLE,
                lineHeight: 0,
                height: INPUT_COMMONS.HEIGHT,
                backgroundColor: INPUT_COMMONS.BACKGROUND_COLOR,
                padding: 0,
                margin: 0,
                borderRadius: INPUT_COMMONS.BORDER_RADIUS,
                borderWidth: INPUT_COMMONS.BORDER_WIDTH,
                borderStyle: 'solid',
                borderColor: INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED,
                paddingLeft: INPUT_COMMONS.PADDING,
                paddingRight: 8,
                '&:hover': {
                  outline: `0px !important`,
                },
                '&:focus': {
                  borderColor: INPUT_COMMONS.PLACEHOLDER_COLOR_FOCUS,
                },
                '&:disabled': {
                  borderColor: INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED,
                },
                ...(error
                  ? {
                      borderColor: INPUT_COMMONS.ERROR_COLOR,
                      '&:focus': {},
                    }
                  : {}),
              };
            },
            valueContainer: (provided) => ({
              ...provided,
              ...valueContainerStyles,
              width: 100,
            }),
            singleValue: (provided, { isDisabled }) => ({
              ...provided,
              ...singleValueStyles,
              ...(isDisabled
                ? {
                    color: INPUT_COMMONS.TEXT_COLOR_DISABLED,
                  }
                : {}),
              ...(error
                ? {
                    color: INPUT_COMMONS.ERROR_COLOR,
                  }
                : {}),
            }),
            placeholder: (provided, { isDisabled }) => {
              return {
                ...provided,
                ...paragraphDesktop.md,
                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                ...(isDisabled
                  ? {
                      color: INPUT_COMMONS.PLACEHOLDER_COLOR_DISABLED,
                    }
                  : {}),
                ...(error
                  ? {
                      color: INPUT_COMMONS.ERROR_COLOR,
                    }
                  : {}),
              };
            },
            option: (provided, { isSelected, isFocused }) => ({
              ...provided,
              ...optionStyles({ isFocused, isSelected }),
            }),
            menuList: (base) => ({
              ...base,
              ...menuListStyles,
            }),

            multiValueLabel: (base) => ({
              ...base,
              ...paragraphDesktop.sm,
            }),

            menu: (base) => ({
              ...base,
              ...menuStyles,
            }),
            indicatorSeparator: (base) => ({
              ...base,
              display: 'none',
            }),
            ...props.styles,
          },
          components: {
            MenuList: MenuList,
            IndicatorsContainer: IndicatorsContainer,
            DropdownIndicator: (props) => (
              <DropdownIndicator {...{ ...props, hasError: !!error }} />
            ),

            ...props.components,
          },
          options,
        }}
      />
      {!!error?.message && (
        <StyledErrorMessage
          {...{
            children: error?.message,
            style: {
              color: INPUT_COMMONS.ERROR_COLOR,
            },
          }}
        />
      )}
    </StyledInputWrapper>
  );
};

export default InputDropdown;
