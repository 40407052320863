import { createSlice } from '@reduxjs/toolkit';

import { dates } from '@/utils/dates';
import { SelectOption } from '@/types/types';
import { facilityHash } from '@/constants/filters/facility';
import { fetchDropdownDataThunk } from './dropdowns-actions';
import { specialtyHash } from '@/constants/selects/specialty';
import { GetDropdownDataResponse } from '@/types/api/responses';
import { professionHash } from '@/constants/filters/profession';
import { employerTypeHash } from '@/constants/selects/employerType';
import { assignmentTypeHash } from '@/constants/selects/assignmentType';
import { employmentTypeHash } from '@/constants/filters/employmentType';

type DropdownState = {
  dropdownsHashes: GetDropdownDataResponse;
  dropdownOptions: Record<keyof GetDropdownDataResponse, SelectOption[]>;
};

const initialState: DropdownState = {
  dropdownsHashes: {
    agency: {},
    facility: { ...facilityHash },
    specialty: { ...specialtyHash },
    profession: { ...professionHash },
    employment: { ...employerTypeHash },
    assignment: { ...assignmentTypeHash },
    date: {
      ...dates,
    },
    employer_type: {},
  },
  dropdownOptions: {
    agency: [],
    employer_type: [],
    facility: Object.values(facilityHash).map((item) => item),
    specialty: Object.values(specialtyHash).map((item) => item),
    profession: Object.values(professionHash).map((item) => item),
    employment: Object.values(employmentTypeHash).map((item) => item),
    assignment: Object.values(assignmentTypeHash).map((item) => item),
    date: Object.values(dates).map((item) => item),
  },
};

export const dropdownsSlice = createSlice({
  name: 'dropdowns',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetchDropdownDataThunk //

    builder.addCase(fetchDropdownDataThunk.fulfilled, (state, { payload }) => {
      state.dropdownsHashes = payload;
      Object.entries(payload).forEach(([key, value]) => {
        const list = Object.values(value)?.map((item) => item) || [];
        state.dropdownOptions[key] = list;
      });
    });
  },
});

export const {} = dropdownsSlice.actions;

export default dropdownsSlice.reducer;
