import { SVGProps, useMemo } from 'react';

const SignUp = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        id="Graphics"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1080 1080"
        width="250px"
      >
        <defs>
          <filter id="drop-shadow-1" filterUnits="userSpaceOnUse">
            <feOffset dx="7" dy="7" />
            <feGaussianBlur result="blur" stdDeviation="4" />
            <feFlood flood-color="#000" flood-opacity=".24" />
            <feComposite in2="blur" operator="in" />
            <feComposite in="SourceGraphic" />
          </filter>
        </defs>
        <path
          fill= "#09091a"
          stroke-width="0px"
          d="M1049.25,608.68c-27.48,123.68-143.09,207.27-269.14,194.6l-437.18-43.94c-84.67,2.05-169.94-29.72-233.82-95.07-122.09-124.89-119.82-325.09,5.06-447.18,72.82-71.19,171.24-100.1,264.86-87.07,21.5,2.28,43.12,7.43,64.37,15.68l452.24,175.6c115.15,44.72,180.4,166.8,153.61,287.38Z"
        />
        <circle
          fill="none"
          stroke="#fff"
          stroke-miterlimit="10"
          stroke-width="9px"
          cx="435.07"
          cy="433.52"
          r="185.93"
        />
        <path
          fill= "#1a54ff"
          stroke-width="0px"
          d="M667.56,505.48h-33.04l-46.59,5.93-228.71,51.67,20.33,64.38c2.73,2.87,9.86,9.59,21.36,12.33,9.85,2.35,18,.61,21.84-.47,50.54-12.99,101.08-25.98,151.62-38.96l11.86,111.81-2.6,70.98,197.42,19.66,10.16-83.86c7.89,28.54,15.79,57.08,23.68,85.62,8.56-.41,18.01-1.28,28.16-2.87,9.31-1.46,17.85-3.3,25.56-5.3-13.38-80.31-26.76-160.62-40.14-240.92-.63-3.39-2.89-13.03-11.48-21.27-8.14-7.81-17.25-9.83-20.71-10.42l-53.36-14.05c1.62,3.09,3.77,8.14,4.42,14.68.45,4.51.05,8.36-.47,11.2-1.68,8.84-4.05,18.63-7.44,29.08-4.63,14.33-10.11,26.66-15.44,36.89-8.5-8.01-18.11-18.28-27.58-31.06-9.29-12.55-16.22-24.51-21.36-34.82-2.12-4.28-4.43-9.98-5.93-16.94-1.07-4.98-1.49-9.48-1.6-13.27Z"
        />
        <path
          fill= "#e2a8a9"
          stroke-width="0px"
          d="M725.54,610.04l28.8-83.01c-3.85-4.16-9.46-11.23-13.55-21.36-4.12-10.21-4.98-19.26-5.08-24.94-.51-7.3-.42-16.79,1.69-27.58,1.33-6.77,3.18-12.67,5.08-17.6,10.16-24.66,20.33-49.32,30.49-73.98l-60.99-50.82-87.53,7.91c-1.68,14.07-2.88,29.33-3.29,45.65-.66,26.11.81,49.85,3.29,70.68,14.31,19.58,28.61,39.15,42.92,58.73l-4.52,42.35,62.68,73.98Z"
        />
        <path
        fill= "#e2a8a9"
        stroke-width="0px"
          d="M758.11,375.68l-10.89,40s-16.57,36.22-26.61,46.56c-.01.01-.01.02-.02.02-2.86,4.2-6.11,6.81-8,8.17-2.97,2.38-6.92,5.46-11.69,8.89-2.19,1.58-5.59,3.85-12.39,8.39-9.04,6.04-12.14,8.02-17.09,9.1-2.36.5-4.35.64-5.68.68-9.07.08-18.14.15-27.21.23-1.36.08-5.05.11-8.72-2.27-2.47-1.6-3.87-3.61-4.55-4.78-.73-2.14-1.43-4.55-2.03-7.19-.63-2.78-1.04-5.37-1.3-7.71-.62-9.19-1.24-18.37-1.86-27.55-1.22-13.63-1.06-120.06-1.06-120.06l29-10.61,72.89,12.14,3.56,47.98,6.32-7.99,27.33,6Z"
        />
        <path
        fill= "#5e5e6c"
        stroke-width="0px"
          d="M795.86,343.15c-.03.15-.08.29-.13.43-1.26,3.61-3.11,7.93-5.85,12.54-2.98,5-6.17,8.93-8.93,11.88-.14.15-.26.29-.37.46-8.29,12.12-16.83,25.95-25.05,41.5-5.97,11.28-11.07,22.15-15.44,32.42-.97-3.73-1.83-8.17-2.26-13.22-.52-6.13-.26-11.55.27-15.98.07-.61.28-1.18.62-1.69,3.97-6.02,7.94-12.04,11.91-18.05.5-.68,2.91-4.05,2.26-8.71-.46-3.29-2.2-5.5-3.21-6.57-.35-.35-.75-.64-1.2-.84-1.74-.78-6.09-2.31-11.21-.68-5.28,1.68-7.97,5.63-8.91,7.28-.21.37-.35.77-.43,1.19-.72,3.8-1.36,7.84-1.89,12.11l-13.49-1.8c4.96-15.39,6.26-28.95,6.46-38.57.08-1.46.37-9.56-5.84-16.35-6.08-6.66-13.97-7.23-15.81-7.29-.13-.01-.25-.01-.37-.03-9.84-1.29-22.73-2.04-37.65-.4-13.93,1.53-25.73,4.72-34.97,7.97-2.45.87-5.03-.83-5.23-3.42-.69-8.78-.17-22.53,7.3-36.27,2.28-4.17,4.83-7.68,7.34-10.59,1-1.16,2.58-1.64,4.06-1.21,1.37.39,3.04,1.15,4.56,2.63.08.07.15.15.23.23,1.84,1.9,4.99,1.54,6.26-.78,1.5-2.74,3.62-5.69,6.64-8.35,2.17-1.92,4.36-3.3,6.31-4.3,1.39-.7,3.05-.51,4.25.48.92.76,1.9,1.69,2.85,2.83.32.39.62.77.89,1.16,1.61,2.22,4.96,2.17,6.45-.14,1.42-2.2,3.38-4.56,6.07-6.66,2.9-2.27,5.81-3.59,8.13-4.36,1.45-.49,3.07-.07,4.1,1.08.68.75,1.36,1.6,2.03,2.57.88,1.28,1.58,2.56,2.14,3.77,1.09,2.38,4.11,3.08,6.11,1.39,2.19-1.84,5.06-3.75,8.69-5.16,3.15-1.22,6.07-1.75,8.45-1.95,1.7-.15,3.29.82,3.95,2.4.53,1.26,1.04,2.72,1.44,4.35.32,1.34.53,2.61.66,3.79.26,2.48,2.76,4.08,5.13,3.28,3.76-1.27,8.04-2.42,12.79-3.26,2.91-.51,5.66-.84,8.24-1.04,1.88-.15,3.59,1.09,4.08,2.91.34,1.22.57,2.69.53,4.34-.03,1.21-.21,2.33-.47,3.33-.67,2.63,1.31,5.15,4.02,4.99,2.92-.16,6.24-.09,9.86.44,3.05.44,5.77,1.14,8.11,1.9,2.05.67,3.16,2.86,2.52,4.92-.76,2.43-1.58,4.91-2.44,7.44-1.03,3.01-2.09,5.93-3.16,8.76-.87,2.28.52,4.79,2.92,5.25,1.19.23,2.45.53,3.77.95,3.88,1.22,6.88,2.95,8.96,4.39.33.23.61.49.85.81.68.88,1.57,2.31,2.05,4.26.56,2.27.29,4.19.05,5.24Z"
        />
        <path
         fill= "#e2a8a9"
         stroke-width="0px"
          d="M397.35,618.18l22.74-7.67c.27-.09.53-.21.77-.37,3.92-2.52,9.11-6.51,14.04-12.48,3.83-4.64,6.41-9.16,8.13-12.82.23-.48.56-.91.98-1.24,4.67-3.72,9.34-7.44,14.01-11.15.44-.35.8-.8,1.02-1.32.48-1.14,1.09-3.22.43-5.65-.77-2.87-2.81-4.4-3.39-4.8-1.03-1.06-3.68-3.49-7.91-4.52-4.39-1.07-7.98-.02-9.46.51-.1.04-.19.08-.29.12-5.13,2.4-10.27,4.8-15.4,7.21-1.2.56-2.61.4-3.63-.43-4.5-3.66-9.18-7.9-13.85-12.77-14.21-14.83-23.15-30.16-28.8-42.35-.69-1.43-2.93-5.58-7.91-8.19-3.49-1.83-6.8-2.1-8.84-2.06-.89.02-1.73.37-2.37,1-1.18,1.17-2.88,3.3-3.47,6.42-.91,4.78,1.42,8.43,2.13,9.42.1.14.18.28.26.43,3.21,6.35,6.41,12.69,9.62,19.04,1.23,2.43-.69,5.19-3.41,5-.04,0-.08,0-.12,0-3.64-.22-6.34,1.19-7.43,1.86-.13.08-.25.16-.37.26-.98.81-3.04,2.78-4.06,6.07-.37,1.18-.53,2.3-.57,3.3-.07,1.94-1.74,3.43-3.67,3.29-1.02-.08-2.16-.04-3.38.19-3.45.65-5.71,2.5-6.74,3.49-.21.2-.39.42-.54.67-.86,1.42-1.94,3.66-2.33,6.57-.28,2.06-.14,3.95.19,5.59.43,2.15-1.18,4.24-3.37,4.21-.43,0-.88.02-1.34.07-3.26.4-5.36,2.22-6.2,3.08-.21.21-.37.44-.52.7-.8,1.4-2.14,4.27-2.03,8.09.1,3.57,1.41,6.2,2.17,7.47.06.11.13.2.2.3,7.65,10.26,15.3,20.53,22.95,30.79.06.08.12.16.19.23,1.63,1.82,6.86,7.16,15.15,8.08,3.56.39,6.58-.15,8.68-.74.24-.07.47-.17.69-.29,8.84-4.79,17.67-9.57,26.51-14.36.17-.09.35-.17.54-.24Z"
        />
        <path
        fill= "#7095ff"
        opacity= ".53"
        stroke-width="0px"
          d="M707.35,646.11l-484.15-47.03c-7.73-.75-13.76-7.02-14.21-14.78l-16.46-284.89c-.45-7.78,5.87-14.28,13.67-14.04l480.23,14.91c8.28.26,15,6.79,15.49,15.07l18.65,318.03c.43,7.4-5.84,13.44-13.22,12.72Z"
        />
        <path
        fill= "#cedaff"
        stroke-width="0px"
          d="M473.52,427.88h-61.98c-3,0-5.43,2.43-5.43,5.43v49.84c0,3,2.43,5.43,5.43,5.43h61.98c3,0,5.43-2.43,5.43-5.43v-49.84c0-3-2.43-5.43-5.43-5.43ZM472.18,477.5c0,3-2.44,5.43-5.44,5.43h-48.43c-3,0-5.43-2.43-5.43-5.43v-38.54c0-3,2.43-5.44,5.43-5.44h48.43c3,0,5.44,2.44,5.44,5.44v38.54Z"
        />
        <path
          fill= "#cedaff"
          stroke-width="0px"
          d="M420.22,401.05v26.83h-4.66l.27-24.42c-.11-2.79-.78-5.24-1.83-7.37-1.88-3.79-3.93-6.68-5.31-7.68-.67-.49-5.18-3.39-9.7-2.98-8.29.76-15.27,6.64-15.54,21.06l-3.59-.07c-2.93-14.16,7.49-26.61,18.91-27.11,10.32-.45,20.91,8.86,21.46,21.74Z"
        />
      </svg>
    ),
    [props]
  );

const PostYourJobs = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        id="Graphics"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1080 1080"
        width="250px"
      >
        <g id="Send">
          <path
            fill="#09091a"
            stroke-width="0px"
            d="M841.51,1020.25c-114.08,74.43-265.78,51.8-353.17-52.67l-303.06-362.35c-66.63-62.06-108.3-150.57-108.3-248.82C76.97,168.65,229.18,16.44,416.95,16.44c109.48,0,206.88,51.74,269.05,132.12,14.82,17.91,27.57,38.12,37.71,60.43l215.69,474.88c54.92,120.92,13.33,263.81-97.89,336.38Z"
          />
          <path
            fill="#0a52e2"
            stroke-width="0px"
            d="M820.43,666.23c-36.04-3.32-77.65-3.94-123.36,1.29-14.37,1.64-28.12,3.75-41.21,6.18-34.85-39.32-69.71-78.63-104.56-117.95l-53.57,41.21,95.29,135.46c4.14,6.06,10.58,14.06,20.09,21.63,7.73,6.16,15.19,10.21,21.12,12.88,62.07-33.57,124.13-67.13,186.2-100.7Z"
          />
          <path
            fill="#e2a8a9"
            stroke-width="0px"
            d="M704.79,639.7c4.25,7.27,9.61,18.32,12.75,32.64,3.02,13.75,2.9,25.58,2.19,33.8,1.5,1.12,4.47,3.03,8.69,3.77,3.32.58,6.09.21,7.79-.16,8.38-1.1,20.98-3.76,34.7-10.85,11.91-6.14,20.49-13.46,26.08-19.03.46-1.21,1.06-3.29.87-5.89-.12-1.61-.5-2.94-.87-3.9-2.43-5.2-5.8-14.16-6.18-25.75-.31-9.44,1.49-17.13,3.09-22.15,9.61-11.16,19.23-22.32,28.84-33.48-12.48-29.4-37.63-75.38-86.79-109.23-10.61-7.31-21.12-13.21-31.16-18-12.15,16.16-30.93,45.79-38.76,87.14-4.51,23.82-4.07,44.62-2.45,60.17.22,2.21,1.76,15.23,13.46,24.24,12.11,9.32,25.64,7.05,27.75,6.66Z"
          />
          <path
            fill="#1a54ff"
            stroke-width="0px"
            d="M476.23,694.81l71.05-10.82s9.3,93.1,9.69,98.12c13.85-9.38,32.19-21.34,54.21-34.51,52.06-31.13,92.85-55.14,148.34-70.05,24.19-6.5,45.22-9.67,60.91-11.33,2.67.19,9.07,1.01,15.32,5.67,7.34,5.46,9.83,12.74,10.56,15.19,3.68,13.52,7.05,29.52,9.01,47.64,2.34,21.57,2.11,40.76.9,56.66-12.36,80.35-24.72,160.7-37.09,241.06-17.73,10.93-52.82,29.28-100.83,33.48-41.12,3.6-74.13-4.72-93.1-11.07,8.76-66.7,17.51-133.4,26.27-200.11-18.32,6.22-32.86,11.27-41.85,14.42-38.3,13.41-50.8,18.39-70.57,20.09-13.34,1.14-24.33.31-31.29-.52-3.5-1.23-10.05-4.05-16.1-10.3-6.64-6.87-9.18-14.27-10.17-17.9-3.39-21.53-6.07-45.36-7.34-71.21-1.72-34.97-.52-66.74,2.06-94.52Z"
          />
          <path
            fill="#5e5e6c"
            stroke-width="0px"
            d="M703.25,463.32l31.1-58.33c.38-.96,2.98-7.28,9.82-9.56,4.28-1.43,8-.54,9.5-.1,3.63,1.87,7.25,3.73,10.88,5.6,1.46.46,3.56.93,6.08.87,4.31-.1,7.54-1.66,9.17-2.61,7.66-3.41,15.32-6.82,22.99-10.24,1.48-.55,11.5-4.11,20.96,1.45,5.05,2.97,7.66,7.19,8.79,9.37,1.22,8.31,2.45,16.61,3.67,24.92.01.51.32,7.52,6.28,11.11,4.33,2.61,8.77,1.68,9.75,1.45,12.68-.13,25.37-.26,38.05-.39.76.08,7.79.97,11.43,7.31,2.73,4.76,1.78,9.5,1.51,10.66-.45,3.34-1.16,7.09-2.29,11.11-1.75,6.27-4.03,11.54-6.21,15.74-.45,1.8-1.03,5.21-.1,9.3,1.23,5.36,4.34,8.71,5.7,10.01,2.01.99,7.42,3.97,10.91,10.46,4.28,7.96,2.68,15.62,2.22,17.54-5.95,9.81-14,20.89-24.85,31.81-29.16,29.31-62.33,41.07-81.19,46.03-5.25,1.96-13.08,4.35-22.86,5.22-6.01.53-11.38.37-15.9-.06-3.89-.12-14.29-.96-24.27-8.43-12.33-9.23-15.45-22.17-16.16-25.56-.25-1.73-.48-4.95.71-8.69,1.37-4.33,3.91-7.07,5.22-8.31,4.03-4.46,8.07-8.93,12.1-13.39.35-.26,4.98-3.75,4.7-9.72-.26-5.5-4.47-8.49-4.96-8.82-.74-.35-2.66-1.12-5.15-.77-2.41.34-4,1.53-4.64,2.06-7.64,8.41-15.28,16.83-22.92,25.24-1.67-2.87-4.34-8.34-4.89-15.71-.67-8.92,2.12-15.74,3.61-18.8,5.41-6.18,10.82-12.36,16.22-18.54,1.28-1.66,3.41-4.9,4.38-9.53,1.09-5.25.1-9.54-.52-11.59-2.42-3.07-6.33-7.31-12.1-11.07-6.52-4.25-12.71-6.13-16.74-7.02Z"
          />
          <path
            fill="#fff"
            stroke-width="0px"
            d="M538.28,561.13l-69.66,12.4c-3.8.68-7.4-1.92-7.97-5.73l-25.21-169.51c-.42-2.84,1.73-5.42,4.61-5.5l70.1-1.99c3.5-.1,6.53,2.44,7.03,5.9l23.65,160.89c.25,1.67-.88,3.24-2.55,3.53Z"
          />
          <path
            fill="#e2a8a9"
            stroke-width="0px"
            d="M494.93,694.43v-32.45l-18.54-46.87c-.99-1.77-2.36-4.76-2.74-8.69-.6-6.26,1.69-11.04,2.74-12.94,5.32-7.04,10.64-14.08,15.97-21.12l15.45-17.51-9.27-47.9c-.23-1.1-.71-4.15.77-7.56,1.31-3,3.43-4.67,4.38-5.32.7-.17,2.76-.57,4.99.45,3.31,1.52,4.17,4.89,4.28,5.34,5.84,19.7,11.68,39.41,17.51,59.11.87,2.28,3.2,3.64,5.5,3.28,2.51-.38,4.4-2.69,4.28-5.34-3.9-28.11-7.8-56.21-11.7-84.32.02-1.07.21-5.66,3.86-9.46,3.02-3.14,6.62-3.88,7.84-4.08.75,1,2.08,3.06,2.59,6.01.54,3.14-.13,5.67-.53,6.87.56,10.33,1.13,20.66,1.69,30.99.93,2.13,2.03,4.87,3.09,8.11,1.89,5.75,2.86,10.24,2.95,11.37l1.26,17.09,6.76-1.93c.55.14,2.41.71,3.67,2.54,1.82,2.65.79,5.64.68,5.96v59.72c-1.55,7.32-3.97,16.03-7.89,25.5-4.28,10.37-9.21,18.88-13.65,25.5.94,10.82,1.89,21.63,2.83,32.45l-48.77,5.22Z"
          />
          <path
            fill="#e2a8a9"
            stroke-width="0px"
            d="M445.5,448.76c-.74-1.01-1.86-2.5-3.28-4.25-2.87-3.53-4.34-5.32-5.92-6.15-1.06-.56-4.03-1.83-9.14-.13-.62,1.05-1.79,3.35-1.87,6.5-.09,3.33,1.09,5.79,1.67,6.86,6.18,8.16,12.36,16.31,18.54,24.47-.81-.17-2.43-.41-4.35.13-2.21.62-3.58,1.91-4.15,2.51-.43.78-1.26,2.52-1.16,4.83.15,3.45,2.27,5.64,2.8,6.17,1.77,1.63,3.54,3.27,5.31,4.9-.83.05-1.85.19-2.99.52-1.48.43-2.65,1.03-3.48,1.55-.47.57-1.89,2.45-1.93,5.22-.05,3.53,2.21,5.79,2.61,6.18,3.05,3.15,6.1,6.31,9.14,9.46-.84-.02-1.89.04-3.06.29-1.81.39-3.22,1.08-4.15,1.64-.35.52-2.07,3.22-1.35,6.76.59,2.94,2.5,4.63,3.09,5.12,5.43,5.61,10.86,11.22,16.29,16.84.87.35,2.8.97,5.25.64,2.38-.32,4.04-1.38,4.8-1.93.39-.5,1.8-2.44,1.64-5.22-.13-2.23-1.2-3.76-1.64-4.31-2.83-3.26-5.67-6.52-8.5-9.79,1.1.44,3.36,1.17,6.21.84,2.22-.26,3.89-1.05,4.86-1.61.26-.21,2.13-1.77,2.16-4.44.03-2.75-1.91-4.37-2.16-4.57-6.52-6.35-13.05-12.71-19.57-19.06.65.14,2.2.4,4.01-.23,1.93-.66,3.02-1.94,3.43-2.46.32-.62,1.02-2.22.77-4.3-.19-1.6-.86-2.74-1.26-3.32-3.79-4.23-7.58-8.46-11.36-12.68l-1.26-26.98Z"
          />
          <g>
            <path
              fill="#fff"
              stroke-width="0px"
              d="M416.25,200.44l-92.24-38.34c-3.82-1.59-7.91,1.56-7.37,5.65l12.92,99.06c.53,4.1,5.31,6.07,8.58,3.57l32.52-40.79,46.81-19.93c3.28-2.52,2.61-7.63-1.2-9.22ZM399.13,208.42l-33.82,14.41-23.51,29.46c-2.37,1.82-5.81.38-6.2-2.57l-9.33-71.57c-.4-2.97,2.57-5.24,5.32-4.09l66.66,27.71c2.76,1.15,3.24,4.84.88,6.66Z"
            />
            <rect
              fill="#fff"
              stroke-width="0px"
              x="355.5"
              y="189.81"
              width="10.82"
              height="53.57"
              rx="3.95"
              ry="3.95"
              transform="translate(-53.55 290.02) rotate(-41.02)"
            />
          </g>
        </g>
      </svg>
    ),
    [props]
  );

const InstantEngagement = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        id="Graphics"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1080 1080"
        width="250px"
      >
        <g id="Browse">
          <path
            stroke-width="0px"
            fill="#09091a"
            d="M1059.98,594.14c1.64,118.89-85.87,220.22-203.72,235.91l-408.75,54.38c-76.88,20.17-161.62,9.6-234.09-36.27-138.5-87.66-179.71-270.98-92.05-409.48,51.11-80.75,134.75-128.44,223.06-136.77,20.13-2.57,40.99-2.54,62.18.4l450.97,62.6c114.83,15.94,200.81,113.33,202.41,229.24Z"
          />
          <path
            stroke-width="0px"
            fill="#e2a8a9"
            d="M748.24,533.72c4.25,7.27,9.61,18.32,12.75,32.64,3.02,13.75,2.9,25.58,2.19,33.8,1.5,1.12,4.47,3.03,8.69,3.77,3.32.58,6.09.21,7.79-.16,8.38-1.1,20.98-3.76,34.7-10.85,11.91-6.14,20.49-13.46,26.08-19.03.46-1.21,1.06-3.29.87-5.89-.12-1.61-.5-2.94-.87-3.9-2.43-5.2-5.8-14.16-6.18-25.75-.31-9.44,1.49-17.13,3.09-22.15,9.61-11.16,19.23-22.32,28.84-33.48-12.48-29.4-37.63-75.38-86.79-109.23-10.61-7.31-21.12-13.21-31.16-18-12.15,16.16-30.93,45.79-38.76,87.14-4.51,23.82-4.07,44.62-2.45,60.17.22,2.21,1.76,15.23,13.46,24.24,12.11,9.32,25.64,7.05,27.75,6.66Z"
          />
          <path
            stroke-width="0px"
            fill="#5e5e6c"
            d="M746.79,358l31.1-58.33c.38-.96,2.98-7.28,9.82-9.56,4.28-1.43,8-.54,9.5-.1,3.63,1.87,7.25,3.73,10.88,5.6,1.46.46,3.56.93,6.08.87,4.31-.1,7.54-1.66,9.17-2.61,7.66-3.41,15.32-6.82,22.99-10.24,1.48-.55,11.5-4.11,20.96,1.45,5.05,2.97,7.66,7.19,8.79,9.37,1.22,8.31,2.45,16.61,3.67,24.92.01.51.32,7.52,6.28,11.11,4.33,2.61,8.77,1.68,9.75,1.45,12.68-.13,25.37-.26,38.05-.39.76.08,7.79.97,11.43,7.31,2.73,4.76,1.78,9.5,1.51,10.66-.45,3.34-1.16,7.09-2.29,11.11-1.75,6.27-4.03,11.54-6.21,15.74-.45,1.8-1.03,5.21-.1,9.3,1.23,5.36,4.34,8.71,5.7,10.01,2.01.99,7.42,3.97,10.91,10.46,4.28,7.96,2.68,15.62,2.22,17.54-5.95,9.81-14,20.89-24.85,31.81-29.16,29.31-62.33,41.07-81.19,46.03-5.25,1.96-13.08,4.35-22.86,5.22-6.01.53-11.38.37-15.9-.06-3.89-.12-14.29-.96-24.27-8.43-12.33-9.23-15.45-22.17-16.16-25.56-.25-1.73-.48-4.95.71-8.69,1.37-4.33,3.91-7.07,5.22-8.31,4.03-4.46,8.07-8.93,12.1-13.39.35-.26,4.98-3.75,4.7-9.72-.26-5.5-4.47-8.49-4.96-8.82-.74-.35-2.66-1.12-5.15-.77-2.41.34-4,1.53-4.64,2.06-7.64,8.41-15.28,16.83-22.92,25.24-1.67-2.87-4.34-8.34-4.89-15.71-.67-8.92,2.12-15.74,3.61-18.8,5.41-6.18,10.82-12.36,16.22-18.54,1.28-1.66,3.41-4.9,4.38-9.53,1.09-5.25.1-9.54-.52-11.59-2.42-3.07-6.33-7.31-12.1-11.07-6.52-4.25-12.71-6.13-16.74-7.02Z"
          />
          <path
            stroke-width="0px"
            fill="#fff"
            d="M639.84,533.44l51.25,48.78c2.79,2.66,7.23,2.49,9.81-.38l114.58-127.44c1.92-2.14,1.54-5.47-.81-7.13l-57.36-40.35c-2.87-2.02-6.79-1.57-9.12,1.04l-108.52,121.12c-1.13,1.26-1.05,3.19.17,4.35Z"
          />
          <path
            stroke-width="0px"
            fill="#e2a8a9"
            d="M707.2,469.91c1.18,5.59,2.42,10.35,3.6,15.94l3.51-1.54c.56.07,2.48.38,3.98,2.03,2.16,2.39,1.54,5.49,1.47,5.81,2.67,19.72,5.35,39.45,8.02,59.17-.55,7.46-1.79,16.42-4.39,26.32-2.85,10.85-6.59,19.94-10.1,27.1,2.39,10.59,4.78,21.18,7.17,31.77l-47.63,11.72-4.36-32.16-24.67-43.96c-1.22-1.62-2.98-4.4-3.88-8.25-1.44-6.12.19-11.16.97-13.19,4.33-7.69,8.66-15.38,12.99-23.07l9.15-13.72-11.81-51.93c-.37-1.06-1.26-4.02-.25-7.59.89-3.15,2.78-5.09,3.62-5.86.67-.26,2.66-.94,5.01-.22,3.48,1.06,4.79,4.29,4.96,4.72,8.43,18.74,11.45,32.23,19.88,50.97,1.17,2.14,6.83,9.08,9.07,8.42,2.43-.71-1.78-10.08-2.52-12.63-9.3-32.17-9.32-55.33-14.64-75.88-.13-1.06-.55-5.64,2.56-9.9,2.57-3.52,8.05-1.8,9.23-2.16.88.89,5.04,7.93,5.94,10.79"
          />
          <path
            stroke-width="0px"
            fill="#e2a8a9"
            d="M640.14,563.25l-11.27-28.59c-.43-1.37-2.09-7.17.94-13.03,1.52-2.93,3.62-4.74,4.97-5.71.45-.49,1.97-2.01,4.48-2.4,2.92-.46,5.09.94,5.57,1.26,1.91,5.81,3.82,11.61,5.74,17.42-3.13-9.84-6.27-19.67-9.4-29.51-.71-1.16-3.76-6.45-2.12-12.83.79-3.07,2.37-5.23,3.44-6.45,2.62-2.42,6.32-3.05,9.29-1.62,3.34,1.61,4.21,5.08,4.33,5.57,2.75,8.81,5.5,17.62,8.24,26.43l-24.2,49.46Z"
          />
          <path
            stroke-width="0px"
            fill="#0a52e2"
            d="M763.18,600.16c-3.2.79-9.45,2.82-15.15,8.26-3.45,3.29-5.54,6.73-6.78,9.22-8.92,48.28-17.84,96.55-26.75,144.83l-134.36,104.31,166.28-22.1,35.89-29.54,20.12,56.62,102.78-73.41-64.76-224.46-77.26,26.27Z"
          />
          <path
            stroke-width="0px"
            fill="#004cc6"
            d="M656.86,636.6l73.31-17.34,107.83,229.36s-33.27-217.24-22.31-243.95c6.99-17.04,19.03-26.74,24.74-30.77,3.72-1.26,16.07-4.91,30.32.27,11.72,4.27,18.37,12.21,20.89,15.57,18.81,62.46,37.62,140.58,56.42,207.6,6.63,23.61.52,48.96-16.14,66.95-8.57,9.26-18.98,19.71-31.98,33.09-31.6,32.52-47.02,38.38-69.86,35.92-3.41,1.1-30.9-6.4-55.13-21.71"
          />
          <path
            stroke-width="0px"
            opacity=".53"
            fill="#7095ff"
            d="M463.84,542.91l-324.4,67.91c-10.86,2.27-21.06-6.01-21.06-17.11V236.94c0-10.7,9.54-18.89,20.12-17.28l324.4,49.56c8.53,1.3,14.84,8.64,14.84,17.28v239.3c0,8.27-5.8,15.41-13.9,17.11Z"
          />
          <path
            stroke-width="0px"
            fill="#315dff"
            opacity=".53"
            d="M451.94,528.47l-294.14,60.23c-9.84,2.02-19.09-5.33-19.09-15.17v-316.4c0-9.49,8.65-16.75,18.24-15.32l294.14,43.95c7.74,1.16,13.45,7.67,13.45,15.32v212.22c0,7.34-5.26,13.67-12.6,15.17Z"
          />
          <path
            stroke-width="0px"
            fill="#fff"
            d="M254.11,315.19l-1.1,45.31c-.05,1.8-1.44,3.27-3.23,3.41l-3.24.25-51.27,4-7.33.57c-2.03.15-3.77-1.45-3.77-3.5v-57.23c0-2.09,1.82-3.72,3.89-3.48l62.94,7.11c1.81.2,3.16,1.75,3.11,3.56Z"
          />
          <ellipse
            stroke-width="0px"
            fill="#9b9ba4"
            cx="220.64"
            cy="334.57"
            rx="12.42"
            ry="14.9"
          />
          <path
            stroke-width="0px"
            fill="#9b9ba4"
            d="M246.54,364.16l-51.27,4c-.35-1.21-.53-2.45-.53-3.73,0-8.42,7.88-15.53,18.66-17.76,2.3-.48,4.73-.74,7.25-.74s4.95.26,7.25.74c10.66,2.21,18.48,9.19,18.64,17.49Z"
          />
          <path
            stroke-width="0px"
            fill="#fff"
            d="M422.64,488.9l-97.2,13.38c-2.83.39-5.37-1.79-5.4-4.65l-2.22-177.32c-.03-2.72,2.22-4.92,4.94-4.81l99.42,3.98c2.55.1,4.56,2.2,4.56,4.75v159.96c0,2.37-1.75,4.39-4.11,4.71Z"
          />
          <line
            fill="none"
            opacity=".63"
            stroke="#919191"
            stroke-miterlimit="10"
            stroke-width="2px"
            x1="334.84"
            y1="345.93"
            x2="406.56"
            y2="345.93"
          />
          <line
            fill="none"
            opacity=".63"
            stroke="#919191"
            stroke-miterlimit="10"
            stroke-width="2px"
            x1="335.46"
            y1="375"
            x2="407.18"
            y2="372.09"
          />
          <line
            fill="none"
            opacity=".63"
            stroke="#919191"
            stroke-miterlimit="10"
            stroke-width="2px"
            x1="336.32"
            y1="405.83"
            x2="408.03"
            y2="401.16"
          />
          <line
            fill="none"
            opacity=".63"
            stroke="#919191"
            stroke-miterlimit="10"
            stroke-width="2px"
            x1="337.81"
            y1="439.19"
            x2="408.86"
            y2="431.99"
          />
          <line
            fill="none"
            opacity=".63"
            stroke="#919191"
            stroke-miterlimit="10"
            stroke-width="2px"
            x1="338.09"
            y1="473.86"
            x2="410.85"
            y2="465.35"
          />
        </g>
      </svg>
    ),
    [props]
  );

export { SignUp, PostYourJobs, InstantEngagement };
