import { CSSProperties, FC, ReactNode, useMemo } from 'react';
import { css } from '@emotion/react';

import { PromptContainer } from '../ModalPrompt';
import CloseIcon from '../CloseIcon';
import { appColors } from '@/constants/colors';
import Button, { ButtonProps } from '../Button';
interface SaveSearchPromptProps {
  containerStyles?: CSSProperties;
  content: ReactNode;
  closeModal: () => void;
  onClick: () => void;
}

const Save = 'Save';
const SaveButton: FC<{ buttonProps: ButtonProps }> = ({ buttonProps }) => {
  return useMemo(() => {
    return (
      <Button
        {...{
          children: Save,
          ...buttonProps,
        }}
      />
    );
  }, [buttonProps]);
};

const SaveSearchModal: FC<SaveSearchPromptProps> = ({
  containerStyles,
  content,
  closeModal,
  onClick,
}) => {
  return (
    <PromptContainer
      {...{ style: { ...containerStyles, padding: '15px 40px 40px 40px' } }}
    >
      <div>
        <p {...{ css: newsCardTitle }}> Save Search</p>{' '}
        <div {...{ css: closeIconContainer }}>
          <CloseIcon {...{ onClick: closeModal }} />{' '}
        </div>
        <div {...{ css: contentContainer }}>
          {content}{' '}
          <SaveButton
            {...{
              buttonProps: {
                children: 'Save',
                onClick: onClick,
              },
            }}
          />
        </div>{' '}
      </div>
    </PromptContainer>
  );
};

export default SaveSearchModal;

const closeIconContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 16px;
  right: 16px;
`;

const newsCardTitle = css`
  color: ${appColors.content.tertiary};
  font-family: 'Hepta Slab';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: auto;
`;

const contentContainer = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 25px;
`;
