import { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Input from '@/components/Input';
import InputDrop from '@/components/InputDrop';
import FakeField from '@/components/FakeField';
import { mediaTypeList } from '@/constants/media-types';
import { ApplyLoggedinFormValues } from '@/types/forms';
import { errorMessages } from '@/components/CustomDropZone';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import { FieldsContainer, FormContainer, RowContainer } from '../..';

const ApplyLoggedinCVForm = ({
  methods,
  isEditMode,
}: {
  methods: UseFormReturn<ApplyLoggedinFormValues, any>;
  isEditMode: boolean;
}) => {
  const {
    control,
    setValue,
    setError,
    formState: { isSubmitting },
  } = methods;

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <RowContainer
            {...{
              style: {
                flexDirection: isEditMode ? 'column' : 'row',
              },
            }}
          >
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'resume',
                  control,
                  // rules: { required: true },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    if (isEditMode) {
                      return (
                        <InputDrop
                          {...{
                            ...fieldsDefaultProps['resume'],
                            ...field,
                            id: field.name,
                            error,
                            removeFileAction: () => {
                              setValue('resume', undefined, {
                                shouldTouch: true,
                                shouldDirty: true,
                                shouldValidate: true,
                              });
                            },

                            dropzoneProps: {
                              maxSize: 1048576,
                              maxFiles: 1,
                              onDrop: (accepted) => {
                                if (!!accepted[0]) {
                                  const file = accepted[0];
                                  const isFileTypeSupported =
                                    mediaTypeList.includes(file.type);

                                  if (isFileTypeSupported) {
                                    setValue('resume', file, {
                                      shouldTouch: true,
                                      shouldDirty: true,
                                      shouldValidate: true,
                                    });
                                    return;
                                  }
                                  setError('resume', {
                                    type: 'manual',
                                    message: errorMessages.type,
                                  });
                                  return;
                                }
                                setError('resume', {
                                  type: 'manual',
                                  message: errorMessages.filesize,
                                });
                                return;
                              },
                            },
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label: 'CV',
                          value: !!field.value.name
                            ? `${field.value.name.slice(0, 10)}...`
                            : '' || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }

            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'profile_link',
                  control,
                  rules: { ...fieldsDefaultRules['profile_link'] },
                  render: ({ fieldState: { error }, field }) => {
                    if (isEditMode) {
                      return (
                        <Input
                          {...{
                            ...fieldsDefaultProps['profile_link'],
                            ...field,
                            id: field.name,
                            error,
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label: fieldsDefaultProps['profile_link'].label || '',
                          value: !!field.value.name
                            ? `${field.value.name.slice(0, 10)}...`
                            : '' || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default memo(ApplyLoggedinCVForm);
