import { updateCsrfToken } from '@/utils/meta-token';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

const successMessages = [
  'Logged in successfully.',
  'Signed up successfully!',
  'Logged out successfully.',
];

interface ErrorData {
  readonly message: string;
  readonly errors?: Record<string, string[]>;
}

class Axios {
  private instance: AxiosInstance;
  token?: string | null | undefined;
  maxRetries?: number;
  retries?: number;

  constructor(url: string, maxTries: number = 3) {
    this.token = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content');
    this.maxRetries = 3;
    this.retries = 0;

    this.instance = axios.create({
      url,
      headers: {},
    });
    this.addInterceptors();
  }

  private addInterceptors() {
    this.instance.interceptors.request.use(
      async (config) => {
        if (this.token) {
          config.headers['X-CSRF-Token'] = this.token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => {
        const message = response?.data?.message;
        // CHECK ( LOGIN / SIGNUP / LOGOUT) RESPONSES WITH THIS MESSAGES
        // Logged in successfully.
        // Signed up successfully!
        // Logged out successfully.
        const isSignInOrUpOrOut = successMessages.includes(message);
        if (isSignInOrUpOrOut) {
          const token = response.data.csrf_token;
          const hasToken = !!token;
          if (hasToken) {
            updateCsrfToken(token);
          }
        }
        if (response?.data?.message === 'CSRF Error') {
          this.token = response?.data?.csrf_token;
          if (this.retries < this.maxRetries) {
            this.retries++;
            return new Promise((resolve) => {
              setTimeout(() => resolve(this.instance(response.config)), 1000); // retry with new token
            });
          }
        }
        return response;
      },
      (error: AxiosError<ErrorData>) => {
        const { message, status } = error;
        if (status) {
          // error Handlers
          console.error(`${status} / ${message}`);
        }
        return Promise.reject(error);
      }
    );
  }

  public get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.instance.get(url, config);
  }

  public post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.instance.post(url, data, config);
  }

  public put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.instance.put(url, data, config);
  }

  public patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.instance.patch(url, data, config);
  }

  public delete<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.instance.delete(url, config);
  }
}

export default Axios;
