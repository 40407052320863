import { FC, Reducer, memo, useCallback, useMemo, useReducer } from 'react';
import { css } from '@emotion/react';
import produce from 'immer';

import { PaginatorType } from '@/types/types';
import PaginatorNumber from './PaginatorNumber';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { IconVectorLeft, IconVectorRight } from '../Icons/Vector';

const GROUP_SIZE = 4;

const paginatorContainerStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const arrowContainerStyles = css`
  display: flex;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const numberContainerStyles = css`
  ${paginatorContainerStyles}
`;

interface PaginatorProps {
  changePage: (page: number) => void;
  pagination: PaginatorType;
}

type State = {
  currentGroup: number;
};

type Action = {
  type: 'SET_GROUP';
  payload: number;
};

const initialState = {
  currentGroup: 1,
};

const reducer = produce((draft, { type, payload }: Action) => {
  switch (type) {
    case 'SET_GROUP':
      draft.currentGroup = payload;
      break;
  }
}, initialState);

const Paginator: FC<PaginatorProps> = ({ pagination, changePage }) => {
  const { totalPages, currentPage } = pagination;

  const [{ currentGroup }, localDispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    initialState
  );

  const onPressLeftArrow = useCallback(() => {
    const previousPage = currentPage - 1;
    const canGoBackPage = previousPage > 0;
    if (canGoBackPage) {
      changePage(previousPage);
      //
      const groupIndex = Math.floor((previousPage - 1) / GROUP_SIZE);
      const currentGroupByPreviousPage = groupIndex + 1;
      const canGoPreviousGroup = currentGroupByPreviousPage < currentGroup;

      if (canGoPreviousGroup) {
        localDispatch({
          type: 'SET_GROUP',
          payload: currentGroupByPreviousPage,
        });
      }
    }
  }, [currentPage, currentGroup]);

  const onPressRightArrow = useCallback(() => {
    const nextPage = currentPage + 1;
    const canGoNextPage = nextPage <= totalPages;

    if (canGoNextPage) {
      changePage(currentPage + 1);
      //
      const groupIndex = Math.floor((nextPage - 1) / GROUP_SIZE);
      const currentGroupByNextPage = groupIndex + 1;
      const canGoNextGroup = currentGroupByNextPage > currentGroup;

      if (canGoNextGroup) {
        localDispatch({ type: 'SET_GROUP', payload: currentGroupByNextPage });
      }
    }
  }, [currentPage, currentGroup]);

  const groups = useMemo(() => {
    return [...new Array(totalPages)]
      .map((_, index) => {
        const number = index + 1;
        const isActive = number === currentPage;
        return (
          <PaginatorNumber
            {...{
              number,
              isActive,
              key: number,
              onClick: () => {
                if (currentPage !== number) {
                  changePage(number);
                }
              },
            }}
          />
        );
      })
      .reduce(
        (
          accumulator: Record<number, EmotionJSX.Element[]>,
          currentValue,
          currentIndex
        ) => {
          const groupIndex = Math.floor(currentIndex / GROUP_SIZE) + 1;

          if (!accumulator[groupIndex]) {
            accumulator[groupIndex] = [];
          }
          accumulator[groupIndex].push(currentValue);

          return accumulator;
        },
        {}
      );
  }, [totalPages, GROUP_SIZE, currentPage]);

  const isAbleToGoBack = currentPage > 1;
  const isAbleToGoNext = currentPage < totalPages;

  return (
    <div {...{ css: paginatorContainerStyles }}>
      <div
        {...{
          css: arrowContainerStyles,
          style: {
            opacity: isAbleToGoBack ? 1 : 0,
            pointerEvents: isAbleToGoBack ? 'auto' : 'none',
          },
          onClick: onPressLeftArrow,
        }}
      >
        <IconVectorLeft />
      </div>
      <div {...{ css: numberContainerStyles }}>{groups[currentGroup]}</div>
      <div
        {...{
          css: arrowContainerStyles,
          style: {
            opacity: isAbleToGoNext ? 1 : 0,
            pointerEvents: isAbleToGoNext ? 'auto' : 'none',
          },
          onClick: onPressRightArrow,
        }}
      >
        <IconVectorRight />
      </div>
    </div>
  );
};
export default memo(Paginator);
