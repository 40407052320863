import { SVGProps, useMemo } from 'react';

const IconDrag = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M4.22 19.47a.75.75 0 101.06 1.06l-1.06-1.06zm1.06 1.06l16-16-1.06-1.06-16 16 1.06 1.06zM12.22 19.47a.75.75 0 101.06 1.06l-1.06-1.06zm1.06 1.06l8-8-1.06-1.06-8 8 1.06 1.06z"
          fill="#05051B"
        />
      </svg>
    ),
    [props]
  );

export { IconDrag };
