import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconMedicalJob = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={2}
          y={7}
          width={20}
          height={14}
          rx={3}
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
        <path
          d="M17 7V6a2 2 0 00-2-2H9a2 2 0 00-2 2v1"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
        <path
          d="M10.5 10v-.75a.75.75 0 00-.75.75h.75zm3 0h.75a.75.75 0 00-.75-.75V10zm-3 2.5v.75a.75.75 0 00.75-.75h-.75zm-2.5 0v-.75a.75.75 0 00-.75.75H8zm0 3h-.75c0 .414.336.75.75.75v-.75zm2.5 0h.75a.75.75 0 00-.75-.75v.75zm0 2.5h-.75c0 .414.336.75.75.75V18zm3 0v.75a.75.75 0 00.75-.75h-.75zm0-2.5v-.75a.75.75 0 00-.75.75h.75zm2.5 0v.75a.75.75 0 00.75-.75H16zm0-3h.75a.75.75 0 00-.75-.75v.75zm-2.5 0h-.75c0 .414.336.75.75.75v-.75zm-3-1.75h3v-1.5h-3v1.5zm.75 1.75V10h-1.5v2.5h1.5zM8 13.25h2.5v-1.5H8v1.5zm.75 2.25v-3h-1.5v3h1.5zm1.75-.75H8v1.5h2.5v-1.5zm.75 3.25v-2.5h-1.5V18h1.5zm2.25-.75h-3v1.5h3v-1.5zm-.75-1.75V18h1.5v-2.5h-1.5zm3.25-.75h-2.5v1.5H16v-1.5zm-.75-2.25v3h1.5v-3h-1.5zm-1.75.75H16v-1.5h-2.5v1.5zM12.75 10v2.5h1.5V10h-1.5z"
          fill={iconColor || appColors.content.primary}
        />
      </svg>
    ),
    [props]
  );

export { IconMedicalJob };
