import { FC, memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { IconFilter } from '../Icons/Filter';
import { appColors } from '@/constants/colors';
import Button, { ButtonProps } from '../Button';
import { paragraphDesktop } from '@/constants/typography';
import { Tooltip } from '@mui/material';
import usePageSession from '@/hooks/usePageSession';

const {
  components: { iconStyles },
} = layout;

interface MoreLessFilterButtonProps {
  isActive: boolean;
  onClick: () => void;
  activeFilters: number;
  isButton?: boolean;
  isMobile?: boolean;
}

const StyledButton = styled(Button)<ButtonProps>`
  display: flex;
  height: 48px;
  width: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  //padding: 12px 12px 12px 20px;
  cursor: pointer;
`;

const buttonTextStyles = css({
  ...paragraphDesktop.md,
});

const moreLessContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  ${buttonTextStyles}
`;

const iconContainerStyles = css`
  ${iconStyles}
  margin-left: 4px;
`;

const MoreLessFilterButton: FC<MoreLessFilterButtonProps> = ({
  isActive,
  activeFilters,
  onClick,
  isButton = true,
  isMobile,
}) => {
  const {
    session: { signed_in },
    session,
  } = usePageSession();

  const isAdmin = session?.current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;
  const isRecruiter =
    isSignedIn && session?.current_user?.is_type === 'recruiter';

  const numberActive = !!activeFilters ? ` (${activeFilters}) ` : '';

  const lessText = `Less Filter${numberActive}`;
  const moreText = `More Filter${numberActive}`;

  const buttonText = isActive ? lessText : moreText;
  const buttonVariant = isActive ? '1' : '2';

  if (isButton) {
    return (
      <Tooltip
        {...{
          title: 'More Filter',
          slotProps: {
            ...{
              tooltip: {
                sx: {
                  backgroundColor: '#05051B',
                  fontSize: '12px',
                  fontWeight: '600',
                },
              },
            },
          },
        }}
      >
        <StyledButton
          {...{
            variant: buttonVariant,
            onClick,
            icon: IconFilter,
            children: isRecruiter ? buttonText : '',
            style: {
              width: isMobile ? '100%' : isRecruiter ? '171px' : '60px',
              padding: isRecruiter ? '12px 12px 12px 20px' : '0',
            },
          }}
        />
      </Tooltip>
    );
  }

  return (
    <div
      {...{
        css: css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        `,
        onClick,
      }}
    >
      <div
        {...{
          css: css`
            ${moreLessContainerStyles}
            color: ${appColors.content.tertiary};
            font-weight: 500;
            cursor: pointer;
          `,
          //children: buttonText,
        }}
      />
      <div
        {...{
          css: css`
            ${iconContainerStyles}
            cursor: pointer;
          `,
        }}
      >
        <IconFilter {...{ iconColor: appColors.content.tertiary }} />
      </div>
    </div>
  );
};

export default memo(MoreLessFilterButton);
