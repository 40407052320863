type MediaType = 'pdf' | 'jpg' | 'doc' | 'xdoc';
type CSVType = 'csv' | 'txt';

const mediaTypes: Record<MediaType, string> = Object.freeze({
  pdf: 'application/pdf',
  // png: 'image/png',
  jpg: 'image/jpeg',
  doc: 'application/msword',
  xdoc: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
});

const csvTypes: Record<CSVType, string> = Object.freeze({
  csv: 'text/csv',
  txt: 'text/plain',
});

const mediaTypeList = Object.values(mediaTypes);
const csvTypesList = Object.values(csvTypes);

export { mediaTypes, mediaTypeList, csvTypes, csvTypesList };
