import { SelectOption } from '@/types/types';
import boardCertificationHash from '../jsons/boardCertification.json';

const boardCertification: SelectOption[] = Object.values(
  boardCertificationHash
);

type BoardCertification = (typeof boardCertification)[number]['value'];

export { boardCertificationHash, BoardCertification, boardCertification };
