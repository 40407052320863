import { ComponentProps, FC, memo } from 'react';

import useModal from '@/hooks/useModal';
import { IconTrash } from '../Icons/Trash';
import Button, { ButtonProps } from '../Button';
import QuestionPrompt from '../ModalPrompt/QuestionPrompt';

interface DeleteButtonProps {
  deleteButtonLabel?: string;
  hasIcon?: boolean;
  deleteButtonProps: ButtonProps;
  questionPromptProps: ComponentProps<typeof QuestionPrompt>;
}

const DeleteButton: FC<DeleteButtonProps> = ({
  deleteButtonLabel,
  hasIcon = true,
  deleteButtonProps,
  questionPromptProps: { headerProps = {}, msgProps = {}, buttonsProps = {} },
}) => {
  const { ModalComponent, openModal, closeModal } = useModal();

  const { leftButton = {}, rightButton = {} } = buttonsProps;

  return (
    <>
      <Button
        {...{
          ...deleteButtonProps,
          variant: '5',
          onClick: openModal,
          children: deleteButtonLabel,
          ...(hasIcon ? { icon: IconTrash } : {}),
        }}
      />
      <ModalComponent {...{}}>
        <QuestionPrompt
          {...{
            headerProps: {
              ...headerProps,
              children: 'Are you sure?',
            },
            msgProps: msgProps,
            buttonsProps: {
              ...buttonsProps,
              leftButton: {
                variant: '2',
                children: 'Cancel',
                ...leftButton,
                onClick: async (e) => {
                  leftButton?.onClick && leftButton?.onClick(e);
                  closeModal();
                },
              },
              rightButton: {
                variant: '5',
                children: 'Yes, delete',
                ...rightButton,
                onClick: async (e) => {
                  rightButton?.onClick && rightButton?.onClick(e);
                  closeModal();
                },
              },
            },
          }}
        />
      </ModalComponent>
    </>
  );
};

export default memo(DeleteButton);
