import { title } from './title';
import { states } from './states';
import { specialty } from './specialty';
import { SelectOption } from '@/types/types';
import { employerType } from './employerType';
import { stateLicense } from './stateLicense';
import { assignmentType } from './assignmentType';
import { boardCertification } from './boardCertification';
import { geographicPreferences } from './geographicPreferences';
import { credentialingTime } from './credentialingtime';
import {anyStateLicenses} from './anyStateLicenses'

// NOTE: TITLE = PROFESSION

const selectsData: Record<string, SelectOption[]> = {
  state: states,
  title: title,
  assignment_type: assignmentType,
  specialty: specialty,
  geographic_preferences: geographicPreferences,
  state_licenses: stateLicense,
  employer_type: employerType,
  board_certification: boardCertification,
  credentialing_time:credentialingTime,
  any_state_licenses:anyStateLicenses
  //
};

export default selectsData;
