import { SVGProps, useMemo } from 'react';

const IconList = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <circle cx={5.5} cy={6} r={0.75} fill="#05051B" />
        <path
          d="M8.25 6h12"
          stroke="#05051B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <circle cx={5.5} cy={12} r={0.75} fill="#05051B" />
        <path
          d="M8.25 12h12"
          stroke="#05051B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <circle cx={5.5} cy={18} r={0.75} fill="#05051B" />
        <path
          d="M8.25 18h12"
          stroke="#05051B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconList };
