import Heading from '@/components/Typography/Heading';
import styled from '@emotion/styled';

const Container = styled.div``;

export default function NotFound() {
  return (
    <Container>
      <Heading
        {...{
          variant: 'h1',
          children: '404 - NOT FOUND',
        }}
      />
    </Container>
  );
}
