import { memo, useCallback } from 'react';
import { css } from '@emotion/react';

import { trending } from './_index';
import SearchCard from './SearchCard';
import layout from '@/constants/layout';
import { router } from '@inertiajs/react';
import SearchLink from './SearchLink';

const {
  windowSizes: { tablet, desktop, mobile },
} = layout;

const desk = css`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  column-gap: 16px;
  row-gap: 16px;
  max-width: 100vw;
  grid-auto-rows: 1fr;
  align-items: stretch;
  margin: 0 auto;
  @media (max-width: ${desktop}px) {
    max-width: 90vw;
  }
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    padding: 40px 16px 0 16px;
  }
  @media (max-width: ${mobile}px) {
    padding: 40px 0px 0px;
  }
`;
const SearchLinkDisplayer = () => {
  const onClickTile = useCallback((value: string) => {
    // const urlRoute = `/jobs?q%5Bprofessions%5D=&q%5Bspecialties%5D=${value}&q%5Bfacility_eq%5D=`;
    const urlRoute = `/jobs?specialties%5B%5D=${value}`;
    router.get(urlRoute);
  }, []);

  return (
    <div {...{ css: desk }}>
      {trending.length > 0 &&
        trending.map((item) => {
          return <SearchLink {...{ item, onClickTile, key: item.value }} />;
        })}
    </div>
  );
};

export default memo(SearchLinkDisplayer);
