import { FC, memo } from 'react';
import { css } from '@emotion/react';

import NavLink from './NavLink';
import { NavLinkProps } from '@/types/layout';

const navLinkContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const NavLinks: FC<{ navLinks?: NavLinkProps[] }> = (props) => {
  return (
    <div
      {...{
        css: navLinkContainerStyle,
      }}
    >
      {!!props.navLinks &&
        props.navLinks.length > 0 &&
        props.navLinks.map((item, index) => {
          return <NavLink {...{ ...item, key: index }} />;
        })}
    </div>
  );
};

export default memo(NavLinks);
