import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconCredentialingTime = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg 
      width={24}
      height={24}
      fill="none"
      viewBox="0 0 35 50"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      >
        <path 
        d="M37.93,46.5H10.07a1,1,0,0,1-1-1V7.09a1,1,0,0,1,1-1H22a1,1,0,0,1,0,2H11.07V44.5H36.93V8.09H26a1,1,0,0,1,0-2H37.93a1,1,0,0,1,1,1V45.5A1,1,0,0,1,37.93,46.5Z"
        fill={iconColor || appColors.content.primary}
        />
        <path 
        d="M28.47,28H19.53a1,1,0,0,1-1-1V23.37A3.44,3.44,0,0,1,22,19.94H26a3.44,3.44,0,0,1,3.43,3.43V27A1,1,0,0,1,28.47,28Zm-7.94-2h6.94V23.37A1.43,1.43,0,0,0,26,21.94H22a1.43,1.43,0,0,0-1.43,1.43Z"
        
        fill={iconColor || appColors.content.primary}
        />
        <path 
        d="M24,19.4a3.29,3.29,0,1,1,3.29-3.29A3.3,3.3,0,0,1,24,19.4Zm0-4.58a1.29,1.29,0,1,0,1.29,1.29A1.29,1.29,0,0,0,24,14.82Z"
        fill={iconColor || appColors.content.primary}
        />
        <path 
        d="M32.21,32.64H15.79a1,1,0,0,1,0-2H32.21a1,1,0,0,1,0,2Z"
        fill={iconColor || appColors.content.primary}
        />
        <path 
        d="M32.21,36.5H15.79a1,1,0,0,1,0-2H32.21a1,1,0,1,1,0,2Z"
        fill={iconColor || appColors.content.primary}
        />
        <path 
        d="M32.21,40.37H15.79a1,1,0,0,1,0-2H32.21a1,1,0,0,1,0,2Z"
        fill={iconColor || appColors.content.primary}
        />
        <path 
        d="M26,10.74H22a1,1,0,0,1-1-1V4.5a3,3,0,0,1,6,0V9.74A1,1,0,0,1,26,10.74Zm-3-2h2V4.5a1,1,0,0,0-1-1,1,1,0,0,0-1,1Z"
        fill={iconColor || appColors.content.primary}
        />
       
        </svg>
    ),
    [props]
  );

export { IconCredentialingTime };
