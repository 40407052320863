import { FC, memo } from 'react';
import { css } from '@emotion/react';
import { FieldError } from 'react-hook-form';

import { appColors } from '@/constants/colors';
import SelectButton, { SelectButtonProps } from '.';
import { StyledInputWrapper, StyledLabel } from '../Input';

interface SelectButtonInputProps {
  readonly options?: SelectButtonProps[];
  readonly disabled?: boolean;
  readonly label?: string;
  readonly error?: FieldError;
  readonly value?: string | number | any;
  readonly onChange: (value: string | number) => void;
}

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const SelectButtonInput: FC<SelectButtonInputProps> = (props) => {
  return (
    <StyledInputWrapper>
      {!!props.label && (
        <StyledLabel
          {...{
            children: props.label,
            style: {
              ...(props.disabled
                ? {
                    color: appColors.content.disabled,
                  }
                : {}),
            },
          }}
        />
      )}
      <div {...{ css: buttonsContainer }}>
        {!!props?.options &&
          props.options.length > 0 &&
          props.options.map((option, index) => {
            const isSelected = props.value === index;
            return (
              <div {...{ key: index }}>
                <SelectButton
                  {...{
                    ...option,
                    isSelected,
                    onClick: () => {
                      props.onChange(index);
                    },
                  }}
                />
              </div>
            );
          })}
      </div>
    </StyledInputWrapper>
  );
};

export default memo(SelectButtonInput);
