import styled from '@emotion/styled';
import { Ref, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import Dropzone, { DropzoneProps, DropzoneRef } from 'react-dropzone';

import { INPUT_COMMONS } from './Input';
import Paragraph from './Typography/Paragraph';
import { appColors } from '@/constants/colors';

const HEIGHT = 84;
const BORDER_RADIUS = INPUT_COMMONS.BORDER_RADIUS;
const BORDER_WIDTH = INPUT_COMMONS.BORDER_WIDTH;

export const errorMessages = {
  filesize: 'Size must be 1MB or less.',
  type: 'File must be a PDF, JPEG or MS-Word.',
};

interface CustomDropZoneProps {
  readonly dropzoneProps?: Partial<DropzoneProps>;
  readonly isUploading?: boolean;
  readonly error?: FieldError;
}

const Container = styled.div`
  display: flex;
  height: ${HEIGHT}px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 32px;
  gap: 10px;
  background: #ffffff;
  border: ${BORDER_WIDTH}px dashed #315eff;
  border-radius: ${BORDER_RADIUS}px;
  cursor: pointer;
  &:hover {
    background: ${appColors.notInDesignSystem[7]};
  }
`;

const CustomDropZone = (
  { dropzoneProps, error, isUploading }: CustomDropZoneProps,
  ref: Ref<DropzoneRef> | undefined
) => {
  return (
    <Dropzone {...{ ...dropzoneProps, ref }}>
      {({ getRootProps, getInputProps }) => {
        return (
          <section>
            <Container
              {...getRootProps()}
              {...{
                style: {
                  ...(!!error
                    ? { borderColor: INPUT_COMMONS.ERROR_COLOR }
                    : {}),
                  ...(!!isUploading
                    ? { backgroundColor: appColors.notInDesignSystem[8] }
                    : {}),
                },
              }}
            >
              <input {...{ ...getInputProps() }} />
              <Paragraph
                {...{
                  variant: 'md',
                  style: {
                    ...(!!error ? { color: INPUT_COMMONS.ERROR_COLOR } : {}),
                  },
                }}
              >
                Drag and drop a file to upload, or{' '}
                <Paragraph
                  {...{
                    children: 'browse.',
                    variant: 'md',
                    style: {
                      color: !!error
                        ? INPUT_COMMONS.ERROR_COLOR
                        : appColors.content.tertiary,
                      textDecorationLine: 'underline',
                    },
                  }}
                />
              </Paragraph>
            </Container>
          </section>
        );
      }}
    </Dropzone>
  );
};

export default forwardRef(CustomDropZone);
