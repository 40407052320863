import { Tooltip, TooltipProps } from '@mui/material';
import { FC, ReactElement } from 'react';

interface CustomTooltipProps extends Omit<TooltipProps, 'children'> {
  children: ReactElement;
}

const CustomTooltip: FC<CustomTooltipProps> = ({
  children,
  title,
  open,
  ...props
}) => {
  return (
    <Tooltip
      title={title}
      open={open}
      slotProps={{
        tooltip: {
          sx: {
            backgroundColor: '#05051B',
            fontSize: '12px',
            fontWeight: '600',
          },
        },
        popper:{
          sx: {
           zIndex:"99999"
          },
        }
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
