import { ComponentProps, FC, Fragment, memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { router } from '@inertiajs/react';

import {
  FormHeader,
  SaveSkipButtons,
  StepperHeaderContainer,
  StepperProgresBar,
  resetFormDefaultOptions,
} from '../Forms';
import {
  SignupRecruiterCompanyFormValues,
  SignupRecruiterContactInfoFormValues,
} from '@/types/forms';
import {
  signupCloseThunk,
  signupRecruiterCompanyThunk,
  signupRecruiterContactInfoThunk,
} from '@/storage/auth/auth-actions';
import { useAppDispatch } from '@/storage/store';
import useModalState from '@/hooks/useModalState';
import useStepperState from '@/hooks/useStepperState';
import ModalLayout from '../Layout/Modal/ModalLayout';
import SignupRecruiterCompanyForm from '../Forms/Signup/Recruiter/SignupRecruiterCompanyForm';
import SignupRecruiterContactInfoForm from '../Forms/Signup/Recruiter/SignupRecruiterContactInfoForm';

interface SignupRecruiterFlowProps {
  readonly id: number;
  readonly isForcingFlow?: boolean;
}

const steps: {
  component: FC<any>;
  header?: ComponentProps<typeof FormHeader>;
  buttonProps?: ComponentProps<typeof SaveSkipButtons>;
}[] = [
  {
    component: SignupRecruiterContactInfoForm,
    header: {
      title: 'Contact Information',
    },
    buttonProps: {
      saveButtonProps: {},
      skipButtonProps: {},
    },
  },
  {
    component: SignupRecruiterCompanyForm,
    header: {
      title: 'Company Information',
    },
    buttonProps: {
      saveButtonProps: {},
      skipButtonProps: { disabled: true },
    },
  },
];

const totalSteps = steps.length;

const SignupRecruiterFlow: FC<SignupRecruiterFlowProps> = ({
  id,
  isForcingFlow = false,
}) => {
  const dispatch = useAppDispatch();
  const {
    actions: { close },
  } = useModalState();
  const {
    state: { canGoBack, currentStep },
    actions: { onSkip, goNext, goBack },
  } = useStepperState({
    ...(isForcingFlow ? { initialCurrentStep: 1 } : {}),
    totalSteps,
    nextActions: {
      lastStepAction: async () => {
        closeAndRefresh();
      },
    },
    skipActions: {
      lastStepAction: async () => {
        beforeCloseModal();
      },
    },
  });

  const contactInfoMethods = useForm<SignupRecruiterContactInfoFormValues>({
    defaultValues: {
      phone: '',
      location: '',
    },
    mode: 'onChange',
  });

  const companyMethods = useForm<SignupRecruiterCompanyFormValues>({
    defaultValues: {
      name: '',
      //@ts-ignore
      employer_type: '',
      website: '',
      //
      //@ts-ignore
      company: '',
    },
    mode: 'onChange',
  });

  const methodsByindex: Record<number, any> = {
    0: contactInfoMethods,
    1: companyMethods,
  };

  const currentFormMethods = methodsByindex[currentStep];

  const {
    formState: { isSubmitting },
    reset,
  } = currentFormMethods;

  const closeAndRefresh = useCallback(() => {
    close();
    // afterSignupRedirect();
    router.get('/recruiters/jobs');
  }, []);

  const beforeCloseModal = useCallback(async () => {
    const response = await dispatch(
      signupCloseThunk({ id, resource: 'recruiter' })
    ).unwrap();

    if (!!response) {
      closeAndRefresh();
    }
  }, []);

  const onSaveAndContinue = useCallback(
    async (form: any) => {
      // Contact Information
      if (currentStep == 0) {
        try {
          const formValues = form as SignupRecruiterContactInfoFormValues;
          const response = await dispatch(
            signupRecruiterContactInfoThunk({
              id,
              params: {
                ...formValues,
                next_signup_status: 'company_info',
              },
            })
          ).unwrap();
          if (!!response) {
            reset(
              {
                formValues,
              },
              resetFormDefaultOptions
            );
            goNext();
          }
        } catch (error) {}
        return;
      }
      // Company Information
      if (currentStep == 1) {
        const formValues = form as SignupRecruiterCompanyFormValues;
        try {
          const companyInfoForm = {
            details: '',
            name: formValues.company.name,
            employer_type: formValues.employer_type.value,
            website: formValues.website,
          };
          const response = await dispatch(
            signupRecruiterCompanyThunk({
              id,
              params: {
                company_attributes: companyInfoForm,
                next_signup_status: 'signup_completed',
              },
            })
          ).unwrap();
          if (!!response) {
            reset(
              {
                formValues,
              },
              resetFormDefaultOptions
            );
            goNext();
          }
        } catch (error) {}
        return;
      }
    },
    [currentStep, steps.length]
  );

  return (
    <ModalLayout
      {...{
        closeModal: beforeCloseModal,
        isLoading: isSubmitting,
        hasLogo: true,
      }}
    >
      <StepperHeaderContainer
        {...{
          canGoBack,
          style: {
            ...(isForcingFlow ? { paddingBottom: 16 } : {}),
          },
        }}
      >
        <StepperProgresBar
          {...{
            ...(isForcingFlow
              ? { total: 1, current: 1 }
              : {
                  canGoBack,
                  onClickBack: goBack,
                  total: totalSteps,
                  current: currentStep,
                }),
          }}
        />
      </StepperHeaderContainer>
      {steps.map(({ component: Form, header, buttonProps }, index) => {
        if (index === currentStep) {
          return (
            <Fragment key={index}>
              <FormHeader {...header} />
              <div {...{ style: { paddingBottom: 32 } }} />
              <Form
                {...{
                  onSaveAndContinue,
                  onSkip,
                  header,
                  buttonProps,
                  key: index,
                  methods: currentFormMethods,
                }}
              />
              <div {...{ style: { paddingBottom: 32 } }} />
              <SaveSkipButtons
                {...{
                  saveButtonProps: {
                    disabled:
                      !currentFormMethods.formState.isValid ||
                      // !currentFormMethods.formState.isDirty ||
                      currentFormMethods.formState.isSubmitting,
                    onClick: currentFormMethods.handleSubmit(onSaveAndContinue),
                    ...buttonProps?.saveButtonProps,
                  },
                  skipButtonProps: {
                    disabled: isSubmitting,
                    onClick: onSkip,
                    ...buttonProps?.skipButtonProps,
                  },
                }}
              />
            </Fragment>
          );
        }
      })}
    </ModalLayout>
  );
};

export default memo(SignupRecruiterFlow);
