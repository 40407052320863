import { useCallback, useEffect, useState } from 'react';

import useWindowSize from './useWindowSize';

const useDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowSize();

  const toggleDrawer = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!isMobile) {
      setIsOpen(false);
    }
  }, [isMobile]);

  return { actions: { toggleDrawer }, state: { isOpen } };
};

export default useDrawer;
