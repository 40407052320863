import { FC, memo } from 'react';
import { css } from '@emotion/react';
import layout from '@/constants/layout';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const {
  windowSizes: { desktop, mobile, tablet, desktopHD },
  mediaQuery,
} = layout;

interface QuoteProps {
  templeName?: string;
  templeOne?: boolean;
  styleNone?: boolean;
}

const Quote: FC<QuoteProps> = ({ templeName, templeOne, styleNone }) => {
  const containerStyle = templeOne
    ? quoteContainerStyles
    : fullWidthContainerStyles;

  const bannerStyle = templeOne ? bannerStyles : fullBannerWidth;

  return (
    <>
      {styleNone ? (
        <div style={{ width: '100%', padding: '1em 0' }}>
          <span
            style={{
              fontFamily: 'Hepta Slab',
              fontSize: '22px',
              color: '#5889df',
              lineHeight: '1.5',
            }}
          >
            &ldquo;{templeName}&rdquo;
          </span>
        </div>
      ) : (
        <>
          <div
            {...{
              css: containerStyle,
            }}
          >
            <FormatQuoteIcon sx={iconStyles} />
          </div>
          <div {...{ css: bannerStyle }}>
            <span {...{ css: fontStyle }}>&ldquo;{templeName}&rdquo;</span>
          </div>
        </>
      )}
    </>
  );
};

export default memo(Quote);
const fullWidthContainerStyles = css`
  width: 100%;
  margin: 0 0 0 1em;
  z-index: 9999;
  @media (max-width: ${mobile}px) {
    z-index: 9;
  }
`;

const quoteContainerStyles = css`
  width: 600px;
  height: 150px;
  margin: 0 0 0 1em;
  z-index: 9999;
`;

const bannerStyles = css`
  background-color: #cdcdd1;
  border-left: 10px solid #315eff;
  width: 600px;
  height: auto;
  padding: 1em;
  color: #315eff;
  margin: -120px 0 23px;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

const fullBannerWidth = css`
  background-color: #cdcdd1;
  border-left: 10px solid #315eff;
  width: 100%;
  height: auto;
  padding: 1em;
  color: #315eff;
  margin: -50px 0px 0px;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

const fontStyle = css`
  margin: 0 auto;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const iconStyles = {
  fontSize: 60,
  color: '#315EFF',
  zIndex: 9,
};
