import { ColumnDef } from '@tanstack/react-table';

import Checkbox from '@/components/Checkbox';
import { AdminJob } from '@/types/entities.d';
import ColumnHeader from '@/components/Table/ColumnHeader';
import CellRendererText from '@/components/Table/CellRendererText';
import { DateCell, ProfessionCell, SpecialtyCell, StatusCell } from './_index';

// // //

interface AdminJobColumnIds {
  posted_at: string;
  id: string;
  company_name: string;
  facility_name: string;
  city: string;
  state: string;
  specialty: string;
  profession: string;
  is_active: string;
}

// //

const columns: ColumnDef<AdminJob>[] = [
  {
    id: 'select',
    accessorKey: 'select',
    enableSorting: false,
    header: ({ table }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Post Date' }} />,
    id: 'posted_at',
    accessorFn: (row) => row?.posted_at,
    cell: (info) => <DateCell {...{ value: info.getValue() }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Locums ID' }} />,
    id: 'id',
    accessorFn: (row) => row?.id,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },

  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Agency' }} />,
    id: 'company_name',
    accessorFn: (row) =>
      row?.recruiter_and_company_data?.company_information.name,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Facility Name' }} />,
    id: 'facility_name',
    accessorFn: (row) => row?.facility_name,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'City' }} />,
    id: 'city',
    accessorFn: (row) => row?.city,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'State' }} />,
    id: 'state',
    accessorFn: (row) => row?.state,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Specialty' }} />,
    id: 'specialty',
    accessorFn: (row) => row?.specialty,
    cell: (info) => <SpecialtyCell {...{ value: info.getValue() }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Profession' }} />,
    id: 'profession',
    accessorFn: (row) => row?.profession,
    cell: (info) => <ProfessionCell {...{ value: info.getValue() }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Status' }} />,
    id: 'is_active',
    accessorFn: (row) => row?.is_active,
    cell: (info) => <StatusCell {...{ value: info.getValue() }} />,
  },
];

const adminJobsCustomDatatableConfig = {
  columns,
};

export { adminJobsCustomDatatableConfig, AdminJobColumnIds };
