import { CSSProperties, memo } from 'react';
import styled from '@emotion/styled';

import layout from '@/constants/layout';
import { headingDesktop } from '@/constants/typography';

const { mediaQuery } = layout;

const StyledText = styled.span({
  ...headingDesktop.h1,
  fontSize: 20,
  [mediaQuery.mobile]: {
    ...headingDesktop.h1,
    fontSize: 24,
  },
});

const JobListTileHeader = ({
  title,
  style,
}: {
  title: string;
  style?: CSSProperties;
}) => {
  return <StyledText {...{ children: title, style }} />;
};

export default memo(JobListTileHeader);
