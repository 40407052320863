import { useCallback, useMemo, useState } from 'react';

type Actions = { stepAction?: () => void; lastStepAction?: () => void };

interface useStepperStateProps {
  totalSteps: number;
  backActions?: Actions;
  nextActions?: Actions;
  skipActions?: Actions;
  initialCurrentStep?: number;
}

const useStepperState = ({
  nextActions,
  backActions,
  skipActions,
  totalSteps,
  initialCurrentStep = 0,
}: useStepperStateProps) => {
  const [currentStep, setCurrentStep] = useState(initialCurrentStep);

  const canGoBack = useMemo(() => currentStep > 0, [currentStep]);

  const goBack = useCallback(async () => {
    const previousStepIndex = currentStep - 1;
    if (previousStepIndex >= 0) {
      !!backActions?.stepAction && backActions.stepAction();
      setCurrentStep(previousStepIndex);
    }
  }, [currentStep, backActions]);

  const goNext = useCallback(async () => {
    const nextStepIndex = currentStep + 1;
    if (nextStepIndex < totalSteps) {
      !!nextActions?.stepAction && nextActions.stepAction();
      setCurrentStep(nextStepIndex);
      return;
    }
    // LAST STEP SAVE ACTION
    !!nextActions?.lastStepAction && nextActions.lastStepAction();
  }, [currentStep, nextActions, totalSteps]);

  const onSkip = useCallback(async () => {
    const nextStepIndex = currentStep + 1;
    if (nextStepIndex < totalSteps) {
      !!skipActions?.stepAction && skipActions.stepAction();
      goNext();
      return;
    }
    // LAST STEP SAVE ACTION
    !!skipActions?.lastStepAction && skipActions.lastStepAction();
  }, [currentStep, skipActions, totalSteps]);

  return {
    state: { currentStep, canGoBack },
    actions: { goBack, goNext, onSkip },
  };
};

export default useStepperState;
