import { FC, memo } from 'react';

import usePageSession from '@/hooks/usePageSession';
import TextAvatar from '@/components/Avatar/TextAvatar';
import Avatar, { AvatarProps } from '@/components/Avatar';
import { router } from '@inertiajs/react';

interface UserAvatarProps extends AvatarProps {}

const UserAvatar: FC<UserAvatarProps> = (props) => {
  const { session } = usePageSession();
  const { current_user } = session;

  const isThereCurrentUser = !!current_user;
  const lastName = (isThereCurrentUser && current_user?.last_name) || '';
  const avatarUrl = (isThereCurrentUser && current_user?.avatar) || '';
  const profileUrl = (isThereCurrentUser && current_user?.is_type === 'recruiter' ? '/recruiters/details' : '/providers/details') || '';

  if (avatarUrl) {
    return <Avatar {...{ ...props, url: avatarUrl }} onClick={() => router.get(profileUrl)} />;
  }

  const lastNameInitial = lastName.split('')[0] || '';

  return <TextAvatar {...{ text: lastNameInitial }} onClick={() => router.get(profileUrl)} />;
};

export default memo(UserAvatar);
