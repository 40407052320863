import { SVGProps, useMemo } from 'react';

const IconCopy = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M10.75 8h-3a1 1 0 00-1 1v9a1 1 0 001 1h6a1 1 0 001-1v-2"
          stroke="#05051B"
          strokeWidth={1.5}
          fill="none"
        />
        <path
          d="M15.75 5h-4a1 1 0 00-1 1v9a1 1 0 001 1h6a1 1 0 001-1V9m-3-4v3a1 1 0 001 1h2m-3-4l3 4"
          stroke="#05051B"
          strokeWidth={1.5}
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconCopy };
