import NavBar from '@/components/Layout/NavBar';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import { DesignSystem } from '@/components/design-system/_index';

export default function DesignSystemPage() {
  return (
    <TopContentFooterLayout
      {...{
        topBar: <NavBar {...{ navLinks: [{ label: 'Home', url: '/' }] }} />,
      }}
    >
      <DesignSystem />
    </TopContentFooterLayout>
  );
}
