import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';

import {
  FieldsContainer,
  FormContainer,
  FormSectionHeader,
  RowContainer,
} from '..';
import Input from '@/components/Input';
import { AccountDetailsFormValues } from '@/types/forms';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import PasswordRequirements from '@/components/PasswordRequirements';

const AccountDetailsForm = () => {
  const {
    control,
    formState: { isSubmitting },
    watch,
  } = useFormContext<AccountDetailsFormValues>();

  const [passwordEngaged, setPasswordEngaged] = useState(false);

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'first_name',
                  control,
                  rules: { ...fieldsDefaultRules['first_name'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['first_name'],
                          ...field,
                          id: field.name,
                          error,
                          autoComplete: 'off',
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'last_name',
                  control,
                  rules: { ...fieldsDefaultRules['last_name'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['last_name'],
                          ...field,
                          id: field.name,
                          error,
                          autoComplete: 'off',
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'email',
                control,
                rules: { ...fieldsDefaultRules['email'] },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['email'],
                        ...field,
                        autoComplete: 'off',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
        <FieldsContainer>
          <FormSectionHeader {...{ header: 'Update Password' }} />
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'current_password',
                control,
                rules: { required: false },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['password'],
                        label: 'Current Password',
                        placeholder: 'Enter current password',
                        ...field,
                        error,
                        id: field.name,
                        autoCorrect: 'off',
                        autoComplete: 'off',
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'password',
                control,
                rules: { ...fieldsDefaultRules['password'], required: false },
                render: ({ field, fieldState: { error } }) => {
                  const isPasswordRequirementsShown =
                    passwordEngaged || (!passwordEngaged && field.value);

                  return (
                    <div
                      {...{
                        css: css`
                          display: flex;
                          flex-direction: column;
                          gap: 16px;
                        `,
                      }}
                    >
                      <Input
                        {...{
                          ...fieldsDefaultProps['password'],
                          label: 'New Password',
                          placeholder: 'Enter new password',
                          ...field,
                          error,
                          id: field.name,
                          onFocus: () => {
                            setPasswordEngaged(true);
                          },
                          onBlur: () => {
                            setPasswordEngaged(false);
                            field.onBlur();
                          },
                          autoCorrect: 'off',
                          autoComplete: 'new-password',
                        }}
                      />
                      {isPasswordRequirementsShown && (
                        <PasswordRequirements {...{ value: field.value }} />
                      )}
                    </div>
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'password_confirmation',
                control,
                rules: {
                  required: false,
                  validate: async (value) => {
                    const passwordField = watch('password');
                    return value === passwordField || 'Passwords do not match.';
                  },
                },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['password_confirmation'],
                        label: 'Confirm New Password',
                        placeholder: 'Confirm new password',
                        ...field,
                        id: field.name,
                        error,
                        autoCorrect: 'off',
                        autoComplete: 'new-password',
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default AccountDetailsForm;
