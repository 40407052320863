import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconApplicants = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={13}
          y={6}
          width={5}
          height={5}
          rx={2.5}
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="transparent"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.05 19c.476.464 1.127.75 1.845.75h5.21a2.645 2.645 0 002.645-2.645 4.855 4.855 0 00-4.855-4.855h-.79a4.833 4.833 0 00-2.797.886c.385.327.718.714.984 1.146a3.34 3.34 0 011.813-.532h.79a3.355 3.355 0 013.355 3.355c0 .633-.512 1.145-1.145 1.145H13.45c-.405.46-.998.75-1.66.75h-.74z"
          fill={iconColor || appColors.content.primary}
        />
        <path
          d="M4 16.79A4.79 4.79 0 018.79 12h.42A4.79 4.79 0 0114 16.79v0c0 1.22-.99 2.21-2.21 2.21H6.21A2.21 2.21 0 014 16.79v0z"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="transparent"
        />
        <rect
          x={6}
          y={4}
          width={6}
          height={6}
          rx={3}
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="transparent"
        />
      </svg>
    ),
    [props]
  );

export { IconApplicants };
