import { SVGProps, useMemo } from 'react';

const GreenChevron = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10}
        height={17}
        fill="none"
        {...props}
      >
        <rect width={10} height={17} fill="#16CF06" rx={5} />
        <path
          stroke="#fff"
          strokeWidth={1.5}
          d="m2.276 7 2.725-2.723 2.723 2.724M2.276 12.723 5.001 10l2.723 2.723"
        />
      </svg>
    ),
    [props]
  );

export { GreenChevron };
