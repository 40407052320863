import { FC, memo } from 'react';
import { css } from '@emotion/react';

import NavLink from '../NavLink';
import UserAvatar from './UserAvatar';
import { NavLinkProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import usePageSession from '@/hooks/usePageSession';
import { IconClose } from '@/components/Icons/Close';
import { LogoBlueBlack } from '@/components/Icons/Logo';
import LoginSignupButton from '@/components/LoginSignupButton';

const ICON_SIZE = 24;

const iconContainerStyle = css`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;

const drawerInnerContainerStyle = css`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 16px 0px 16px;
`;

const headerContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 64px;
`;

const navLinksContainerStyle = css`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 1;
  flex-grow: 0;
  justify-content: center;
  gap: 80px;
`;

const authButtonsContainerStyle = css`
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  gap: 8px;
  padding-bottom: 32px;
`;

const closeAvatarContainerStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

interface DrawerProps {
  readonly toggleDrawer: () => void;
  readonly navLinks?: NavLinkProps[];
}

const NavBarDrawerContent: FC<DrawerProps> = ({ toggleDrawer, navLinks }) => {
  const { session } = usePageSession();
  const { signed_in } = session;

  const isAdmin = session?.current_user?.is_type === 'admin';
  const isSignedIn = signed_in && !isAdmin;

  return (
    <div
      {...{
        css: drawerInnerContainerStyle,
      }}
    >
      <div
        {...{
          css: headerContainerStyle,
        }}
      >
        <div {...{ style: { width: 150, height: 32 } }}>
          <LogoBlueBlack {...{ width: 150, height: 32 }} />
        </div>
        <div {...{ css: closeAvatarContainerStyle }}>
          {isSignedIn && <UserAvatar {...{ url: '' }} />}
          <div
            {...{
              onClick: toggleDrawer,
              css: iconContainerStyle,
            }}
          >
            <IconClose
              {...{
                iconColor: appColors.content.tertiary,
              }}
            />
          </div>
        </div>
      </div>
      <div {...{ css: navLinksContainerStyle }}>
        {!!navLinks &&
          navLinks.map((link, index) => {
            return (
              <NavLink
                {...{
                  ...link,
                  style: {
                    color: appColors.content.tertiary,
                    fontSize: 24,
                    fontWeight: 400,
                    fontFamily: 'Inter',
                  },
                  key: index,
                }}
              />
            );
          })}
      </div>
      {!isSignedIn && (
        <div {...{ css: authButtonsContainerStyle }}>
          <LoginSignupButton {...{ mode: 'signIn' }} />
          <LoginSignupButton {...{ mode: 'signUp' }} />
        </div>
      )}
    </div>
  );
};

export default memo(NavBarDrawerContent);
