import { memo } from 'react';
import styled from '@emotion/styled';
import { DropdownIndicatorProps, components } from 'react-select';

import layout from '@/constants/layout';
import { INPUT_COMMONS } from '../Input';
import { IconArrowDown } from '../Icons/Arrow';

const {
  components: { iconStyles },
} = layout;

const Container = styled.div`
  display: flex;
  ${iconStyles}
  align-items: center;
  justify-content: center;
  height: 24;
  width: 24;
`;

const DropdownIndicator = (
  props: DropdownIndicatorProps & { hasError: boolean }
) => {
  const { hasValue, isDisabled } = props;

  return (
    <components.DropdownIndicator {...{ ...props }}>
      <Container>
        <IconArrowDown
          {...{
            iconColor: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
            ...(hasValue
              ? { iconColor: INPUT_COMMONS.TEXT_COLOR_DEFAULT }
              : {}),
            ...(isDisabled
              ? { iconColor: INPUT_COMMONS.TEXT_COLOR_DISABLED }
              : {}),
            ...(props.hasError ? { iconColor: INPUT_COMMONS.ERROR_COLOR } : {}),
          }}
        />
      </Container>
    </components.DropdownIndicator>
  );
};

export default memo(DropdownIndicator);
