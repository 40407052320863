import { css } from '@emotion/react';
import { Controller, useFormContext } from 'react-hook-form';

import { MultiValueChip } from '..';
import selectsData from '@/constants/selects';
import InputDropdown from '@/components/InputDropdown';
import useDropdownsData from '@/hooks/useDropdownsData';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { AdminProvidersFilterFormValues } from '@/types/forms';
import { fieldsDefaultRules } from '@/constants/form-validations';
import MultiSelectOption from '@/components/InputDropdown/MultiSelectOption';

const AdminProvidersFilterForm = () => {
  const methods = useFormContext<AdminProvidersFilterFormValues>();
  const { control } = methods;
  const { dropdownOptions } = useDropdownsData();

  return (
    <form>
      <div
        {...{
          css: css`
            display: flex;
            gap: 16px;
            flex-direction: column;
          `,
        }}
      >
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'q.professional_detail_title_eq',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['profession_eq'],
                      options: dropdownOptions.profession,

                      ...field,
                      id: field.name,
                      components: {
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'q.professional_detail_specialty_eq',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['specialty_eq'],
                      options: dropdownOptions.specialty,

                      ...field,
                      id: field.name,
                      components: {
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'q.state_in',
              control,
              rules: {},
              render: ({ field: { ref, ...field } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['state_in'],
                      ...field,
                      options: selectsData['state'],
                      isMulti: true,
                      id: field.name,
                      components: {
                        Option: (rest) => (
                          <MultiSelectOption
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                    }}
                  />
                );
              },
            }}
          />
        }

        {
          //@ts-ignore
          <Controller
            {...{
              name: 'state_licenses',
              control,
              rules: { ...fieldsDefaultRules['state_licenses'] },
              render: ({ field: { ref, ...field }, fieldState: { error } }) => {
                return (
                  <InputDropdown
                    {...{
                      ...fieldsDefaultProps['state_licenses'],
                      ...field,
                      options: selectsData['state'],
                      id: field.name,
                      components: {
                        Option: (rest) => (
                          <MultiSelectOption
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                        MultiValue: (rest) => (
                          <MultiValueChip
                            {...{ ...rest, shownValue: 'value' }}
                          />
                        ),
                      },
                      isMulti: true,
                      error,
                    }}
                  />
                );
              },
            }}
          />
        }
      </div>
    </form>
  );
};
export default AdminProvidersFilterForm;
