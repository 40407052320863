import { ComponentProps, FC, useCallback, useEffect, useReducer } from 'react';
import produce from 'immer';

import { Status } from '@/types/types';
import { AdminProvider } from '@/types/entities.d';
import { getAdminProviderById } from '@/api/providers';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { GetAdminProviderByIdResponse } from '@/types/api/responses';
import AdminJobRowTileModalContent from './AdminProviderRowTileModalContent';

interface AdminProviderRowTileModalContentProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  provider: AdminProvider;
}

interface State {
  status: Status;
  providerByIdResponse: GetAdminProviderByIdResponse;
  isEditMode: boolean;
}

type Action =
  | {
      type: 'SET_JOB';
      payload: GetAdminProviderByIdResponse;
    }
  | {
      payload: boolean;
      type: 'SET_MODE';
    }
  | {
      payload: Status;
      type: 'SET_STATUS';
    };

const initialState: State = {
  providerByIdResponse: {},
  status: 'IDLE',
  isEditMode: false,
};

const reducer = produce((draft, { type, payload }: Action) => {
  switch (type) {
    case 'SET_MODE':
      draft.isEditMode = payload;
      return;
    case 'SET_JOB':
      draft.providerByIdResponse = payload;
      draft.status = 'SUCCESS';
      return;
    case 'SET_STATUS':
      draft.status = payload;
      return;
  }
}, initialState);

const AdminProviderRowTileModalContent: FC<
  AdminProviderRowTileModalContentProps
> = ({ provider, modalProps }) => {
  const [{ providerByIdResponse, status }, localDispatch] = useReducer(
    reducer,
    initialState
  );

  const hasError = status === 'ERROR';
  const isReady = status === 'SUCCESS';
  const isLoading = status === 'IDLE' || status === 'FETCHING';

  const initialRequest = useCallback(async () => {
    localDispatch({ type: 'SET_STATUS', payload: 'FETCHING' });
    try {
      const response = await getAdminProviderById(provider.id);
      if (response) {
        localDispatch({ type: 'SET_JOB', payload: response });
        return;
      }
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
      return;
    } catch (error) {
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
    }
  }, [provider.id]);

  useEffect(() => {
    initialRequest();
  }, [initialRequest]);

  return (
    <ModalLayout
      {...{
        ...modalProps,
        hasLogo: false,
        isLoading: isLoading,
        containerStyles: {
          padding: 40,
        },
      }}
    >
      {isLoading && <div />}
      {hasError && <div />}
      {isReady && (
        <AdminJobRowTileModalContent
          {...{
            providerByIdResponse: providerByIdResponse,
            modalLayoutProps: modalProps,
          }}
        />
      )}
    </ModalLayout>
  );
};

export default AdminProviderRowTileModalContent;
