import { NavBarProps } from '@/types/layout';

import NavBar from '@/components/Layout/NavBar';
import Footer from '@/components/Layout/Footer';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import ProvidersApplied from '@/components/Dashboard/Clinician/ProvidersApplied';

const navProps: NavBarProps = {
  navLinks: [
    { label: 'Design System', url: '/design_system' },
    { label: 'Home', url: '/' },
  ],
  buttons: [
    { children: 'Log in', variant: '1' },
    { children: 'Sign Up', variant: '2' },
  ],
};

export default function ProvidersAppliedJobs() {
  return (
    <>
      <TopContentFooterLayout
        {...{
          topBar: <NavBar {...navProps} />,
          footer: <Footer {...navProps} />,
        }}
      >
        <ProvidersApplied />
      </TopContentFooterLayout>
    </>
  );
}
