import { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { router, usePage } from '@inertiajs/react';

import {
  HEADER_TITLE,
  HeaderContainer,
  NavHeaderContainer,
  dashboardContainer,
  dashboardDetailsContainer,
} from '../_index';
import NavButton from '../NavButton';
import PageHeader from '../PageHeader';
import { MyDetailsProps } from '@/types/page';
import AccountDetails from '../MyDetails/AccountDetails';
import CompanyDetails from '../MyDetails/CompanyDetails';

export const NavHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
`;

const navButtons: ComponentProps<typeof NavButton>[] = [
  {
    children: 'Jobs',
    onClick: () => {
      router.get(
        `/recruiters/jobs`,
        {},
        {
          preserveState: false,
          preserveScroll: false,
        }
      );
    },
  },
  {
    children: 'My Details',
    onClick: () => {},
  },
];

function RecruitersMyDetails() {
  // URL PARAMS
  const page = usePage<MyDetailsProps>();
  const {
    props: {
      session: { current_user },
      company,
    },
  } = page;

  const { phone, location } = current_user || {};

  return (
    <div {...{ css: dashboardContainer }}>
      <HeaderContainer>
        <PageHeader {...{ variant: 'h1Small', children: HEADER_TITLE }} />
        <NavHeaderContainer>
          <NavHeaderButtonsContainer>
            {navButtons.map((navButton, index) => {
              const isActive = index === 1;
              return <NavButton {...{ ...navButton, isActive, key: index }} />;
            })}
          </NavHeaderButtonsContainer>
        </NavHeaderContainer>
      </HeaderContainer>
      <div {...{ css: dashboardDetailsContainer }}>
        {!!current_user && (
          <AccountDetails
            {...{
              details: {
                user_id: current_user?.id || -1,
                is_type: 'recruiter',
                first_name: current_user?.first_name || '',
                last_name: current_user?.last_name || '',
                email: current_user?.email || '',
              },
            }}
          />
        )}
        {!!company && (
          <CompanyDetails
            {...{
              details: {
                user_id: current_user?.id || -1,
                company,
                location,
                phone,
              },
            }}
          />
        )}
      </div>
    </div>
  );
}

export default RecruitersMyDetails;
