import { createSlice } from '@reduxjs/toolkit';

import {
  signInThunk,
  recoveryEmailThunk,
  resetPasswordThunk,
  signUpThunk,
  recoveryEmailResendConfirmationThunk,
  signUpResendConfirmationThunk,
  signupPhysicianContactInfoThunk,
  signupPhysicianProfessionalDetailsThunk,
  signupPhysicianCVThunk,
  signupRecruiterContactInfoThunk,
  signupRecruiterCompanyThunk,
  logoutThunk,
  signupCloseThunk,
} from '@/storage/auth/auth-actions';
import {
  applyJobAsProviderThunk,
  updateProviderThunk,
} from '../jobboard/jobboard-actions';
import { defaultToastError, defaultToastSuccess } from '@/constants/toasts';

const SIGNUP_SUCCESS_MSG = `Your information has been saved`;

type ToastState = {};

const initialState: ToastState = {};

export const modalSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // signInThunk //
    builder.addCase(
      signInThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signInThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        // defaultToastSuccess(requestId);
      }
    );
    builder.addCase(signInThunk.rejected, (state, {payload, meta: { requestId } }) => {
      defaultToastError(requestId);
    });

    // recoveryEmailThunk //
    builder.addCase(
      recoveryEmailThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      recoveryEmailThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {}
    );
    builder.addCase(
      recoveryEmailThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // recoveryEmailResendConfirmationThunk //
    builder.addCase(
      recoveryEmailResendConfirmationThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      recoveryEmailResendConfirmationThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        defaultToastSuccess(requestId);
      }
    );
    builder.addCase(
      recoveryEmailResendConfirmationThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // resetPasswordThunk //
    builder.addCase(
      resetPasswordThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      resetPasswordThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {}
    );
    builder.addCase(
      resetPasswordThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // signUpThunk //
    builder.addCase(
      signUpThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signUpThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {}
    );
    builder.addCase(signUpThunk.rejected, (state, { meta: { requestId } }) => {
      defaultToastError(requestId);
    });

    // signUpResendConfirmationThunk //
    builder.addCase(
      signUpResendConfirmationThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signUpResendConfirmationThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        defaultToastSuccess(requestId);
      }
    );
    builder.addCase(
      signUpResendConfirmationThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // signupCloseThunk //
    builder.addCase(
      signupCloseThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signupCloseThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {}
    );
    builder.addCase(
      signupCloseThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // SIGNUP PHYSICIAN

    // I

    builder.addCase(
      signupPhysicianContactInfoThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signupPhysicianContactInfoThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        defaultToastSuccess(requestId, SIGNUP_SUCCESS_MSG);
      }
    );
    builder.addCase(
      signupPhysicianContactInfoThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // II

    builder.addCase(
      signupPhysicianProfessionalDetailsThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signupPhysicianProfessionalDetailsThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        defaultToastSuccess(requestId, SIGNUP_SUCCESS_MSG);
      }
    );
    builder.addCase(
      signupPhysicianProfessionalDetailsThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // III

    builder.addCase(
      signupPhysicianCVThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signupPhysicianCVThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        defaultToastSuccess(requestId, SIGNUP_SUCCESS_MSG);
      }
    );
    builder.addCase(
      signupPhysicianCVThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // SIGNUP RECRUITER

    // I
    builder.addCase(
      signupRecruiterContactInfoThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signupRecruiterContactInfoThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        defaultToastSuccess(requestId, SIGNUP_SUCCESS_MSG);
      }
    );
    builder.addCase(
      signupRecruiterContactInfoThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // II

    builder.addCase(
      signupRecruiterCompanyThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      signupRecruiterCompanyThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        defaultToastSuccess(requestId, SIGNUP_SUCCESS_MSG);
      }
    );
    builder.addCase(
      signupRecruiterCompanyThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // LOGOUT

    builder.addCase(
      logoutThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      logoutThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {}
    );
    builder.addCase(logoutThunk.rejected, (state, { meta: { requestId } }) => {
      defaultToastError(requestId);
    });

    // applyJobAsProviderThunk

    builder.addCase(
      applyJobAsProviderThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      applyJobAsProviderThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        defaultToastSuccess(requestId);
      }
    );
    builder.addCase(
      applyJobAsProviderThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );

    // updateProviderThunk

    builder.addCase(
      updateProviderThunk.pending,
      (state, { meta: { requestId } }) => {}
    );
    builder.addCase(
      updateProviderThunk.fulfilled,
      (state, { payload, meta: { requestId } }) => {
        // defaultToastSuccess(requestId);
      }
    );
    builder.addCase(
      updateProviderThunk.rejected,
      (state, { meta: { requestId } }) => {
        defaultToastError(requestId);
      }
    );
  },
});

export const {} = modalSlice.actions;

export default modalSlice.reducer;
