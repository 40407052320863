import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconAlert = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x={5.75}
          y={5}
          width={14}
          height={14}
          rx={7}
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          fill="none"
        />
        <path
          d="M12.75 13V8"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
        <rect
          x={13.5}
          y={16}
          width={1.5}
          height={1.5}
          rx={0.75}
          transform="rotate(-180 13.5 16)"
          fill={iconColor || appColors.content.primary}
        />
      </svg>
    ),
    [props]
  );

export { IconAlert };
