import { Fragment, memo, useCallback, useEffect } from 'react';

import Modal, { ModalProps } from './Modal';
import useModalState from '@/hooks/useModalState';
import { useAppDispatch } from '@/storage/store';
import usePageSession from '@/hooks/usePageSession';
import { ResourceType, UserType } from '@/types/types';
import { extractQueryParams } from '@/utils/query-params';
import { afterSignupRedirect } from '@/constants/redirects';
import { signupCloseThunk } from '@/storage/auth/auth-actions';
import SignupPhysicianFlow from './AuthFlows/SignupPhysicianFlow';
import SignupRecruiterFlow from './AuthFlows/SignupRecruiterFlow';
import RecoveryPasswordFlow from './AuthFlows/RecoveryPasswordFlow';

const AuthModal = () => {
  const {
    modalProps,
    isVisible,
    isLoading,
    actions: { open, close },
  } = useModalState();
  const dispatch = useAppDispatch();

  const {
    session: { current_user, signed_in },
    url,
  } = usePageSession();

  const triggerModals = useCallback(
    ({
      url,
      open,
      close,
    }: {
      url: string;
      open: (params: ModalProps) => void;
      close: () => void;
    }) => {
      const { user, resource, reset_password_token, id } = extractQueryParams(
        url
      ) as {
        user: UserType;
        resource: ResourceType;
        reset_password_token: string;
        id: string;
      };

      // CONDITION TO OPEN THE SPECIFIC SIGNUP MODAL
      const isRecruiter = current_user?.is_type === 'recruiter';
      const isSignedIn = signed_in;
      const hasCompanySelected = current_user?.has_company_selected; 
      const isNotRecruiterDetailsPage = url !== '/recruiters/details';

      // Must be logged in
      // Must be a Recruiter
      // Not having a company selected
      // Is not Recruiter's Detail Page

      const condition =
        isSignedIn &&
        isRecruiter &&
        !hasCompanySelected &&
        isNotRecruiterDetailsPage;
      if (condition) {
        open({
          children: (
            <SignupRecruiterFlow
              {...{ id: current_user.id, isForcingFlow: true }}
            />
          ),
          onClose: close,
        });
      }

      // CONDITION TO OPEN THE SPECIFIC SIGNUP MODAL

      const isResetPasswordShown = user && reset_password_token;
      const isSignupPhysicianShown = !!id && resource === 'provider';
      const isSignupRecruiterShown = !!id && resource === 'recruiter';

      // RESET PASSWORD (ONLY FOR PHYSICIAN / RECRUITER)
      if (isResetPasswordShown) {
        open({
          children: (
            <RecoveryPasswordFlow
              {...{ user, resource, reset_password_token }}
            />
          ),
          onClose: close,
        });
      }
      // SIGN UP PHYSICIAN

      if (isSignupPhysicianShown) {
        open({
          children: <SignupPhysicianFlow {...{ id: parseInt(id) }} />,
          onClose: async () => {
            const response = await dispatch(
              signupCloseThunk({ id: parseInt(id), resource: 'provider' })
            ).unwrap();

            if (!!response) {
              close();
              afterSignupRedirect();
            }
          },
        });
      }

      // SIGN UP RECRUITER
      if (isSignupRecruiterShown) {
        open({
          children: <SignupRecruiterFlow {...{ id: parseInt(id) }} />,
          onClose: async () => {
            const response = await dispatch(
              signupCloseThunk({ id: parseInt(id), resource: 'recruiter' })
            ).unwrap();

            if (!!response) {
              close();
              afterSignupRedirect();
            }
          },
        });
      }
    },
    [dispatch, current_user, signed_in]
  );

  useEffect(() => {
    triggerModals({ url, open, close });
    return () => {
      close();
    };
  }, []);

  if (isVisible) {
    return <Modal {...{ ...modalProps, isLoading }}></Modal>;
  }

  return <Fragment />;
};

export default memo(AuthModal);
