import { createSlice } from '@reduxjs/toolkit';

type CachedResourcesState = {};

const initialState: CachedResourcesState = {};

export const cachedResourcesSlice = createSlice({
  name: 'cachedResources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export const {} = cachedResourcesSlice.actions;
export default cachedResourcesSlice.reducer;
