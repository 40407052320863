import { FC, useMemo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { SaveSearchFormValues } from '@/types/forms';
import { useAppDispatch } from '@/storage/store';

import Input from '@/components/Input';
import Checkbox from '@/components/Checkbox';
import Button, { ButtonProps } from '@/components/Button';
import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { router } from '@inertiajs/react';
import CustomTooltip from '@/components/Tooltip';

const FlexContainer = styled.div`
  display: flex;
  align-items: center; /* Vertically center the items */
  gap: 8px; /* Optional: add some space between the checkbox and the icon */
`;

const SaveSearchForm = ({
  methods2,
}: {
  methods2: UseFormReturn<SaveSearchFormValues, any>;
}) => {
  const { control } = methods2;

  return (
    <>
      {
        //@ts-ignore
        <Controller
          {...{
            name: 'search_name',
            rules: {
              required: { value: true, message: 'Search Name is required' },
            },
            control,
            render: ({ field, fieldState: { error } }) => {
              return (
                <Input
                  {...{
                    ...fieldsDefaultProps['search_name'],
                    ...field,
                    id: field.name,
                    error,
                  }}
                />
              );
            },
          }}
        />
      }
      <FlexContainer>
        {
          //@ts-ignore
          <Controller
            {...{
              name: 'send_notification',
              control,
              //rules: { required: true },
              render: ({ field, fieldState: { error } }) => {
                return (
                  <Checkbox
                    {...{
                      ...field,
                      id: field.name,
                      label: 'Send Notifications',
                      error,
                    }}
                  />
                );
              },
            }}
          />
        }
        <CustomTooltip title="Click for instructions" placement="top">
          <IconButton
            {...{
              onClick: () => {
                window.open('/providers/saved_search_instructions', '_blank');
              },
              disableRipple: true,
            }}
          >
            <HelpIcon />
          </IconButton>
        </CustomTooltip>
      </FlexContainer>
    </>
  );
};

export default SaveSearchForm;
