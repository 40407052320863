import { memo, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { router, usePage } from '@inertiajs/react';
import { useForm as useInertiaForm } from '@inertiajs/react';

import { SessionProps } from '@/types/page';
import { AdminLogoSideBar } from '../_index';
import NavButton from '@/components/NavButton';
import { getMetaToken } from '@/utils/meta-token';
import { IconLogout } from '@/components/Icons/Logout';
import { IconApplicants } from '@/components/Icons/Applicants';
import { IconMedicalJob } from '@/components/Icons/MedicalJob';
import { extractPathFromQueryString } from '@/utils/query-params';

const container = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const logoMenuContainer = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const menuContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const logoutContainer = css`
  display: flex;
  flex-direction: column;
`;

const AdminPanelSideBar = () => {
  return (
    <div
      {...{
        css: container,
      }}
    >
      <div {...{ css: logoMenuContainer }}>
        <AdminLogoSideBar />
        <div {...{ css: menuContainer }}>
          <MenuOptions />
        </div>
      </div>
      <div {...{ css: logoutContainer }}>
        <LogoutButton />
      </div>
    </div>
  );
};

export default memo(AdminPanelSideBar);

const MenuOptions = () => {
  const { url } = usePage();
  const goToPage = useCallback((url: string) => {
    router.get(url, {}, { preserveScroll: false, preserveState: true });
  }, []);

  const path = extractPathFromQueryString(url);

  return useMemo(() => {
    return (
      <>
        <NavButton
          {...{
            label: 'User',
            isActive:
              path === '/admins/recruiters' || path === '/admins/providers',
            icon: IconApplicants,
            onClick: () => goToPage('/admins/providers'),
          }}
        />
        <NavButton
          {...{
            label: 'Jobs',
            isActive: path === '/admins/jobs',
            icon: IconMedicalJob,
            onClick: () => goToPage('/admins/jobs'),
          }}
        />
      </>
    );
  }, [path]);
};

const LogoutButton = () => {
  const { delete: destroy, processing } = useInertiaForm();
  const { props } = usePage<SessionProps>();

  const {
    session: { signed_in },
  } = props;

  const logout = useCallback(async () => {
    const metaTokenHeader = await getMetaToken();
    if (!!metaTokenHeader) {
      destroy('/admins/sign_out', {
        headers: {
          ...metaTokenHeader,
        },
        onSuccess: (successResponse) => {
          window.location.reload();
        },
        onError: (errorResponse) => {
          console.log({ errorResponse });
        },
      });
    }
  }, []);

  if (signed_in || !processing) {
    return (
      <NavButton
        {...{
          label: 'Log Out',
          isActive: false,
          icon: IconLogout,
          onClick: logout,
        }}
      />
    );
  }
  return <></>;
};
