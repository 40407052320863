import { FC, memo } from 'react';
import { css } from '@emotion/react';

import TagLabel from '../TagLabel';
import useModal from '@/hooks/useModal';
import layout from '@/constants/layout';
import { Job } from '@/types/entities.d';
import BookMarked from '../Job/BookMarked';
import JobDetails from '../Job/JobDetails';
import Heading from '../Typography/Heading';
import ApplyButton from '../Job/ApplyButton';
import JobPostedId from '../Job/JobPostedId';
import BackComponent from '../BackComponent';
import { appColors } from '@/constants/colors';
import JobDescDetail from '../Job/JobDescDetail';
import ApplyModalContent from '../Job/ApplyModalContent';
import JobShowRecruiterCard from '../Job/JobShowRecruiterCard';
import { datatableUtils } from '@/constants/datatables/_index';

const {
  windowSizes: { tablet, mobile, desktop },
} = layout;

interface JobSelectedProps {
  item: Job;
  onClickFavorited: (item: Job) => void;
  setHasApplied: (id: number) => void;
  type: 'applied' | 'saved';
  onClickBack?: () => void;
}

const container = css`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  background-color: white;
  width: 100%;
  border: 1px solid ${appColors.content.tertiary};
  border-radius: 16px;
  @media (max-width: ${tablet}px) {
    border-color: transparent;
    /* position: absolute; */
    top: 0;
    /* padding-top: 0px; */
  }
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media (max-width: ${tablet}px) {
    gap: 16px;
  }
`;

const statusOptionsContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  flex-wrap: wrap;
`;

const favoriteApplyDesktopContainer = css`
  display: flex;
  flex-direction: row;
  gap: 8px;
  @media (max-width: ${tablet}px) {
    display: none;
  }
`;

const favoriteApplyMobileContainer = css`
  display: none;
  @media (max-width: ${tablet}px) {
    display: flex;
  }
`;

const backButtonContainer = css`
  display: none;
  @media (max-width: ${tablet}px) {
    display: flex;
  }
`;

const detailGridContainerStyles = css`
  display: grid;
  grid-auto-rows: 1fr;
  justify-content: center;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  flex-wrap: wrap;
  @media (max-width: ${desktop}px) {
    grid-template-columns: repeat(2, 1fr);
  }
  // CHECK THE CSS IN THE TWO CONTAINERS
`;

const JobSelected: FC<JobSelectedProps> = ({
  item,
  onClickFavorited,
  setHasApplied,
  type,
  onClickBack,
}) => {
  const { ModalComponent, openModal, closeModal } = useModal();

  const isAppliedType = type === 'applied';

  const apply = (id: number) => {
    setHasApplied(id);
    localStorage.setItem(`${item?.id}`, 'true');
    closeModal();
  };

  return (
    <>
      <div
        {...{
          css: container,
        }}
      >
        <div {...{ css: headerContainer }}>
          <div {...{ css: backButtonContainer }}>
            <BackComponent {...{ onClickBack, backArrowType: 'arrow' }} />
          </div>
          <div {...{ css: statusOptionsContainer }}>
            {isAppliedType ? (
              <TagLabel
                {...{
                  text: item?.applied_date
                    ? `Applied on ${item?.applied_date}`
                    : 'Applied',
                  variant: 4,
                  size: 'lg',
                }}
              />
            ) : (
              <TagLabel
                {...{
                  text: item?.saved_on ? `Saved on ${item?.saved_on}` : 'Saved',
                  variant: 5,
                  size: 'lg',
                }}
              />
            )}
            <div {...{ css: favoriteApplyDesktopContainer }}>
              <BookMarked
                {...{
                  id: item?.id,
                  isActive: item?.is_favorite,
                  hasCardWrap: true,
                  action: () => onClickFavorited(item),
                }}
              />
              <ApplyButton
                {...{
                  onClick: openModal,
                  style: {
                    width: '100% !important',
                    marginLeft: 8,
                  },
                  disabled: item?.has_applied,
                  hasApplied: !!item?.has_applied && item?.has_applied,
                }}
              />
            </div>
          </div>
          <div>
            <Heading
              {...{
                children: item?.title,
                variant: 'h1Small',
              }}
            />

            <JobPostedId
              {...{
                id: item?.id,
                posted_at: item?.posted_at,
              }}
            />
          </div>
          <div {...{ css: favoriteApplyMobileContainer }}>
            <BookMarked
              {...{
                id: item?.id,
                isActive: item?.is_favorite,
                hasCardWrap: true,
                action: () => onClickFavorited(item),
              }}
            />
            <ApplyButton
              {...{
                onClick: openModal,
                style: {
                  width: '100% !important',
                  marginLeft: 8,
                },
                disabled: item?.has_applied,
                hasApplied: !!item?.has_applied && item?.has_applied,
              }}
            />
          </div>
        </div>
        <div
          {...{
            css: css`
              ${detailGridContainerStyles}
              display: none;
              @media (max-width: ${tablet}px) {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
              }
              @media (max-width: ${mobile}px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
              }
            `,
          }}
        >
          <JobDetails
            {...{
              job: item || {},
              defaultCardProps: {
                dense: false,
                //
              },
            }}
          />
        </div>

        <div
          {...{
            css: css`
              ${detailGridContainerStyles}
              @media (max-width: ${tablet}px) {
                display: none;
              }
            `,
          }}
        >
          <JobDetails
            {...{
              job: item || {},
              defaultCardProps: {
                dense: true,
                extraChar: datatableUtils.MIN_HOUR_SUBTEXT,
              },
            }}
          />
        </div>
        {!!item?.description && (
          <JobDescDetail
            {...{
              header: 'Description',
              detail: item?.description,
            }}
          />
        )}
        {!!item?.requirements && (
          <JobDescDetail
            {...{
              header: 'Requirements',
              detail: item?.requirements,
            }}
          />
        )}
        <div
          {...{
            css: css`
              display: flex;
              align-items: flex-start;
            `,
          }}
        >
          <JobShowRecruiterCard
            {...{
              recruiter: item?.recruiter_data,
              company: item?.recruiter_data?.company_information,
            }}
          />
        </div>
      </div>
      <ModalComponent {...{}}>
        <ApplyModalContent
          {...{
            modalProps: { closeModal },
            setHasApplied: apply,
            job_id: item?.id,
          }}
        />
      </ModalComponent>
    </>
  );
};

export default memo(JobSelected);
