import { SVGProps, useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

interface IconBookmarkProps extends SVGProps<SVGSVGElement> {
  strokeColor: string;
  fillColor: string;
}

const IconBookmark = ({
  strokeColor,
  fillColor,
  ...props
}: IconBookmarkProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill={'white'}
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.75 6a1 1 0 011-1h8a1 1 0 011 1v11.346a1 1 0 01-1.465.885l-3.07-1.612a1 1 0 00-.93 0l-3.07 1.612a1 1 0 01-1.465-.885V6z"
          stroke={strokeColor || appColors.content.primary}
          strokeWidth={1.5}
          fill={fillColor || appColors.content.primary}
        />
      </svg>
    ),
    [props]
  );

export { IconBookmark };
