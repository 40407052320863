import { FC } from 'react';
import styled from '@emotion/styled';

import CustomInputArea, { InputAreaProps } from '../InputArea/index';

const props: InputAreaProps = {
  label: 'Option',
  placeholder: 'Placeholder Text',
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const InputArea: FC = () => {
  return (
    <Container>
      <CustomInputArea {...props} />
      <CustomInputArea {...{ ...props, disabled: true }} />
      <CustomInputArea
        {...{ ...props, error: { message: 'Error message', type: 'error' } }}
      />
    </Container>
  );
};

export default InputArea;
