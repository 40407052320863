import { FC, ReactNode, memo } from 'react';
import { css } from '@emotion/react';

import PageHeader from '@/components/Dashboard/PageHeader';

const container = css`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
`;

const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const titleSearchContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const navOptionsContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const tableContainer = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const navButtonsContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
`;

const optionsContainer = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`;

interface AdminPanelContentLayoutProps {
  pageHeader?: string;
  searchComponent?: ReactNode;
  navButtonsComponent?: ReactNode;
  optionsComponent?: ReactNode;
  tableComponent?: ReactNode;
}

const AdminPanelContentLayout: FC<AdminPanelContentLayoutProps> = ({
  pageHeader,
  searchComponent,
  navButtonsComponent,
  optionsComponent,
  tableComponent,
}) => {
  return (
    <div
      {...{
        css: container,
      }}
    >
      <div {...{ css: headerContainer }}>
        <div {...{ css: titleSearchContainer }}>
          <div>
            {!!pageHeader && <PageHeader {...{ children: pageHeader }} />}
          </div>
          <div>{!!searchComponent && searchComponent}</div>
        </div>
        <div {...{ css: navOptionsContainer }}>
          <div {...{ css: navButtonsContainer }}>
            <div
              {...{
                css: css`
                  display: flex;
                  flex-direction: row;
                `,
              }}
            >
              {!!navButtonsComponent && navButtonsComponent}
            </div>
          </div>
          <div {...{ css: optionsContainer }}>
            {!!optionsComponent && optionsComponent}
          </div>
        </div>
      </div>
      <div {...{ css: tableContainer }}>
        {!!tableComponent && tableComponent}
      </div>
    </div>
  );
};

export default memo(AdminPanelContentLayout);
