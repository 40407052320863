import { css } from '@emotion/react';

import styled from '@emotion/styled';
import layout from '@/constants/layout';

const {
  windowSizes: { tablet, mobile, desktop, desktopHD },
} = layout;

const HEADER_TITLE = 'Dashboard';

const dashboardContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  padding: 0 22.5%;
  @media (max-width: ${desktopHD}px) {
    padding: 0 20%;
  }
  @media (max-width: ${desktop}px) {
    padding: 0 10%;
  }
  @media (max-width: ${tablet}px) {
    padding: 0 5%;
  }
  @media (max-width: ${mobile}px) {
    padding: 0 16px;
  }
`;

const providerDashboardContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  padding: 0 22.5%;
  @media (max-width: ${desktopHD}px) {
    padding: 0 17.5%;
  }
  @media (max-width: ${desktop}px) {
    padding: 0 5%;
  }
  @media (max-width: ${tablet}px) {
    padding: 0 2.5%;
    gap: 24px;
  }
  @media (max-width: ${mobile}px) {
    padding: 0 16px;
  }
`;

const providerDashboardContentContainer = css`
  display: grid;
  grid-template-columns: 25% 1fr;
  gap: 24px;
  @media (max-width: ${desktop}px) {
    grid-template-columns: 30% 1fr;
  }
  @media (max-width: ${tablet}px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${mobile}px) {
  }
`;

const providerDashboardMenuContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  grid-row: 1 / span 2;
`;

const providerDashboardJobContainer = css`
  display: flex;
  width: 100%;
  grid-row: 1 / span 1;
`;

const dashboardDetailsContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding: 0 22.5%;
  @media (max-width: ${desktopHD}px) {
    padding: 0 17.5%;
  }
  @media (max-width: ${desktop}px) {
    padding: 0 7.5%;
  }
  @media (max-width: ${tablet}px) {
    padding: 0;
  }
  @media (max-width: ${mobile}px) {
    padding: 0;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

const NavHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const NavHeaderButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 4px;
`;

export {
  dashboardContainer,
  dashboardDetailsContainer,
  HEADER_TITLE,
  HeaderContainer,
  NavHeaderContainer,
  NavHeaderButtonsContainer,
  providerDashboardContainer,
  providerDashboardContentContainer,
  providerDashboardMenuContainer,
  providerDashboardJobContainer,
};
