import { FC, memo } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Card from '../Card';
import layout from '@/constants/layout';
import { Company } from '@/types/entities';
import RecruiterInfo from './RecruiterInfo';
import CustomDivider from '../CustomDivider';
import { Recruiter } from '@/types/entities';
import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const {
  windowSizes: { mobile },
} = layout;

interface JobShowRecruiterCardProps {
  recruiter: Recruiter | {};
  company: Company | {};
}

const cardStyles = css``;

const contentStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  max-width: 500px;
  @media (max-width: ${mobile}px) {
    max-width: 100%;
    gap: 16px;
    flex-direction: column;
    width: 100%;
  }
`;

const recruiterContainerStyles = css`
  display: flex;
  width: 40%;
  flex-grow: 0;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

const dividerContainerStyles = css`
  @media (max-width: ${mobile}px) {
    display: none;
  }
`;

const textContainerStyles = css`
  display: flex;
  width: 60%;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${mobile}px) {
    width: 100%;
  }
`;

const StyledInfoDetails = styled.label({
  ...paragraphDesktop.sm,
  color: appColors.content.secondary,
});

const JobShowRecruiterCard: FC<JobShowRecruiterCardProps> = ({
  recruiter,
  company,
}) => {
  return (
    <Card {...{ css: cardStyles }}>
      <div {...{ css: contentStyles }}>
        {!!recruiter && !!company && (
          <div {...{ css: recruiterContainerStyles }}>
            <RecruiterInfo {...{ recruiter, company }} />
          </div>
        )}

        <div {...{ css: dividerContainerStyles }}>
          <CustomDivider {...{ type: 'vertical' }} />
        </div>

        <div {...{ css: textContainerStyles }}>
          <StyledInfoDetails
            {...{
              children:
                'Your recruiter has the specialized knowledge and personal connections to source premier assignments while negotiating top pay on your behalf',
            }}
          />
        </div>
      </div>
    </Card>
  );
};

export default memo(JobShowRecruiterCard);
