import { appColors } from '@/constants/colors';
import { css } from '@emotion/react';
import {
  SortingState,
  ColumnDef,
  useReactTable,
  getCoreRowModel,
  flexRender,
  OnChangeFn,
  RowSelectionState,
} from '@tanstack/react-table';

import layout from '@/constants/layout';
import { IconArrowCarrotDown, IconArrowCarrotUp } from '../Icons/ArrowCarrot';

const {
  components: { iconStyles },
} = layout;

const ROW_HEIGHT = 40;
const TABLE_BACKGROUND_COLOR = 'white';
const TABLE_BORDER_COLOR = appColors.border.primary;
const TABLE_BORDER_WIDTH = 1;
const ROW_HOVER_COLOR = '#f5f8ff';
const ROW_SELECTED_COLOR = '#f5f8ff';
const ROW_SELECTED_BORDERCOLOR = appColors.content.tertiary;

const ROW_FINAL_HEIGHT = ROW_HEIGHT + TABLE_BORDER_WIDTH * 2;

const PADDING = 8;

const tableStyles = css`
  border-collapse: collapse;
  border-radius: 10px;
`;

const tableBodyStyles = css`
  background-color: ${TABLE_BACKGROUND_COLOR};
  border: ${TABLE_BORDER_WIDTH}px solid ${TABLE_BORDER_COLOR};
`;

const tableHeadStyles = css``;

const tableHeaderStyles = css`
  height: 24px;
  padding: 0px ${PADDING}px;
  padding-bottom: 10px;
`;

const tableHeaderContentStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const tableHeaderContentArrowStyles = css`
  ${iconStyles}
  width: 16px;
  height: 16px;
`;

const tableRowStyles = css`
  align-items: center;
  justify-content: center;
  vertical-align: center;
  border: none;
  cursor: pointer;
  height: ${ROW_FINAL_HEIGHT}px;
  &:hover {
    background-color: ${ROW_HOVER_COLOR};
  }
`;

interface CustomDataTableProps<Model> {
  columns: ColumnDef<Model>[];
  data: Model[];
  sorting: SortingState;
  rowSelection: RowSelectionState;
  onSortingChange: OnChangeFn<SortingState>;
  onRowSelectionChange: OnChangeFn<RowSelectionState> | undefined;
  onClickRow?: (row: Model) => void;
}

function CustomDataTable<Model>({
  columns,
  data,
  sorting,
  rowSelection,
  onSortingChange,
  onRowSelectionChange,
  onClickRow,
}: CustomDataTableProps<Model>) {
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
    },
    debugTable: true,
    manualSorting: true,
    enableMultiRowSelection: true,
    onSortingChange,
    onRowSelectionChange: (r) =>
      !!onRowSelectionChange && onRowSelectionChange(r),
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <table
      {...{
        css: tableStyles,
      }}
    >
      <thead
        {...{
          css: tableHeadStyles,
        }}
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              const isSelectHeader = header.id === 'select';
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  {...{
                    css: tableHeaderStyles,
                    style: {
                      ...(isSelectHeader ? { paddingLeft: 9 } : {}),
                    },
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        css: tableHeaderContentStyles,
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      <div
                        {...{
                          css: tableHeaderContentArrowStyles,
                        }}
                      >
                        {header.id !== 'select' && (
                          <SortIcon
                            {...{ headerProps: header.column.getIsSorted() }}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>

      <tbody
        {...{
          css: tableBodyStyles,
        }}
      >
        {table
          .getRowModel()
          .rows.slice(0, 10 | 25 | 50| 75| 100)
          .map((row) => {
            const isRowSelected = row.getIsSelected();
            return (
              <tr
                {...{
                  css: css`
                    ${tableRowStyles}
                    background-color: ${isRowSelected
                      ? ROW_SELECTED_COLOR
                      : 'transparent'};

                    /* border: ${TABLE_BORDER_WIDTH}px solid
                        ${isRowSelected
                      ? ROW_SELECTED_BORDERCOLOR
                      : 'white'}; */
                    /* border-top-color: black; */
                  `,
                  key: row.id,
                }}
              >
                {row.getVisibleCells().map((cell, index, array) => {
                  const isSelectColumn =
                    cell.column.id === 'select' ||
                    cell.column.id === 'application_count' ||
                    cell.column.id === 'email' ||
                    cell.column.id === 'website'
                    ;
                  const size = array.length;
                  // const isFirstCell = index === 0;
                  // const isLastCell = index === size - 1;
                  return (
                    <td
                      {...{
                        css: css`
                          border: none;
                          flex-shrink: 1;
                          border-radius: 10px;
                          height: ${ROW_FINAL_HEIGHT}px;
                          padding: 0px ${PADDING}px;
                        `,
                        // This prevents triggering onClickRow when is the Checkbox column;
                        ...(isSelectColumn
                          ? {}
                          : {
                              onClick: () =>
                                !!onClickRow && onClickRow(row.original),
                            }),
                        key: cell.id,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

export default CustomDataTable;

const SortIcon = ({ headerProps }: { headerProps: false | 'asc' | 'desc' }) => {
  const noSort = headerProps === null || headerProps === false;

  if (noSort) return <></>;

  return (
    <>
      {headerProps === 'desc' && <IconArrowCarrotDown />}
      {headerProps === 'asc' && (
        <IconArrowCarrotUp {...{ style: { transform: 'rotate(180deg)' } }} />
      )}
    </>
  );
};
