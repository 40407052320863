import { FC } from 'react';

import { IconTrash } from './Icons/Trash';
import Button, { ButtonProps } from './Button';

interface FakeDeleteButtonProps {
  buttonProps?: ButtonProps;
  hasIcon?: boolean;
}

const FakeDeleteButton: FC<FakeDeleteButtonProps> = ({
  buttonProps,
  hasIcon,
}) => {
  return (
    <>
      <Button
        {...{
          children: 'Delete',
          variant: '5',
          ...(hasIcon ? { icon: IconTrash } : {}),
          ...buttonProps,
        }}
      />
    </>
  );
};

export default FakeDeleteButton;
