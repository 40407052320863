import { css } from '@emotion/react';

const authButtonsContainerStyle = css`
  display: grid;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 0px 8px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export { authButtonsContainerStyle };
