import { ComponentProps, FC, useCallback, useEffect, useReducer } from 'react';
import produce from 'immer';

import { Status } from '@/types/types';
import { AdminRecruiter } from '@/types/entities.d';
import { getAdminRecruiterById } from '@/api/recruiters';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { GetAdminRecruiterByIdResponse } from '@/types/api/responses';
import AdminRecruiterRowTileModalContent from './AdminRecruiterRowTileModalContent';

interface AdminRecruiterRowTileModalProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  recruiter: AdminRecruiter;
}

interface State {
  status: Status;
  recruiterByIdResponse: GetAdminRecruiterByIdResponse;
  isEditMode: boolean;
}

type Action =
  | {
      type: 'SET_JOB';
      payload: GetAdminRecruiterByIdResponse;
    }
  | {
      payload: boolean;
      type: 'SET_MODE';
    }
  | {
      payload: Status;
      type: 'SET_STATUS';
    };

const initialState: State = {
  recruiterByIdResponse: {},
  status: 'IDLE',
  isEditMode: false,
};

const reducer = produce((draft, { type, payload }: Action) => {
  switch (type) {
    case 'SET_MODE':
      draft.isEditMode = payload;
      return;
    case 'SET_JOB':
      draft.recruiterByIdResponse = payload;
      draft.status = 'SUCCESS';
      return;
    case 'SET_STATUS':
      draft.status = payload;
      return;
  }
}, initialState);

const AdminRecruiterRowTileModal: FC<AdminRecruiterRowTileModalProps> = ({
  recruiter,
  modalProps,
}) => {
  const [{ recruiterByIdResponse, status }, localDispatch] = useReducer(
    reducer,
    initialState
  );

  const hasError = status === 'ERROR';
  const isReady = status === 'SUCCESS';
  const isLoading = status === 'IDLE' || status === 'FETCHING';

  const initialRequest = useCallback(async () => {
    localDispatch({ type: 'SET_STATUS', payload: 'FETCHING' });
    try {
      const response = await getAdminRecruiterById(recruiter.id);
      if (response) {
        localDispatch({ type: 'SET_JOB', payload: response });
        return;
      }
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
      return;
    } catch (error) {
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
    }
  }, [recruiter.id]);

  useEffect(() => {
    initialRequest();
  }, [initialRequest]);

  return (
    <ModalLayout
      {...{
        ...modalProps,
        hasLogo: false,
        isLoading: isLoading,
        containerStyles: {
          padding: 40,
        },
      }}
    >
      {isLoading && <div />}
      {hasError && <div />}
      {isReady && (
        <AdminRecruiterRowTileModalContent
          {...{
            recruiterByIdResponse: recruiterByIdResponse,
            modalLayoutProps: modalProps,
          }}
        />
      )}
    </ModalLayout>
  );
};

export default AdminRecruiterRowTileModal;
