import styled from '@emotion/styled';
import AuthModal from '@/components/AuthModal';
import LogoutButton from '@/components/LogoutButton';
import Heading from '@/components/Typography/Heading';
import TopContentFooterLayout from '@/layouts/TopContentFooterLayout';
import RecruiterCompanyModal from '@/components/RecruiterCompanyModal';

const Container = styled.div`
  display: flex;
  height: 100%;
  padding: 12px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  background-color: white;
`;

export default function JobBoard() {
  return (
    <>
      <TopContentFooterLayout>
        <Container>
          <Heading {...{ children: 'HOME PAGE', variant: 'h1' }} />

          <div>
            <LogoutButton />
          </div>
        </Container>
      </TopContentFooterLayout>
      <AuthModal />
    </>
  );
}
