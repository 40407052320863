import { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import InputArea from '@/components/InputArea';
import { PostNewJobFormValues } from '@/types/forms';
import { FieldsContainer, FormContainer } from '../';

const INPUT_HEIGHT = 110;

const PostNewJobDescAndReqsForm = ({
  methods,
}: {
  methods: UseFormReturn<PostNewJobFormValues, any>;
}) => {
  const { control } = methods;

  return (
    <form>
      <FormContainer>
        <FieldsContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'description',
                control,
                rules: {
                  required: true,
                },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <InputArea
                      {...{
                        ...field,
                        style: {
                          height: INPUT_HEIGHT,
                        },
                        label: 'Job Description',
                        placeholder: 'Enter job description',
                        labelHint:
                          '(general location, dates of coverage, work schedule, job details, etc)',
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'requirements',
                control,
                rules: {
                  required: true,
                },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <InputArea
                      {...{
                        ...field,
                        style: {
                          height: INPUT_HEIGHT,
                        },
                        label: 'Requirements',
                        placeholder: 'Enter job requirements',
                        labelHint:
                          '(list of certifications, procedures, call coverage, years of experience, etc)',
                        id: field.name,

                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default memo(PostNewJobDescAndReqsForm);
