import { URIScheme } from '@/types/types';

const openLink = (url: string) => {
  window.open(url || '', '_blank');
};

const openUri = (contact: string, scheme: URIScheme) => {
  if (contact && scheme) {
    window.location.href = `${scheme}:${contact}`;
  }
  return;
};

export { openLink, openUri };
