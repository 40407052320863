import {
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FieldError } from 'react-hook-form';

import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import { paragraphDesktop } from '@/constants/typography';

export const INPUT_COMMONS = {
  HEIGHT: 46,
  PADDING: 12,
  BORDER_RADIUS: 8,
  BORDER_WIDTH: 1,
  BORDER_COLOR_DEFAULT_DISABLED: appColors.border.primary,
  BORDER_COLOR_DEFAULT_FOCUS: appColors.border.secondary,
  TEXT_COLOR_DEFAULT: appColors.content.primary,
  TEXT_COLOR_DISABLED: appColors.content.disabled,
  PLACEHOLDER_COLOR_DEFAULT: appColors.content.placeholder,
  PLACEHOLDER_COLOR_FOCUS: appColors.content.placeholder,
  PLACEHOLDER_COLOR_DISABLED: appColors.content.disabled,
  ERROR_COLOR: appColors.notInDesignSystem.error,
  BACKGROUND_COLOR: appColors.background.primary,
  //
  TEXT_STYLE: paragraphDesktop.md,
  LABEL_STYLE: paragraphDesktop.sm,
  ERROR_STYLE: paragraphDesktop.sm,
  //
  TRANSITION: `border-color 0.2s ease`,
};

export interface Input {
  readonly label?: string | ReactNode;
  readonly error?: FieldError;
}

export interface InputProps
  extends Input,
    InputHTMLAttributes<HTMLInputElement> {
  hasExtraChar?: boolean;
  labelHint?: string;
  subText?: string;
  required?: boolean;
  limit?: string | number;
}

export const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
`;

export const StyledLabel = styled.label({
  ...INPUT_COMMONS.LABEL_STYLE,
  color: INPUT_COMMONS.TEXT_COLOR_DEFAULT,
  userSelect: 'none',
});

export const StyledErrorMessage = styled.label({
  ...INPUT_COMMONS.ERROR_STYLE,
  color: INPUT_COMMONS.ERROR_COLOR,
  userSelect: 'none',
});

export const inputTtextStyles = css({
  ...INPUT_COMMONS.TEXT_STYLE,
  userSelect: 'none',
});

export const inputDefaultStyles = css`
  ${inputTtextStyles}
  border: ${INPUT_COMMONS.BORDER_WIDTH}px solid ${INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED};
  background-color: ${INPUT_COMMONS.BACKGROUND_COLOR};
  color: ${INPUT_COMMONS.TEXT_COLOR_DEFAULT};
  padding: ${INPUT_COMMONS.PADDING}px;
  border-radius: ${INPUT_COMMONS.BORDER_RADIUS}px;
  height: ${INPUT_COMMONS.HEIGHT}px;
  transition: ${INPUT_COMMONS.TRANSITION};
`;

export const StyledInput = styled.input<InputProps>`
  ${inputDefaultStyles}
  &:focus {
    outline: none;
    border-color: ${INPUT_COMMONS.BORDER_COLOR_DEFAULT_FOCUS};
    color: ${INPUT_COMMONS.TEXT_COLOR_DEFAULT};
  }
  &:disabled {
    border-color: ${INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED};
    color: ${INPUT_COMMONS.TEXT_COLOR_DISABLED};
    cursor: not-allowed;
  }
  &::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT};
  }
  &:focus::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_FOCUS};
  }
  &:disabled::placeholder {
    color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DISABLED};
  }
  ${(props) =>
    props.error &&
    css`
      border-color: ${INPUT_COMMONS.ERROR_COLOR};
      color: ${INPUT_COMMONS.ERROR_COLOR};
      ::placeholder {
        color: ${INPUT_COMMONS.ERROR_COLOR};
      }
      &:focus {
        outline: none;
        border-color: ${INPUT_COMMONS.ERROR_COLOR};
        color: ${INPUT_COMMONS.ERROR_COLOR};
      }
      &:focus::placeholder {
        color: ${INPUT_COMMONS.ERROR_COLOR};
      }
    `}
  ${(props) =>
    props.hasExtraChar &&
    css`
      padding-left: 24px;
    `}
`;

const requiredLabel = css`
  color: red;
`;

const charactersLimit = css`
  font-size: 12px;
  color: ${INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT};
  font-family: Inter;
`;

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { label, required, labelHint, ...props },
  ref
) => {
  return (
    <StyledInputWrapper>
      <StyledLabel
        {...{
          children: label,
          style: {
            ...(props.disabled
              ? {
                  color: appColors.content.disabled,
                }
              : {}),
          },
        }}
      >
        {label} <span {...{ css: requiredLabel }}>{required ? '*' : ''}</span>
        {!!labelHint && (
          <Paragraph
            {...{
              children: `  ${labelHint}`,
              variant: 'sm',
              style: {
                color: appColors.content.secondary,
                flexWrap: 'wrap',
              },
            }}
          />
        )}
      </StyledLabel>
      {props.hasExtraChar && (
        <Paragraph
          variant="md"
          style={{
            position: 'relative',
            top: 16,
            left: 10,
            padding: 0,
            margin: 0,
            height: 0,
          }}
        >
          $
        </Paragraph>
      )}
      <StyledInput
        {...{
          ...props,
          ref,
        }}
      />
      {!!props.error?.message && (
        <StyledErrorMessage
          {...{
            children: props.error?.message,
          }}
        />
      )}

      {!!props.subText && (
        <Paragraph
          {...{
            variant: 'sm',
            children: props.subText,
            style: {
              color: appColors.content.secondary,
              flexWrap: 'wrap',
            },
          }}
        />
      )}
      {!!props.limit && (
        <span {...{ css: charactersLimit }}>
          {props.limit} / 120 Character limit
        </span>
      )}
    </StyledInputWrapper>
  );
};

export default forwardRef(Input);
