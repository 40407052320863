import produce from 'immer';

import { initialJob } from './_index';
import { Job } from '@/types/entities.d';

type JobShowAction =
  | {
      payload: boolean;
      type: 'SET_IS_FAVORITED';
    }
  | {
      payload: boolean;
      type: 'SET_HAS_APPLIED';
    };

const initialState: Job = {
  ...initialJob,
};

const reducer = produce((draft, { type, payload }: JobShowAction) => {
  switch (type) {
    case 'SET_IS_FAVORITED':
      draft.is_favorite = payload;
      return;
    case 'SET_HAS_APPLIED':
      draft.has_applied = payload;
      return;
  }
}, initialState);

export { reducer, initialState, JobShowAction };
