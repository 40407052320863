import { FC, memo } from 'react';
import { css } from '@emotion/react';
import { useSpring, animated } from '@react-spring/web';

const drawerContainerStyle = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9995;
  overflow-y: auto;
`;

interface DrawerProps {
  readonly isOpen: boolean;
  readonly children: React.ReactNode;
}

const Drawer: FC<DrawerProps> = ({ children, isOpen }) => {
  const drawerAnimation = useSpring({
    transform: isOpen ? 'translateX(0%)' : 'translateX(-100%)',
  });

  return (
    <animated.div
      {...{ css: drawerContainerStyle, style: drawerAnimation, children }}
    />
  );
};

export default memo(Drawer);
