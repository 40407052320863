import { css } from '@emotion/react';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export type DisplayType =
  | 'mobile'
  | 'tablet'
  | 'desktop'
  | 'desktopHD'
  | 'desktopQHD'
  | 'desktopUHD';

const MOBILE = 600;
const TABLET = 768;
const DESKTOP = 1280;
const DESKTOP_HD = 1920;
const DESKTOP_QHD = 2560;
const DESKTOP_UHD = 3840;

const mediaQuery: Record<DisplayType, string> = Object.freeze({
  mobile: `@media (max-width: ${MOBILE}px)`,
  tablet: `@media (max-width: ${TABLET}px)`,
  desktop: `@media (max-width: ${DESKTOP}px)`,
  desktopHD: `@media (max-width: ${DESKTOP_HD}px)`,
  desktopQHD: `@media (max-width: ${DESKTOP_QHD}px)`,
  desktopUHD: `@media (max-width: ${DESKTOP_UHD}px)`,
});

const defaultMultiProps: StateManagerProps = {
  isMulti: true,
  closeMenuOnSelect: false,
  hideSelectedOptions: false,
  blurInputOnSelect: false,
};

//

const layout = Object.freeze({
  mediaQuery,
  windowSizes: {
    mobile: MOBILE,
    tablet: TABLET,
    desktop: DESKTOP,
    desktopHD: DESKTOP_HD,
    desktopQHD: DESKTOP_QHD,
    desktopUHD: DESKTOP_UHD,
  },
  utils: { scrollToTop },
  components: {
    textIconStyles: css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      gap: 4px;
    `,
    iconStyles: css`
      width: 24px;
      height: 24px;
    `,
    textWrap: css`
      flex-shrink: 1;
      flex-wrap: wrap;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `,
    header: {
      mobile: {
        height: 96,
      },
      desktop: {
        height: 90,
      },
    },
    footer: {
      mobile: {
        height: 408,
      },
      desktop: {
        height: 223,
      },
    },
    selects: {
      multiProps: defaultMultiProps,
    },
  },
});

export default { ...layout };
