import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { router } from '@inertiajs/react';

import SigninForm from '../Forms/SigninForm';
import { SigninFormValues } from '@/types/forms';
import { useAppDispatch } from '@/storage/store';
import useModalState from '@/hooks/useModalState';
import ModalLayout from '../Layout/Modal/ModalLayout';
import { defaultToastError } from '@/constants/toasts';
import { signInThunk } from '@/storage/auth/auth-actions';

const SigninFlow = () => {
  const {
    actions: { close },
  } = useModalState();
  const dispatch = useAppDispatch();

  const methods = useForm<SigninFormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const {
    formState: { isSubmitting },
    setError,
  } = methods;

  const onSubmit = useCallback(async (form: SigninFormValues) => {
    try {
      const response = await dispatch(signInThunk(form)).unwrap();
      if (response === 'User not found') {
        defaultToastError(
          'signin',
          'Email not found. Please check and try again.'
        );
      } else if (response === 'User not confirmed') {
        defaultToastError(
          'signin',
          'Please check your email and follow the instructions to confirm your account. Thank you.'
        );
      } else if (!!response) {
        const isARecruiter = !!response?.current_recruiter;
        const isAProvider = !!response?.current_provider;
        close();
        if (isARecruiter) {
          router.get('/recruiters/jobs');
          return;
        }
        if (isAProvider) {
          router.reload();
          return;
        }
        return;
      }
    } catch (error) {
      setError('email', {
        message: '',
        type: 'manual',
      });
      setError(
        'password',
        {
          message: '',
          type: 'manual',
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, []);

  return (
    <ModalLayout
      {...{
        closeModal: close,
        isLoading: isSubmitting,
        hasLogo: true,
      }}
    >
      <SigninForm {...{ methods, onSubmit }} />
    </ModalLayout>
  );
};

export default memo(SigninFlow);
