import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconAssignment = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.469 9.414a.75.75 0 01.117 1.055l-4 5a.75.75 0 01-1.116.061l-2-2a.75.75 0 111.06-1.06l1.408 1.407 3.476-4.346a.75.75 0 011.055-.117z"
          fill={iconColor || appColors.content.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 3.25H7A2.75 2.75 0 004.25 6v13A2.75 2.75 0 007 21.75h10A2.75 2.75 0 0019.75 19V6A2.75 2.75 0 0017 3.25h-2v1.5h2c.69 0 1.25.56 1.25 1.25v13c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V6c0-.69.56-1.25 1.25-1.25h2v-1.5z"
          fill={iconColor || appColors.content.primary}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 3c0-.69.56-1.25 1.25-1.25h5c.69 0 1.25.56 1.25 1.25v2c0 .69-.56 1.25-1.25 1.25h-5c-.69 0-1.25-.56-1.25-1.25V3zm1.5.25v1.5h4.5v-1.5h-4.5z"
          fill={iconColor || appColors.content.primary}
        />
      </svg>
    ),
    [props]
  );

export { IconAssignment };
