import { memo } from 'react';
import styled from '@emotion/styled';

const CardLink = ({ ...props }) => {
  return <StyledLinkCard {...{ ...props }} />;
};

const StyledLinkCard = styled.div(() => ({
  display: 'flex',
  color: 'white',
  border: '1px solid #fff',
  borderRadius: 16,
  padding: 16,
}));

export default memo(CardLink);
