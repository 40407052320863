import { css } from '@emotion/react';
import { CSSProperties, FC, memo } from 'react';

import Label from './Typography/Label';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const {
  components: { textWrap },
} = layout;

interface FakeFieldProps {
  label: string;
  value: string;
  textStyles?: CSSProperties;
  onClickValue?: () => void;
}

const FakeField: FC<FakeFieldProps> = ({
  label,
  value,
  textStyles,
  onClickValue,
}) => {
  const hasLinkStyles = !!onClickValue && value;
  return (
    <div {...{ css: textWrap }}>
      <div
        {...{
          css: css`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 8px;
          `,
        }}
      >
        <Label {...{ text: label }} />
        <label
          {...{
            css: css({
              color: appColors.content.primary,
              ...paragraphDesktop.md,
              ...textStyles,
              overflow: 'hidden',
              textOverflow: 'ellipsis',

              ...(hasLinkStyles
                ? {
                    userSelect: 'text',
                  }
                : {}),
            }),
            children: value,
            ...(hasLinkStyles
              ? {
                  onClick: onClickValue,
                }
              : {}),
          }}
        />
      </div>
    </div>
  );
};

export default memo(FakeField);
