import {
  AnchorHTMLAttributes,
  CSSProperties,
  FC,
  ReactNode,
  memo,
} from 'react';
import styled from '@emotion/styled';

import {
  ParagraphVariants,
  paragraphDesktop,
  paragraphMobile,
} from '@/constants/typography';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';

const { mediaQuery } = layout;

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  readonly children?: ReactNode;
  readonly onClick?: () => void;
  readonly hasUnderline?: boolean;
  readonly style?: CSSProperties;
  readonly variant?: ParagraphVariants;
}

const StyledText = styled.a<{ variant: ParagraphVariants }>(({ variant }) => ({
  textDecoration: 'none',
  color: 'inherit',
  cursor: 'pointer',
  userSelect: 'none',
  ...paragraphDesktop[variant],
  [mediaQuery.mobile]: {
    ...paragraphMobile[variant],
  },
}));

const Link: FC<LinkProps> = ({
  onClick,
  hasUnderline = true,
  variant = 'sm',
  ...props
}) => {
  return (
    <StyledText
      {...{
        onClick,
        variant,
        ...props,
        style: {
          color: appColors.content.tertiary,
          ...props?.style,
          textDecoration: hasUnderline ? 'underline' : 'none',
        },
      }}
    />
  );
};

export default memo(Link);
