import { SVGProps, useMemo } from 'react';

const IconLink = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M13.604 9.622a3.23 3.23 0 01.892 5.201l-3.23 3.23a3.23 3.23 0 11-4.57-4.568l1.262-1.262m9.584-.446l1.262-1.262a3.23 3.23 0 10-4.57-4.569l-3.23 3.231a3.23 3.23 0 00.892 5.2"
          stroke="#05051B"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconLink };
