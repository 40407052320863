import { FC, memo } from 'react';

import { css } from '@emotion/react';
import CustomDivider from '@/components/CustomDivider';
import Paragraph from '@/components/Typography/Paragraph';
import { ParagraphVariants } from '@/constants/typography';

const headerContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  align-items: flex-start;
`;

interface MyDetailsCardSubheaderProps {
  subheader?: string;
  variant?: ParagraphVariants;
}

const MyDetailsCardSubheader: FC<MyDetailsCardSubheaderProps> = ({
  subheader,
  variant = 'lg',
}) => {
  return (
    <div {...{ css: headerContainer }}>
      {!!subheader && (
        <div
          {...{
            css: css``,
          }}
        >
          <Paragraph
            {...{
              children: subheader,
              variant,
              style: {
                fontWeight: 500,
              },
            }}
          />
        </div>
      )}
      <CustomDivider {...{ type: 'horizontal' }} />
    </div>
  );
};

export default memo(MyDetailsCardSubheader);
