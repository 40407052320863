import { FC, memo } from 'react';
import { css } from '@emotion/react';
import { usePage } from '@inertiajs/react';

import NavLinks from '../NavLinks';
import UserAvatar from './UserAvatar';
import Drawer from '@/components/Drawer';
import layout from '@/constants/layout';
import useDrawer from '@/hooks/useDrawer';
import { NavBarProps } from '@/types/layout';
import { appColors } from '@/constants/colors';
import usePageSession from '@/hooks/usePageSession';
import NavBarDrawerContent from './NavBarDrawerContent';
import { authButtonsContainerStyle } from '../AuthButtons';
import { IconMenuStaggered } from '@/components/Icons/Menu';
import LoginSignupButton from '@/components/LoginSignupButton';

const {
  windowSizes: { tablet },
  components: { header },
} = layout;

const ICON_SIZE = 24;

const iconContainerStyle = css`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;

const containerStyle = css`
  display: flex;
  flex-direction: row;
`;

const toggleAvatarContainerStyle = css`
  display: none;
  @media (max-width: ${tablet}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
`;

const menuContainerStyle = css`
  display: flex;
  align-items: center;
  gap: 36px;
  @media (max-width: ${tablet}px) {
    display: none;
  }
`;

const NavBarMenu: FC<NavBarProps> = ({ navLinks = [] }) => {
  const {
    actions: { toggleDrawer },
    state: { isOpen },
  } = useDrawer();

  const { session } = usePageSession();
  const { signed_in } = session;

  const isAdmin = session?.current_user?.is_type === 'admin';

  const isSignedIn = signed_in && !isAdmin;

  const formattedNavLinks = navLinks.map((link) => ({
    ...link,
    style: {
      // color: appColors.content.tertiary,
      fontSize: 16,
      fontWeight: 500,
      fontFamily: 'Hepta Slab',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 12,
      paddingRight: 12,
    },
  }));

  return (
    <>
      <div {...{ css: containerStyle }}>
        <div {...{ css: toggleAvatarContainerStyle }}>
          {signed_in && <UserAvatar {...{ url: '' }} />}
          <div
            {...{
              onClick: toggleDrawer,
              css: iconContainerStyle,
            }}
          >
            <IconMenuStaggered {...{ iconColor: appColors.content.tertiary }} />
          </div>
        </div>
        <div {...{ css: menuContainerStyle }}>
          <NavLinks {...{ navLinks: formattedNavLinks }} />
          {isSignedIn && <UserAvatar {...{ url: '' }} />}
          {!isSignedIn && (
            <div
              {...{
                css: authButtonsContainerStyle,
              }}
            >
              <>
                <LoginSignupButton {...{ mode: 'signIn' }} />
                <LoginSignupButton {...{ mode: 'signUp' }} />
              </>
            </div>
          )}
        </div>
      </div>

      <Drawer {...{ isOpen }}>
        <NavBarDrawerContent {...{ toggleDrawer, navLinks: navLinks }} />
      </Drawer>
    </>
  );
};

export default memo(NavBarMenu);
