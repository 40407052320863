import { memo } from 'react';

import Paragraph from '../Typography/Paragraph';

const JobShowDescriptionHeader = ({ text }: { text: string }) => {
  return (
    <Paragraph
      {...{
        children: text,
        variant: 'lg',
        style: {
          fontWeight: 500,
        },
      }}
    />
  );
};

export default memo(JobShowDescriptionHeader);
