import { FC, memo } from 'react';
import styled from '@emotion/styled';

import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const TEXT_COLOR = appColors.content.primary;
const TEXT_STYLES = paragraphDesktop.sm;

const {
  windowSizes: { mobile },
} = layout;

const RemainingText = styled.span({
  color: TEXT_COLOR,
  ...TEXT_STYLES,
  fontWeight: 400,
  textWrap: 'nowrap',
});

const RemainingValuesText = styled.span({
  color: TEXT_COLOR,
  ...TEXT_STYLES,
  fontWeight: 600,
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  user-select: none;
  @media (max-width: ${mobile}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

interface RemainingProps {
  total: number;
  current: number;
  name: string;
}

const Remaining: FC<RemainingProps> = ({ current, total, name }) => {
  return (
    <Container>
      <RemainingValuesText {...{ children: `${current}/${total}` }} />
      <RemainingText {...{ children: ` ${name} remaining` }} />
    </Container>
  );
};

export default memo(Remaining);
