import { memo, useMemo } from 'react';
import styled from '@emotion/styled';

import Paragraph from './Typography/Paragraph';
import { IconValidationChecked, IconValidationX } from './Icons/Validations';

const ICON_SIZE = 20;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const ValidationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5.5px;
`;

const ValidationChecksContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`;

const IconContainer = styled.div`
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
`;

interface PasswordRequirementsProps {
  readonly value: string;
}

interface ValidationsChecks {
  readonly text: string;
  readonly isValid: boolean;
}

const PasswordRequirements: React.FC<PasswordRequirementsProps> = ({
  value,
}) => {
  // Each validation should macth with passwordRegex on utils/regex.ts
  const valueValidationsChecks = useMemo((): Array<ValidationsChecks> => {
    return [
      {
        text: 'At least 8 characters',
        isValid: new RegExp(/^(?=.*\d).{8,}$/).test(value),
      },
      {
        text: 'At least one uppercase character',
        isValid: new RegExp(/^.*[A-Z].*$/).test(value),
      },
      {
        text: 'At least one lowercase character',
        isValid: new RegExp(/^.*[a-z].*$/).test(value),
      },
      {
        text: 'At least one number',
        isValid: new RegExp(/^.*[0-9]+.*$/).test(value),
      },
      {
        text: 'At least one symbol',
        isValid: new RegExp(/^.*[!"\#$%&'()*+,\-./:;<=>?@\[\\\]^_'{|}~]/).test(
          value
        ),
      },
    ];
  }, [value]);

  return (
    <Container>
      <Paragraph
        {...{
          variant: 'sm',
          style: { fontWeight: '600' },
          children: 'Password must include:',
        }}
      />
      <ValidationsContainer>
        {valueValidationsChecks.map(({ text, isValid }, index) => {
          return (
            <ValidationChecksContainer {...{ key: index }}>
              {isValid ? (
                <IconContainer>
                  <IconValidationChecked />
                </IconContainer>
              ) : (
                <IconContainer>
                  <IconValidationX />
                </IconContainer>
              )}
              <Paragraph
                {...{
                  variant: 'sm',
                  children: text,
                }}
              />
            </ValidationChecksContainer>
          );
        })}
      </ValidationsContainer>
    </Container>
  );
};

export default memo(PasswordRequirements);
