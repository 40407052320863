import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconEye = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M2.813 12l4.343-4.343a8 8 0 0111.313 0L22.812 12l-4.343 4.343a8 8 0 01-11.313 0L2.813 12z"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          strokeLinejoin="round"
          fill="transparent"
        />
        <circle
          cx={12.8126}
          cy={12.0001}
          r={2.77355}
          fill="transparent"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
        />
      </svg>
    ),
    [props]
  );

export { IconEye };
