import { FC, memo } from 'react';

import Label from '../Typography/Label';
import { appColors } from '@/constants/colors';

interface ColumnHeaderProps {
  label?: string;
}

const ColumnHeader: FC<ColumnHeaderProps> = ({ label }) => {
  return (
    <Label
      {...{
        text: label || '',
        style: {
          color: appColors.content.primary,
          textTransform: 'uppercase',
        },
      }}
    />
  );
};

export default memo(ColumnHeader);
