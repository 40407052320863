import { ReactNode, memo } from 'react';
import { css } from '@emotion/react';

import AdminOnlyDesktopMessage from './AdminOnlyDesktopMessage';

const WIDTH = 992;

const messageContainer = css`
  display: none;
  @media (max-width: ${WIDTH}px) {
    display: flex;
  }
`;
const childrenContainer = css`
  display: flex;
  @media (max-width: ${WIDTH}px) {
    display: none;
  }
`;

const AdminOnlyDesktopWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div
        {...{ css: messageContainer, children: <AdminOnlyDesktopMessage /> }}
      />
      <div {...{ css: childrenContainer, children }} />
    </>
  );
};

export default memo(AdminOnlyDesktopWrapper);
