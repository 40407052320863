import { FC, memo, useCallback } from 'react';
import { css } from '@emotion/react';

import useModal from '@/hooks/useModal';
import layout from '@/constants/layout';
import Button from '@/components/Button';
import MyDetailsCard from './MyDetailsCard';
import { AccountDetailsType } from './_index';
import FakeField from '@/components/FakeField';
import AccountDetailsContent from './AccountDetailsContent';

const {
  utils: { scrollToTop },
  windowSizes: { tablet },
} = layout;

const fieldsContainer = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
  grid-auto-rows: 1fr;
  @media (max-width: ${tablet}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

interface AccountDetailsProps {
  details: AccountDetailsType;
}

const AccountDetails: FC<AccountDetailsProps> = ({ details }) => {
  const { ModalComponent, openModal, closeModal } = useModal();

  const onClickEdit = useCallback(() => {
    scrollToTop();
    openModal();
  }, []);

  return (
    <>
      <MyDetailsCard
        {...{
          header: 'Account Details',
          content: (
            <div {...{ css: fieldsContainer }}>
              <FakeField
                {...{
                  label: 'First Name',
                  value: details?.first_name || '',
                }}
              />
              <FakeField
                {...{
                  label: 'Last Name',
                  value: details?.last_name || '',
                }}
              />
              <FakeField {...{ label: 'Email', value: details?.email || '' }} />
              <FakeField {...{ label: 'Password', value: '••••••••••••' }} />
            </div>
          ),
          buttons: <Button {...{ children: 'Edit', onClick: onClickEdit }} />,
        }}
      />
      {/* MODAL  */}
      <ModalComponent
        {...{
          position: 'top',
        }}
      >
        <AccountDetailsContent
          {...{
            details,
            modalLayoutProps: { closeModal },
          }}
        />
      </ModalComponent>
    </>
  );
};

export default memo(AccountDetails);
