import { QueryParams } from '@/types/types';

const extractQueryParams = (url: string): { [key: string]: string } => {
  const params: { [key: string]: string } = {};
  const urlParts = url.split('?');

  if (urlParts.length > 1) {
    const queryString = urlParts[1];
    const paramPairs = queryString.split('&');

    for (const pair of paramPairs) {
      const [key, value] = pair.split('=');
      params[key] = value;
    }
  }

  return params;
};

const parseQueryString = (url: string): Record<string, any> => {
  const queryString = url.substring(url.indexOf('?') + 1);
  const urlParams = new URLSearchParams(queryString);

  // Convert URLSearchParams to an object
  const params: Record<string, string> = {};
  for (const [key, value] of urlParams.entries()) {
    const decodedValue = decodeURIComponent(value.replace(/\+/g, ' '));
    params[key] = decodedValue;
  }
  return params;
};

const transformQueryParams = <T extends QueryParams>(params: T): T => {
  const transformedParams = {} as T;

  for (const [key, value] of Object.entries(params)) {
    if (key.startsWith('q[')) {
      const closingBracketIndex = key.indexOf(']');
      if (closingBracketIndex !== -1) {
        const nestedKey = key.substring(2, closingBracketIndex);
        if (!transformedParams.q) {
          transformedParams.q = {} as T['q'];
        }
        transformedParams.q![nestedKey] = value; // Non-null assertion here
      }
    } else {
      transformedParams[key as keyof T] = value;
    }
  }

  return transformedParams;
};

const cleanParams = <T extends any>(params: T): T => {
  const cleanParams: T = {} as T;

  for (const key in params) {
    if (
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ''
    ) {
      cleanParams[key] = params[key];
    }
  }

  return cleanParams;
};

const extractPathFromQueryString = (url: string): string => {
  if (url) {
    const path = url.split('?')[0];

    return path;
  }
  return '';
};

export {
  extractQueryParams,
  parseQueryString,
  transformQueryParams,
  cleanParams,
  extractPathFromQueryString,
};
