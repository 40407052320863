import { useCallback } from 'react';

import {
  closeModal,
  openAuthModals,
  openModal,
} from '@/storage/modal/modal-actions';
import { AuthModalsMode } from '@/types/types';
import { ModalProps } from '@/components/Modal';
import { useAppDispatch, useAppSelector } from '@/storage/store';

const useModalState = () => {
  const state = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  const open = useCallback((params: ModalProps) => {
    dispatch(openModal({ params }));
  }, []);

  const openAuth = useCallback((params: ModalProps, mode: AuthModalsMode) => {
    dispatch(openAuthModals({ params, mode }));
  }, []);

  const close = useCallback(() => {
    dispatch(closeModal());
  }, []);

  return { ...state, actions: { open, close, openAuth } };
};

export default useModalState;
