import { FC, memo } from 'react';
import styled from '@emotion/styled';

import Heading from '../Typography/Heading';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColorElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ColorsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  flex-shrink: 1;
  flex-wrap: wrap;
`;

const ColorExampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColorExample = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  border-radius: 100px;
  border: 1px solid black;
`;

const Color: FC<{
  name: string;
  colors: Record<string, string>;
}> = ({ name, colors }) => {
  return (
    <ColorElementContainer>
      <Heading
        {...{
          children: name,
          variant: 'h3',
          style: {
            textTransform: 'capitalize',
          },
        }}
      />
      <ColorsContainer>
        {Object.entries(colors).map(([key, value]) => {
          return (
            <ColorExampleContainer
              {...{
                key,
              }}
            >
              <ColorExample
                {...{
                  style: {
                    backgroundColor: value,
                  },
                }}
              />
              <Paragraph {...{ children: key, variant: 'lg' }} />
            </ColorExampleContainer>
          );
        })}
      </ColorsContainer>
    </ColorElementContainer>
  );
};

const MemoizedColorComponent = memo(Color);

const Colors: FC = () => {
  return (
    <Container>
      {Object.entries(appColors).map(([key, value]) => {
        return (
          <MemoizedColorComponent {...{ key, name: key, colors: value }} />
        );
      })}
    </Container>
  );
};

export default Colors;
