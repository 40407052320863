import { Fragment } from 'react';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { css } from '@emotion/react';

import FilterDropdown from './FilterDropdown';
import { appColors } from '@/constants/colors';
import { FilterButtonProps } from './FilterButton';

const filterOptionsContainerStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-grow: 0;
  width: 100%;
`;

const filterOptionsDividerStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 1px;
  background-color: ${appColors.border.primary};
  height: 30px;
`;

interface FilterDropdownsProps {
  filters: FilterButtonProps[];
}

const FilterDropdowns = <T extends FieldValues>({
  filters,
}: FilterDropdownsProps) => {
  const { control } = useFormContext<T>();

  return (
    <form>
      <div {...{ css: filterOptionsContainerStyles }}>
        {filters.map((filter, index, array) => {
          const isLastOne = index === array.length - 1;
          return (
            <Fragment
              {...{
                key: index,
              }}
            >
              <Controller
                {...{
                  control,
                  name: filter.dropDownProps.name,
                  render: ({ field: { value, onChange } }) => {
                    return (
                      <FilterDropdown
                        {...{
                          ...filter.dropDownProps,
                          value,
                          onChange,
                        }}
                      />
                    );
                  },
                }}
              />
              {!isLastOne && <div {...{ css: filterOptionsDividerStyles }} />}
            </Fragment>
          );
        })}
      </div>
    </form>
  );
};

export default FilterDropdowns;
