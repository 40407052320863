const getMetaToken = (): Promise<Record<string, string>> =>
  new Promise((resolve, reject) => {
    const token = document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content');

    if (token) {
      return resolve({ 'X-CSRF-Token': token });
    }
    return reject(null);
  });

const updateCsrfToken = (tokenValue: string) => {
  var csrfMetaTag = document.querySelector('meta[name="csrf-token"]');

  if (csrfMetaTag) {
    csrfMetaTag.setAttribute('content', tokenValue);
  } else {
    var newCsrfMetaTag = document.createElement('meta');
    newCsrfMetaTag.setAttribute('name', 'csrf-token');
    newCsrfMetaTag.setAttribute('content', tokenValue);
    document.head.appendChild(newCsrfMetaTag);
  }
};

export { getMetaToken, updateCsrfToken };
