import { FC, memo } from 'react';
import styled from '@emotion/styled';

import { appColors } from '@/constants/colors';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  hasShadow?: boolean;
  hasBorder?: boolean;
}

const StyledCard = styled.div<CardProps>(({ hasShadow, hasBorder }) => ({
  display: 'flex',
  background: 'white',
  ...(hasShadow
    ? {
        boxShadow: '0px 10px 30px rgba(49, 94, 255, 0.08)',
      }
    : {}),
  border: hasBorder ? `1px solid ${appColors.border.primary}` : 'none',
  borderRadius: 16,
  padding: 16,
}));

const Card: FC<CardProps> = ({ hasBorder = true, ...props }) => {
  return <StyledCard {...{ hasBorder, ...props }} />;
};

export default memo(Card);
