import { FC } from 'react';
import styled from '@emotion/styled';

import CustomInputDropdown, {
  InputDropdownProps,
} from '../InputDropdown/index';

const options = [
  { value: 'option1', label: 'Option 1' },
  { value: 'option2', label: 'Option 2' },
  { value: 'option3', label: 'Option 3' },
];

const props: InputDropdownProps = {
  label: 'Option',
  placeholder: 'Placeholder Text',
  options,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const InputDropdown: FC = () => {
  return (
    <Container>
      <CustomInputDropdown {...props} />
      <CustomInputDropdown {...{ ...props, isDisabled: true }} />
      <CustomInputDropdown {...{ ...props, error: {} }} />
    </Container>
  );
};

export default InputDropdown;
