import { Job } from '@/types/entities.d';

const initialJob: Job = {
  id: -1,
  title: null,
  specialty: null,
  profession: null,
  state: null,
  assignment: [],
  facility: null,
  posted_at: '',
  description: null,
  reference_number: null,
  street: null,
  city: null,
  zip: null,
  employment_type: null,
  created_at: '',
  updated_at: '',
  requirements: null,
  board_certification: null,
  credentialing_time: null,
  min_hourly_rate: 0,
  target_start_date: null,
  any_state_licenses: null,
  //
  is_favorite: false,
  has_applied: false,
};

export { initialJob };
