import { FC } from 'react';
import styled from '@emotion/styled';
import AnimatedBar from '../ProgressBar';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
`;

const ProgressBar: FC = () => {
  return (
    <Container>
      <AnimatedBar {...{ toValue: 50 }} />
      <AnimatedBar {...{ toValue: 100 }} />
      <AnimatedBar {...{ toValue: 20 }} />
      <AnimatedBar {...{ toValue: 30 }} />
    </Container>
  );
};

export default ProgressBar;
