import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetchDropdownData } from '@/api/dropdowns';

//#//#//#//#//#// T H U N K S //#//#//#//#//#//
const fetchDropdownDataThunk = createAsyncThunk(
  `dropdowns/fetchDropdownDataThunk`,
  async () => {
    try {
      const response = await fetchDropdownData();

      if (response) {
        return response;
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export { fetchDropdownDataThunk };
