import { ColumnDef } from '@tanstack/react-table';

import { DateCell } from './_index';
import Checkbox from '@/components/Checkbox';
import { AdminRecruiter } from '@/types/entities.d';
import ColumnHeader from '@/components/Table/ColumnHeader';
import CellRendererText from '@/components/Table/CellRendererText';

// // //

interface AdminRecruiterColumnIds {
  id: string;
  first_name: string;
  email: string;
  company_name: string;
  company_website: string;
  created_at: string;
  jobs_posted: string;
  location: string;
}

// //

const columns: ColumnDef<AdminRecruiter>[] = [
  {
    id: 'select',
    accessorKey: 'select',
    enableSorting: false,
    header: ({ table }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        </div>
      );
    },
    cell: ({ row }) => {
      return (
        <div>
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              disabled: !row.getCanSelect(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      );
    },
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'User ID' }} />,
    id: 'id',
    accessorFn: (row) => row?.id,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'User' }} />,
    id: 'first_name',
    accessorFn: (row) => `${row?.first_name} ${row?.last_name}`,
    cell: (info) => {
      return <CellRendererText {...{ text: info.getValue() || '' }} />;
    },
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'User email' }} />,
    id: 'email',
    accessorFn: (row) => row?.email,
    cell: (info) => (
      <CellRendererText
        {...{ link: info.getValue() || '', linkType: 'email' }}
      />
    ),
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Agency' }} />,
    id: 'company_name',
    accessorFn: (row) => row?.company_information?.name,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Agency website' }} />,
    id: 'company_website',
    accessorFn: (row) => row?.company_information?.website,
    cell: (info) => (
      <CellRendererText
        {...{ link: info.getValue() || '', linkType: 'link' }}
      />
    ),
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Date joined' }} />,
    id: 'created_at',
    accessorFn: (row) => row?.created_at,
    cell: (info) => <DateCell {...{ value: info.getValue() }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: '# Jobs posted' }} />,
    id: 'jobs_posted',
    accessorFn: (row) => row?.jobs_posted,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
  {
    header: ({ header }) => <ColumnHeader {...{ label: 'Location' }} />,
    id: 'location',
    accessorFn: (row) => row?.location,
    cell: (info) => <CellRendererText {...{ text: info.getValue() || '' }} />,
  },
];

const adminRecruitersCustomDatatableConfig = {
  columns,
};

export { adminRecruitersCustomDatatableConfig, AdminRecruiterColumnIds };