import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconArrowCarrotUp = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={17}
        fill="none"
        {...props}
      >
        <path
          fill={iconColor || appColors.content.primary}
          d="m8.938 10.5 4-4h-8l4 4Z"
        />
      </svg>
    ),
    [props]
  );

const IconArrowCarrotDown = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={17}
        height={17}
        fill="none"
        {...props}
      >
        <path
          fill={iconColor || appColors.content.primary}
          d="m8.938 10.5 4-4h-8l4 4Z"
        />
      </svg>
    ),
    [props]
  );

export { IconArrowCarrotUp, IconArrowCarrotDown };
