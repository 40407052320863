import { ComponentProps, FC } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { FormHeader } from '@/components/Forms';
import FakeField from '@/components/FakeField';
import useDropdownsData from '@/hooks/useDropdownsData';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import AccountDetailsResetCard from './AccountDetailsResetCard';
import { GetAdminRecruiterByIdResponse } from '@/types/api/responses';
import { fieldsContainer } from '@/components/Dashboard/MyDetails/_index';
import MyDetailsCardSubheader from '@/components/Dashboard/MyDetails/MyDetailsCardSubheader';

const {
  windowSizes: { tablet },
} = layout;

const subheaderFieldContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface AdminRecruiterRowTileModalContentProps {
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
  recruiterByIdResponse: GetAdminRecruiterByIdResponse;
}

const contentContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  @media (max-width: ${tablet}px) {
    gap: 16px;
  }
`;

const AdminRecruiterRowTileModalContent: FC<
  AdminRecruiterRowTileModalContentProps
> = ({ recruiterByIdResponse, modalLayoutProps }) => {
  const { dropdownsHashes } = useDropdownsData();
  const { company } = recruiterByIdResponse;

  const hasEmployerType = !!company?.employer_type;
  const hasState = !!recruiterByIdResponse?.location;

  return (
    <>
      <div {...{ css: contentContainer }}>
        <FormHeader {...{ title: 'Details' }} />
        <div>
          <AccountDetailsResetCard
            {...{
              user: recruiterByIdResponse,
              is_type: 'recruiter',
              modalLayoutProps,
            }}
          />
        </div>
        <div
          {...{
            css: css`
              display: flex;
              flex-direction: column;
              gap: 24px;
            `,
          }}
        >
          <div>
            <MyDetailsCardSubheader {...{ subheader: 'Company Details' }} />
          </div>
          <div {...{ css: subheaderFieldContainer }}>
            <div {...{ css: fieldsContainer }}>
              <FakeField
                {...{
                  label: 'Location',
                  value: hasState ? recruiterByIdResponse?.location : '',
                }}
              />
              <FakeField
                {...{
                  label: 'Phone Number',
                  value: recruiterByIdResponse.phone || '',
                }}
              />

              <FakeField
                {...{ label: 'Company Name', value: company?.name || '' }}
              />
              <FakeField
                {...{
                  label: 'Company Website',
                  value: company?.website || '',
                }}
              />

              <FakeField
                {...{
                  label: 'Employer Type',
                  value: hasEmployerType
                    ? dropdownsHashes?.employer_type?.[company?.employer_type]
                        .label
                    : '',
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminRecruiterRowTileModalContent;
