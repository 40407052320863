import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconArrowRight = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M9.75 6l6 6-6 6"
          stroke={iconColor || appColors.content.primary}
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

const IconArrowLeft = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          stroke={iconColor || appColors.content.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m15.75 18-6-6 6-6"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

const IconArrowUp = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        {...props}
      >
        <path
          stroke={iconColor || appColors.content.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m6.75 15 6-6 6 6"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

const IconArrowDown = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        {...props}
      >
        <path
          stroke={iconColor || appColors.content.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="m18.75 9-6 6-6-6"
          fill="none"
        />
      </svg>
    ),
    [props]
  );

export { IconArrowRight, IconArrowLeft, IconArrowUp, IconArrowDown };
