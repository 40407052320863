import { FC, memo, useCallback } from 'react';

import useModal from '@/hooks/useModal';
import layout from '@/constants/layout';
import Button from '@/components/Button';
import MyDetailsCard from './MyDetailsCard';
import FakeField from '@/components/FakeField';
import useDropdownsData from '@/hooks/useDropdownsData';
import CompanyDetailsContent from './CompanyDetailsContent';
import { CompanyDetailsType, fieldsContainer } from './_index';

const {
  utils: { scrollToTop },
} = layout;

interface CompanyDetailsProps {
  details: CompanyDetailsType;
}

const CompanyDetails: FC<CompanyDetailsProps> = ({ details }) => {
  const { dropdownsHashes } = useDropdownsData();
  const { ModalComponent, openModal, closeModal } = useModal();
  const { employer_type, name, website } = details?.company || {};

  const hasEmployerType = !!employer_type;
  const employerType = hasEmployerType
    ? dropdownsHashes?.employer_type?.[details?.company.employer_type]?.label
    : '';

  const onClickEdit = useCallback(() => {
    scrollToTop();
    openModal();
  }, []);

  return (
    <>
      <MyDetailsCard
        {...{
          header: 'Contact & Company Details',
          content: (
            <div {...{ css: fieldsContainer }}>
              <FakeField
                {...{ label: 'Location', value: details?.location || '' }}
              />
              <FakeField
                {...{ label: 'Phone Number', value: details?.phone || '' }}
              />

              <FakeField
                {...{
                  label: 'Company Name',
                  value: !!name ? `${name.slice(0, 22)}...` : '',
                }}
              />
              <FakeField
                {...{
                  label: 'Company Website',
                  value: !!website ? `${website.slice(0, 22)}...` : '',
                }}
              />

              <FakeField {...{ label: 'Employer Type', value: employerType }} />
            </div>
          ),
          buttons: <Button {...{ children: 'Edit', onClick: onClickEdit }} />,
        }}
      />
      {/* MODAL  */}
      <ModalComponent
        {...{
          position: 'top',
        }}
      >
        <CompanyDetailsContent
          {...{
            details,
            modalLayoutProps: { closeModal },
          }}
        />
      </ModalComponent>
    </>
  );
};

export default memo(CompanyDetails);
