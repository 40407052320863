import { FC, memo } from 'react';
import { css } from '@emotion/react';

import { NavLinkProps } from '@/types/layout';

const textStyles = css`
  text-decoration: none;
  color: inherit;
  text-align: center;
`;

const NavLink: FC<NavLinkProps> = (props) => {
  return (
    <a
      {...{
        css: textStyles,
        href: props.url,
        children: props.label,
        style: {
          // fontFamily: 'Hepta Slab',
          // fontWeight: 500,
          // fontSize: 16,
          // lineHeight: '140%',
          ...props?.style,
        },
      }}
    />
  );
};

export default memo(NavLink);
