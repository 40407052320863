import { createAsyncThunk } from '@reduxjs/toolkit';

import { AuthModalsMode } from '@/types/types';
import { delayWithValue } from '@/utils/utils';
import { ModalProps } from '@/components/Modal';

//#//#//#//#//#// T H U N K S //#//#//#//#//#//

const openModal = createAsyncThunk(
  `modal/openModal`,
  async ({ params, delay = 50 }: { delay?: number; params: ModalProps }) => {
    if (!!params.children) {
      return await delayWithValue(params, delay);
    }
    return Promise.reject('No content');
  }
);

//
const openAuthModals = createAsyncThunk(
  `modal/openAuthModals`,
  async ({
    params,
    mode,
    delay = 150,
  }: {
    delay?: number;
    params: ModalProps;
    mode: AuthModalsMode;
  }) => {
    if (!!params.children) {
      return await delayWithValue({ params, mode }, delay);
    }
    return Promise.reject('No content');
  }
);

const closeModal = createAsyncThunk(`modal/closeModal`, async () => {
  return true;
});

export { openModal, closeModal, openAuthModals };
