import { FC } from 'react';
import styled from '@emotion/styled';

import Logo from './Logo';
import Icons from './Icons';
import Radio from './Radio';
import Input from './Input';
import Toast from './Toast';
import Colors from './Colors';
import Button from './Button';
import Checkbox from './Checkbox';
import TagLabel from './TagLabel';
import InputArea from './InputArea';
import Typography from './Typography';
import Breadcrumbs from './Breadcrumbs';
import ProgressBar from './ProgressBar';
import InputDropdown from './InputDropdown';
import InputDropdownAsync from './InputDropdownAsync';
//
import Heading from '../Typography/Heading';
import { appColors } from '@/constants/colors';

const ElementsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 8px;
`;

const ElementContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
`;

const elements = [
  {
    name: 'Colors',
    component: Colors,
  },
  {
    name: 'Typography',
    component: Typography,
  },
  {
    name: 'Button',
    component: Button,
  },
  {
    name: 'Logo',
    component: Logo,
  },
  {
    name: 'Breadcrumb',
    component: Breadcrumbs,
  },
  {
    name: 'Icons',
    component: Icons,
  },
  {
    name: 'Checkbox',
    component: Checkbox,
  },
  {
    name: 'Radio',
    component: Radio,
  },
  {
    name: 'Input',
    component: Input,
  },
  {
    name: 'Input Area',
    component: InputArea,
  },
  {
    name: 'InputDropdownAsync',
    component: InputDropdown,
  },
  {
    name: 'Input Dropdown Async',
    component: InputDropdownAsync,
  },
  {
    name: 'ProgressBar',
    component: ProgressBar,
  },
  {
    name: 'Toast',
    component: Toast,
  },
  {
    name: 'TagLabel',
    component: TagLabel,
  },
];

const DesignSystem: FC = () => {
  return (
    <div
      {...{
        style: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: appColors.background.secondary,
          padding: '2%',
        },
      }}
    >
      <Heading {...{ children: 'Design System', variant: 'h1' }} />
      <ElementsContainer>
        {elements.map(({ name, component: Element }, index) => {
          return (
            <ElementContainer {...{ key: index }}>
              <Heading {...{ children: name, variant: 'h1Small' }} />
              <Element />
            </ElementContainer>
          );
        })}
      </ElementsContainer>
    </div>
  );
};

export { DesignSystem };
