import Axios from './axios-api';
import {
  GetAdminProviderByIdResponse,
  GetLoggedInProviderResponse,
} from '@/types/api/responses';

const PROVIDER_DATA = '/providers/current';

// GET

const getLoggedInProvider = async () => {
  const axios = new Axios('');
  try {
    const response = await axios.get<GetLoggedInProviderResponse>(
      PROVIDER_DATA,
      {
        params: {},
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const getAdminProviderById = async (id: number) => {
  const axios = new Axios('');
  try {
    const response = await axios.get<GetAdminProviderByIdResponse>(
      `/admins/providers/${id}`,
      {
        params: {},
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
    return Promise.reject(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export { getLoggedInProvider, getAdminProviderById };
