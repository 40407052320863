import { memo } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Input from '@/components/Input';
import FakeField from '@/components/FakeField';
import InputMask from '@/components/InputMask';
import { masks } from '@/constants/input-masks';
import selectsData from '@/constants/selects/_index';
import InputDropdown from '@/components/InputDropdown';
import { ApplyLoggedinFormValues } from '@/types/forms';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { fieldsDefaultRules } from '@/constants/form-validations';
import { FieldsContainer, FormContainer, RowContainer } from '../..';

const ApplyLoggedinContactInfoForm = ({
  methods,
  isEditMode,
}: {
  methods: UseFormReturn<ApplyLoggedinFormValues, any>;
  isEditMode: boolean;
}) => {
  const {
    control,
    formState: { isSubmitting },
  } = methods;

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'state',
                  control,
                  rules: { ...fieldsDefaultRules['state'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    if (isEditMode) {
                      return (
                        <InputDropdown
                          {...{
                            ...fieldsDefaultProps['state'],
                            options: selectsData['state'],
                            ...field,
                            id: field.name,
                            error,
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label: fieldsDefaultProps['state'].label || '',
                          value: field.value.label || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'city',
                  control,
                  rules: { ...fieldsDefaultRules['city'] },
                  render: ({ field, fieldState: { error } }) => {
                    if (isEditMode) {
                      return (
                        <Input
                          {...{
                            ...fieldsDefaultProps['city'],
                            ...field,

                            id: field.name,
                            error,
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label: fieldsDefaultProps['city'].label || '',
                          value: field.value || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          <RowContainer {...{}}>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'zip',
                  control,
                  rules: { ...fieldsDefaultRules['zip'] },
                  render: ({ field, fieldState: { error } }) => {
                    if (isEditMode) {
                      return (
                        <InputMask
                          {...{
                            ...fieldsDefaultProps['zip'],
                            mask: masks['zip'],
                            ...field,
                            id: field.name,
                            error,
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label: fieldsDefaultProps['zip'].label || '',
                          value: field.value || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'phone',
                  control,
                  rules: { ...fieldsDefaultRules['phone'] },
                  render: ({ field, fieldState: { error } }) => {
                    if (isEditMode) {
                      return (
                        <InputMask
                          {...{
                            ...fieldsDefaultProps['phone'],
                            ...field,
                            mask: masks['phone'],
                            id: field.name,
                            error,
                          }}
                        />
                      );
                    }
                    return (
                      <FakeField
                        {...{
                          label: fieldsDefaultProps['phone'].label || '',
                          value: field.value || '',
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default memo(ApplyLoggedinContactInfoForm);
