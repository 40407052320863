import { CSSProperties, FC, memo } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { IconSearch } from '../Icons/Search';
import { appColors } from '@/constants/colors';

const SIZE = 46;

const {
  components: { iconStyles },
} = layout;

interface SearchIconButtonProps {
  onClick: any;
  isDisabled?: boolean;
  containerStyles?: CSSProperties;
}

const searchIconStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${SIZE}px;
  width: ${SIZE}px;
  border-radius: ${SIZE}px;
  padding: 11px 12px;
  background-color: ${appColors.background.tertiary};
  cursor: pointer;
  &:hover {
    background-color: ${appColors.notInDesignSystem[1]};
  }
  &:active {
    background-color: ${appColors.notInDesignSystem[2]};
  }
`;

const SearchIconButton: FC<SearchIconButtonProps> = ({
  onClick,
  isDisabled,
  containerStyles,
}) => {
  return (
    <div
      {...{
        css: searchIconStyles,
        style: { ...containerStyles },
        onClick: () => {
          if (!isDisabled) {
            !!onClick && onClick();
          }
        },
      }}
    >
      <div
        {...{
          css: css`
            ${iconStyles}
          `,
        }}
      >
        <IconSearch {...{ iconColor: 'white' }} />
      </div>
    </div>
  );
};

export default memo(SearchIconButton);
