import { SelectOption } from '@/types/types';

// 1. Anesthesia
// 2. Emergency Medicine
// 3. Psychiatry
// 4. Family Practice
// 5. Surgery
// 6. OB/GYN
// 7. Hospitalist
// 8. Neurology

const trending: SelectOption[] = [
  {
    value: 'anesthesiology',
    label: 'Anesthesiology',
  },
  {
    value: 'emergency_medicine',
    label: 'Emergency Medicine',
  },
  {
    value: 'psychiatry',
    label: 'Psychiatry',
  },
  {
    value: 'family_practice',
    label: 'Family Practice',
  },
  {
    value: 'general_surgery',
    label: 'General Surgery',
  },
  {
    value: 'obstetrics_gynecology',
    label: 'Ob/Gyn',
  },
  {
    value: 'hospitalist',
    label: 'Hospitalist',
  },
  {
    value: 'neurology',
    label: 'Neurology',
  },
];

export { trending };
