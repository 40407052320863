import { memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { Controller, UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { saveAs } from 'file-saver';

import layout from '@/constants/layout';
import { createShortID } from '@/utils/dates';
import { appColors } from '@/constants/colors';
import InputDrop from '@/components/InputDrop';
import { csvTemplateDownload } from '@/api/csv';
import Link from '@/components/Typography/Link';
import { FormContainer, RowContainer } from '../';
import { UploadCSVFormValues } from '@/types/forms';
import { defaultToastError } from '@/constants/toasts';
import { csvTypesList } from '@/constants/media-types';
import BorderedNumber from '@/components/BorderedNumber';
import Paragraph from '@/components/Typography/Paragraph';

const ROW_HEIGHT = 22;

const errorMessages = {
  filesize: 'Size must be 1MB or less.',
  type: 'File must be a CSV or TXT.',
};

const {
  windowSizes: { mobile },
} = layout;

const MAX_POSTS = 42;

const StyledRowContainer = styled(RowContainer)`
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: ${mobile}px) {
    align-items: flex-start;
  }
`;

const UploadCSVFormForm = ({
  methods,
  csvErrors = [],
  cleanErrors,
}: {
  methods: UseFormReturn<UploadCSVFormValues, any>;
  csvErrors: string[];
  cleanErrors: () => void;
}) => {
  const { control, setValue, setError } = methods;
  const hasErrors = csvErrors.length > 0;

  const onClickDownloadLink = useCallback(async () => {
    const id = createShortID('csv-template');
    const response = await csvTemplateDownload();
    if (!!response) {
      const id = createShortID('csv-template');
      const filename = `${id}.csv`;
      saveAs(response, filename);
    } else {
      defaultToastError(id);
    }
  }, []);

  return (
    <form>
      <FormContainer>
        <StyledRowContainer
          {...{
            css: css``,
          }}
        >
          <BorderedNumber {...{ children: '1' }} />
          <Link
            {...{
              children: 'Download CSV template',
              variant: 'md',
              onClick: onClickDownloadLink,
            }}
          />
        </StyledRowContainer>
        <StyledRowContainer
          {...{
            css: css``,
          }}
        >
          <BorderedNumber {...{ children: '2' }} />
          <Paragraph {...{ variant: 'md' }}>
            {'Match column data with provided template.  '}
            <Paragraph
              {...{
                variant: 'md',
                children: `(${MAX_POSTS} post max)`,
                style: {
                  color: appColors.content.secondary,
                },
              }}
            />
          </Paragraph>
        </StyledRowContainer>
        <StyledRowContainer
          {...{
            css: css`
              align-items: flex-start;
            `,
          }}
        >
          <BorderedNumber {...{ children: '3' }} />
          <div
            {...{
              css: css`
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 8px;
              `,
            }}
          >
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'csv',
                  control,
                  rules: { required: true },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDrop
                        {...{
                          label: 'CSV Upload',
                          ...field,
                          id: field.name,
                          error,
                          removeFileAction: () => {
                            setValue('csv', undefined, {
                              shouldTouch: true,
                              shouldDirty: true,
                              shouldValidate: true,
                            });
                          },
                          dropzoneProps: {
                            maxSize: 1048576,
                            maxFiles: 1,
                            onDrop: (accepted) => {
                              cleanErrors();
                              if (!!accepted[0]) {
                                const file = accepted[0];
                                const isFileTypeSupported =
                                  csvTypesList.includes(file.type);

                                if (isFileTypeSupported) {
                                  setValue('csv', file, {
                                    shouldTouch: true,
                                    shouldDirty: true,
                                    shouldValidate: true,
                                  });
                                  return;
                                }
                                setError('csv', {
                                  type: 'manual',
                                  message: errorMessages.type,
                                });
                                return;
                              }
                              setError('csv', {
                                type: 'manual',
                                message: errorMessages.filesize,
                              });
                              return;
                            },
                          },
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {hasErrors && (
              <div
                {...{
                  css: css`
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    width: '100%';
                    height: ${ROW_HEIGHT * 6}px;
                    overflow: auto;
                  `,
                }}
              >
                {csvErrors.map((error, index) => {
                  return (
                    <div>
                      <Paragraph
                        {...{
                          variant: 'md',
                          children: error,
                          style: { color: appColors.notInDesignSystem.error },
                          key: `CSV-ERROR-${index}`,
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </StyledRowContainer>
      </FormContainer>
    </form>
  );
};

export default memo(UploadCSVFormForm);
