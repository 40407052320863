import { memo } from 'react';

import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';

const JobShowSmallDetail = ({ text }: { text: string }) => {
  return (
    <Paragraph
      {...{
        children: text,
        variant: 'sm',
        style: {
          color: appColors.content.secondary,
        },
      }}
    />
  );
};

export default memo(JobShowSmallDetail);
