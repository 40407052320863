import { ComponentProps, FC, memo, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useForm as useInertiaForm } from '@inertiajs/react';

import { getMetaToken } from '@/utils/meta-token';
import { AccountDetailsFormValues } from '@/types/forms';
import { defaultToastSuccess } from '@/constants/toasts';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { CancelNextButtons, FormHeader } from '@/components/Forms';
import { AccountDetailsType, SAVED_MSG, containerStyles } from './_index';
import AccountDetailsForm from '@/components/Forms/DashboardMyDetails/AccountDetailsForm';

interface AccountDetailsContentProps {
  details: AccountDetailsType;
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
}

const AccountDetailsContent: FC<AccountDetailsContentProps> = ({
  details,
  modalLayoutProps,
}) => {
  const { closeModal } = modalLayoutProps;
  const { patch, transform, processing } = useInertiaForm();

  const initialFormValues = {
    first_name: details?.first_name || '',
    last_name: details?.last_name || '',
    email: details?.email || '',
    current_password: '',
    password: '',
    password_confirmation: '',
  };

  const methods = useForm<AccountDetailsFormValues>({
    defaultValues: {
      ...initialFormValues,
    },
    mode: 'onChange',
  });

  const {
    formState: { isSubmitting, isDirty, isValid },
    handleSubmit,
    reset,
  } = methods;

  const isSubmittingForm = processing || isSubmitting;

  // //

  const resetForm = useCallback(() => {
    reset(
      {},
      {
        keepIsSubmitted: false,
        keepErrors: false,
        keepDefaultValues: true,
        keepDirty: false,
      }
    );
  }, [reset]);

  const closeResetModal = useCallback(() => {
    resetForm();
    !!closeModal && closeModal();
  }, [closeModal]);

  const onClickCancel = useCallback(() => {
    resetForm();
    closeResetModal();
  }, []);

  const onClickSave = useCallback(
    async (form: AccountDetailsFormValues) => {
      const url = `/${details.is_type}s/${details.user_id}/inertia`;
      const params = {
        [details.is_type]: {
          first_name: form.first_name,
          last_name: form.last_name,
          email: form.email,
          ...(!!form.current_password
            ? { current_password: form.current_password }
            : {}),
          ...(!!form.password ? { password: form.password } : {}),
          ...(!!form.password_confirmation
            ? { password_confirmation: form.password_confirmation }
            : {}),
        },
      };

      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        patch(url, {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
          onSuccess: (successResponse) => {
            defaultToastSuccess(undefined, SAVED_MSG);
            closeModal();
          },
          onError: (errorResponse) => {
            console.log(errorResponse);
          },
        });
      }
    },
    [details.is_type, details.user_id]
  );

  return (
    <FormProvider {...methods}>
      <ModalLayout {...{ closeModal: closeResetModal }}>
        <div {...{ css: containerStyles }}>
          <FormHeader {...{ title: 'Edit Account Details' }} />
          <div {...{}}>
            <AccountDetailsForm />
          </div>
          <CancelNextButtons
            {...{
              cancelButtonProps: {
                onClick: onClickCancel,
                disabled: isSubmittingForm,
              },
              nextButtonProps: {
                onClick: handleSubmit(onClickSave),
                disabled: isSubmittingForm || !isDirty || !isValid,
                variant: '6',
                children: 'Save Changes',
                icon: undefined,
              },
            }}
          />
        </div>
      </ModalLayout>
    </FormProvider>
  );
};

export default memo(AccountDetailsContent);
