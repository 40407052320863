import { FC } from 'react';
import styled from '@emotion/styled';

import CustomInputDropdownAsync, {
  InputDropdownAsyncProps,
} from '../InputDropdownAsync/index';
import { IconSearch } from '../Icons/Search';
import { loadOptions } from '@/constants/mocks/mocks';

const props: InputDropdownAsyncProps = {
  label: 'Option',
  placeholder: 'Placeholder Text',
  defaultOptions: false,
  cacheOptions: true,
  loadOptions: loadOptions,
  icon: IconSearch,
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
`;

const InputDropdownAsync: FC = () => {
  return (
    <Container>
      <CustomInputDropdownAsync {...props} />
      <CustomInputDropdownAsync {...{ ...props, isDisabled: true }} />
      <CustomInputDropdownAsync
        {...{ ...props, error: { type: 'manual', message: 'Error goes here' } }}
      />
    </Container>
  );
};

export default InputDropdownAsync;
