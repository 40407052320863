import { useMemo } from 'react';

import { appColors } from '@/constants/colors';
import { IconCustomProps } from '@/types/types';

const IconLogout = ({ iconColor, ...props }: IconCustomProps) =>
  useMemo(
    () => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={25}
        fill="none"
        {...props}
      >
        <path
          stroke={iconColor || appColors.content.primary}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M8.426 12.547h10.456m0 0-4 4m4-4-4-4"
        />
        <path
          fill="none"
          stroke={iconColor || appColors.content.primary}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M12.497 4.469H8.118a3 3 0 0 0-3 3v10.157a3 3 0 0 0 3 3h4.38"
        />
      </svg>
    ),
    [props]
  );

export { IconLogout };
