import { ComponentProps, FC, useCallback, useEffect, useReducer } from 'react';
import produce from 'immer';

import { Status } from '@/types/types';
import { Job } from '@/types/entities.d';
import { geAdminJobById } from '@/api/jobs';
import { GetAdminJobByIdResponse } from '@/types/api/responses';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import AdminJobRowTileModalContent from './AdminJobRowTileModalContent';

interface AdminJobRowTileModalProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  job: Job;
}

interface State {
  jobByIdResponse: GetAdminJobByIdResponse;
  status: Status;
  isEditMode: boolean;
}

type Action =
  | {
      payload: GetAdminJobByIdResponse;
      type: 'SET_JOB';
    }
  | {
      payload: boolean;
      type: 'SET_MODE';
    }
  | {
      payload: Status;
      type: 'SET_STATUS';
    };

const initialState: State = {
  jobByIdResponse: {
    recruiter: {},
    job: {},
    company: {},
  },
  status: 'IDLE',
  isEditMode: false,
};

const reducer = produce((draft, { type, payload }: Action) => {
  switch (type) {
    case 'SET_MODE':
      draft.isEditMode = payload;
      return;
    case 'SET_JOB':
      draft.jobByIdResponse = payload;
      draft.status = 'SUCCESS';
      return;
    case 'SET_STATUS':
      draft.status = payload;
      return;
  }
}, initialState);

const AdminJobRowTileModal: FC<AdminJobRowTileModalProps> = ({
  job,
  modalProps,
}) => {
  const [{ jobByIdResponse, status }, localDispatch] = useReducer(
    reducer,
    initialState
  );

  const hasError = status === 'ERROR';
  const isReady = status === 'SUCCESS';
  const isLoading = status === 'IDLE' || status === 'FETCHING';

  const initialRequest = useCallback(async () => {
    localDispatch({ type: 'SET_STATUS', payload: 'FETCHING' });
    try {
      const response = await geAdminJobById(job.id);
      if (response) {
        localDispatch({ type: 'SET_JOB', payload: response });
        return;
      }
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
      return;
    } catch (error) {
      localDispatch({ type: 'SET_STATUS', payload: 'ERROR' });
    }
  }, [job.id]);

  useEffect(() => {
    initialRequest();
  }, [initialRequest]);

  return (
    <ModalLayout
      {...{
        ...modalProps,
        hasLogo: false,
        isLoading: isLoading,
        containerStyles: {
          padding: 40,
        },
      }}
    >
      {isLoading && <div />}
      {hasError && <div />}
      {isReady && (
        <AdminJobRowTileModalContent
          {...{
            modalLayoutProps: modalProps,
            jobByIdResponse: jobByIdResponse,
          }}
        />
      )}
    </ModalLayout>
  );
};

export default AdminJobRowTileModal;
