import { useState, useEffect } from 'react';

import layout, { DisplayType } from '@/constants/layout';

const {
  windowSizes: { mobile, tablet, desktop, desktopHD, desktopQHD, desktopUHD },
} = layout;

type WindowSizeState = {
  width: number;
  height: number;
  display: DisplayType;
  //
  isMobile: boolean;
  isDesktop: boolean;
  isTablet: boolean;
};

const getDisplayType = (width: number): DisplayType => {
  const pixelRatio = window.devicePixelRatio || 1;
  const isTouchDevice =
    'maxTouchPoints' in navigator && navigator.maxTouchPoints > 0;
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent) && !isIOS;

  if (isTouchDevice) {
    if (isIOS) {
      if (width >= tablet) {
        return 'tablet';
      } else {
        return 'mobile';
      }
    } else if (isAndroid) {
      if (width >= mobile / pixelRatio) {
        return 'tablet';
      } else {
        return 'mobile';
      }
    } else {
      if (width >= 960 / pixelRatio) {
        return 'tablet';
      } else {
        return 'mobile';
      }
    }
  } else {
    if (width >= desktopUHD / pixelRatio) {
      return 'desktopUHD';
    } else if (width >= desktopQHD / pixelRatio) {
      return 'desktopHD';
    } else if (width >= desktopHD / pixelRatio) {
      return 'desktopHD';
    } else if (width >= desktop / pixelRatio) {
      return 'desktop';
    } else if (width >= 600 / pixelRatio) {
      return 'tablet';
    } else {
      return 'mobile';
    }
  }
};

const newWindowSize = (windowWidth: number) => {
  const display = getDisplayType(windowWidth);
  return {
    width: windowWidth,
    height: windowWidth,
    display,
    isMobile: display === 'mobile',
    isTablet: display === 'tablet',
    isDesktop: display === 'desktop',
    isDesktopHD: display === 'desktopHD',
    isDesktopQHD: display === 'desktopQHD',
    isDesktopUHD: display === 'desktopUHD',
  };
};

const useWindowSize = (): WindowSizeState => {
  const [windowSize, setWindowSize] = useState<WindowSizeState>(
    newWindowSize(window.innerWidth)
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(newWindowSize(window.innerWidth));
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
