import { Controller, useFormContext } from 'react-hook-form';

import {
  CompanySelectOption,
  FieldsContainer,
  FormContainer,
  RowContainer,
} from '..';
import Input from '@/components/Input';
import { Company } from '@/types/entities';
import InputMask from '@/components/InputMask';
import { masks } from '@/constants/input-masks';
import { fetchCompanies } from '@/api/companies';
import selectsData from '@/constants/selects/_index';
import InputDropdown from '@/components/InputDropdown';
import { IconSearch } from '@/components/Icons/Search';
import useDropdownsData from '@/hooks/useDropdownsData';
import { CompanyDetailsFormValues } from '@/types/forms';
import { fieldsDefaultProps } from '@/constants/form-fields';
import InputDropdownAsync from '@/components/InputDropdownAsync';
import { fieldsDefaultRules } from '@/constants/form-validations';

const CompanyDetailsForm = () => {
  const {
    control,
    formState: { isSubmitting },
    setValue,
  } = useFormContext<CompanyDetailsFormValues>();

  const { dropdownsHashes, dropdownOptions } = useDropdownsData();

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FieldsContainer>
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'location',
                  control,
                  rules: { ...fieldsDefaultRules['state'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['state'],
                          options: selectsData['state'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'phone',
                  control,
                  rules: { ...fieldsDefaultRules['phone'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <InputMask
                        {...{
                          ...fieldsDefaultProps['phone'],
                          ...field,
                          mask: masks['phone'],
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'company',
                control,
                rules: { ...fieldsDefaultRules['company'] },
                render: ({
                  field: { ref, ...field },
                  fieldState: { error },
                }) => {
                  return (
                    <InputDropdownAsync
                      {...{
                        ...fieldsDefaultProps['company'],
                        ...field,
                        onChange: (value: Company) => {
                          !!value.name &&
                            setValue('name', value.name, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });

                          !!value.website &&
                            setValue('website', value.website, {
                              shouldDirty: true,
                              shouldValidate: true,
                            });

                          !!value?.employer_type &&
                            setValue(
                              'employer_type',
                              dropdownsHashes?.employer_type?.[
                                value?.employer_type
                              ] || '',
                              {
                                shouldDirty: true,
                                shouldValidate: true,
                              }
                            );
                          setValue('company', value, {
                            shouldDirty: true,
                            shouldValidate: true,
                          });

                          field.onChange(value);
                        },
                        id: field.name,
                        icon: IconSearch,
                        loadOptions: fetchCompanies,
                        error,
                        cacheOptions: true,
                        components: {
                          Option: CompanySelectOption,
                        },
                      }}
                    />
                  );
                },
              }}
            />
          }
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'website',
                  control,
                  rules: { ...fieldsDefaultRules['website'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['website'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'employer_type',
                  control,
                  rules: { ...fieldsDefaultRules['employer_type'] },
                  render: ({
                    field: { ref, ...field },
                    fieldState: { error },
                  }) => {
                    return (
                      <InputDropdown
                        {...{
                          ...fieldsDefaultProps['employer_type'],
                          options: dropdownOptions.employer_type,
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
        </FieldsContainer>
      </FormContainer>
    </form>
  );
};

export default CompanyDetailsForm;
