import { CSSProperties, FC, memo } from 'react';
import styled from '@emotion/styled';

import {
  ParagraphVariants,
  paragraphDesktop,
  paragraphMobile,
} from '@/constants/typography';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';

const { mediaQuery } = layout;

export interface ParagraphProps {
  readonly style?: CSSProperties;
  readonly variant?: ParagraphVariants;
  readonly children: React.ReactNode;
  readonly onClick?: () => void;
}

const StyledText = styled.label<{ variant: ParagraphVariants }>(
  ({ variant, onClick }) => ({
    userSelect: 'none',
    ...(!!onClick ? { cursor: 'pointer' } : {}),
    ...paragraphDesktop[variant],
    [mediaQuery.mobile]: {
      ...paragraphMobile[variant],
    },
  })
);

const Paragraph: FC<ParagraphProps> = ({ variant = 'md', ...props }) => {
  return (
    <StyledText
      {...{
        ...props,
        variant,
        style: {
          color: appColors.content.primary,
          whiteSpace: 'pre-wrap',
          ...props.style,
        },
      }}
    />
  );
};

export default memo(Paragraph);
