import { useCallback } from 'react';
import { useForm as useInertiaForm, usePage } from '@inertiajs/react';
import { useForm } from 'react-hook-form';

import { getMetaToken } from '@/utils/meta-token';
import { ResetPasswordParams } from '@/types/api/params';
import { RecoveryPasswordFormValues } from '@/types/forms';
import SignInContent from '@/components/AdminPanel/SignInContent';
import { FieldsContainer, FormContainer } from '@/components/Forms';
import RecoveryPasswordForm from '@/components/Forms/RecoveryPasswordForm ';
import AdminOnlyDesktopWrapper from '@/components/AdminPanel/AdminOnlyDesktopWrapper';

const URL = '/admins/password';

export default function PasswordsEdit() {
  const { patch, transform, processing } = useInertiaForm();
  const { props } = usePage<{ reset_password_token: string }>();
  const { reset_password_token } = props;

  const methods = useForm<RecoveryPasswordFormValues>({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    mode: 'onChange',
  });

  const {
    setError,
    formState: { isSubmitting },
  } = methods;

  const isRequesting = processing || isSubmitting;

  const onSubmit = useCallback(
    async (form: RecoveryPasswordFormValues) => {
      const params: ResetPasswordParams = {
        password: form.password,
        password_confirmation: form.password_confirmation,
        reset_password_token: reset_password_token,
      };

      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        patch(URL, {
          preserveState: true,
          data: transform(() => ({
            admin: { ...params },
          })),
          headers: {
            ...metaTokenHeader,
          },
          onSuccess: ({ props }) => {
            const { admin_errors } = props;

            const hasErrors =
              Array.isArray(admin_errors) && admin_errors.length > 0;

            if (hasErrors) {
              setError('root', {
                message: '',
                type: 'manual',
              });
              return;
            }
            // REFRESH NEEDED FOR META TOKEN
            window.location.reload();
            return;
          },
          onError: (errorResponse) => {
            console.log({ errorResponse });
          },
        });
      }
    },
    [reset_password_token]
  );

  return (
    <AdminOnlyDesktopWrapper>
      <SignInContent>
        <form {...{ style: { width: '100%' } }}>
          <FormContainer
            {...{
              style: { ...(isRequesting ? { pointerEvents: 'none' } : {}) },
            }}
          >
            <FieldsContainer>
              <RecoveryPasswordForm {...{ onSubmit, methods }} />
            </FieldsContainer>
          </FormContainer>
        </form>
      </SignInContent>
    </AdminOnlyDesktopWrapper>
  );
}
