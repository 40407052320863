import { FC } from 'react';
import { css } from '@emotion/react';

import NavBarMenu from './NavBarMenu';
import layout from '@/constants/layout';
import { router } from '@inertiajs/react';
import { NavBarProps } from '@/types/layout';
import { LogoBlueBlack, LogoBlueWhite, LogoWhiteBlack } from '@/components/Icons/Logo';

const {
  windowSizes: { tablet },
  components: { header },
} = layout;

const topbarStyle = css`
  display: flex;
  height: ${header.desktop.height}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${tablet}px) {
    height: ${header.mobile.height}px;
    padding-top: 40px;
    padding-bottom: 16px;
  }
`;

const topbarRightStyle = css`
  display: flex;
  align-items: center;
  gap: 32px;
  height: inherit;
`;

const logoContainer = css`
  height: 50px;
  width: 234px;
  align-items: center;
  cursor: pointer;
  @media (max-width: ${tablet}px) {
    height: 32px;
    width: 150px;
  }
`;

const NavBar: FC<NavBarProps> = ({ logoVariant = 'blue-black', ...props }) => {
  return (
    <div
      {...{
        css: topbarStyle,
      }}
    >
      <div
        {...{
          css: logoContainer,
        }}
        onClick={() => router.get('/')}
      >
        {logoVariant === 'blue-black' && <LogoBlueBlack {...{}} />}
        {logoVariant === 'blue-white' && <LogoBlueWhite {...{}} />}
        {logoVariant === 'black-white' && <LogoWhiteBlack {...{}} />}
      </div>
      <div
        {...{
          css: topbarRightStyle,
        }}
      >
        <NavBarMenu {...props} />
      </div>
    </div>
  );
};

export default NavBar;
