import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// Middlewares
// import { logger } from './logger';

// Slices
import authReducer from './auth/AuthSlice';
import modalReducer from './modal/ModalSlice';
import toastReducer from './toast/ToastSlice';
import jobboardReducer from './jobboard/JobboardSlice';
import dropdownsReducer from './dropdowns/DropdownsSlice';
import cachedResourcesReducer from './cached-resources/CachedResourcesSlice';

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

const useAppDispatch: () => AppDispatch = useDispatch;
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const store = configureStore({
  reducer: {
    auth: authReducer,
    modal: modalReducer,
    toast: toastReducer,
    jobboard: jobboardReducer,
    //
    cachedResources: cachedResourcesReducer,
    dropdowns: dropdownsReducer,
  },
  // NOTE need to remove this for Prod release
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware({ serializableCheck: false }).concat(logger);
  // },
});

export { store, useAppDispatch, useAppSelector };
