import { FC, memo } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { IconArrowLeft } from './Icons/Arrow';
import { appColors } from '@/constants/colors';
import Paragraph from './Typography/Paragraph';
import { IconVectorLeft } from './Icons/Vector';

const {
  components: { iconStyles },
} = layout;

const TEXT_COLOR = appColors.content.tertiary;

type BackArrow = 'vector' | 'arrow';

interface BackComponentProps {
  backArrowType?: BackArrow;
  onClickBack: () => void;
  backText?: string;
}

const BackComponent: FC<BackComponentProps> = ({
  onClickBack,
  backArrowType = 'vector',
  backText,
}) => {
  const isVectorType = backArrowType === 'vector';
  return (
    <div
      {...{
        css: css`
          display: flex;
        `,
      }}
    >
      <div
        {...{
          onClick: onClickBack,
          css: css`
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            gap: ${isVectorType ? 8 : 4}px;
            cursor: pointer;
          `,
        }}
      >
        <div
          {...{
            css: css`
              ${iconStyles}
            `,
          }}
        >
          {isVectorType ? (
            <IconVectorLeft {...{ iconColor: TEXT_COLOR }} />
          ) : (
            <IconArrowLeft {...{ iconColor: TEXT_COLOR }} />
          )}
        </div>
        <Paragraph
          {...{
            variant: 'md',
            children: backText || 'Back',
            style: { color: TEXT_COLOR, cursor: 'pointer' },
          }}
        />
      </div>
    </div>
  );
};

export default memo(BackComponent);
