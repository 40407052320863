import { ReactNode } from 'react';
import { css } from '@emotion/react';

import Card from '../Card';
import layout from '@/constants/layout';
import { AdminLogoDesktop, pageContainer } from './_index';

const WIDTH = 360;

const {
  windowSizes: { mobile },
} = layout;

const contentContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-shadow: 0px 0px 4px -10px rgba(0, 0, 0, 0.8);
  @media (max-width: ${mobile}px) {
    padding: 16px;
  }
`;

const cardContentContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  width: ${WIDTH}px;
  @media (max-width: ${mobile}px) {
    gap: 24px;
    width: 100%;
  }
`;

const formContainer = css`
  display: flex;
  width: 100%;
`;

function SignInContent({ children }: { children: ReactNode }) {
  return (
    <div {...{ css: pageContainer }}>
      <div {...{ css: contentContainer }}>
        <Card
          {...{
            css: css`
              border-radius: 10px;
              padding: 40px;
              @media (max-width: ${mobile}px) {
                padding: 16px;
                width: 100%;
              }
            `,
            hasBorder: false,
          }}
        >
          <div {...{ css: cardContentContainer }}>
            <AdminLogoDesktop />
            <div {...{ css: formContainer }}>{!!children && children}</div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default SignInContent;
