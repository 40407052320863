import { FC } from 'react';
import { css } from '@emotion/react';

import ListSorter from './ListSorter';
import CustomPaginator from '../Paginator';
import ListShowCounter from './ListShowCounter';
import { PaginatorType, SortType } from '@/types/types';

const listContainerStyles = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const showingSortContainerStyles = css`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const showSortListContainerStyles = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

const paginatorContainerStyles = css``;

interface ListProps<T> {
  data: T[];
  pagination: PaginatorType;
  sort?: SortType;
  changeSort?: () => void;
  changePage?: (newPage: number) => void;
  RenderedItem?: FC<{ item: T; index: number }>;
  ListEmptyComponent?: FC;
}

const List = <T extends any>({
  data = [],
  pagination,
  sort,
  changeSort,
  changePage,
  RenderedItem,
  ListEmptyComponent,
}: ListProps<T>) => {
  const isEmpty = data.length === 0;

  const isPaginatorShown = !!changePage && pagination;
  const isSorterShown = !!changeSort && sort;

  return (
    <div {...{ css: listContainerStyles }}>
      <div
        {...{
          css: showSortListContainerStyles,
        }}
      >
        {!!isSorterShown && (
          <div {...{ css: showingSortContainerStyles }}>
            <ListShowCounter {...pagination} currentCount={data.length} />
            <ListSorter {...{ sort, changeSort, disabled: isEmpty }} />
          </div>
        )}
        <div {...{ css: listContainerStyles }}>
          {isEmpty
            ? !!ListEmptyComponent && <ListEmptyComponent />
            : data.map((item, index) => {
                return (
                  !!RenderedItem && (
                    <RenderedItem
                      {...{ item, index, key: item?.id || index }}
                    />
                  )
                );
              })}
        </div>
      </div>
      {isPaginatorShown && !isEmpty && (
        <div {...{ css: paginatorContainerStyles }}>
          <CustomPaginator
            {...{
              pagination,
              changePage,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default List;
