import { ComponentProps, FC, useCallback } from 'react';
import { css } from '@emotion/react';
import { useFormContext } from 'react-hook-form';
import { useForm as useInertiaForm } from '@inertiajs/react';

import layout from '@/constants/layout';
import { getMetaToken } from '@/utils/meta-token';
import { PostEditJobFormValues } from '@/types/forms';
import { PostNewJobParams } from '@/types/api/params';
import { defaultToastSuccess } from '@/constants/toasts';
import { getParamValueFromForm } from '@/utils/form-data';
import ModalLayout from '../../../Layout/Modal/ModalLayout';
import { CancelNextButtons, FormHeader } from '@/components/Forms';
import EditJobPostForm from '@/components/Forms/EditJobPost/EditJobPostForm';

const URL = '/recruiters/jobs';

const {
  windowSizes: { tablet },
  utils: { scrollToTop },
} = layout;

interface RecruitersDashboardTileModalContentEditModeProps {
  modalProps: ComponentProps<typeof ModalLayout>;
  changeMode: () => void;
}

const contentContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
`;

const buttonsContainer = css`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
  }
`;

const RecruitersDashboardTileModalContentEditMode: FC<
  RecruitersDashboardTileModalContentEditModeProps
> = ({ changeMode }) => {
  const { patch, transform, processing } = useInertiaForm();

  const methods = useFormContext<PostEditJobFormValues>();

  const isRequesting = processing || methods.formState.isSubmitting;

  const onCancel = useCallback(() => {
    changeMode();
    scrollToTop();
  }, []);

  const onSaveChanges = useCallback(async (form: PostEditJobFormValues) => {
    try {
      const params = {
        job: {
          // DETAILS //
          title: form.job.title,
          //
          ...getParamValueFromForm('profession', form?.job?.profession),
          ...getParamValueFromForm(
            'employment_type',
            form?.job?.employment_type
          ),
          // employment_type: form.job.employment_type.value || 'all',
          ...getParamValueFromForm('assignment', form?.job?.assignment),
          ...getParamValueFromForm('facility', form?.job?.facility),
          ...getParamValueFromForm('specialty', form?.job?.specialty),
          //
          ...getParamValueFromForm(
            'board_certification',
            form?.job?.board_certification
          ),
          ...getParamValueFromForm('state', form?.job?.state),
          //
          min_hourly_rate: form.job.min_hourly_rate,
          ...getParamValueFromForm(
            'credentialing_time',
            form?.job?.credentialing_time
          ),
          target_start_date: form.job.target_start_date,
          ...getParamValueFromForm(
            'any_state_licenses',
            form.job.any_state_licenses
          ),
          // RECRUITERS ONLY
          reference_number: form.job.reference_number,
          facility_name: form.job.facility_name,
          city: form.job.city,
          notes: form.job.notes,
          // DESC AND REQS //
          description: form.job.description,
          requirements: form.job.requirements,
        },
      } as PostNewJobParams;

      const metaTokenHeader = await getMetaToken();
      if (!!metaTokenHeader) {
        patch(`${URL}/${form.job.id}`, {
          preserveState: true,
          data: transform(() => ({
            ...params,
          })),
          headers: {
            ...metaTokenHeader,
          },
          onSuccess: (successResponse) => {
            changeMode();
            defaultToastSuccess('', 'Your job has been updated');
          },
          onError: (errorResponse) => {
            console.log({ errorResponse });
          },
        });
      }
      return;
    } catch (error) {}
  }, []);

  return (
    <div
      {...{
        css: contentContainer,
        style: {
          pointerEvents: isRequesting ? 'none' : 'auto',
        },
      }}
    >
      <FormHeader
        {...{
          title: 'Edit Job Post',
          subtitle: 'Save changes in order to submit application.',
          type: 'gray',
        }}
      />
      <div>
        <EditJobPostForm {...{ methods }} />
      </div>
      <div {...{ css: buttonsContainer }}>
        <CancelNextButtons
          {...{
            cancelButtonProps: { onClick: onCancel },
            nextButtonProps: {
              children: ' Save Changes',
              onClick: methods.handleSubmit(onSaveChanges),
              icon: undefined,
              variant: '6',
            },
          }}
        />
      </div>
    </div>
  );
};

export default RecruitersDashboardTileModalContentEditMode;
