import { FC, ReactNode, memo } from 'react';
import { css } from '@emotion/react';

import CustomDivider from '../CustomDivider';
import { appColors } from '@/constants/colors';
import { paragraphDesktop } from '@/constants/typography';

const fieldsContainerStyles = css`
  display: grid;
  grid-auto-rows: 1fr;
  flex-basis: 100%;
`;

interface ApplyFormDetailProps {
  header: string;
  children: ReactNode;
}

const ApplyFormDetail: FC<ApplyFormDetailProps> = ({ children, header }) => {
  return (
    <div
      {...{
        css: css`
          display: flex;
          flex-direction: column;
          gap: 16px;
          width: 100%;
        `,
      }}
    >
      <label
        {...{
          css: css({
            width: '100%',
            ...paragraphDesktop.lg,
            fontWeight: 500,
            color: appColors.content.primary,
          }),
          children: header,
        }}
      />
      <CustomDivider />
      <div {...{ css: fieldsContainerStyles }}>{children}</div>
    </div>
  );
};

export default memo(ApplyFormDetail);
