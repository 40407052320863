import { memo } from 'react';
import { IndicatorsContainerProps, components } from 'react-select';

const IndicatorsContainer = ({ ...props }: IndicatorsContainerProps) => {
  const newChildren =
    Array.isArray(props.children) &&
    props.children.length > 0 &&
    props.children.reduce((accumulator, el) => {
      const name = el?.type?.name;
      if (name === 'DropdownIndicator') {
        accumulator.push(el);
      }

      return accumulator;
    }, []);

  return (
    <components.IndicatorsContainer {...{ ...props, children: newChildren }} />
  );
};

export default memo(IndicatorsContainer);
