import regex from '@/utils/regex';

const NO_NUMBER_NO_CHARS_MSG = 'cannot contain special characters or numbers.';
const LENGTH_MSG = 'must be between 2 and 50 characters long.';
export const PLS_ENTER_MSG = 'Please enter a ';

const fieldsDefaultRules: Record<string, any> = {
  email: {
    required: {
      value: true,
      message: 'Please enter your email address.',
    },
    pattern: {
      value:
        regex.regexExps.emailRegex || `${PLS_ENTER_MSG} valid email address.`,
    },
  },
  password: {
    required: {
      value: true,
      message: `${PLS_ENTER_MSG} password.`,
    },
    pattern: {
      value: regex.regexExps.passwordRegex,
    },
  },
  first_name: {
    required: {
      value: true,
      message: `${PLS_ENTER_MSG} first name.`,
    },
    validate: {
      noNumbersAndSpecialChars: async (value: string) =>
        regex.regexExps.noNumbersOrSpecialCharsRegex.test(value) ||
        `First name ${NO_NUMBER_NO_CHARS_MSG}`,
      length: async (value: string) =>
        regex.regexExps.lengthRegex.test(value) || `First name ${LENGTH_MSG}`,
    },
  },
  last_name: {
    required: {
      value: true,
      message: `${PLS_ENTER_MSG} last name.`,
    },
    validate: {
      noNumbersAndSpecialChars: async (value: string) =>
        regex.regexExps.noNumbersOrSpecialCharsRegex.test(value) ||
        `Last name ${NO_NUMBER_NO_CHARS_MSG}`,
      length: async (value: string) =>
        regex.regexExps.lengthRegex.test(value) || `Last name ${LENGTH_MSG}`,
    },
  },
  is_type: {
    required: true,
  },
  terms_accepted: {
    required: {
      value: 'Terms must be accepted to signup',
    },
  },
  state: {
    // required: true,
  },
  city: {
    // required: true,
    validate: {
      noNumbersAndSpecialChars: async (value: string) => {
        if (value) {
          return (
            regex.regexExps.noNumbersOrSpecialCharsRegex.test(value) ||
            `City ${NO_NUMBER_NO_CHARS_MSG}`
          );
        }
      },
      length: async (value: string) => {
        if (value) {
          return (
            regex.regexExps.lengthRegex.test(value) || `City ${LENGTH_MSG}`
          );
        }
      },
    },
  },
  zip: {
    // required: true,
    validate: {
      // zipCodeLength: async (value: string) =>
      //   regex.regexExps.zipLengthRegex.test(value) ||
      //   `ZIP code must be a 5-digit number.`,
      zipCodeValid: async (value: string) => {
        if (value) {
          return (
            regex.regexExps.zipValidRegex.test(value) ||
            `Invalid ZIP code. ${PLS_ENTER_MSG} valid 5-digit ZIP code.`
          );
        }
      },
    },
  },
  phone: {
    // required: true,
    pattern: {
      value: regex.regexExps.usPhoneRegex,
      message: `Invalid phone number. ${PLS_ENTER_MSG} valid phone number.`,
    },
  },
  title: {
    // required: true,
  },
  assignment_type: {
    // required: true,
  },
  specialty: {
    // required: true,
  },
  geographic_preferences: {
    // required: true,
  },
  state_licenses: {
    // required: true,
  },
  npi_state_license_number: {
    // required: true,
    pattern: {
      value: regex.regexExps.npiLengthRegex,
      message: `Invalid NPI number. ${PLS_ENTER_MSG} valid 10-digit NPI number.`,
    },
  },
  resume: {
    validate: (value: File) => {
      return !!!value?.name;
    },
  },
  profile_link: {
    // required: true,
    pattern: {
      value: regex.regexExps.linkedInProfileUrlRegex,
      message: `Invalid LinkedIn profile link. ${PLS_ENTER_MSG} valid URL.`,
    },
  },
  company: {
    required: true,
  },
  website: {
    required: true,
    validate: {
      websitePattern: async (value: string) => {
        console.log(value);
        if (value) {
          return (
            regex.regexExps.websiteRegex.test(value) ||
            `Invalid website URL. ${PLS_ENTER_MSG} valid URL.`
          );
        }
      },
    },
  },
  employer_type: {
    required: true,
  },
  message: {
    required: true,
  },
};

const isValidEmailAsync = async (email: string) => {
  return regex.isValidEmail(email);
};

export { isValidEmailAsync, fieldsDefaultRules };
