import { useCallback } from 'react';

import useAuthState from './useAuthState';
import usePageSession from './usePageSession';
import { useAppDispatch } from '@/storage/store';
import { logoutThunk } from '@/storage/auth/auth-actions';
import { router } from '@inertiajs/react';

const useLogout = () => {
  const { session } = usePageSession();
  const { signed_in, current_user } = session;

  const dispatch = useAppDispatch();
  const { isLoginOut } = useAuthState();

  const isAdmin = current_user?.is_type === 'admin';

  const canLogout = !!signed_in && !isAdmin;

  const logout = useCallback(async () => {
    if (current_user?.is_type) {
      const is_type = current_user?.is_type;
      const response = await dispatch(logoutThunk({ user: is_type })).unwrap();
      if (!!response) {
        if (
          window.location.pathname.includes('/providers') ||
          window.location.pathname.includes('/recruiters')
        ) {
          router.get('/');
        } else {
          window.location.reload();
        }
      }
    }
  }, [dispatch, signed_in, current_user]);

  return {
    actions: { logout },
    state: { isLoginOut, canLogout, signed_in, current_user, isAdmin },
  };
};

export default useLogout;
