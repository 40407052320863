import { FC, ReactNode, memo } from 'react';
import { css } from '@emotion/react';

import { appColors } from '@/constants/colors';

const container = css`
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 24px;
  background-color: ${appColors.background.secondary};
  border-radius: 16px;
`;

const headerContainer = css`
  display: flex;
  flex-direction: row;
`;

const detailsContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface RecruitersOnlyDetailsProps {
  header?: ReactNode;
  content?: ReactNode;
}

const RecruitersOnlyDetails: FC<RecruitersOnlyDetailsProps> = ({
  header,
  content,
}) => {
  return (
    <div {...{ css: container }}>
      {!!header && <div {...{ css: headerContainer, children: header }} />}
      {!!content && (
        <div
          {...{
            css: detailsContainer,
            children: content,
          }}
        />
      )}
    </div>
  );
};

export default memo(RecruitersOnlyDetails);
