import { memo, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  recoveryEmailThunk,
  recoveryEmailResendConfirmationThunk,
} from '@/storage/auth/auth-actions';
import { useAppDispatch } from '@/storage/store';
import useModalState from '@/hooks/useModalState';
import { RecoveryFlowState } from '@/types/types';
import { RecoveryEmailConfirmation } from '../Forms';
import ModalLayout from '../Layout/Modal/ModalLayout';
import { RecoveryEmailFormValues } from '@/types/forms';
import RecoveryEmailForm from '../Forms/RecoveryEmailForm';

const recoveryFlowInitialState: RecoveryFlowState = {
  mode: 'form',
};

const RecoveryEmailFlow = () => {
  const {
    actions: { close },
  } = useModalState();
  const [{ mode }, setState] = useState(recoveryFlowInitialState);
  const dispatch = useAppDispatch();

  const methods = useForm<RecoveryEmailFormValues>({
    defaultValues: {
      email: '',
    },
  });

  const {
    formState: { isSubmitting },
    setError,
  } = methods;

  const isFormMode = mode === 'form';

  const onSubmit = useCallback(async (form: RecoveryEmailFormValues) => {
    try {
      const response = await dispatch(recoveryEmailThunk(form)).unwrap();
      if (!!response) {
        setState((prevState) => {
          return {
            ...prevState,
            mode: 'check',
          };
        });
      }
    } catch (error) {
      setError(
        'email',
        {
          message:
            'The email address you entered is not recognized. Please try again.',
          type: 'manual',
        },
        {
          shouldFocus: true,
        }
      );
    }
  }, []);

  const resendAction = useCallback(async () => {
    const email = methods.watch('email');
    try {
      await dispatch(recoveryEmailResendConfirmationThunk({ email }));
    } catch (error) {}
  }, []);

  if (isFormMode) {
    return (
      <ModalLayout
        {...{
          closeModal: close,
          isLoading: isSubmitting,
          hasLogo: true,
        }}
      >
        <RecoveryEmailForm {...{ onSubmit, methods }} />
      </ModalLayout>
    );
  }

  const email = methods.watch('email');

  return (
    <ModalLayout
      {...{
        closeModal: close,
        isLoading: isSubmitting,
        hasLogo: true,
      }}
    >
      <RecoveryEmailConfirmation {...{ email, resendAction }} />
    </ModalLayout>
  );
};

export default memo(RecoveryEmailFlow);
