import { FC } from 'react';

import SigninFlow from './SigninFlow';
import SignupFlow from './SignupFlow';
import { AuthModalsMode } from '@/types/types';
import useModalState from '@/hooks/useModalState';
import RecoveryEmailFlow from './RecoveryEmailFlow';

// THIS IS FOR NAVIGATING BETWEEN SIGN IN, RECOVERY AND SIGN UP

const flowByModes: Record<AuthModalsMode, FC> = {
  signIn: SigninFlow,
  recovery: RecoveryEmailFlow,
  signUp: SignupFlow,
};

const SigninRecoverySignup: FC = () => {
  const {
    modals: {
      authModals: { mode },
    },
  } = useModalState();

  const CurrentFlow = flowByModes[mode];

  return <CurrentFlow />;
};

export default SigninRecoverySignup;
