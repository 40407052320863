import { FC } from 'react';
import styled from '@emotion/styled';

import CustomRadio from '../Radio/index';

const props = { label: 'Option' };

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const Radio: FC = () => {
  return (
    <Container>
      <CustomRadio {...props} />
      <CustomRadio {...{ ...props, disabled: true, checked: true }} />
      <CustomRadio {...{ ...props, disabled: true, checked: false }} />
    </Container>
  );
};

export default Radio;
