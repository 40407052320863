import { memo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { router } from '@inertiajs/react';

import { useAppDispatch } from '@/storage/store';
import useModalState from '@/hooks/useModalState';
import ModalLayout from '../Layout/Modal/ModalLayout';
import { ResourceType, UserType } from '@/types/types';
import { RecoveryPasswordFormValues } from '@/types/forms';
import { resetPasswordThunk } from '@/storage/auth/auth-actions';
import RecoveryPasswordForm from '../Forms/RecoveryPasswordForm ';

const RecoveryPasswordFlow = ({
  user,
  resource,
  reset_password_token,
}: {
  user: UserType;
  resource: ResourceType;
  reset_password_token: string;
}) => {
  const {
    actions: { close },
  } = useModalState();
  const dispatch = useAppDispatch();

  const methods = useForm<RecoveryPasswordFormValues>({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    mode: 'onChange',
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const onSubmit = useCallback(async (form: RecoveryPasswordFormValues) => {
    try {
      const response = await dispatch(
        resetPasswordThunk({
          params: { ...form, reset_password_token },
          user,
          resource,
        })
      );
      if (response.meta.requestStatus === 'fulfilled') {
        router.get('/');
        close();
        return;
      }
    } catch (error) {
      console.error('onSubmi', error);
    }
  }, []);

  return (
    <ModalLayout
      {...{
        closeModal: close,
        isLoading: isSubmitting,
        hasLogo: true,
      }}
    >
      <RecoveryPasswordForm {...{ onSubmit, methods }} />
    </ModalLayout>
  );
};
export default memo(RecoveryPasswordFlow);
