import { ComponentProps, FC } from 'react';
import { css } from '@emotion/react';

import layout from '@/constants/layout';
import { FormHeader } from '@/components/Forms';
import FakeField from '@/components/FakeField';
import useDropdownsData from '@/hooks/useDropdownsData';
import { openLink, openUri } from '@/utils/openExternal';
import AccountDetailsResetCard from './AccountDetailsResetCard';
import ModalLayout from '@/components/Layout/Modal/ModalLayout';
import { GetAdminProviderByIdResponse } from '@/types/api/responses';
import { fieldsContainer } from '@/components/Dashboard/MyDetails/_index';
import MyDetailsCardSubheader from '@/components/Dashboard/MyDetails/MyDetailsCardSubheader';

const {
  windowSizes: { tablet },
  components: { textWrap },
} = layout;

const subheaderFieldContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

interface AdminJobRowTileModalContentProps {
  modalLayoutProps: ComponentProps<typeof ModalLayout>;
  providerByIdResponse: GetAdminProviderByIdResponse;
}

const contentContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  @media (max-width: ${tablet}px) {
    gap: 16px;
  }
`;

const AdminJobRowTileModalContent: FC<AdminJobRowTileModalContentProps> = ({
  providerByIdResponse,
  modalLayoutProps,
}) => {
  const { dropdownsHashes } = useDropdownsData();

  const {
    title = '',
    assignment_type = '',
    specialty = '',
    state_licenses = '',
    geographic_preferences = '',
    npi_state_license_number = '',
  } = providerByIdResponse?.professional_detail || {};

  const hasTitle = !!title;
  const hastAssignmentType = !!assignment_type;
  const hasSpecialty = !!specialty;
  const hasStateLicense =
    Array.isArray(state_licenses?.length) && state_licenses?.length > 0;
  const hasGeoPref =
    Array.isArray(geographic_preferences) && geographic_preferences?.length > 0;
  const hasNpi = !!npi_state_license_number;

  return (
    <>
      <div {...{ css: contentContainer }}>
        <FormHeader {...{ title: 'Details' }} />
        <div>
          <AccountDetailsResetCard
            {...{
              user: providerByIdResponse,
              is_type: 'provider',
              modalLayoutProps,
            }}
          />
        </div>
        <div>
          <div
            {...{
              css: css`
                display: flex;
                flex-direction: column;
                gap: 24px;
              `,
            }}
          >
            <div>
              <MyDetailsCardSubheader
                {...{ subheader: 'Application Details' }}
              />
            </div>
            <div {...{ css: subheaderFieldContainer }}>
              <MyDetailsCardSubheader
                {...{ subheader: 'Contact Information', variant: 'md' }}
              />
              <div {...{ css: fieldsContainer }}>
                <FakeField
                  {...{
                    label: 'Location',
                    value: providerByIdResponse?.state || '',
                  }}
                />
                <FakeField
                  {...{
                    label: 'City',
                    value: providerByIdResponse?.city || '',
                  }}
                />

                <FakeField
                  {...{
                    label: 'ZIP Code',
                    value: providerByIdResponse?.zip || '',
                  }}
                />
                <FakeField
                  {...{
                    label: 'Phone Number',
                    value: providerByIdResponse?.phone || '',
                  }}
                />
              </div>
            </div>
            <div {...{ css: subheaderFieldContainer }}>
              <MyDetailsCardSubheader
                {...{ subheader: 'Professional Information', variant: 'md' }}
              />
              <div {...{ css: fieldsContainer }}>
                <FakeField
                  {...{
                    label: 'Title',
                    value: hasTitle
                      ? dropdownsHashes.profession?.[title]?.label
                      : '',
                  }}
                />
                <FakeField
                  {...{
                    label: 'Assignment Type',
                    value: hastAssignmentType
                      ? dropdownsHashes.assignment?.[assignment_type]?.label
                      : '',
                  }}
                />

                <FakeField
                  {...{
                    label: 'Specialty',
                    value: hasSpecialty
                      ? dropdownsHashes.specialty?.[specialty]?.label
                      : '',
                  }}
                />
                <FakeField
                  {...{
                    label: 'Geographic Preference',
                    value: hasGeoPref
                      ? geographic_preferences.join(', ')
                      : '' || '',
                  }}
                />

                <FakeField
                  {...{
                    label: 'State Licenses',
                    value: hasStateLicense
                      ? state_licenses.join(', ')
                      : '' || '',
                  }}
                />
                <FakeField
                  {...{
                    label: 'NPI Number',
                    value: hasNpi ? npi_state_license_number : '',
                  }}
                />
              </div>
            </div>
            <div {...{ css: subheaderFieldContainer }}>
              <MyDetailsCardSubheader {...{ subheader: 'CV & Linkedin' }} />
              <div {...{ css: fieldsContainer }}>
                <FakeField
                  {...{
                    label: 'CV',
                    ...(!!providerByIdResponse?.resume_information?.name
                      ? {
                          value: providerByIdResponse?.resume_information?.name,
                          onClickValue: () => {
                            openLink(
                              providerByIdResponse?.resume_information?.url
                            );
                          },
                        }
                      : { value: '' }),
                  }}
                />

                <FakeField
                  {...{
                    label: 'Linkedin Profile',
                    ...(!!providerByIdResponse?.profile_link
                      ? {
                          value: providerByIdResponse?.profile_link,
                          onClickValue: () => {
                            openLink(providerByIdResponse?.profile_link);
                          },
                        }
                      : { value: '' }),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminJobRowTileModalContent;
