const data = [
    {
      id: 1,
      name: "Brian C., Recruiter",
      message:
        "My favorite thing about locums.com is the transparency with the job rates, it cuts down on the back and forth and providers appreciate it. I also enjoy the ease of posting a single job or uploading several jobs at once. The job posting requirements of locums.com allow for better connections for both recruiter and provider because it cuts out the guess work.",    
    },
    {
      id: 2,
      name: "Maria S., Recruiter",
      message:
        "I’m so glad I came across this job board! We had a position we were struggling to identify the right candidates for. I utilize another pretty well known job board where I had had the same position posted for a few weeks. I decided to take a shot and post on Locums.com. Honestly, I didn’t have high hopes – if you’ve seen one job board… you’ve seen them all, right? Well, apparently not because I was able to gain traction almost immediately. We ended up identifying a candidate within a couple of days! And on top of that, I’ve found the features on this Locums.com to be incredibly well thought out. I really appreciate how easy it is to post and stay on top of jobs. Well worth it to invest some time in this resource!",    
    },
    {
      id: 3,
      name: "David T., Recruiter",
      message:
        "The dashboard feature is very nice, seeing how many views my jobs are getting tells me if I need to adjust my posting and figure out what is getting the best results.",
    },
    {
      id: 4,
      name: "Amanda R., Recruiter",
      message:
        "Locums.com has been a treasure trove and breath of fresh air.  The postings are simple and require information the providers deem important and it shows by the quality of providers I have had apply to my job postings!  My team alone has been able to place a full-time locum physician and connect with providers across multiple specialties for open jobs.",
    },
    {
      id: 5,
      name: "Charlotte W., Recruiter",
      message:
        "Locums.com has been a great job board for finding available candidates in my specialty.",
    },
  ];
  export default data;
  