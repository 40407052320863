import { ComponentProps, FC, memo } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import AsyncSelect from 'react-select/async';

import {
  INPUT_COMMONS,
  Input,
  StyledErrorMessage,
  StyledInputWrapper,
  StyledLabel,
} from '../Input';
import layout from '@/constants/layout';
import { appColors } from '@/constants/colors';
import Paragraph from '../Typography/Paragraph';
import { paragraphDesktop } from '@/constants/typography';

const {
  components: { iconStyles },
} = layout;

const BORDER_RADIUS_MENU = 10;
export const OPTION_HEIGHT = 80;
export const OPTION_PADDING = 16;

export interface InputDropdownAsyncProps
  extends Input,
    ComponentProps<typeof AsyncSelect> {
  readonly icon?: any;
  readonly labelHint?: string;
}

export const StyledInputDropdownAsync = styled(
  AsyncSelect
)<InputDropdownAsyncProps>`
  outline: none;
  width: 100%;
  border: none;
`;

const InputDropdownAsync: FC<InputDropdownAsyncProps> = ({
  label,
  labelHint,
  options,
  ...props
}) => {
  return (
    <StyledInputWrapper>
      <StyledLabel
        {...{
          children: label,
          style: {
            ...(props.isDisabled
              ? {
                  color: appColors.content.disabled,
                }
              : {}),
          },
        }}
      >
        {label}
        {!!labelHint && (
          <Paragraph
            {...{
              children: `  ${labelHint}`,
              variant: 'sm',
              style: {
                color: appColors.content.secondary,
                flexWrap: 'wrap',
              },
            }}
          />
        )}
      </StyledLabel>
      <StyledInputDropdownAsync
        {...{
          ...props,
          noOptionsMessage: () => 'Company not listed',
          styles: {
            container: (provided, {}) => ({
              ...provided,
              ...INPUT_COMMONS.TEXT_STYLE,
              padding: 0,
              margin: 0,
              outline: 0,
              border: `0 !important`,
            }),
            control: (provided, {}) => {
              return {
                ...provided,
                ...INPUT_COMMONS.TEXT_STYLE,
                height: INPUT_COMMONS.HEIGHT,
                backgroundColor: INPUT_COMMONS.BACKGROUND_COLOR,
                padding: 0,
                margin: 0,
                borderRadius: INPUT_COMMONS.BORDER_RADIUS,
                borderWidth: INPUT_COMMONS.BORDER_WIDTH,
                borderStyle: 'solid',
                borderColor: INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED,
                paddingRight: 8,
                '&:hover': {
                  outline: `0px !important`,
                },
                '&:focus': {
                  borderColor: INPUT_COMMONS.PLACEHOLDER_COLOR_FOCUS,
                },
                '&:disabled': {
                  borderColor: INPUT_COMMONS.BORDER_COLOR_DEFAULT_DISABLED,
                },
                ...(props.error
                  ? {
                      borderColor: INPUT_COMMONS.ERROR_COLOR,
                      '&:focus': {},
                    }
                  : {}),
              };
            },
            valueContainer: (provided) => ({
              ...provided,
              ...paragraphDesktop.md,
              padding: 0,
              margin: 0,
              paddingLeft: INPUT_COMMONS.PADDING,
            }),
            singleValue: (provided, { isDisabled }) => ({
              ...provided,
              ...paragraphDesktop.md,
              padding: 0,
              margin: 0,
              color: INPUT_COMMONS.TEXT_COLOR_DEFAULT,
              ...(isDisabled
                ? {
                    color: INPUT_COMMONS.TEXT_COLOR_DISABLED,
                  }
                : {}),
              ...(props.error
                ? {
                    color: INPUT_COMMONS.ERROR_COLOR,
                  }
                : {}),
            }),
            placeholder: (provided, { isDisabled }) => {
              return {
                ...provided,
                ...paragraphDesktop.md,
                color: INPUT_COMMONS.PLACEHOLDER_COLOR_DEFAULT,
                ...(isDisabled
                  ? {
                      color: INPUT_COMMONS.PLACEHOLDER_COLOR_DISABLED,
                    }
                  : {}),
                ...(props.error
                  ? {
                      color: INPUT_COMMONS.ERROR_COLOR,
                    }
                  : {}),
              };
            },
            option: (provided, { isSelected, isFocused }) => ({
              ...provided,
              ...paragraphDesktop.md,
              height: OPTION_HEIGHT,
              margin: 0,
              backgroundColor:
                isFocused || isSelected
                  ? appColors.notInDesignSystem[6]
                  : 'white',
              color: isSelected
                ? appColors.content.primary
                : appColors.content.secondary,
              cursor: 'pointer',
            }),
            menuList: (base) => ({
              ...base,
              display: 'flex',
              flexDirection: 'column',
              padding: 0,
              margin: 0,
              borderRadius: BORDER_RADIUS_MENU,
              maxHeight: OPTION_HEIGHT * 5,
            }),
            menu: (base) => ({
              ...base,
              display: 'flex',
              flexDirection: 'column',
              outline: 1,
              padding: 0,
              marginTop: 7,
              borderRadius: BORDER_RADIUS_MENU,
              border: `1px solid ${appColors.border.primary}`,
              boxShadow: '10px 10px 20px rgba(0, 0, 0, 0.08)',
              transition: 'background-color 0.2s ease',
            }),
            indicatorSeparator: (base) => ({
              ...base,
              display: 'none',
            }),
            dropdownIndicator: (base) => ({
              ...base,
              display: 'none',
            }),
          },
          components: {
            ...props.components,
            ...(!!props.icon
              ? {
                  DropdownIndicator: ({ isDisabled }) => {
                    const Icon = props.icon;
                    return (
                      <div
                        {...{
                          css: css`
                            display: flex;
                            ${iconStyles}
                            align-items: center;
                            justify-content: center;
                          `,
                        }}
                      >
                        <Icon
                          {...{
                            ...(isDisabled
                              ? { iconColor: appColors.content.disabled }
                              : {}),
                            ...(!!props.error
                              ? { iconColor: INPUT_COMMONS.ERROR_COLOR }
                              : {}),
                          }}
                        />
                      </div>
                    );
                  },
                }
              : {}),
          },
        }}
      />
      {!!props.error?.message && (
        <StyledErrorMessage
          {...{
            children: props.error?.message,
            style: {
              color: INPUT_COMMONS.ERROR_COLOR,
            },
          }}
        />
      )}
    </StyledInputWrapper>
  );
};

export default memo(InputDropdownAsync);
