import { SVGProps, useMemo } from 'react';

const IconShare = (props: SVGProps<SVGSVGElement>) =>
  useMemo(
    () => (
      <svg
        width={24}
        height={24}
        fill="none"
        viewBox="0 0 25 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g clipPath="url(#clip0_558_34260)">
          <path
            d="M16.527 4.876l-6.5 11.258m6.5-11.258l.483 16.445a.5.5 0 01-.836.385l-6.147-5.572m6.5-11.258l-14.07 8.047a.5.5 0 00.101.912l7.47 2.3"
            stroke="#05051B"
            strokeWidth={1.5}
            fill="none"
          />
        </g>
        <defs>
          <clipPath id="clip0_558_34260">
            <path fill="#fff" d="M0 0H24V24H0z" />
          </clipPath>
        </defs>
      </svg>
    ),
    [props]
  );

export { IconShare };
