//FUNCTIONALITY & HOOKS
import React from 'react';

//CSS
import { css } from '@emotion/react';

//ICONS
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Avatar from '@mui/material/Avatar';
import Brian from './images/recruiter-1.png';
import David from './images/recruiter-2.png';
import Maria from './images/recruiter-3.png';
import Charlotte from './images/recruiter-4.png';
import Amanda from './images/recruiter-5.png';

function Card(props: {
  alt: string;
  message: string;
  name: string;
}) {
  return (
    <div {...{ css: main }}>
      <div {...{ css: ClientCard }} style={{ display: 'flex' }}>
        <div {...{ css: HeaderContainer }}>
          <Avatar
            alt={props.name}
            src={
              props.name === 'Brian C., Recruiter'
                ? Brian
                : '' || props.name === 'Maria S., Recruiter'
                ? Maria
                : '' || props.name === 'David T., Recruiter'
                ? David
                : '' || props.name === 'Amanda R., Recruiter'
                ? Amanda
                : '' || props.name === 'Charlotte W., Recruiter'
                ? Charlotte
                : ''
            }
            {...{ css: AvatarIcon }}
          />

          <FormatQuoteIcon
            sx={{
              transform: 'rotate(180deg)',
              fontSize: 60,
              color: ' #d6dfff',
            }}
          />
        </div>
        <h1 {...{ css: CardTitle }}>Why Locums.com?</h1>
        <p style={{ marginTop: 25 }}>
          <span>&ldquo;</span>
          {props.message}
          <span>&rdquo;</span>
        </p>
        <p
          style={{
            alignSelf: 'center',
            marginRight: '3em',
            fontWeight: '600',
          }}
        >
          {' '}
          - {props.name}
        </p>
      </div>
    </div>
  );
}

export default Card;

const main = css`
  padding: 20px 60px;
  background: #fff;
`;

/* Cards */
const ClientCard = css`
  color: gray !important;
  align-items: flex-start !important;
  flex-direction: column !important;
  font-family: Inter;
  //text-align: center !important;
`;

const HeaderContainer = css`
  display: flex;
  justify-content: flex-start;
  text-align: left !important;
`;

const AvatarIcon = css`
  width: 80px !important;
  height: 80px !important;
`;

const CardTitle = css`
  align-self: center;
  margin: 0 auto;
`;
