import { CSSProperties, FC, useCallback } from 'react';
import styled from '@emotion/styled';

import { IconArrowRight } from '../Icons/Arrow';
import { paragraphDesktop } from '@/constants/typography';

const SIZE = 24;

type BreadcrumbProps = {
  readonly label: string;
  readonly url: string;
  readonly isActive: boolean;
};

interface BreadcrumbsProps {
  readonly links: BreadcrumbProps[];
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: ${SIZE}px;
  align-items: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${SIZE}px;
`;

const LinkText = styled.a`
  text-decoration: none;
  color: inherit;
`;

const Breadcrumb: FC<BreadcrumbProps> = ({ label, url, isActive }) => {
  const onPressLink = useCallback(() => {
    //NAVIGATE
  }, []);

  const disabledStyles: CSSProperties = {};

  return (
    <LinkText
      {...{
        ...(isActive ? { onClick: onPressLink, href: url } : {}),
        children: label,
        style: {
          ...paragraphDesktop.md,
          ...(isActive ? {} : disabledStyles),
        },
      }}
    />
  );
};

const Breadcrumbs: FC<BreadcrumbsProps> = ({ links }) => {
  return (
    <Container>
      {links.map((breadcrumb, index) => {
        return (
          <InnerContainer
            {...{
              key: index,
              style: {},
            }}
          >
            <Breadcrumb {...breadcrumb} />
            <IconArrowRight {...{ width: SIZE, height: SIZE }} />
          </InnerContainer>
        );
      })}
    </Container>
  );
};

export default Breadcrumbs;
