import { FC, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  FieldsContainer,
  FormButtonContainer,
  FormContainer,
  FormHeader,
  RowContainer,
} from '..';
import Input from '../../Input';
import Button from '../../Button';
import Checkbox, { StyledLabel } from '../../Checkbox';
import { AuthModalsMode } from '@/types/types';
import { SignupFormValues } from '@/types/forms';
import { useAppDispatch } from '@/storage/store';
import LinkMessage from '@/components/LinkMessage';
import { SelectButtonProps } from '../../SelectButton';
import { fieldsDefaultProps } from '@/constants/form-fields';
import { setAuthModalMode } from '@/storage/modal/ModalSlice';
import PasswordRequirements from '../../PasswordRequirements';
import { fieldsDefaultRules } from '@/constants/form-validations';
import SelectButtonInput from '../../SelectButton/SelectButtonInput';
import Link from '@/components/Typography/Link';
import { router } from '@inertiajs/react';
import styled from '@emotion/styled';
import { paragraphDesktop } from '@/constants/typography';
import { appColors } from '@/constants/colors';

const OPTIONS: SelectButtonProps[] = [
  {
    children: 'Physician',
  },
  {
    children: 'CRNA, PA, or NP',
  },
  {
    children: 'Recruiter',
  },
];

const StyledLink = styled(Link)
({  ...paragraphDesktop.md,
  color: appColors.content.secondary
});

const SignupForm: FC<{
  onSubmit: (form: SignupFormValues) => void;
  methods: UseFormReturn<SignupFormValues, any>;
}> = ({ methods, onSubmit }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
    watch,
  } = methods;
  const [passwordEngaged, setPasswordEngaged] = useState(false);
  const passwordField = watch('password');

  const dispatch = useAppDispatch();

  const goToMode = useCallback(
    (mode: AuthModalsMode) => {
      dispatch(setAuthModalMode(mode));
    },
    [dispatch]
  );

  return (
    <form>
      <FormContainer
        {...{ style: { ...(isSubmitting ? { pointerEvents: 'none' } : {}) } }}
      >
        <FormHeader {...{ title: 'Create an account' }} />
        <FieldsContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'is_type',
                control,
                rules: { ...fieldsDefaultRules['is_type'] },
                render: ({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <SelectButtonInput
                      {...{
                        ...fieldsDefaultProps['is_type'],
                        value,
                        onChange,
                        error,
                        options: OPTIONS,
                      }}
                    />
                  );
                },
              }}
            />
          }
          <RowContainer>
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'first_name',
                  control,
                  rules: { ...fieldsDefaultRules['first_name'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['first_name'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
            {
              //@ts-ignore
              <Controller
                {...{
                  name: 'last_name',
                  control,
                  rules: { ...fieldsDefaultRules['last_name'] },
                  render: ({ field, fieldState: { error } }) => {
                    return (
                      <Input
                        {...{
                          ...fieldsDefaultProps['last_name'],
                          ...field,
                          id: field.name,
                          error,
                        }}
                      />
                    );
                  },
                }}
              />
            }
          </RowContainer>
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'email',
                control,
                rules: { ...fieldsDefaultRules['email'] },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['email'],
                        ...field,
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'password',
                control,
                rules: { ...fieldsDefaultRules['password'] },
                render: ({ field, fieldState: { error } }) => {
                  const isPasswordRequirementsShown =
                    passwordEngaged || (!passwordEngaged && field.value);

                  return (
                    <div
                      {...{
                        css: css`
                          display: flex;
                          flex-direction: column;
                          gap: 16px;
                        `,
                      }}
                    >
                      <Input
                        {...{
                          ...fieldsDefaultProps['password'],
                          ...field,
                          error,
                          id: field.name,
                          onFocus: () => {
                            setPasswordEngaged(true);
                          },
                          onBlur: () => {
                            setPasswordEngaged(false);
                            field.onBlur();
                          },
                          autoComplete: 'new-password',
                        }}
                      />
                      {isPasswordRequirementsShown && (
                        <PasswordRequirements {...{ value: field.value }} />
                      )}
                    </div>
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'password_confirmation',
                control,
                rules: {
                  validate: (value) => {
                    return value === passwordField || 'Passwords do not match.';
                  },
                },
                render: ({ field, fieldState: { error } }) => {
                  return (
                    <Input
                      {...{
                        ...fieldsDefaultProps['password_confirmation'],
                        ...field,
                        id: field.name,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
          {
            //@ts-ignore
            <Controller
              {...{
                name: 'terms_accepted',
                control,
                rules: { required: true },
                render: ({ field, fieldState: { error } }) => {
                  return (
                     //@ts-ignore
                    <Checkbox
                      {...{
                        ...field,
                        label: <StyledLabel>I accept Locums.com's <StyledLink onClick={() => router.get('/terms')}>terms and conditions</StyledLink></StyledLabel>,
                        error,
                      }}
                    />
                  );
                },
              }}
            />
          }
        </FieldsContainer>
        <FormButtonContainer>
          <Button
            {...{
              variant: '1',
              children: 'Continue',
              style: { width: '100%' },
              onClick: handleSubmit(onSubmit),
              disabled: !isValid || isSubmitting,
            }}
          />
        </FormButtonContainer>
        <LinkMessage
          {...{
            msg: 'Already have an account?  ',
            link: {
              children: 'Sign in.',
              onClick: () => goToMode('signIn'),
            },
          }}
        />
      </FormContainer>
    </form>
  );
};

export default SignupForm;
