import { useCallback, useEffect, useReducer, useState } from 'react';
import { css } from '@emotion/react';
import { router, useForm as useInertiaForm } from '@inertiajs/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  AdminJobColumnIds,
  adminJobsCustomDatatableConfig,
} from '@/constants/datatables/admin-jobs';
import Modal from '@/components/Modal';
import { SessionProps } from '@/types/page';
import { AdminJob } from '@/types/entities.d';
import { getMetaToken } from '@/utils/meta-token';
import { DataTable, SortKey } from '@/types/types';
import CustomPaginator from '@/components/Paginator';
import SearchBar from '@/components/Dashboard/SearchBar';
import NavButton from '@/components/Dashboard/NavButton';
import { getParamValueFromForm } from '@/utils/form-data';
import { AdminJobsFilterFormValues } from '@/types/forms';
import CustomDataTable from '@/components/Table/DataTable';
import { useUrlSearchParams } from 'use-url-search-params';
import FiltersDropdown from '@/components/FiltersDropdown';
import { transformQueryParams } from '@/utils/query-params';
import { transformSortingValues } from '@/utils/data-table';
import NoResultsFound from '@/components/List/NoResultsFound';
import { datatableUtils } from '@/constants/datatables/_index';
import { AdminPanelLayout } from '@/components/AdminPanel/_index';
import { initialState, reducer } from '@/constants/contexts/datatable-jobs';
import AdminJobsFilterForm from '@/components/Forms/Admin/AdminJobsFilterForm';
import DeleteAdminJobsButton from '@/components/DeleteRows/DeleteAdminJobsButton';
import AdminJobRowTileModal from '@/components/AdminPanel/Jobs/AdminJobRowTileModal';
import AdminPanelContentLayout from '@/components/AdminPanel/Layout/AdminPanelContentLayout';
import RowsPerPageButton from '@/components/RowsPerPageButton';

const URL = '/admins/jobs';

type AdminJobsPageProps = DataTable<AdminJob> & SessionProps;

const AdminJobsTable = CustomDataTable<AdminJob>;

export default function Jobs(props: AdminJobsPageProps) {
  const { get: getJobs, patch, transform, processing } = useInertiaForm();

  const [params, setParams] = useUrlSearchParams({});
  const urlParams = params as AdminJobsFilterFormValues;
  const parsedParams =
    transformQueryParams<AdminJobsFilterFormValues>(urlParams);

  // LOCAL STATE
  const [{ modal }, localDispatch] = useReducer(reducer, {
    ...initialState,
  });
  // INITAL STATES
  const initialRowSelection = {};
  const initialSorting = [
    {
      id: '',
      desc: true,
    },
  ];
  const initialFilterValues: AdminJobsFilterFormValues = {
    q: {
      title_cont: parsedParams?.q?.title_cont || '',
      state_in: '',
      company_name_cont: '',
      posted_at_eq: '',
    },
    professions: '',
    specialties: '',
    per: parsedParams?.per || 10,
    sort: parsedParams?.sort || '',
    page: parsedParams?.page || 1,
  };
  //

  // UI TABLE CONTROLS
  const [rowSelection, setRowSelection] = useState(initialRowSelection);
  const [sorting, setSorting] = useState(initialSorting);

  // FORM STATE
  const methods = useForm<AdminJobsFilterFormValues>({
    defaultValues: {
      ...initialFilterValues,
    },
    shouldUnregister: false,
  });

  // CONSTANTS
  const { isTileModalOpen, clickedTile } = modal;
  const { control, handleSubmit, setValue, reset } = methods;
  const { collection, pagination } = props;
  //
  const rowSelected = Object.keys(rowSelection).map((id) => {
    return collection[parseInt(id)];
  });

  const isEmpty = collection.length === 0;

  //

  const onCloseTileModal = useCallback(() => {
    localDispatch({ type: 'CLOSE_TILE_MODAL', payload: null });
  }, [localDispatch]);

  const onOpenTileModal = useCallback(
    (job: AdminJob) => {
      localDispatch({ type: 'OPEN_TILE_MODAL', payload: job });
    },
    [localDispatch]
  );

  const triggerSearch = useCallback(async (form: AdminJobsFilterFormValues) => {
    const hasSearchValue = !!form?.q?.title_cont;
    const hasSort = !!form?.sort;
    const isPageDiffOne = !!form?.page && form.page > 1;
    const isPerDiffTen = !!form?.per && form.per !== 10;

    const params = {
      q: {
        ...(hasSearchValue ? { company_name_cont: form?.q?.title_cont } : {}),
        ...getParamValueFromForm('state_in', form?.q?.state_in),
        ...getParamValueFromForm(
          'company_name_cont',
          form?.q?.company_name_cont
        ),
        ...getParamValueFromForm('posted_at_eq', form?.q?.posted_at_eq),
      },
      //
      ...getParamValueFromForm('professions', form?.professions),
      ...getParamValueFromForm('specialties', form?.specialties),
      //
      ...(hasSort ? { sort: form.sort } : {}),
      ...(isPageDiffOne ? { page: form.page } : {}),
      ...(isPerDiffTen ? { per: form.per } : {}),
    };

    const metaTokenHeader = await getMetaToken();
    if (!!metaTokenHeader) {
      getJobs(URL, {
        preserveState: true,
        data: transform(() => ({
          ...params,
        })),
        headers: {
          ...metaTokenHeader,
        },
      });
    }
  }, []);

  const onRowClicked = useCallback((job: AdminJob) => {
    onOpenTileModal(job);
  }, []);

  const changePage = useCallback(async (page: number) => {
    setValue('page', page, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(triggerSearch)();
    setRowSelection(initialRowSelection);
  }, []);

  const submitSearch = useCallback(async () => {
    changePage(1);
  }, [changePage]);

  const goToPage = useCallback((url: string) => {
    router.get(url, {}, { preserveScroll: false, preserveState: true });
  }, []);

  const onResetFilters = useCallback(() => {
    reset({
      ...initialFilterValues,
    });
    changePage(1);
  }, [triggerSearch]);

  const onApplyilters = useCallback(() => {
    changePage(1);
  }, [submitSearch]);

  const resetRows = useCallback(() => {
    setRowSelection(initialRowSelection);
  }, [initialRowSelection]);

  // EFFECT FOR SORTING
  useEffect(() => {
    const sortValue = transformSortingValues(
      sorting
    ) as SortKey<AdminJobColumnIds>;
    setValue('sort', sortValue);
    changePage(1);
    return () => {};
  }, [sorting]);

  // EFFECT FOR ROWS PER PAGE
  const pageSizes = [10, 25, 50, 100] as number[];

  const rowsPerPage = useCallback(async (per: 10 | 25 | 50 | 100) => {
    setValue('per', per, {
      shouldDirty: false,
      shouldTouch: false,
      shouldValidate: false,
    });
    handleSubmit(triggerSearch)();
    setRowSelection(initialRowSelection);
  }, []);

  const handlePageSizeChange = (pageSize: number) => {
    const allowedPageSizes = [10, 25, 50, 100];
    if (allowedPageSizes.includes(pageSize)) {
      rowsPerPage(pageSize as 10 | 25 | 50 | 100);
      changePage(1);
    }
  };

  return (
    <div {...{ style: { pointerEvents: processing ? 'none' : 'auto' } }}>
      <AdminPanelLayout>
        <FormProvider {...methods}>
          <AdminPanelContentLayout
            {...{
              pageHeader: 'Jobs',
              searchComponent: (
                //@ts-ignore
                <Controller
                  {...{
                    name: 'q.title_cont',
                    control,
                    rules: {},
                    render: ({ field }) => {
                      return (
                        <SearchBar
                          {...{
                            placeholder: 'Search all jobs',
                            ...field,
                            searchIconAction: submitSearch,
                          }}
                        />
                      );
                    },
                  }}
                />
              ),
              navButtonsComponent: (
                <>
                  <NavButton
                    {...{
                      children: 'All Jobs',
                      isActive: true,
                      onClick: () => goToPage('/admins/jobs'),
                    }}
                  />
                </>
              ),
              optionsComponent: (
                <>
                  <div>
                    <RowsPerPageButton
                      {...{
                        label: 'rows per page',
                        pageSize: pageSizes,
                        onClick: handlePageSizeChange,
                      }}
                    />
                  </div>
                  <div>
                    <DeleteAdminJobsButton
                      {...{
                        resetRows,
                        rowSelectedIds: rowSelected.map(({ id }) => id),
                      }}
                    />
                  </div>
                  <div>
                    <FiltersDropdown
                      {...{
                        form: AdminJobsFilterForm,
                        resetAction: handleSubmit(onResetFilters),
                        applyAction: handleSubmit(onApplyilters),
                      }}
                    />
                  </div>
                </>
              ),
              tableComponent: isEmpty ? (
                <>
                  <NoResultsFound
                    {...{
                      header: datatableUtils.EMPTY_HEADER,
                      subheader: datatableUtils.EMPTY_SUBHEADER,
                    }}
                  />
                </>
              ) : (
                <>
                  <div
                    {...{
                      css: css`
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        gap: 10px;
                        overflow: auto;
                      `,
                    }}
                  >
                    <AdminJobsTable
                      {...{
                        data: collection,
                        sorting,
                        rowSelection,
                        onClickRow: onRowClicked,
                        onSortingChange: setSorting,
                        onRowSelectionChange: setRowSelection,
                        columns: adminJobsCustomDatatableConfig.columns,
                      }}
                    />
                  </div>
                  <CustomPaginator
                    {...{
                      pagination,
                      changePage,
                    }}
                  />
                  {isTileModalOpen && (
                    <Modal {...{ position: 'top', onClose: onCloseTileModal }}>
                      {!!clickedTile && (
                        <AdminJobRowTileModal
                          {...{
                            modalProps: { closeModal: onCloseTileModal },
                            job: clickedTile,
                          }}
                        />
                      )}
                    </Modal>
                  )}
                </>
              ),
            }}
          />
        </FormProvider>
      </AdminPanelLayout>
    </div>
  );
}
